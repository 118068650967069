import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Loader';
import CostMtd from './Mtd';
import CostCompare from './Compare';
import Alert from '../../Common/Alert';
import DetailedCost from './DetailedCost';
import CloudabilityData from './CloudabilityData';
import { getAccountCost } from '../../../store/actions/accountAction';

const AccountCostTab = (props) => {
  const { accountId } = props;
  const [activeMtd, setActiveMtd] = useState(true);
  const [activeCompare, setActiveCompare] = useState(false);
  const [activeDetailed, setActiveDetailed] = useState(false);
  const [activeData, setActiveData] = useState(false);

  const [loaded, setLoaded] = useState(false);
  const [cumulativeMtdSpend, setCumlMtdSpend] = useState([]);
  const [details, setDetails] = useState([]);
  const [estimatedSpend, setEstSpend] = useState(0);
  const [previousMonthFinalized, setPrvMonthFinalized] = useState(false);
  const [previousMonthSpend, setPrvMonthSpend] = useState(0);
  const [currentDate, setCurrentDate] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [suspendedErrMsg, setSusErrMsg] = useState(undefined);

  const dispatch = useDispatch();
  const accountSpecificData = useSelector((state) => state.accountProfile);
  const { errCost, accountCost } = accountSpecificData;

  useEffect(() => {
    dispatch(getAccountCost(`/api/account/${accountId}/Cost`));
  }, [dispatch]);

  useEffect(() => {
    if (Object.keys(accountCost).length > 0) {
      const { result } = accountCost || {};
      const {
        estimatedSpend,
        previousMonthSpend,
        previousMonthFinalized,
        currentDate,
        details,
        cumulativeMtdSpend,
      } = result || {};
      if (result) {
        setCumlMtdSpend(cumulativeMtdSpend);
        setDetails(details);
        setEstSpend(Math.round(estimatedSpend * 100) / 100);
        setPrvMonthFinalized(previousMonthFinalized);
        setPrvMonthSpend(Math.round(previousMonthSpend * 100) / 100);
        setCurrentDate(currentDate);
      } else {
        setSusErrMsg('No Cloudability Cost data is available to graph.');
      }
      setLoaded(true);
    }
  }, [accountCost]);

  useEffect(() => {
    if (errCost !== '') {
      setErrorMessage(errCost);
      setLoaded(true);
    }
  }, [errCost]);

  function collapseSection(button) {
    switch (button) {
      case 'mtd':
        setActiveMtd(!activeMtd);
        setActiveCompare(false);
        setActiveDetailed(false);
        setActiveData(false);
        break;
      case 'compare':
        setActiveCompare(!activeCompare);
        setActiveMtd(false);
        setActiveDetailed(false);
        setActiveData(false);
        break;
      case 'detailed':
        setActiveDetailed(!activeDetailed);
        setActiveCompare(false);
        setActiveMtd(false);
        setActiveData(false);
        break;
      case 'data':
        setActiveData(!activeData);
        setActiveDetailed(false);
        setActiveCompare(false);
        setActiveMtd(false);
        break;
      default:
        return '';
    }
    return '';
  }

  return (
    <>
      {loaded ? (
        <>
          {errorMessage && (
            <div className="col-12">
              <Alert
                message={errorMessage}
                alertClass="light-danger"
                onClose={() => setErrorMessage(undefined)}
              />
            </div>
          )}
          {suspendedErrMsg && (
            <div className="col-12">
              <Alert message={suspendedErrMsg} alertClass="info" />
            </div>
          )}
          {errorMessage === '' && !suspendedErrMsg && (
            <>
              {currentDate && (
                <div className="row justify-content-center justify-content-end-xs">
                  <div className="col-md-4 col-sm-4 col-12">
                    <div className="form-group">
                      <strong>Last Month: </strong>
                      <span>
                        {previousMonthFinalized ? (
                          <FontAwesomeIcon
                            className="success-font"
                            icon={faThumbsUp}
                          />
                        ) : (
                          ''
                        )}
                        {' '}
                        $
                        {previousMonthSpend.toLocaleString()}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-5 col-sm-5 col-12">
                    <div className="form-group">
                      <strong>This Month (estimated): </strong>
                      <span>
                        $
                        {estimatedSpend.toLocaleString()}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3 col-sm-3 col-12">
                    <div className="form-group">
                      <strong>As Of: </strong>
                      <span>{currentDate}</span>
                    </div>
                  </div>
                </div>
              )}
              <div className="tab-pane active" id="messages-v">
                <div className="accordion" id="accordion">
                  <CostMtd
                    isOpen={activeMtd}
                    onClick={(e) => collapseSection(e)}
                    asOf={currentDate}
                    accountId={accountId}
                    summaryData={cumulativeMtdSpend}
                    detailData={details}
                  />
                  <CostCompare
                    isOpen={activeCompare}
                    onClick={(e) => collapseSection(e)}
                    asOf={currentDate}
                    accountId={accountId}
                    data={details}
                  />
                  <DetailedCost
                    isOpen={activeDetailed}
                    onClick={(e) => collapseSection(e)}
                    asOf={currentDate}
                    accountId={accountId}
                    data={details}
                  />
                  <CloudabilityData
                    isOpen={activeData}
                    onClick={(e) => collapseSection(e)}
                    data={details}
                    accountId={accountId}
                  />
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

AccountCostTab.propTypes = {
  accountId: PropTypes.string.isRequired,
};

export default AccountCostTab;
