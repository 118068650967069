import React from 'react';

const PostEventSummriesInfo = () => (
  <div className="row">
    <div className="col-12">
      <h4 className="custom-tooltip-text">
        Click
        {' '}
        <strong>Read More</strong>
        {' '}
        option to open the full summary from AWS
      </h4>
    </div>
  </div>
);

export default PostEventSummriesInfo;
