import React, { useState } from 'react';

const Accordion = ({
  eventKey, title, children, defaultOpen,
}) => {
  const targetId = `collapse-${eventKey}`;
  const headingId = `collapse-${eventKey}`;
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion" id="accordion">
      <div className={`card border-0 ${isOpen ? 'card-active' : ''}`}>
        <div className="card-header card-header2 pr-5" id={headingId}>
          <h5 className="mb-0">
            <button
              className={`btn btn-link position-relative ${isOpen ? '' : 'collapsed'}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#${targetId}`}
              aria-expanded="true"
              aria-controls={targetId}
              onClick={() => toggleAccordion(eventKey)}
            >
              {title}
            </button>
          </h5>
        </div>
        <div
          id={targetId}
          className={`accordion-collapse collapse ${isOpen ? 'show' : ''}`}
          aria-labelledby={headingId}
          data-parent="#accordion"
        >
          <div className="card-body px-0">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
