import axios from 'axios';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ApiTypeAhead from '../Common/ApiTypeAhead';
import ApiDropDown from '../Common/ApiDropdown';
import Alert from '../Common/Alert';

const EditEmployeeModal = (props) => {
  const { onCancel } = props;
  const [trainingLevel, setTrainingLevel] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);
  const [modalAlertMsg, setModalAlertMsg] = useState('');
  const [messageClass, setMessageClass] = useState('success');

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    // this field updates twice, and we want to grab the one that contains employee ID to reference in RDS
    if (name === 'employeeName' && /\d/.test(value)) {
      setEmployeeId(value);
    }
    if (name === 'trainingLevel') {
      setTrainingLevel(value);
    }
  };

  const updateTraining = () => {
    if (employeeId === null || trainingLevel === null) {
      setModalAlertMsg('Employee Name and Training Level need to be selected.');
      setMessageClass('light-danger');
      return;
    }

    axios
      .get(
        `/api/people/OverrideTraining?employeeId=${employeeId}&trainingLevel=${trainingLevel}`,
      )
      .then(() => {
        setModalAlertMsg('Successfully Updated!');
        setMessageClass('success');
        setTrainingLevel(null);
        setEmployeeId(null);
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          setModalAlertMsg(err.message);
          setMessageClass('light-danger');
        }
      });
  };

  const removeTraining = () => {
    if (employeeId === null || trainingLevel === null) {
      setModalAlertMsg('Employee Name and Training Level need to be selected.');
      setMessageClass('light-danger');
      return;
    }

    axios
      .get(
        `/api/people/RemoveTraining?employeeId=${employeeId}&trainingLevel=${trainingLevel}`,
      )
      .then(() => {
        setModalAlertMsg('Successfully Removed!');
        setMessageClass('success');
        setTrainingLevel(null);
        setEmployeeId(null);
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          setModalAlertMsg(err.message);
          setMessageClass('light-danger');
        }
      });
  };

  return (
    <div
      className="modal fade editEmployeeTraining show"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      style={{ display: 'block' }}
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header px-4">
            <h4 className="modal-title" id="exampleModalLabel">
              Edit Employee Training
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => onCancel(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <form className="form">
              <div className="row">
                {modalAlertMsg && (
                  <div className="col-12">
                    <Alert
                      message={modalAlertMsg}
                      alertClass={messageClass}
                      onClose={(val) => setModalAlertMsg(val)}
                    />
                  </div>
                )}
                <div className="col-12">
                  <div className="form-group">
                    <ApiTypeAhead
                      name="employeeName"
                      defaultId={null}
                      displayKey="employeeName"
                      query={null}
                      title="Employee Name"
                      placeholder="Type to search"
                      url="/api/lookup/people"
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="form-group">
                    <ApiDropDown
                      name="trainingLevel"
                      value={trainingLevel}
                      placeholder="Pick One..."
                      title="Training Level"
                      url="/api/people/TrainingLevelsWithCloudDescription"
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer px-4">
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
              onClick={updateTraining}
            >
              Add
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={removeTraining}
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

EditEmployeeModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
};

export default EditEmployeeModal;
