import {
  GET_VN_COMPUTE_INST,
  ERROR_VN_COMPUTE_INST,
  GET_VN_COMPUTE_SRVR,
  ERROR_VN_COMPUTE_SRVR,
  GET_VN_COMPUTE_DB,
  ERROR_VN_COMPUTE_DB,
  GET_VN_COMPUTE_CONTR,
  ERROR_VN_COMPUTE_CONTR,
} from '../types';

const initialState = {
  compInstance: [],
  compInstanceLoading: true,
  errCompInstance: '',
  compServerless: [],
  compServerlessLoading: true,
  errCompServerless: '',
  compDatabases: [],
  compDatabasesLoading: true,
  errCompDatabases: '',
  compContainer: [],
  compContainerLoading: true,
  errCompContainer: '',
};

/* eslint-disable func-names */
export default function (state = initialState, action) {
  /* eslint-enable */
  switch (action.type) {
    case GET_VN_COMPUTE_INST:
      return {
        ...state,
        compInstance: action.payload,
        compInstanceLoading: false,
      };
    case ERROR_VN_COMPUTE_INST:
      return {
        ...state,
        compInstanceLoading: false,
        errCompInstance: action.payload.message,
      };
    case GET_VN_COMPUTE_SRVR:
      return {
        ...state,
        compServerlessLoading: false,
        compServerless: action.payload,
      };
    case ERROR_VN_COMPUTE_SRVR:
      return {
        ...state,
        compServerlessLoading: false,
        errCompServerless: action.payload.message,
      };
    case GET_VN_COMPUTE_DB:
      return {
        ...state,
        compDatabases: action.payload,
        compDatabasesLoading: false,
      };
    case ERROR_VN_COMPUTE_DB:
      return {
        ...state,
        compDatabasesLoading: false,
        errCompDatabases: action.payload.message,
      };
    case GET_VN_COMPUTE_CONTR:
      return {
        ...state,
        compContainer: action.payload,
        compContainerLoading: false,
      };
    case ERROR_VN_COMPUTE_CONTR:
      return {
        ...state,
        compContainerLoading: false,
        errCompContainer: action.payload.message,
      };
    default: return state;
  }
}
