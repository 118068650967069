import React from 'react';

const AccountScoreCard = (props) => {
  const { name, value, showProg } = props;

  // for convert number to thousand seperator
  const successfulVal = value.successful
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const totalVal = value.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const { score } = value;

  // for progressbar color
  const getColor = (percent) => {
    if (percent < 77) {
      return 'danger';
    }
    if (percent < 85) {
      return 'warning';
    }
    if (percent < 93) {
      return 'info';
    }
    return 'success';
  };

  return (
    <div className="col-xl-3 col-md-6 col-sm-6 col-12 mb-4">
      <div className="card border-left-primary shadow h-100">
        <div className="card-body p-6">
          <div className="row no-gutters align-items-center">
            <div className="col">
              <span className="svg-icon svg-icon-primary svg-icon-2x">
                {/* <!--begin::Svg Icon --> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                  >
                    <rect x="0" y="0" width="24" height="24" />
                    <rect
                      fill="#000000"
                      opacity="0.3"
                      x="13"
                      y="4"
                      width="3"
                      height="16"
                      rx="1.5"
                    />
                    <rect
                      fill="#000000"
                      x="8"
                      y="9"
                      width="3"
                      height="11"
                      rx="1.5"
                    />
                    <rect
                      fill="#000000"
                      x="18"
                      y="11"
                      width="3"
                      height="9"
                      rx="1.5"
                    />
                    <rect
                      fill="#000000"
                      x="3"
                      y="13"
                      width="3"
                      height="7"
                      rx="1.5"
                    />
                  </g>
                </svg>
                {/* <!--end::Svg Icon--> */}
              </span>
              <div className="text-xs font-weight-normal text-primary text-uppercase my-2">
                {name}
              </div>
              {showProg && (
                <div className="progress my-3">
                  <div
                    className={`progress-bar badge-${getColor(score)}`}
                    role="progressbar"
                    style={{ width: `${score}%` }}
                    aria-valuenow={score}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {score}
                    %
                  </div>
                </div>
              )}
              <div className="h5 mb-0 font-weight-normal text-gray-800 text-xs">
                {`${successfulVal} out of ${totalVal}`}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountScoreCard;
