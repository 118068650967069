import axios from 'axios';
import {
  GET_ACC_INFO,
  GET_ACC_COST,
  ERROR_ACC_COST,
  ERROR_ACC_INFO,
  GET_ACC_WITH_ROLE,
  ERROR_ACC_WITH_ROLE,
  GET_ACC_COMPLIANCE,
  ERROR_ACC_COMPLIANCE,
  GET_ACC_NETWORK,
  ERROR_ACC_NETWORK,
} from '../types';

export const getAccountProfile = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_ACC_INFO,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_ACC_INFO,
      payload: error,
    });
  }
};

export const getAccountCost = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_ACC_COST,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_ACC_COST,
      payload: error,
    });
  }
};
export const getAccountWithRole = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_ACC_WITH_ROLE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_ACC_WITH_ROLE,
      payload: error,
    });
  }
};
export const getAccountCompliance = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_ACC_COMPLIANCE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_ACC_COMPLIANCE,
      payload: error,
    });
  }
};

export const getAccountNetwork = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_ACC_NETWORK,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_ACC_NETWORK,
      payload: error,
    });
  }
};

export const getAccountCompute = (apiUrl, getType, errType) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: getType,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: errType,
      payload: error,
    });
  }
};
