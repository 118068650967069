import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import SubReqPopup from '../SubReqPopup';
import Success from '../SubReqPopup/Success';

const SubscriptionReqList = (props) => {
  const { list, reload, type } = props;
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [successPopup, setSuccessPopup] = useState(false);
  const [message, setMessage] = useState('');

  const openPopupBox = (rowIndex) => {
    setSelectedRow(rowIndex.rowData);
    setOpenPopup(true);
  };

  const onCancel = (value) => {
    setOpenPopup(false);
    if (value !== 'Cancel') { setSuccessPopup(true); }
  };
  const onSuccessPopupClose = () => {
    setSuccessPopup(false);
    reload();
  };

  function getColumn() {
    const columns = [
      {
        name: 'Id',
        options: {
          display: false,
          download: true,
          filter: false,
          print: false,
          viewColumns: false,
        },
      },
    ];
    columns.push({
      name: 'bussinessJustification',
      options: {
        display: false,
        download: true,
        filter: false,
        print: false,
        viewColumns: false,
      },
    });
    columns.push({
      name: 'createdOn',
      options: {
        display: false,
        download: true,
        filter: false,
        print: false,
        viewColumns: false,
      },
    });
    columns.push({
      name: 'environment',
      options: {
        display: false,
        download: true,
        filter: false,
        print: false,
        viewColumns: false,
      },
    });
    columns.push({
      name: 'isActive',
      options: {
        display: false,
        download: true,
        filter: false,
        print: false,
        viewColumns: false,
      },
    });
    columns.push({
      name: 'tenantId',
      options: {
        display: false,
        download: true,
        filter: false,
        print: false,
        viewColumns: false,
      },
    });
    columns.push({
      name: 'tenant',
      options: {
        display: false,
        download: true,
        filter: false,
        print: false,
        viewColumns: false,
      },
    });
    columns.push({
      name: 'Subscription',
      options: {
        download: true,
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
        setCellProps: () => ({
          style: {
            padding: '0 2rem',
          },
        }),
      },
    });
    columns.push({
      name: 'Owner',
      options: {
        download: true,
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
        setCellProps: () => ({
          style: {
            padding: '0 2rem',
          },
        }),
      },
    });
    columns.push({
      name: 'Team_Email',
      options: {
        download: true,
        filter: false,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
        setCellProps: () => ({
          style: {
            padding: '0 2rem',
          },
        }),
      },
    });
    columns.push({
      name: 'ITag',
      options: {
        download: false,
        filter: false,
        viewColumns: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
            textAlign: 'center',
          },
        }),
      },
    });
    columns.push({
      name: type === 'new' ? 'Action' : 'view',
      options: {
        download: false,
        filter: false,
        viewColumns: true,
        sort: false,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            background: '#f3f6f9',
            zIndex: 8,
            textAlign: 'center',
          },
        }),

        customBodyRender: (dataIndex, rowIndex) => (
          <div className="text-center">
            <button
              type="button"
              className="btn text-primary me-1"
              onClick={() => openPopupBox(rowIndex)}
            >
              <FontAwesomeIcon icon={faEye} />
            </button>
          </div>
        ),
      },
    });
    return [...columns];
  }

  const options = {
    filter: true,
    fixedHeader: true,
    fixedSelectColumn: true,
    responsive: 'scroll',
    rowsPerPage: 5,
    expandableRows: false,
    expandableRowsHeader: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    print: false,
    filterType: 'dropdown',
    sortOrder: {
      name: 'UploadedDate',
      sort: true,
      direction: 'desc',
    },
  };
  return (
    <>
      {
        <div className="table-responsive">
          <MUIDataTable
            columns={getColumn()}
            options={options}
            data={list}
          />
        </div>
    }

      {openPopup ? (
        <>
          <div className="log-out-layer" />
          <SubReqPopup row={selectedRow} onCancel={onCancel} data={list} type={type} message={(msg) => setMessage(msg)} />
        </>
      ) : ''}
      {successPopup ? (
        <>
          <div className="log-out-layer" />
          <Success onCancel={onSuccessPopupClose} message={message} />
        </>
      ) : ''}
    </>
  );
};
export default SubscriptionReqList;
