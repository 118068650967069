import React, { useState } from 'react';

export const Context = React.createContext();

export const MenuContext = ({ children }) => {
  const [selectedMenu, setSelectedMenu] = useState('home');
  const [selectedSubMenu, setSelectedSubMenu] = useState('');

  return (
    <Context.Provider value={[selectedMenu, setSelectedMenu, selectedSubMenu, setSelectedSubMenu]}>
      {children}
    </Context.Provider>
  );
};
