import { Link } from '@reach/router';
import React from 'react';

const NetworkResult = ({ network, onScroll }) => (
  <>
    {network.length > 0 && (
    <div className="card card-custom mb-4">
      <div className="card-body">
        <div className="row">
          <div className="col-md-12 col-12">
            <div className="FilteredResult" onScroll={onScroll}>
              {network.length > 0
                                        && network.map((data) => (
                                          <Link key={data.id} to={data.id} className="FilteredResultList">
                                            <div className="d-flex justify-content-between align-items-center">
                                              <h5 className="mb-1">
                                                <span className="text-primary network-name">{data.name}</span>
                                                {' '}
                                                <span className="text-muted network-id">{data.id}</span>
                                              </h5>
                                              <small title={`${data.default === true ? 'Default Network' : 'Approved Network'}`} className={`badge badge-${data.default === true ? 'warning' : 'secondary'} badge-pill network-region`}>{data.region}</small>
                                            </div>
                                            <div className="d-flex w-100 justify-content-between">
                                              <div className="mb-1">
                                                <small className="network-cidr">{data.cidr}</small>
                                              </div>
                                              <small className="text-right">
                                                <span className="text-info account-owner" title="Account Contact / Owner">{data.owner}</span>
                                                <div>
                                                  <span className="account-name">{data.accountName}</span>
                                                  {' '}
                                                  <span className="text-muted account-number">{data.accountNumber}</span>
                                                </div>
                                              </small>
                                            </div>
                                          </Link>
                                        ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    )}
  </>
);

export default NetworkResult;
