import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Grid } from '@material-ui/core';
import Loader from '../Loader';
import Alert from '../Common/Alert';

const Feedback = (props) => {
  const { isOpen } = props;
  const [loaded, setLoaded] = useState(true);
  const [feedback, setFeedback] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [feedbackHeader, setFeedbackHeader] = useState('');
  const [messageClass, setMessageClass] = useState('');
  const [anonymousUser, setAnonymousUser] = useState(false);
  const modalStyle = {
    display: `${isOpen ? 'block' : 'none'}`,
    paddingRight: '4px',
  };
  const boxStyle = {
    fontSize: '17px',
    display: 'flex',
    alignItems: 'center',
    gridGap: '0.5rem',

  };
  useEffect(() => {
    setFeedbackHeader('Please Provide Feedback !!');
  }, []);
  const submitFeedback = () => {
    setLoaded(false);
    axios({
      method: 'PUT',
      url: `/api/feedback/SubmitFeedback/${feedback}/${anonymousUser}`,
      headers: { 'Content-Type': 'application/json' },
    })
      .then((response) => {
        if (response.data === 'OK') {
          setSuccessMessage('Thank you for reaching out and providing us with valuable feedback.');
          setFeedbackHeader('Successfully submitted !!');
        }
        setLoaded(true);
        setFeedback('');
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          setLoaded(true);
          setErrorMessage('Something went wrong try again...');
          setFeedbackHeader('Error !!');
          setMessageClass('light-danger');
        }
      });
  };

  const handleFeedbackChange = (e) => {
    const { target } = e;
    setFeedback(target.value);
    setSuccessMessage('');
    setFeedbackHeader('Please Provide Feedback !!');
  };
  const resetFeedback = () => {
    setFeedback('');
    setErrorMessage('');
    setSuccessMessage('');
    setFeedbackHeader('Please Provide Feedback !!');
  };
  return (
    <div>
      {loaded
        ? (
          <div
            className={`modal fade ${isOpen ? 'show' : ''}`}
            id="logoutModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            style={modalStyle}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    {feedbackHeader}
                  </h5>
                  <button
                    className="close"
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() => { props.onChange(false); resetFeedback(); setAnonymousUser(false); }}
                  >
                    <span aria-hidden="true">x</span>
                  </button>
                </div>
                <div className="modal-body">
                  { !errorMessage && (successMessage === ''
                    ? (
                      <div>
                        <textarea
                          maxLength={500}
                          rows={5}
                          cols={70}
                          value={feedback}
                          onChange={handleFeedbackChange}
                          style={{ width: '100%' }}
                        />
                        <Grid container justifyContent="flex-start">
                          <label style={boxStyle}>
                            <input type="checkbox" style={{ width: '14px', height: '14px' }} onChange={() => setAnonymousUser(!anonymousUser)} />
                            <span>
                              Anonymous user
                            </span>
                          </label>
                        </Grid>
                      </div>
                    )
                    : <span>{ successMessage }</span>)}
                  {errorMessage
                    && (
                    <div className="col-12">
                      <Alert
                        message={errorMessage}
                        alertClass={messageClass}
                        onClose={resetFeedback}
                      />
                    </div>
                    )}
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-secondary"
                    type="button"
                    data-dismiss="modal"
                    onClick={() => { props.onChange(false); resetFeedback(); setAnonymousUser(false); }}
                  >
                    {errorMessage === '' && successMessage === '' ? 'Cancel' : 'OK'}
                  </button>
                  {errorMessage === '' && successMessage === ''
                    ? (
                      <button
                        className="btn btn-primary"
                        type="button"
                        data-dismiss="modal"
                        onClick={submitFeedback}
                        disabled={feedback === ''}
                      >
                        Submit
                      </button>
                    ) : ''}
                </div>
              </div>
            </div>
          </div>
        ) : <Loader />}
    </div>
  );
};

export default Feedback;
