import React, { useState } from 'react';
import { FaChevronDown } from 'react-icons/fa';

const SubscriptionResult = ({
  subscription, onScroll, beginEdit,
}) => {
  const [active, setActive] = useState(null);
  return (
    <>
      {subscription.length > 0 && (
        <div className="card card-custom mb-4">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="FilteredResult" onScroll={onScroll}>
                  {subscription.length > 0
                    && subscription.map((data) => (
                      <div
                        className="FilteredResultList position-relative border mb-3 pe-auto"
                        onMouseOver={() => setActive(data)}
                        onMouseLeave={() => setActive(null)}
                        onFocus={() => setActive(data)}
                        onClick={(e) => beginEdit(e, data.subscriptionRequestId, data.subscriptionId, data.subscriptionName)}
                      >
                        <div className="row">
                          <div className="col-md-4 col-sm-4 col-8">
                            <div className="d-flex flex-column align-items-start">
                              <span className="text-dark text-hover-primary font-size-lg font-weight-500">
                                <span className="text-primary mr-1 font-size-lg">
                                  {data.subscriptionName}
                                  <br />
                                  <span
                                    className="text-dark"
                                    title={
                                      data.suspended === true ? 'suspended' : ''
                                    }
                                    style={{
                                      textDecoration:
                                        data.suspended === true
                                          ? 'line-through'
                                          : 'none',
                                    }}
                                  >
                                    {data.subscriptionId}
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="col-md-5 col-sm-5 col-8 ml-auto">
                            <div className="account-cost-section">
                              <span
                                className="text-dark text-hover-primary font-size-lg font-weight-500"
                                title="Account Contact / Owner"
                              >
                                <span>{data.owner}</span>
                              </span>
                              <div
                                className="font-weight-normal text-right"
                                title="Executive / Director over account"
                              >
                                <span className="text-inverse">
                                  {data.director}
                                  -
                                  {data.directorTitle}
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* {user.employeeId === data.ownerId
                            ? (
                              <div className="col-md-1 col-sm-1 col-8 text-center">
                                <button
                                  type="button"
                                  className="btn btn-link text-edit"
                                  title="Edit"
                                  onClick={(e) => beginEdit(e, data.subscriptionRequestId, data.subscriptionId, data.subscriptionName)}
                                >
                                  <FaEdit />
                                </button>
                              </div>
                            ) : ''} */}
                          <div className="col-md-1 col-sm-1 col-8  d-flex align-items-center justify-content-center">
                            <FaChevronDown />
                          </div>
                        </div>
                        <div id="collapseOne" className={`collapse mt-3 pt-3 border-top ${active === data ? 'show' : ''}`} aria-labelledby="headingOne" data-parent="#accordion">
                          <div to={data.number} className="row">
                            <div className="col-md-4">
                              <strong>Product</strong>
                              {' '}
                              -
                              {' '}
                              {data.productCode}
                            </div>
                            <div className="col-md-4">
                              <strong>Subfamily</strong>
                              {' '}
                              -
                              {data.subFamily}
                            </div>
                            <div className="col-md-4">
                              <strong>Family</strong>
                              {' '}
                              -
                              {' '}
                              {data.family}
                            </div>

                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default SubscriptionResult;
