import React, { useState } from 'react';

const initialState = {
  accountName: '',
  serviceNames: [],
};

export const Context = React.createContext();

export const AwsServiceAnalyticsContext = ({ children }) => {
  const [serviceAnalyticsFilter, setServiceAnalyticsFilter] = useState(initialState);
  return (
    <Context.Provider value={[serviceAnalyticsFilter, setServiceAnalyticsFilter]}>
      {children}
    </Context.Provider>
  );
};
