import React, { useState } from 'react';

const initialState = {
  showWhatsNew: false,
  newAnnouncementCount: 0,
  allAnnouncements: [],
};

export const Context = React.createContext();

export const AnnouncementContext = ({ children }) => {
  const [anouncementPopup, setAnouncementPopup] = useState(initialState);
  return (
    <Context.Provider value={[anouncementPopup, setAnouncementPopup]}>
      {children}
    </Context.Provider>
  );
};
