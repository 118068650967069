import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Loader from '../Loader';
import RadioButtons from './RadioButtons';

const ApiRadioButtonList = (props) => {
  const {
    url, name, title, errorText, disabled, requiredSign,
  } = props;
  const [options, setOptions] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  function serviceCall() {
    const { selectedItems } = props;
    axios
      .get(url)
      .then((response) => {
        setOptions(response.data);
        setLoaded(true);
        setSelectedItems(selectedItems || []);
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          console.error(err);
          setErrorMessage(err);
        }
      });
  }

  useEffect(() => {
    serviceCall();
  }, []);

  // Call when url props are changed.
  useEffect(() => {
    serviceCall();
  }, [url]);

  useEffect(() => {
    setSelectedItems(props.selectedItems || []);
  }, [props.selectedItems]);

  const handleChange = (e) => {
    const { onChange } = props;
    const { target } = e;
    const { value, name } = target;
    const tempTarget = {
      ...e.target,
      type: 'OptionsRadioButtonList',
      value,
      name,
    };
    const event = { ...e, target: tempTarget };
    setSelectedItems(value);
    if (onChange && onChange instanceof Function) {
      onChange(event);
    }
  };

  return (
    <div>
      {loaded === true ? (
        <RadioButtons
          name={name}
          value={selectedItems}
          inline
          options={options}
          onChange={handleChange}
          required
          title={title}
          requiredSign={requiredSign}
          errorText={errorMessage || errorText}
          disabled={disabled}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};

ApiRadioButtonList.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  errorText: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ApiRadioButtonList;
