import React, { useState, useEffect, useContext } from 'react';
import queryString from 'query-string';
import { navigate } from '@reach/router';
import axios from 'axios';
import Loader from '../Components/Loader';
import RequestHeader from '../Components/Admin/Requests/Header';
import RequestSearch from '../Components/Admin/Requests/Search';
import RequestSearchResults from '../Components/Admin/Requests/Results';
import Alert from '../Components/Common/Alert';
import { Context } from '../Context/RequestsContext';
import { Context as MenuContext } from '../Context/MenuContext';
import EditAccountRequest from '../Components/Admin/Requests/EditAccount';
import CreateEditRequest from '../Components/Admin/Requests/CreateEdit';

const initialState = {
  results: [],
  loaded: true,
  scrolling: false,
  matchCount: 0,
  showingTotal: 0,
  id: null,
  count: 0,
  pageIndex: 0,
  currentSearch: {
    environments: [],
    accountStatus: [],
    requestStatus: [],
    searchType: 'Account',
  },
  errorMessage: '',
};

const Requests = (props) => {
  const { user } = props;
  const [results, setResults] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [scrolling, setScrolling] = useState(false);
  const [matchCount, setMatchCount] = useState(0);
  const [showingTotal, setShowingTotal] = useState(0);
  const [id, setId] = useState(null);
  const [count, setCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [currentSearch, setCurrentSearch] = useState({
    environments: [],
    accountStatus: [],
    requestStatus: [],
    searchType: 'Account',
  });
  const [errorMessage, setErrorMessage] = useState('');
  // Model popup open from Request Context
  const [modelPopup, setModelPopup] = useContext(Context);
  const { editMode } = modelPopup;
  let setDelete = false;

  // Get selectedMenu data from Menu Context
  const [, setSelectedMenu, , setSelectedSubMenu] = useContext(MenuContext);
  // Change menu css with peope page is loaded.

  useEffect(() => {
    setSelectedMenu('admin');
    setSelectedSubMenu('requests');
  }, []);

  const doSearch = (clear, pgIndex) => {
    const {
      searchType, criteria, environments, accountStatus, requestStatus,
    } = currentSearch;

    if (criteria !== undefined) {
      axios
        .get(
          `/api/accountRequest?t=${searchType}&q=${criteria}&e=${environments}&s=${accountStatus}&r=${requestStatus}&i=${clear ? 0 : pgIndex}`,
        )
        .then((response) => {
          const result = response.data;
          setResults(clear ? result.items : [...results, ...result.items]);
          setMatchCount(result.matchCount);
          setShowingTotal(result.showingTotal);
          setCount(result.count);
          setPageIndex(result.index);
          setLoaded(true);
          setScrolling(false);
        })
        .catch((err) => {
          if (err.name !== 'AbortError') {
            console.error(err);
            setLoaded(true);
            setErrorMessage(err.message);
          }
        });
    }
  };

  useEffect(() => {
    doSearch(false, pageIndex);
  }, [currentSearch]);

  const parseQueryString = () => {
    const { location } = props;
    const { search } = location;
    return queryString.parse(search);
  };

  const nextPage = () => {
    if (scrolling !== true && matchCount > showingTotal) {
      setScrolling(true);
      setPageIndex(pageIndex + 1);
      doSearch(false, pageIndex + 1);
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (Math.round(scrollTop) + clientHeight === scrollHeight) {
      nextPage();
    }
  };

  const beginAdd = () => {
    setModelPopup({ editMode: 'add' });
    setId(null);
  };

  const beginEdit = (e, id) => {
    e.preventDefault();
    if (!setDelete) {
      setModelPopup({ editMode: 'edit' });
      setId(id);
    }
  };

  const cancelEdit = () => {
    setModelPopup({ editMode: 'off' });
    setId(null);
  };

  const beginDelete = (e, id) => {
    e.preventDefault();
    setModelPopup({ editMode: 'delete' });
    setId(id);
    setDelete = true;
  };

  const executeSearch = (searchType, criteria, accountStatus, requestStatus, environments) => {
    if (currentSearch.searchType !== searchType
      || currentSearch.criteria !== criteria
      || currentSearch.accountStatus.length !== accountStatus.length
      || !currentSearch.accountStatus.every((e) => accountStatus.includes(e))
      || currentSearch.requestStatus.length !== requestStatus.length
      || !currentSearch.requestStatus.every((e) => requestStatus.includes(e))
      || currentSearch.environments.length !== environments.length
      || !currentSearch.environments.every((e) => environments.includes(e))) {
      setResults([]);
      setLoaded(false);
      setPageIndex(0);
      setCurrentSearch({
        searchType,
        criteria,
        accountStatus,
        requestStatus,
        environments,
      });
      const stateVar = {
        count,
        currentSearch: {
          searchType,
          criteria,
          // eslint-disable-next-line no-restricted-globals
          status,
          environments,
        },
        loaded: false,
        pageIndex: 0,
        results: [],
        scrolling,
        showingTotal,
      };
      navigate(
        `/requests?t=${searchType}&q=${criteria}&e=${environments}&s=${accountStatus}&r=${requestStatus}`,
        { state: stateVar, replace: true },
      );
    }
  };

  const clearSearch = (status, environments) => {
    const defaultState = initialState;
    const currentSearchClr = {
      ...defaultState.currentSearch,
      status: status || [],
      environments: environments || [],
    };
    setResults([]);
    setLoaded(true);
    setScrolling(false);
    setMatchCount(0);
    setShowingTotal(0);
    setCount(0);
    setCurrentSearch(currentSearchClr);
    setErrorMessage('');
  };

  const editModes = {
    off: { showModal: false },
    edit: {
      showModal: true,
      title: 'Edit Account Request',
      verb: 'PUT',
      url: '/api/accountRequest/',
      label: 'Save',
    },
    add: {
      showModal: true,
      title: 'Add Account Request',
      verb: 'POST',
      url: '/api/accountRequest/admin',
      label: 'Add',
    },
    delete: {
      showModal: true,
      title: 'Confirm Delete',
      url: '/api/accountRequest/',
      delete: true,
    },
  };

  const performDelete = () => {
    if (id) {
      setLoaded(false);
      const { signal } = new AbortController();
      fetch(`${editModes[editMode].url}${id}?force=true`, {
        signal,
        method: 'DELETE',
      })
        .then((response) => {
          if (response.ok !== true) {
            throw new Error(response.statusText);
          } else {
            cancelEdit();
            setId(null);
            setLoaded(true);
            doSearch(true, pageIndex);
          }
        })
        .catch((e) => {
          if (e.name !== 'AbortError') {
            console.error(e);
            console.error(e.message);
            setLoaded(true);
            setErrorMessage(e.message);
          }
        });
    }
  };

  const qs = parseQueryString();
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card card-custom mb-4 card-custom-request">
            <div className="card-header align-items-center">
              <RequestHeader handleClick={beginAdd} />
            </div>
            <div className="card-body accout-search">
              <div>
                <RequestSearch
                  selectedValue={qs.t}
                  search={qs.q}
                  environments={qs.e}
                  accountStatuses={qs.s}
                  requestStatuses={qs.r}
                  executeSearch={executeSearch}
                  clearSearch={clearSearch}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        {errorMessage && (
          <div className="col-12">
            <Alert
              message={errorMessage}
              alertClass="light-danger"
              onClose={(val) => setErrorMessage(val)}
            />
          </div>
        )}
      </div>

      {/* <!-- Begin::Search list --> */}
      {
        loaded ? (
          <>
            {count > 0 && (
              <>
                <div className="row gutter-b">
                  <div className="col-12">
                    <div>
                      <h6 className="text-inverse h6">
                        {matchCount > 0 ? (
                          <span>
                            {showingTotal}
                            {' '}
                            of
                            {' '}
                            {matchCount}
                          </span>
                        ) : null}
                      </h6>
                      <RequestSearchResults account={results} onScroll={handleScroll} beginDelete={beginDelete} beginEdit={beginEdit} />
                      {scrolling ? <Loader /> : null}
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        ) : (
          <Loader />
        )
      }

      {editModes[editMode].showModal === true
        ? (
          <div>
            <div className="modal show" tabIndex="-1" role="dialog">
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">{editModes[editMode].title}</h5>
                    <button type="button" className="close" onClick={cancelEdit} aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    {editModes[editMode].delete === true && (
                    <div>
                      <p>Are you sure you want to delete this request, this cannot be undone?</p>
                      {errorMessage
                            && (<Alert message={errorMessage} alertClass="light-danger" />)}
                    </div>
                    )}
                    {editModes[editMode].title === 'Add Account Request' && (
                    <CreateEditRequest
                      adminPage
                      saveUrl={`${editModes[editMode].url}${id || ''}`}
                      saveVerb={editModes[editMode].verb}
                      showNumber
                      label={editModes[editMode].label}
                      id={id}
                      user={user}
                      onCancel={cancelEdit}
                      doSearch={doSearch}
                      title={editModes[editMode].title}
                    />
                    )}
                    {editModes[editMode].title === 'Edit Account Request' && (
                    <EditAccountRequest
                      adminPage
                      saveUrl={`${editModes[editMode].url}${id || ''}`}
                      saveVerb={editModes[editMode].verb}
                      showNumber
                      label={editModes[editMode].label}
                      id={id}
                      user={user}
                      onCancel={cancelEdit}
                      doSearch={doSearch}
                      title={editModes[editMode].title}
                    />
                    )}
                  </div>
                  {editModes[editMode].delete === true && loaded === true
                    ? (
                      <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={cancelEdit}>No, keep it</button>
                        <button type="button" className="btn btn-danger" onClick={performDelete}>Yes, delete it</button>
                      </div>
                    )
                    : editModes[editMode].delete === true && loaded === false && <div className="modal-footer"><Loader /></div>}
                </div>
              </div>
            </div>
            <div className="modal-backdrop show" />
          </div>
        ) : ''}
    </>
  );
};

export default Requests;
