import axios from 'axios';
import React, { useEffect, useContext, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import Alert from '../Components/Common/Alert';
import Loader from '../Components/Loader';
import RoleView from '../Components/Admin/Roles/View';
import { Context } from '../Context/MenuContext';

const Roles = (props) => {
  const { vendor } = props;
  const [roles, setRoles] = useState([]);
  const [levels, setLevels] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Get selectedMenu data from Menu Context
  const [, setSelectedMenu, , setSelectedSubMenu] = useContext(Context);

  // Change menu css with peope page is loaded.
  useEffect(() => {
    setSelectedMenu('admin');
    setSelectedSubMenu(`${vendor.toLowerCase()}roles`);
  }, [vendor]);

  const getLevels = () => {
    axios.get(`/api/role/levels/${vendor}`)
      .then((response) => {
        setLevels(response.data);
        setLoaded(true);
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          setErrorMessage(err.message);
          setShowError(true);
          setLoaded(true);
        }
      });
  };

  const getRoles = () => {
    axios.get(`/api/role/${vendor}`)
      .then((response) => {
        setRoles(response.data);
        getLevels();
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          setErrorMessage(err.message);
          setShowError(true);
          setLoaded(true);
        }
      });
  };

  useEffect(() => {
    getRoles();
  }, [vendor]);

  function getColumn() {
    const columns = [
      {
        name: 'code',
        options: {
          display: true,
          download: true,
          filter: false,
          print: false,
          viewColumns: false,
        },
      },
    ];
    columns.push({
      name: 'Name',
      options: {
        display: true,
        download: true,
        filter: false,
        print: false,
        viewColumns: false,
      },
    });
    columns.push({
      name: 'Minimum Training',
      options: {
        display: true,
        download: true,
        filter: false,
        print: false,
        sort: false,
        viewColumns: false,
      },
    });
    columns.push({
      name: 'Accounts',
      options: {
        display: true,
        download: true,
        filter: false,
        print: false,
        viewColumns: false,
        sort: false,
        align: 'center',
      },
    });
    columns.push({
      name: 'Employees',
      options: {
        display: true,
        download: true,
        filter: false,
        print: false,
        viewColumns: false,
        sort: false,
      },
    });
    columns.push({
      name: 'Action',
      options: {
        display: true,
        download: true,
        filter: false,
        print: false,
        viewColumns: false,
        sort: false,
      },
    });
    return [...columns];
  }

  const onChangeRowsPerPage = (rowsPerPagee) => {
    setRowsPerPage(rowsPerPagee);
  };

  const options = {
    filter: true,
    filterType: 'dropdown',
    rowsPerPage,
    rowsPerPageOptions: [10, 20],
    onChangeRowsPerPage,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    downloadOptions: { filename: `${vendor}-Roles.csv` },
    responsive: 'standard',
    customRowRender: (data) => {
      const filter = roles.filter((i) => i.code === data[0]);
      return (
        <RoleView key={data[0]} role={filter[0]} levels={levels} vendor={vendor} />
      );
    },
    page: 0,
  };

  return (
    <div className="row mb-2">
      <div className="col-12">
        <div className="card card-custom mb-4">
          <div className="card-header align-items-center">
            <div className="card-title align-items-center justify-content-between w-100">
              <h3 className="card-label col p-0">
                <div className="d-flex align-items-center justify-content-start">
                  <span>
                    {vendor}
                    {' '}
                    Roles
                  </span>
                </div>
              </h3>
            </div>
          </div>
          {loaded
            ? (
              <>
                {showError
                  ? (
                    <Alert
                      message={errorMessage}
                      alertClass="light-danger"
                      onClose={() => setShowError(false)}
                    />
                  ) : (
                    !errorMessage && (
                      <div className="card-body">
                        <MUIDataTable
                          data={roles}
                          columns={getColumn()}
                          options={options}
                        />
                      </div>
                    )
                  )}
              </>
            ) : <Loader />}
        </div>
      </div>
    </div>
  );
};

export default Roles;
