import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAmountUp } from '@fortawesome/free-solid-svg-icons';

const HighestAndLowestCosts = (props) => {
  const data = props.filterServiceCost;
  function formatMoney(number) {
    return Number(number).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  }
  return (
    <div className="row mb-2">
      <div className="col-lg-10 col-md-9 col-sm-12 col-12 orderno-2">
        <div className="card card-custom mb-4">
          <div className="p-5">
            <div className="row">
              {data.length > 0 && data.map((item, i) => (
                <div id={`highestConsumingServices${i}`} className={`col-md-4 col-sm-4 col-4 ${i === 1 ? 'border-right border-left' : ''}`}>
                  <div className="row no-gutters align-items-center">
                    <div className="col text-center">
                      <span className="svg-icon svg-icon-primary svg-icon-2x">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <rect x="0" y="0" width="24" height="24" />
                            <rect fill="#000000" opacity="0.3" x="13" y="4" width="3" height="16" rx="1.5" />
                            <rect fill="#000000" x="8" y="9" width="3" height="11" rx="1.5" />
                            <rect fill="#000000" x="18" y="11" width="3" height="9" rx="1.5" />
                            <rect fill="#000000" x="3" y="13" width="3" height="7" rx="1.5" />
                          </g>
                        </svg>
                      </span>
                      <div className="text-xs font-weight-normal text-primary text-uppercase my-2">{item.serviceName}</div>
                      <div className="h5 mb-0 font-weight-normal text-gray-800 text-xs">{`${formatMoney(item.cost.toFixed(2))}`}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-2 col-md-3 col-sm-12 col-12 pl-0 orderno-1">
        <div className={`d-flex h-100 align-items-center p-4 org-lvl-srvc ${props.showHighCostByOrg ? '' : 'rotate'}`}>
          <FontAwesomeIcon
            icon={faSortAmountUp}
            onClick={props.onHighLowButtonClick}
          />
          <p className="mb-0 ml-4">{`${props.showHighCostByOrg ? 'Highest' : 'Lowest'} Consuming Services by Org Level`}</p>
        </div>
      </div>
    </div>
  );
};
export default HighestAndLowestCosts;
