import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { getServiceList, getAwsServiceCount } from '../../../store/actions/awsServicesAction';
import BasicBarChart from '../../Common/BasicBarChart';
import Loader from '../../Loader';

const CountAwsServices = ({ payerAccount, startDate, endDate }) => {
  const dispatch = useDispatch();
  const awsServiceState = useSelector((state) => state.awsServicesInfo);
  const {
    serviceList, serviceListLoading, errorServiceList, awsServiceCountData, awsServiceCountLoading, errorAwsServiceCount,
  } = awsServiceState;
  const [formattedServiceLst, setFormattedServiceList] = useState({ multiValue: [], filterOptions: [] });
  const [formattedAwsCountData, setFormattedAwsCountData] = useState(null);
  const [origAwsCntData, setOrigAwsCntData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (startDate && endDate) {
      dispatch(getServiceList(`/api/AwsAnalytics/GetServices/${startDate}/${endDate}/custom`));
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (serviceList.length > 0) {
      dispatch(getAwsServiceCount(`/api/AwsAnalytics/GetAccountServiceCount/${payerAccount.value}/${startDate}/${endDate}/custom`));
    }
  }, [payerAccount, serviceList]);

  useEffect(() => {
    if (awsServiceCountData && awsServiceCountData.length > 0) {
      const reducedData = awsServiceCountData.reduce((r, a) => {
        r[a.dimensions[1]] = (r[a.dimensions[1]] || 0) + 1;
        return r;
      }, {});
      setFormattedAwsCountData(reducedData);
      // for storing the orig data later used in filtering.
      setOrigAwsCntData(reducedData);

      // filter service list based on services
      const srvList = serviceList.map((s) => ({ value: s.dimensions[0], label: s.dimensions[0] }));
      setFormattedServiceList({
        multiValue: [],
        filterOptions: srvList?.filter((f) => Object.keys(reducedData).includes(f.value)),
      });
    } else if (awsServiceCountData) {
      // when no data exist for aws service count
      setFormattedAwsCountData(awsServiceCountData);
      setOrigAwsCntData(awsServiceCountData);
      setFormattedServiceList({ multiValue: [], filterOptions: [] });
    }
  }, [awsServiceCountData]);

  useEffect(() => {
    // set loading is false only when formattedawscountdata is changed
    setLoading(false);
  }, [formattedAwsCountData]);

  const handleChange = (item) => {
    setFormattedServiceList({ ...formattedServiceLst, multiValue: item });
    if (item) {
      const filteredService = item.map((i) => i.value);
      const filteredAwsCountData = Object.keys(origAwsCntData)
        .filter((key) => filteredService.includes(key))
        .reduce((obj, key) => {
          obj[key] = origAwsCntData[key];
          return obj;
        }, {});
      setFormattedAwsCountData(filteredAwsCountData);
    } else {
      // if filter is cleared show non filtered data
      setFormattedAwsCountData(origAwsCntData);
    }
  };

  return (
    <>
      <div className="tab-pane active" id="home-v">
        {serviceListLoading || awsServiceCountLoading || loading ? (
          <Loader />
        ) : (
          <div className="form-group">
            {formattedServiceLst?.filterOptions?.length > 0 && !errorServiceList && (
            <div className="row justify-content-center justify-content-center-xs">
              <div className="col-12 col-md-6 col-sm-12">
                <Select
                  name="serviceListFilter"
                  value={formattedServiceLst.multiValue}
                  options={formattedServiceLst.filterOptions}
                  onChange={handleChange}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select AWS Services filter"
                  isMulti
                />
              </div>
            </div>
            )}
            <div className="row mt-8">
              <div className="col-12">
                {formattedAwsCountData && !errorAwsServiceCount && (
                <BasicBarChart
                  barChartCategories={Object.keys(formattedAwsCountData)}
                  barChartSeries={Object.values(formattedAwsCountData)}
                  valueName="Total Counts"
                />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CountAwsServices;
