import {
  GET_CLOUDABILITY_COST,
  ERROR_CLOUDABILITY_COST,
  GET_CLOUDABILITY_CUSTOMER_COST,
  ERROR_CLOUDABILITY_CUSTOMER_COST,
  GET_CUSTOMER_CSV,
  ERROR_CUSTOMER_CSV,
  GET_THIRTY_DAYS_COST,
  ERROR_THIRTY_DAYS_COST,
  GET_SIXTY_DAYS_COST,
  ERROR_SIXTY_DAYS_COST,
  GET_ASCD_STATS_COUNT,
  GET_ASCD_STATS_EXEC_TIME,
  GET_ASCD_STATS_DATA,
  ERROR_ASCD_STATS,
} from '../types';

const initialState = {
  cloudabilityCosting: {},
  cloudabilityCustomerCosting: {},
  errorCloudabilityCustomerCosting: '',
  customerMapping: {},
  customerCsv: '',
  loading: true,
  customerCostLoading: true,
  customerMappingLoading: true,
  monthlyCostLoading: true,
  statsCount: [],
  statsExecTime: [],
  statsData: [],
  error: '',
};

/* eslint-disable func-names */
export default function (state = initialState, action) {
  /* eslint-enable */
  switch (action.type) {
    case GET_CLOUDABILITY_COST:
      return {
        ...state,
        cloudabilityCosting: action.payload,
        loading: false,
      };
    case ERROR_CLOUDABILITY_COST:
      return {
        loading: false,
        error: action.payload,
      };
    case GET_CLOUDABILITY_CUSTOMER_COST:
      return {
        ...state,
        cloudabilityCustomerCosting: action.payload,
        customerCostLoading: false,
      };
    case ERROR_CLOUDABILITY_CUSTOMER_COST:
      return {
        customerCostLoading: false,
        errorCloudabilityCustomerCosting: action.payload.message,
      };
    case GET_CUSTOMER_CSV:
      return {
        ...state,
        customerCsv: action.payload,
        loading: false,
      };
    case ERROR_CUSTOMER_CSV:
      return {
        loading: false,
        errorCloudabilityCustomerCosting: action.payload.message,
      };
    case GET_THIRTY_DAYS_COST:
      return {
        ...state,
        thirtydaysCosting: action.payload,
        monthlyCostLoading: true,
      };
    case ERROR_THIRTY_DAYS_COST:
      return {
        monthlyCostLoading: false,
        error: action.payload,
      };
    case GET_SIXTY_DAYS_COST:
      return {
        ...state,
        sixtydaysCosting: action.payload,
        monthlyCostLoading: false,
      };
    case ERROR_SIXTY_DAYS_COST:
      return {
        monthlyCostLoading: false,
        error: action.payload,
      };
    case GET_ASCD_STATS_COUNT:
      return {
        ...state,
        loading: false,
        statsCount: action.payload,
      };
    case GET_ASCD_STATS_EXEC_TIME:
      return {
        ...state,
        loading: false,
        statsExecTime: action.payload,
      };
    case GET_ASCD_STATS_DATA:
      return {
        ...state,
        loading: false,
        statsData: action.payload,
      };
    case ERROR_ASCD_STATS:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
