import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import Alert from '../Components/Common/Alert';
import HeaderWithInfo from '../Components/Common/HeaderWithInfo';
import Loader from '../Components/Loader';
import ProductAnnouncementsDisplay from '../Components/Vendor/ProductAnnouncements/Display';
import { Context } from '../Context/MenuContext';
import { Context as PageContext } from '../Context/PageContext';
import { saveUserPageVisitHistory } from '../store/actions/peopleAction';

const ProductAnnouncement = (props) => {
  const [announcements, setAnnouncements] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);

  // Get selectedMenu data from Menu Context
  const [, setSelectedMenu, , setSelectedSubMenu] = useContext(Context);
  const [pageContextState, setPageContextState] = useContext(PageContext);
  const { productAnnouncementsPage } = pageContextState;
  const dispatch = useDispatch();
  const { user } = props;

  // Get user login details
  useEffect(() => {
    if (productAnnouncementsPage === '') {
      setPageContextState({
        ...pageContextState,
        productAnnouncementsPage: 'productAnnouncements',
      });
      const apiUrl = `/api/Auth/SaveUserPageVisit/${user.employeeId}/productAnnouncements`;
      dispatch(saveUserPageVisitHistory(apiUrl));
    }
  }, [productAnnouncementsPage]);

  useEffect(() => {
    // Change menu css with peope page is loaded.
    setSelectedMenu('vendor');
    setSelectedSubMenu('productAnnouncements');
    axios.get('/api/Vendor/GetProductAnnouncements')
      .then((response) => {
        setAnnouncements(response.data);
        setLoaded(true);
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          setErrorMessage(err.message);
          setShowError(true);
          setLoaded(true);
        }
      });
  }, []);

  return (
    <div className="row">
      <div className="col-12">
        <div className="card card-custom mb-4">
          <div className="card-header align-items-center">
            <div className="card-title align-items-center justify-content-between w-100">
              <HeaderWithInfo headerName="Product Announcements" />
            </div>
          </div>
          <div className="card-body announcement-style-1">
            <div className="row">
              <div className="col-12">
                {loaded
                  ? (
                    <>
                      {showError
                        ? (
                          <Alert
                            message={errorMessage}
                            alertClass="light-danger"
                            onClose={() => setShowError(false)}
                          />
                        ) : (
                          !errorMessage && (
                            <ProductAnnouncementsDisplay list={announcements} />
                          )
                        )}
                    </>
                  ) : <Loader />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductAnnouncement;
