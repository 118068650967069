import React from 'react';

const AzureSubscriptionInfo = () => (
  <div className="row">
    <div className="col-12">
      <h4 className="custom-tooltip-text">
        You can request an azure subscription by clicking on
        <strong> Request a Subscription</strong>
        {' '}
        button.
        <br />
        {' '}
        *Currently You can only filter subscriptions which are created from Arcus
      </h4>
    </div>
  </div>
);

export default AzureSubscriptionInfo;
