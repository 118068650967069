import React from 'react';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';

const Header = ({ userDetail }) => (
  <div className="card-header align-items-center">
    <div className="card-title align-items-start flex-column">
      <h3 className="card-label">
        <Link to={`/education?s=${userDetail.employeeId}`}>
          <span className="svg-icon svg-icon-primary svg-icon-2x">
            {/* begin::Svg Icon */}
            <svg
              stroke="currentColor"
              fill="#3699ff"
              strokeWidth="0"
              viewBox="0 0 512 512"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M496 128v16a8 8 0 0 1-8 8h-24v12c0 6.627-5.373 12-12 12H60c-6.627 0-12-5.373-12-12v-12H24a8 8 0 0 1-8-8v-16a8 8 0 0 1 4.941-7.392l232-88a7.996 7.996 0 0 1 6.118 0l232 88A8 8 0 0 1 496 128zm-24 304H40c-13.255 0-24 10.745-24 24v16a8 8 0 0 0 8 8h464a8 8 0 0 0 8-8v-16c0-13.255-10.745-24-24-24zM96 192v192H60c-6.627 0-12 5.373-12 12v20h416v-20c0-6.627-5.373-12-12-12h-36V192h-64v192h-64V192h-64v192h-64V192H96z" />
            </svg>
            {/* end::Svg Icon */}
          </span>
        </Link>
        <span className="mr-5">
          {userDetail.firstName}
          {' '}
          Team&apos;s Education
        </span>
      </h3>
    </div>
  </div>
);

Header.propTypes = {
  userDetail: PropTypes.shape({
    employeeId: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
  }).isRequired,
};

export default Header;
