import {
  GET_CUMULATIVE_SERVICE_COST,
  ERROR_CUMULATIVE_SERVICE_COST,
  GET_ACCOUNT_VIEW_LIST,
  ERROR_ACCOUNT_VIEW_LIST,
  GET_ASCD_TIME_PERIODS,
  ERROR_ASCD_TIME_PERIODS,
  GET_AWS_SERVICE_COST_DETAILS,
  ERROR_AWS_SERVICE_COST_DETAILS,
  GET_SERVICE_LIST,
  ERROR_SERVICE_LIST,
  GET_COUNT_AWS_SERVICES,
  ERROR_COUNT_AWS_SERVICES,
  SET_SERVICE_LIST_LOADING,
  SET_AWS_COUNT_LOADING,
  GET_ACCOUNT_LIST,
  ERROR_ACCOUNT_LIST,
  SET_ACCOUNT_LIST_LOADING,
  GET_SERVICES_BY_ACCOUNT,
  SET_SERVICES_BY_ACCOUNT_LOADING,
  ERROR_SERVICES_BY_ACCOUNT,
  GET_ACCOUNT_SERVICES_MAPPING_USAGE,
  SET_ACCOUNT_SERVICES_MAPPING_USAGE_LOADING,
  ERROR_ACCOUNT_SERVICES_MAPPING_USAGE,
} from '../types';

const initialState = {
  cumulativeServiceCost: [],
  cumulativeServiceCostLoading: true,
  errorCumulativeServiceCost: '',
  accountViewlist: [],
  filtersLoading: true,
  errorFilters: '',
  timePeriodsList: {},
  awsServiceCostList: [],
  awsServiceCostLoading: true,
  errorAwsServiceCost: '',
  serviceList: [],
  serviceListLoading: true,
  errorServiceList: '',
  awsServiceCountData: null,
  awsServiceCountLoading: true,
  errorAwsServiceCount: '',
  accountList: {},
  accountListLoading: true,
  errorAccountList: '',
  servicesByAccount: [],
  servicesByAccountLoading: true,
  errorServicesByAccount: '',
  accountServiceUsageMapping: [],
  accountServiceUsageMappingLoading: true,
  errorAccountServiceUsageMapping: '',
};

/* eslint-disable func-names */
export default function (state = initialState, action) {
  /* eslint-enable */
  switch (action.type) {
    case GET_CUMULATIVE_SERVICE_COST:
      return {
        ...state,
        cumulativeServiceCost: action.payload,
        cumulativeServiceCostLoading: false,
      };
    case ERROR_CUMULATIVE_SERVICE_COST:
      return {
        ...state,
        cumulativeServiceCostLoading: false,
        errorCumulativeServiceCost: action.payload.message,
      };
    case GET_ACCOUNT_VIEW_LIST:
      return {
        ...state,
        accountViewlist: action.payload,
        filtersLoading: false,
      };
    case ERROR_ACCOUNT_VIEW_LIST:
      return {
        ...state,
        filtersLoading: false,
        errorFilters: `Filters Loading Error: ${action.payload.message}`,
      };
    case GET_ASCD_TIME_PERIODS:
      return {
        ...state,
        timePeriodsList: action.payload,
      };
    case ERROR_ASCD_TIME_PERIODS:
      return {
        ...state,
        errorFilters: `Filters Loading Error: ${action.payload.message}`,
      };
    case GET_AWS_SERVICE_COST_DETAILS:
      return {
        ...state,
        awsServiceCostList: action.payload,
        awsServiceCostLoading: false,
      };
    case ERROR_AWS_SERVICE_COST_DETAILS:
      return {
        ...state,
        awsServiceCostLoading: false,
        errorAwsServiceCost: `Aws Service Cost Error: ${action.payload.message}`,
      };
    case GET_SERVICE_LIST:
      return {
        ...state,
        serviceList: action.payload,
        serviceListLoading: false,
      };
    case SET_SERVICE_LIST_LOADING:
      return {
        ...state,
        serviceListLoading: action.payload,
      };
    case ERROR_SERVICE_LIST:
      return {
        ...state,
        serviceListLoading: false,
        errorServiceList: action.payload.message,
      };
    case GET_ACCOUNT_LIST:
      return {
        ...state,
        accountList: action.payload,
        accountListLoading: false,
      };
    case SET_ACCOUNT_LIST_LOADING:
      return {
        ...state,
        accountListLoading: action.payload,
      };
    case ERROR_ACCOUNT_LIST:
      return {
        ...state,
        accountListLoading: false,
        errorAccountList: action.payload.message,
      };
    case GET_COUNT_AWS_SERVICES:
      return {
        ...state,
        awsServiceCountData: action.payload,
        awsServiceCountLoading: false,
      };
    case SET_AWS_COUNT_LOADING:
      return {
        ...state,
        awsServiceCountLoading: action.payload,
      };
    case ERROR_COUNT_AWS_SERVICES:
      return {
        ...state,
        awsServiceCountLoading: false,
        errorAwsServiceCount: action.payload.message,
      };
    case GET_SERVICES_BY_ACCOUNT:
      return {
        ...state,
        servicesByAccount: action.payload,
        servicesByAccountLoading: false,
      };
    case SET_SERVICES_BY_ACCOUNT_LOADING:
      return {
        ...state,
        servicesByAccountLoading: action.payload,
      };
    case ERROR_SERVICES_BY_ACCOUNT:
      return {
        ...state,
        servicesByAccountLoading: false,
        errorServicesByAccount: action.payload.message,
      };
    case GET_ACCOUNT_SERVICES_MAPPING_USAGE:
      return {
        ...state,
        accountServiceUsageMapping: action.payload,
      };
    case SET_ACCOUNT_SERVICES_MAPPING_USAGE_LOADING:
      return {
        ...state,
        accountServiceUsageMappingLoading: false,
      };
    case ERROR_ACCOUNT_SERVICES_MAPPING_USAGE:
      return {
        ...state,
        accountServiceUsageMappingLoading: false,
        errorAccountServiceUsageMapping: action.payload.message,
      };
    default:
      return state;
  }
}
