import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import MUIDataTable from 'mui-datatables';
import { getHealthEvents } from '../../../store/actions/vendorAction';
import HealthEventModalPopUp from './ModalPopUp';
import Alert from '../../Common/Alert';
import Loader from '../../Loader';

const AWSHealthEvents = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [showModalPopUp, setShowModalPopUp] = useState(false);
  const [healthEventData, setHealthEventData] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const dispatch = useDispatch();
  const vendorInfo = useSelector((state) => state.vendorInfo);
  const { healthEvents, healthEventsLoading, errorHealthEvents } = vendorInfo;
  const [tableData, setTableData] = useState([]);
  const headers = [
    {
      name: 'Event Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Event Code',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Region',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Status Code',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Start Time',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'End Time',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Details',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <button type="button" className="detailsButton" onClick={() => { setShowModalPopUp(true); setHealthEventData(value); }}>
            Read More
            {' '}
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </button>
        ),
      },
    },
  ];

  const onChangeRowsPerPage = (rowsPerPagee) => {
    setRowsPerPage(rowsPerPagee);
  };

  const options = {
    filter: true,
    filterType: 'dropdown',
    rowsPerPage,
    rowsPerPageOptions: [5, 10],
    onChangeRowsPerPage,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    downloadOptions: { filename: 'HealthEvents.csv' },
    responsive: 'standard',
    onDownload: (buildHead, buildBody, columns, data) => {
      const formattedData = data.map((x) => {
        const description = x.data[6].eventDescription;
        x.data[5] = x.data[5] === '-' ? '' : x.data[5];
        x.data.pop();
        x.data.push(description);
        return (
          {
            index: x.index,
            data: x.data,
          }
        );
      });
      return (buildHead(columns) + buildBody(formattedData));
    },
  };

  useEffect(() => {
    const apiUrl = '/api/Vendor/GetHealthEvents';
    dispatch(getHealthEvents(apiUrl));
  }, [dispatch]);
  useEffect(() => {
    if (errorHealthEvents !== '' && errorHealthEvents !== undefined) {
      setErrorMessage(errorHealthEvents);
    }
  }, [errorHealthEvents]);
  useEffect(() => {
    const s = healthEvents.map((x) => (
      {
        eventTypeCategory: x.eventTypeCategory.value,
        eventTypeCode: x.eventTypeCode,
        region: x.region,
        statusCode: x.statusCode.value,
        startTime: x.startTime,
        endTime: x.endTime === undefined ? '-' : x.endTime,
        readMore: x,
      }
    ));
    setTableData(s.map(Object.values));
  }, [healthEvents]);

  return (
    <>
      {healthEventsLoading ? <Loader />
        : (
          <div className="custom-table">
            {healthEvents && healthEvents.length > 0 && (
              <MUIDataTable
                data={tableData}
                columns={headers}
                options={options}
              />
            )}
            {errorMessage && (
              <div className="col-12">
                <Alert
                  message={errorMessage}
                  alertClass="light-danger"
                  onClose={(val) => setErrorMessage(val)}
                />
              </div>
            )}
          </div>
        )}
      {showModalPopUp && (
        <>
          <div className="log-out-layer" />
          <HealthEventModalPopUp
            healthEventData={healthEventData}
            onClose={() => setShowModalPopUp(false)}
          />
        </>
      )}
    </>
  );
};

export default AWSHealthEvents;
