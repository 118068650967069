import React from 'react';

const AccessReqHistotoryPopup = (props) => {
  const {
    data, row, onCancel,
  } = props;

  const tableData = data[row];

  return (
    <>
      <div
        className="modal fade reqNewAccount show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        style={{ display: 'block' }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLabel">
                Subscription Access Request History
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => onCancel('Cancel')}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <th>Subscription Id</th>
                    <td>{tableData.subscriptionId}</td>
                  </tr>
                  <tr>
                    <th>Subscription Name</th>
                    <td>{tableData.subscriptionName}</td>
                  </tr>
                  <tr>
                    <th>Subscription Owner</th>
                    <td>{tableData.subscriptionOwnerName}</td>
                  </tr>
                  <tr>
                    <th>Employee Name</th>
                    <td>{tableData.employeeName}</td>
                  </tr>
                  <tr>
                    <th>Role</th>
                    <td>{tableData.role}</td>
                  </tr>
                  <tr>
                    <th>Training</th>
                    <td>{tableData.training}</td>
                  </tr>
                  <tr>
                    <th>Requestor</th>
                    <td>{tableData.requestor}</td>
                  </tr>
                  <tr>
                    <th>Expedite Justification</th>
                    <td>{tableData.classOfService}</td>
                  </tr>
                  <tr>
                    <th>Created on</th>
                    <td>{new Date(tableData.createdDate).toLocaleDateString()}</td>
                  </tr>
                  <tr>
                    <th>Request Completion Date</th>
                    <td>{new Date(tableData.requestCompletionDate).toLocaleDateString()}</td>
                  </tr>
                  {tableData.expediteJustification && (
                    <tr>
                      <th>Expedite Justification</th>
                      <td>{tableData.expediteJustification}</td>
                    </tr>
                  )}
                  {tableData.comments && (
                    <tr>
                      <th>Comments</th>
                      <td>{tableData.comments}</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => onCancel('Cancel')}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AccessReqHistotoryPopup;
