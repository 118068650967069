import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { Link } from '@reach/router';
import PropTypes from 'prop-types';

const UserDetail = ({ userDetails, cloudEnv }) => {
  const renderStarts = (number) => {
    const lis = [];
    for (let i = 0; i < number; i++) {
      lis.push(<FontAwesomeIcon key={i} icon={faStar} />);
    }
    return lis;
  };

  const nameIcon = () => `${userDetails.firstName.slice(0, 1)}${userDetails.lastName.slice(0, 1)}`;
  const cloudRank = () => {
    switch (cloudEnv) {
      case 'azure':
        return userDetails.azMaxTrainingLevelRankAchieved;
      default:
        return userDetails.awsMaxTrainingLevelRankAchieved;
    }
  };
  const cloudLevel = () => {
    switch (cloudEnv) {
      case 'azure':
        return userDetails.azMaxTrainingLevelAchieved;
      default:
        return userDetails.awsMaxTrainingLevelAchieved;
    }
  };

  return (
    <div>
      <div className="tab-pane" id="home-v">
        <div className="row justify-content-center justify-content-end-xs">
          <div className="col-md-12 col-sm-12 col-12">
            <div className="d-flex">
              {/* begin::Pic */}
              <div className="flex-shrink-0 mr-7 d-none d-sm-block">
                <div className="symbol symbol-50 symbol-lg-120 symbol-light-primary">
                  <span className="font-size-h3 symbol-label font-weight-boldest">
                    {nameIcon()}
                  </span>
                </div>
              </div>
              {/* end::Pic */}
              {/* begin: Info */}
              <div className="flex-grow-1">
                {/* begin::Title */}
                <div className="d-flex align-items-center justify-content-between flex-wrap">
                  {/* begin::User */}
                  <div className="mr-3">
                    {/* begin::Name */}
                    <div className="d-flex align-items-center text-inverse font-size-h3 font-weight-500 mr-3 mb-5">
                      {userDetails.name}
                      <span className="d-flex align-items-center text-secondary font-size-sm ml-2">
                        {renderStarts(cloudRank())}
                        <span className="text-muted">
                          {renderStarts(
                            4 - cloudRank(),
                          )}
                        </span>
                      </span>
                    </div>
                    {/* end::Name */}
                    {/* begin::Contacts */}
                    <div className="d-flex flex-wrap mb-4 mb-xs-0">
                      <div className="font-size-md text-user-profile text-nowrap font-weight-500 mr-5 mb-2">
                        <span className="svg-icon svg-icon-primary svg-icon-2x-">
                          <svg
                            stroke="#919191"
                            fill="none"
                            strokeWidth="0"
                            viewBox="0 0 24 24"
                            height="1.2em"
                            width="1.2em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                            />
                          </svg>
                          {/* end::Svg Icon */}
                        </span>
                        <a
                          className="text-lowercase"
                          href={`mailto:${userDetails.email}`}
                        >
                          {userDetails.email}
                        </a>
                      </div>
                      <div className="font-size-md text-user-profile text-nowrap font-weight-500 mr-lg-8 mr-5 mb-lg-0 mb-2">
                        <span className="svg-icon svg-icon-primary svg-icon-2x- mr-1">
                          {/* begin::Svg Icon | */}
                          <svg
                            stroke="currentColor"
                            fill="#919191"
                            strokeWidth="0"
                            viewBox="0 0 512 512"
                            height="1.2em"
                            width="1.2em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path d="M255 471L91.7 387V41h328.6v346zm-147.3-93.74L255 453l149.3-75.76V57H107.7v320.26zm187.61-168.34l-14.5-46 38.8-28.73-48.27-.43L256 87.94l-15.33 45.78-48.27.43 38.8 28.73-14.5 46 39.31-28zM254.13 311.5l98.27-49.89v-49.9l-98.14 49.82-94.66-48.69v50zm.13 32.66l-94.66-48.69v50l94.54 48.62 98.27-49.89v-49.9z" />
                          </svg>
                          {/* end::Svg Icon */}
                        </span>
                        {cloudLevel()}
                      </div>
                    </div>
                    <div className="d-flex flex-wrap">
                      {userDetails.supervisorId
                                                && userDetails.supervisorId !== userDetails.employeeId && (
                                                <div className="font-size-md text-user-profile text-nowrap font-weight-500 mr-5  mb-2 w-xs-100">
                                                  <span className="svg-icon svg-icon-primary svg-icon-2x-">
                                                    <svg
                                                      stroke="currentColor"
                                                      fill="#919191"
                                                      strokeWidth="0"
                                                      viewBox="0 0 448 512"
                                                      height="1.1em"
                                                      width="1.1em"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm95.8 32.6L272 480l-32-136 32-56h-96l32 56-32 136-47.8-191.4C56.9 292 0 350.3 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-72.1-56.9-130.4-128.2-133.8z" />
                                                    </svg>
                                                  </span>
                                                  <Link
                                                    to={`/userProfile/${userDetails.supervisorId}`}
                                                  >
                                                    {userDetails.supervisorName}
                                                  </Link>
                                                </div>
                      )}
                      {userDetails.directorId
                                                && userDetails.directorId !== userDetails.supervisorId && (
                                                <div className="font-size-md text-user-profile text-nowrap font-weight-500 mb-2">
                                                  <span className="svg-icon svg-icon-primary svg-icon-2x-">
                                                    <svg
                                                      stroke="currentColor"
                                                      fill="#919191"
                                                      strokeWidth="2"
                                                      viewBox="0 0 512 512"
                                                      height="1.3em"
                                                      width="1.3em"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                      <path d="M157 21.234v16h18v-16zm180 0v16h18v-16zM153 55.232v62.002h206V55.232zm-3.89 80c-26.567 5.315-53.134 10.626-79.7 15.942l3.531 17.648L87 166.01v80.222h18V162.41l52-10.4v45.224h18v-62.002zm187.89 0v62.002h18V152.01l52 10.4v83.822h18V166.01l14.059 2.812 3.53-17.648c-26.565-5.315-53.132-10.628-79.698-15.942zm-174 80l-40.004 30.002h266.008L349 215.232zm-69.836 48l118.363 82.854c-37.367 27.406-74.74 54.805-112.105 82.213l10.642 14.514 18.743-13.745-8.008 20.823-37.332 26.13 10.322 14.745L256 377.216c54.07 37.851 108.142 75.698 162.21 113.55l10.323-14.745-37.332-26.13-8.008-20.823 18.743 13.745 10.642-14.514c-37.367-27.406-74.737-54.809-112.105-82.213l118.363-82.854h-31.383l-102.307 71.616-13.927-10.215 83.728-61.4H324.51L256 313.472l-68.51-50.24h-30.437l83.728 61.4-13.927 10.215-102.307-71.616zM256 335.793l13.574 9.955L256 355.25l-13.574-9.502zm-28.9 21.193l13.209 9.246-93.125 65.188 8.48-22.047zm57.8 0l71.436 52.387 8.48 22.047-93.125-65.186z" />
                                                    </svg>
                                                    {/* end::Svg Icon */}
                                                  </span>
                                                  <Link to={`/userProfile/${userDetails.directorId}`}>
                                                    {userDetails.directorName}
                                                  </Link>
                                                </div>
                      )}
                    </div>
                    {/* end::Contacts */}
                  </div>
                  {/* begin::User */}
                  {/* begin::Actions */}

                  {/* end::Actions */}
                </div>
                {/* end::Title */}
                {/* begin::Content */}

                {/* end::Content */}
              </div>
              {/* end::Info */}
            </div>
          </div>
        </div>
        {' '}
        {/* End:: Select Section */}
      </div>
    </div>
  );
};

UserDetail.propTypes = {
  userDetails: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    supervisorId: PropTypes.number.isRequired,
    supervisorName: PropTypes.string.isRequired,
    employeeId: PropTypes.number.isRequired,
    directorId: PropTypes.number.isRequired,
    directorName: PropTypes.string.isRequired,
    awsMaxTrainingLevelAchieved: PropTypes.string.isRequired,
    awsMaxTrainingLevelRankAchieved: PropTypes.number.isRequired,
    azMaxTrainingLevelAchieved: PropTypes.string.isRequired,
    azMaxTrainingLevelRankAchieved: PropTypes.number.isRequired,
  }).isRequired,
};

export default UserDetail;
