import React from 'react';
import { Link } from '@reach/router';
import './account.css';

const AccountList = ({ acctName, acctNum, link }) => (

  <Link
    to={link}
    className="d-flex align-items-center mb-3 justify-content-between card-hoverable py-2"
  >
    {/* <!--begin::Text--> */}
    <div className="d-flex justify-content-start align-items-center font-weight-500">
      <div className="navi-icon ml-2">
        <div className="navi-icon-label">
          <span className="svg-icon svg-icon-xl svg-icon-primary">
            {/* <!--begin::Svg Icon --> */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              version="1.1"
            >
              <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <polygon points="0 0 24 0 24 24 0 24" />
                <path
                  d="M7.74714567,15.0425758 C6.09410362,13.9740356 5,12.1147886 5,10 C5,6.6862915 7.6862915,4 11,4 C13.7957591,4 16.1449096,5.91215918 16.8109738,8.5 L19.25,8.5 C21.3210678,8.5 23,10.1789322 23,12.25 C23,14.3210678 21.3210678,16 19.25,16 L10.25,16 C9.28817895,16 8.41093178,15.6378962 7.74714567,15.0425758 Z"
                  fill="#000000"
                  opacity="0.3"
                />
                <path
                  d="M3.74714567,19.0425758 C2.09410362,17.9740356 1,16.1147886 1,14 C1,10.6862915 3.6862915,8 7,8 C9.79575914,8 12.1449096,9.91215918 12.8109738,12.5 L15.25,12.5 C17.3210678,12.5 19,14.1789322 19,16.25 C19,18.3210678 17.3210678,20 15.25,20 L6.25,20 C5.28817895,20 4.41093178,19.6378962 3.74714567,19.0425758 Z"
                  fill="#000000"
                />
              </g>
            </svg>
            {/* <!--end::Svg Icon--> */}
          </span>
        </div>
      </div>
      <div className="d-flex flex-column font-weight-500">
        <div className="text-dark text-hover-primary mb-1 font-size-lg">
          {acctName}
        </div>
        <span className="text-muted">
          #
          {acctNum}
        </span>
      </div>
    </div>
    <div className="navi-icon">
      <span className="svg-icon svg-icon-xl svg-icon-primary">
        {/* <!--begin::Svg Icon --> */}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          width="24px"
          height="24px"
          viewBox="0 0 24 24"
          version="1.1"
        >
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <polygon points="0 0 24 0 24 24 0 24" />
            <path
              d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
              fill="#000000"
              fillRule="nonzero"
            />
            <path
              d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
              fill="#000000"
              fillRule="nonzero"
              opacity="0.3"
              transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
            />
          </g>
        </svg>
        {/* <!--end::Svg Icon--> */}
      </span>
    </div>
    {/* <!--end::Text--> */}
  </Link>
);

export default AccountList;
