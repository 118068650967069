import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import ProfileTab from '../Components/People/ProfileTab';
import Loader from '../Components/Loader';
import CloudTab from '../Components/People/CloudTab';
import UserDetail from '../Components/People/UserDetail';
import UserTraining from '../Components/People/UserTraining';
import PeopleAccount from '../Components/People/PeopleAccount';
import Header from '../Components/People/Header';
import { Context } from '../Context/MenuContext';

const UserProfile = (props) => {
  const { id } = props;
  const [tab, setTab] = useState('userDetail');
  const [loaded, setLoaded] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [cloudEnv, setCloudEnv] = useState('aws');

  const outerTabList = [
    { value: 'aws', label: 'AWS' },
    { value: 'azure', label: 'Azure' },
  ];

  const innerTabList = [
    { value: 'userDetail', label: 'User Profile' },
    { value: 'userTraining', label: 'Training' },
    { value: 'accounts', label: 'Accounts' },
  ];

  // Get selectedMenu data from Menu Context
  const [, setSelectedMenu] = useContext(Context);

  // Change menu css with peope page is loaded.
  useEffect(() => {
    setSelectedMenu('people');
  }, []);

  const updatePage = () => {
    setLoaded(false);
    axios
      .get(`/api/people/${id}`)
      .then((response) => {
        setUserDetails(response.data);
        setLoaded(true);
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          console.error(err);
          setLoaded(true);
        }
      });
  };

  useEffect(() => {
    updatePage();
  }, []);

  useEffect(() => {
    updatePage();
  }, [id]);

  function userDetailTab(tabName) {
    switch (tabName) {
      case 'userDetail':
        return <UserDetail userDetails={userDetails} cloudEnv={cloudEnv} />;
      case 'userTraining':
        return <UserTraining userId={props.id} cloudEnv={cloudEnv} />;
      case 'accounts':
        return <PeopleAccount userId={props.id} cloudEnv={cloudEnv} />;
      default:
        return '';
    }
  }

  function tabCloudEnv(cloudTab) {
    setCloudEnv(cloudTab);
    setTab('userDetail');
  }

  return (
    <div className="row gutter-b">
      {loaded ? (
        Object.keys(userDetails).length > 0 && (
        <div className="col-12">
          {/* Begin::Page Content */}
          <div className="card card-custom gutter-b">
            <Header userDetail={userDetails} />
            <div className="card-body p-0">
              <div className="row">
                <div className="col-12">
                  <div className="horizontal-tab">
                    <CloudTab
                      onClick={(e) => tabCloudEnv(e)}
                      tabList={outerTabList}
                      selected="aws"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="horizontal-tab">
                    <ProfileTab
                      onClick={(e) => setTab(e)}
                      tabList={innerTabList}
                      selected={tab}
                      cloudEnv={cloudEnv}
                    />
                    <div className="tab-content">{userDetailTab(tab)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        )
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default UserProfile;
