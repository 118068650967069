import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../Loader';
import AccessByRole from './AccessByRole';
import AccessByUsers from './AccessByUsers';
import Alert from '../../Common/Alert';
import { getAccountWithRole } from '../../../store/actions/accountAction';

const AccountUserTab = (props) => {
  const { accountId } = props;
  const [activeByRole, setActiveByRole] = useState(true);
  const [activeByUsers, setActiveByUsers] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrMsg, setShowErrMsg] = useState(false);

  const dispatch = useDispatch();
  const accountData = useSelector((state) => state.accountProfile);
  const { roleLoading, errWithRole, accountWithRole } = accountData;

  useEffect(() => {
    const apiUrl = `/api/account/${accountId}/users`;
    dispatch(getAccountWithRole(apiUrl));
  }, [dispatch]);

  useEffect(() => {
    if (errWithRole !== '') {
      setErrorMessage(errWithRole);
      setShowErrMsg(true);
    }
  }, [errWithRole]);

  function collapseSection(button) {
    switch (button) {
      case 'byRole':
        setActiveByRole(!activeByRole);
        setActiveByUsers(false);
        break;
      case 'byUsers':
        setActiveByUsers(!activeByUsers);
        setActiveByRole(false);
        break;
      default:
        return '';
    }
    return '';
  }

  return (
    <div>
      {roleLoading ? (
        <Loader />
      ) : (
        <>
          {showErrMsg ? (
            <div className="col-12">
              <Alert
                message={errorMessage}
                alertClass="light-danger"
                onClose={() => setShowErrMsg(false)}
              />
            </div>
          ) : (
            !errorMessage && (
              <>
                {accountWithRole.length === 0 ? (
                  <div className="col-12">
                    <Alert
                      message="Account contains no users info"
                      alertClass="info"
                    />
                  </div>
                ) : (
                  <div className="tab-pane active" id="messages-v">
                    <div className="accordion" id="accordion">
                      <AccessByRole
                        isOpen={activeByRole}
                        accountWithRole={accountWithRole}
                        onClick={(e) => collapseSection(e)}
                      />
                      <AccessByUsers
                        isOpen={activeByUsers}
                        accountWithRole={accountWithRole}
                        onClick={(e) => collapseSection(e)}
                      />
                    </div>
                  </div>
                )}
              </>
            )
          )}
        </>
      )}
    </div>
  );
};

export default AccountUserTab;
