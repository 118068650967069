import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

const Popup = (props) => {
  const {
    title, children, onClose, onSubmit, primaryBtn, secondaryBtn, size,
  } = props;
  return (
    <>
      <div className="modal" tabIndex="-1">
        <div className={`modal-dialog modal-${size}`}>
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {title}
              </h5>
              <button type="button" className="btn btn-link p-0 m-0" data-bs-dismiss="modal" aria-label="Close" onClick={onClose}>
                <FontAwesomeIcon
                  icon={faTimes}
                  className="ml-3"
                  style={{ color: 'green' }}
                />
              </button>
            </div>
            <div className="modal-body">
              {children}
            </div>
            {(primaryBtn || secondaryBtn) && (
              <div className="modal-footer">
                {secondaryBtn && (<button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={onClose}>Close</button>)}
                {primaryBtn && (<button type="button" className="btn btn-primary" onClick={onSubmit}>Save changes</button>)}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="modal-backdrop show" />
    </>
  );
};

export default Popup;
