import React, { useState } from 'react';

export const Context = React.createContext();

const initialState = {
  homePage: '',
  accountPage: '',
  subscriptionsPage: '',
  networkPage: '',
  peoplePage: '',
  educationPage: '',
  cloudabilityCostingPage: '',
  apiStatsPage: '',
  awsServiceDashboardPage: '',
  awsHealthEventPage: '',
  awsServicesPage: '',
  productAnnouncementsPage: '',
  postEventSummariesPage: '',
  stockWidgetPage: '',

};

export const PageContext = ({ children }) => {
  const [pageContextState, setPageContextState] = useState(initialState);
  return (

    <Context.Provider value={[pageContextState, setPageContextState]}>
      {children}
    </Context.Provider>
  );
};
