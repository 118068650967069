import React from 'react';
import { Link } from '@reach/router';

const VirtualNetworkInfo = ({ data }) => (
  <div>
    <div className="tab-pane" id="home-v">
      <div className="row justify-content-center justify-content-end-xs">
        <div className="col-md-12 col-sm-12 col-12">
          <div className="d-flex screen-md-d-flex">
            {/* begin::Pic */}
            <div className="flex-shrink-0 mr-2 screen-md-flex-shrink">
              <div className={`symbol symbol-50 symbol-lg-120 symbol-light-${data.VnIsDefualt ? 'warning' : 'primary'}`}>
                <div className="font-size-h5 symbol-label font-weight-boldest text-center p-4 text-break d-flex flex-column aling-items-center">
                  {data.VnName}
                </div>
              </div>
            </div>
            {/* end::Pic */}

            {/* begin: Info */}
            <div className="w-100">
              <span className="font-size-h4 col pr-1">{data.VnId}</span>
              <div id="infoDiv" className="d-flex justify-content-start aling-items-center mt-8 flex-wrap">
                {data.VnRegion && (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                    <div className="d-flex align-items-center font-size-h6">
                      <span className="text-muted text-nowrap mr-3">
                        Region:
                      </span>
                      {data.VnRegion}
                    </div>
                  </div>
                )}
                {data.VnCidr && (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 mb-3">
                    <div className="d-flex align-items-center font-size-h6">
                      <span className="text-muted text-nowrap mr-3">
                        CIDR:
                      </span>
                      {data.VnCidr}
                    </div>
                  </div>
                )}
                {data.VnOwnerId && (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                    <div className="d-flex align-items-center font-size-h6">
                      <span className="text-muted text-nowrap mr-3">
                        Account:
                      </span>
                      <Link to={`/accounts/${data.VnOwnerId}`}>{data.VnOwnerId}</Link>
                    </div>
                  </div>
                )}
                <div className="col-12">
                  {data.VnIsDefualt && (
                    <div className="mt-1">
                      <div className="messageDispaly">
                        This is a default network and needs to be removed in accordance with the Cloud Usage Framework.
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* end::Info */}
        </div>
      </div>
    </div>
  </div>
);

export default VirtualNetworkInfo;
