import React from 'react';

const Success = (props) => {
  const { onCancel, message } = props;

  return (
    <>
      <div
        className="modal fade reqNewAccount show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        style={{ display: 'block' }}
      >

        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLabel">
                Submit Response
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => props.onCancel(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                Sucessfully
                {' '}
                {message === 'APPROVED' ? 'Approved' : 'Rejected'}
                {' '}
                Subscription Request
              </div>
              <div className="modal-footer">

                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => onCancel(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};
export default Success;
