import React, { useState } from 'react';

const initialState = {
  showAccntReqModal: false,
  editMode: 'off',
};

export const Context = React.createContext();

export const RequestsContext = ({ children }) => {
  const [modelPopup, setModelPopup] = useState(initialState);
  return (
    <Context.Provider value={[modelPopup, setModelPopup]}>
      {children}
    </Context.Provider>
  );
};
