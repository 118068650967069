import React from 'react';
import { Link } from '@reach/router';

const Year = new Date().getFullYear();
const YearString = ` ${Year} `;

const Footer = () => (
  <div>
    <footer className="sticky-footer bg-theme">
      <div className="container-fluid my-auto">
        <div className="copyright text-center my-auto text-white d-flex justify-content-between">
          <span>
            &copy;
            {YearString}
            - CSG Systems Inc.
          </span>
          <Link to="health" className="text-white">
            Arcus Health
          </Link>
        </div>
      </div>
    </footer>
  </div>
);

export default Footer;
