import React from 'react';
import PropTypes from 'prop-types';
import BigSearch from '../Common/BigSearch';

const PeopleSearch = (props) => {
  const { selectedValue, search, levels } = props;

  const searchTypes = [
    {
      key: 'Name',
      display: 'Name / Email',
      placeholder: 'The name or email',
    },
    {
      key: 'Supervisor',
      display: 'Manager',
      placeholder: 'The first or last name of the manager',
    },
    {
      key: 'Director',
      display: 'Executive / Director',
      placeholder:
        'The first or last name of the director or executive over the manager',
    },
    {
      key: 'Mine',
      display: 'Mine',
      placeholder: 'The first or last name - only direct reports',
      alwaysSearch: true,
    },
  ];

  const filters = [
    {
      key: 'levels',
      url: '/api/lookup/traininglevels',
      label: 'Levels',
    },
  ];

  const findIndex = (value) => searchTypes.findIndex((p) => p.key === (value || 'Name')) || 0;

  const executeSearch = (e) => {
    const { executeSearch } = props;
    const { bigSearch } = e;
    const { search, selectedValue, selectedFilters } = bigSearch;
    const { key } = selectedValue;
    const { levels } = selectedFilters;
    executeSearch(key, search, levels || []);
  };

  const clearSearch = (e) => {
    const { clearSearch } = props;
    const { bigSearch } = e;
    const { selectedFilters } = bigSearch;
    const { levels } = selectedFilters;
    clearSearch(levels || []);
  };

  const levelIndex = filters.findIndex((p) => p.key === 'levels');
  if (levelIndex >= 0 && levels !== undefined && String(levels) !== '') {
    filters[levelIndex].initialSelection = levels.split(',');
  }

  return (
    <BigSearch
      srLabelTitle="People Search"
      selectedIndex={findIndex(selectedValue)}
      search={search}
      executeSearch={executeSearch}
      clearSearch={clearSearch}
      searchTypes={searchTypes}
      filters={filters}
    />
  );
};

PeopleSearch.propTypes = {
  selectedValue: PropTypes.string,
  search: PropTypes.string,
  levels: PropTypes.string,
  executeSearch: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
};

export default PeopleSearch;
