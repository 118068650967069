import React, { useState } from 'react';

const initialState = {
  environment: 'PRD3',
  statTypeLoaded: false,
};

export const Context = React.createContext();

export const ApiStatsContext = ({ children }) => {
  const [apiStatsFilter, setApiStatsFilter] = useState(initialState);
  return (
    <Context.Provider value={[apiStatsFilter, setApiStatsFilter]}>
      {children}
    </Context.Provider>
  );
};
