import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Loader from '../Loader';
import CheckboxList from './CheckboxList';

const ApiCheckboxList = (props) => {
  const {
    url, name, title, tabIndexStart, errorText, disabled,
  } = props;
  const [options, setOptions] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  function serviceCall() {
    const { selectedItems } = props;
    axios
      .get(url)
      .then((response) => {
        setOptions(response.data);
        setLoaded(true);
        setSelectedItems(selectedItems || []);
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          console.error(err);
          setErrorMessage(err);
        }
      });
  }

  useEffect(() => {
    serviceCall();
  }, []);

  // Call when url props are changed.
  useEffect(() => {
    serviceCall();
  }, [url]);

  useEffect(() => {
    setSelectedItems(props.selectedItems || []);
  }, [props.selectedItems]);

  const handleChange = (e) => {
    const { onChange } = props;
    const { target } = e;
    const { checked, value, name } = target;
    let items = selectedItems;

    if (checked === true) {
      items.push(value);
    } else {
      items = selectedItems.filter((p) => String(p) !== value);
    }

    const tempTarget = {
      ...e.target,
      type: 'OptionsCheckBoxList',
      value: items,
      name,
    };
    const event = { ...e, target: tempTarget };
    setSelectedItems(items);
    if (onChange && onChange instanceof Function) {
      onChange(event);
    }
  };

  const handleChecked = (name, value) => selectedItems.map((p) => String(p)).indexOf(value) > -1;

  return (
    <div>
      {loaded === true ? (
        <CheckboxList
          name={name}
          title={title}
          options={options}
          onChange={handleChange}
          handleChecked={handleChecked}
          tabIndexStart={tabIndexStart}
          errorText={errorMessage || errorText}
          disabled={disabled}
        />
      ) : (
        <Loader />
      )}
    </div>
  );
};

ApiCheckboxList.propTypes = {
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  tabIndexStart: PropTypes.number,
  errorText: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ApiCheckboxList;
