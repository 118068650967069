import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';
import Loader from '../Loader';
import noData from '../../Images/no-data.png';

const CloudabilityLineChart = (props) => {
  const {
    currentProduct, serviceCosts, businessUnit, timePeriods,
  } = props;
  const [loaded, setLoaded] = useState(false);

  const [categories, setCategories] = useState(null);
  const [timePer, setTimePeriods] = useState(null);
  const [allenvironmentInstances, setAllEnvInstances] = useState(null);
  const [dataFound, setDataFound] = useState(true);
  const [series, setSeries] = useState(null);

  // Calculate top cost for ascendon cloudability data for service
  function calcTopCosts(serviceArr) {
    const costArr = [];
    for (let i = 0; i < serviceArr.length; i++) {
      let foundInCosts = false;
      let serviceCost = serviceArr[i].cost.toString().replace(/[$,]+/g, '');
      serviceCost = parseFloat(serviceCost);
      for (let j = 0; j < costArr.length; j++) {
        if (
          costArr[j].environmentInstance === serviceArr[i].environmentInstance
        ) {
          foundInCosts = true;
          costArr[j].cost += serviceCost;
          break;
        }
      }
      if (!foundInCosts) {
        costArr.push({
          environmentInstance: serviceArr[i].environmentInstance,
          cost: serviceCost,
        });
      }

      if (i >= 100) {
        break;
      }
    }
    costArr.sort((a, b) => b.cost - a.cost);
    return JSON.stringify(costArr.map((x) => x.environmentInstance).slice(0, 5));
  }

  // Format data for apex line chart
  function dataFormating(timePeriodsList, businessData) {
    const startEndDate = timePeriodsList
      .filter((x) => x.filterDay === timePeriods)
      .map((a) => a.filterDate);
    const costDataArray = [];
    let dateArray = [];
    /* eslint-disable */
    for (const i in businessData) {
      const costArray = [];
      const { businessUnits } = businessData[i];
      for (const j in businessUnits) {
        if (
          new Date(businessUnits[j].date)
          >= new Date(startEndDate.map((d) => d.startDate))
          && new Date(businessUnits[j].date)
          <= new Date(startEndDate.map((d) => d.endDate))
        ) {
          const cost = businessUnits[j].cost.toFixed(2);
          costArray.push(cost >= 0 ? cost : 0);
        }
      }
      costDataArray.push({ name: businessData[i].title, data: costArray });
    }
    /* eslint-enable */
    try {
      dateArray = businessData[0].businessUnits
        .filter(
          (x) => new Date(x.date)
            >= new Date(startEndDate.map((x) => x.startDate))
            && new Date(x.date) <= new Date(startEndDate.map((x) => x.endDate)),
        )
        .map((a) => a.date);
    } catch (e) {
      setDataFound(false);
    }
    setCategories(dateArray);
    setSeries(costDataArray);
    setTimePeriods(timePeriodsList);
    setLoaded(true);
  }

  // Bind business unit with time periods
  function bindAllBusinessUnit(timePeriodsList, businessData) {
    dataFormating(timePeriodsList, businessData);
    setAllEnvInstances(businessData);
  }

  // Get daily Business Data based on product, total cost, time period selected
  function dailyBusinessData(timePeriodsList) {
    const topCosts = calcTopCosts(serviceCosts);
    let timePeriod;
    const currentDate = new Date();
    if (
      timePeriods === 'last_quarter'
      && currentDate >= new Date(currentDate.getFullYear(), 0, 1)
      && currentDate <= new Date(currentDate.getFullYear(), 2, 31)
    ) {
      timePeriod = 'last_year';
    } else {
      timePeriod = timePeriods === 'last_year' ? 'last_year' : 'this_year';
    }
    axios
      .get(
        `/api/AscendonCost/GetEnvironmentInstanceCosting?ascProduct=${currentProduct}&topCosts=${topCosts}&timePeriod=${timePeriod}`,
      )
      .then((response) => {
        bindAllBusinessUnit(timePeriodsList, response.data);
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          console.error(err);
        }
      });
  }

  // Get Ascendon Time Period list for mapping
  function getTimePeriodList() {
    axios
      .get('/api/AscendonCost/GetTimePeriodsList')
      .then((response) => {
        dailyBusinessData(response.data);
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          console.error(err);
        }
      });
  }

  // Get daily cost for each environment instance.
  function dailyCostingBySingleEnvInstance(
    timePeriodsList,
    environmentInstance,
  ) {
    let timePeriod;
    const currentDate = new Date();
    if (
      timePeriods === 'last_quarter'
      && currentDate >= new Date(currentDate.getFullYear(), 0, 1)
      && currentDate <= new Date(currentDate.getFullYear(), 2, 31)
    ) {
      timePeriod = 'last_year';
    } else {
      timePeriod = timePeriods === 'last_year' ? 'last_year' : 'this_year';
    }
    axios
      .get(
        `/api/AscendonCost/GetSingleEnvironmentInstanceCosting?view=${currentProduct}&timePeriod=${timePeriod}&environmentInstance=${environmentInstance.replace(
          / /g,
          '_',
        )}`,
      )
      .then((response) => {
        bindAllBusinessUnit(timePeriodsList, response.data);
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          console.error(err);
        }
      });
  }

  useEffect(() => {
    getTimePeriodList();
  }, []);

  // When business Unit changes from props format data
  useEffect(() => {
    setLoaded(false);
    if (timePer === null) {
      getTimePeriodList();
    } else {
      const filterData = allenvironmentInstances.filter(
        (a) => a.environmentInstance === businessUnit,
      );

      if (businessUnit === 'all') {
        dailyBusinessData(timePer);
      } else if (filterData.length === 0) {
        dailyCostingBySingleEnvInstance(timePer, businessUnit);
      } else {
        let dataByBusinessUnit = [];
        if (businessUnit === 'all') {
          dataByBusinessUnit = allenvironmentInstances;
        } else {
          dataByBusinessUnit = allenvironmentInstances.filter(
            (a) => a.title === businessUnit.replace(/_/g, ' '),
          );
        }
        dataFormating(timePer, dataByBusinessUnit);
      }
    }
  }, [businessUnit]);

  // Set option value for Apex line chart
  function getOptionValues() {
    const optionDate = {
      chart: {
        width: '100%',
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'],
          opacity: 0.5,
        },
      },
      yaxis: {
        title: {
          text: '$ (Cost)',
        },
      },
      tooltip: {
        y: {
          formatter(val) {
            return `$ ${val
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
          },
        },
      },
      xaxis: {
        type: 'datetime',
        categories,
      },
      legend: {
        show: true,
        position: 'top',
        fontSize: '8px',
        horizontalAlign: 'right',
      },
      responsive: [
        {
          breakpoint: 767,
          options: {
            yaxis: {
              labels: {
                show: false,
              },
              title: {
                text: '$ (Cost)',
              },
            },
          },
        },
      ],
    };
    return optionDate;
  }
  const option = getOptionValues(categories);

  return (
    <>
      {dataFound ? (
        loaded ? (
          <div className="line-chart">
            <Chart options={option} series={series} type="line" />
          </div>
        ) : (
          <Loader />
        )
      ) : (
        <div>
          <img alt="noDataImg" src={noData} className="img-fluid d-block m-auto w-50" />
          <p className="text-center mt-10 font-size-lg font-weight-normal text-muted">
            Daily data not available for this Product.
          </p>
        </div>
      )}
    </>
  );
};

export default CloudabilityLineChart;
