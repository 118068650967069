import React, { useEffect, useState } from 'react';
import { Router, navigate } from '@reach/router';

import Health from './Screens/Health';
import AccessDenied from './Screens/AccessDenied';
import Home from './Screens/Home';
import UserProfile from './Screens/UserProfile';
import AscendonCloudability from './Screens/AscendonCloudabilityCost';
import SideNavigator from './Components/SideNavigator';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Loader from './Components/Loader';
import ScrollToTop from './Components/ScrollToTop';
import SignIn from './Components/SignIn';
import People from './Screens/People';
import AscendonApiStats from './Screens/AscendonApiStats';
import Accounts from './Screens/Accounts';
import Subscriptions from './Screens/Subscriptions';
import Networks from './Screens/Networks';
import Education from './Screens/Education';
import AccountProfile from './Screens/AccountProfile';
import VirtualNetworkProfile from './Screens/VirtualNetworkProfile';
import Roles from './Screens/Roles';
import CloudGuru from './Screens/CloudGuru';
import Requests from './Screens/Requests';
import PostEventSummaries from './Screens/PostEventSummaries';
import StockWidget from './Screens/StockWidget';
import HealthEvents from './Screens/HealthEvents';
import VendorServices from './Screens/VendorServices';
import ProductAnnouncement from './Screens/ProductAnnouncement';
import AwsServiceDashboard from './Screens/AwsServiceDashboard';
import AwsServiceAnalytics from './Screens/AwsServiceAnalytics';
import SRCustomerDashboard from './Screens/SRCustomerDashboard';
import SubscriptionProfile from './Screens/SubscriptionProfile';

// Contexts
import { RequestsContext } from './Context/RequestsContext';
import { MenuContext } from './Context/MenuContext';
import { PageContext } from './Context/PageContext';
import { AnnouncementContext } from './Context/AnnouncementContext';
import SubscriptionRequests from './Screens/SubscriptionRequests';
import TrainingOverride from './Screens/TrainingOverride';
import SubscriptionAccessRequests from './Screens/SubscriptionAccessRequests';
import AzureSubscriptionEditRequests from './Screens/AzureSubscriptionEditRequests';

const App = () => {
  const [user, setUser] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [startInterval, setStartInterval] = useState(0);
  function logUserDetail(data) {
    const userFullName = `${data.firstName} ${data.lastName}`;
    fetch(
      `/api/auth/logUserDetail?id=${data.employeeId}&userName=${userFullName}`,
    ).then((response) => {
      if (response.ok !== true) {
        throw new Error(response.statusText);
      }
    });
  }
  function whoAmI() {
    fetch('/api/auth/whoami')
      .then((response) => {
        if (response.ok !== true) {
          throw new Error(response.statusText);
        } else {
          return response.json();
        }
      })
      .then((newUser) => {
        if (newUser.isAuthenticated === true) {
          if (JSON.stringify(user) !== JSON.stringify(newUser)) {
            setUser(newUser);
            setLoaded(true);
            logUserDetail(newUser);
            if (localStorage.getItem('afterLogInReturnPath')) {
              navigate(localStorage.getItem('afterLogInReturnPath'));
              localStorage.removeItem('afterLogInReturnPath');
            }
          }
          setTimeout(() => setStartInterval(startInterval + 1), 120000);
        } else {
          setUser(null);
          setLoaded(true);
          localStorage.setItem('afterLogInReturnPath', window.location.pathname);
          navigate('/');
        }
      })
      .catch((e) => {
        if (e.name !== 'AbortError') {
          console.error(e);
        }
      });
  }
  useEffect(() => {
    whoAmI();
  }, []);

  useEffect(() => {
    if (startInterval > 0) {
      whoAmI();
    }
  }, [startInterval]);

  const { isAdmin } = user || {};
  return (
    <MenuContext>
      <PageContext>
        <RequestsContext>
          <div id="wrapper" className={user ? '' : 'loginWrapper'}>
            {loaded
              ? (
                <>
                  {/* Display SideNavigator only if user is loggedIn */}
                  {user && <SideNavigator user={user} />}
                  <div id="content-wrapper" className="d-flex flex-column">
                    <div id="content">
                      <AnnouncementContext>
                        <Header user={user} />
                      </AnnouncementContext>
                      <div className="container">
                        <Router>
                          {user && (<Home path="/" user={user} />)}
                          {isAdmin && (<Roles path="roles/:vendor" />)}
                          {isAdmin && (<Requests path="requests" />)}
                          {isAdmin && (<AzureSubscriptionEditRequests user={user} path="azurerequests" />)}
                          {isAdmin && (<SubscriptionRequests path="subscriptionrequests" />)}
                          {isAdmin && (<CloudGuru path="cloudguru" />)}
                          {user && (<SubscriptionRequests path="subscriptionrequests" />)}
                          {user && (<Accounts user={user} path="accounts" />)}
                          {user && (<AccountProfile path="accounts/:accountId" />)}
                          {user && (<Subscriptions user={user} path="subscriptions" />)}
                          {user && (<TrainingOverride user={user} path="trainingoverride/:vendor" />)}
                          {user && (<SubscriptionAccessRequests user={user} path="subscriptionaccessrequests" />)}
                          {user && (<SubscriptionProfile path="subscriptions/:subscriptionId" />)}
                          {user && (<People user={user} path="people" />)}
                          {user && (<UserProfile user={user} path="userProfile/:id" />)}
                          {user && (<Education path="education" user={user} />)}
                          {user && (<Networks path="virtualnetworks" user={user} />)}
                          {user && (<VirtualNetworkProfile path="virtualnetworks/:vnId" />)}
                          {user && (<AscendonCloudability user={user} path="cloudabilitycosting" />)}
                          {user && (<AscendonApiStats user={user} path="ascendonapistats" />)}
                          {user && (<ProductAnnouncement user={user} path="productannouncements" />)}
                          {user && (<HealthEvents user={user} path="awshealthevents" />)}
                          {user && (<VendorServices path="services" user={user} />)}
                          {user && (<PostEventSummaries path="posteventsummaries" user={user} />)}
                          {user && (<StockWidget path="stockwidget" user={user} />)}
                          {user && (<AwsServiceDashboard user={user} path="awsservicedashboard" />)}
                          {user && (<AwsServiceAnalytics path="awsserviceanalytics" />)}
                          {user && (<SRCustomerDashboard path="srcustomerdashboard" />)}
                          <Health path="health" />
                          <AccessDenied path="denied" />
                          <SignIn default />
                        </Router>
                      </div>
                    </div>
                    <Footer />
                  </div>
                </>
              )
              : <Loader />}
          </div>
          <ScrollToTop />
        </RequestsContext>
      </PageContext>
    </MenuContext>
  );
};
export default App;
