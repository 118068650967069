import MUIDataTable from 'mui-datatables';
import React, { useState, useEffect } from 'react';
import DonutChart from '../Common/DonutChart';

const AwsServiceCostDetails = ({ details }) => {
  const [awsCstDt, setAwsCstDet] = useState(details);
  const [activeTab, setActiveTab] = useState('graph');
  const [dataArray, setDataArray] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const formatDataForTable = (data) => {
    if (data) {
      return data.map((d) => ({
        awsServiceName: d.dimensions[0],
        cost: Number(d.metrics[0].sum).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }),
      }));
    }
    return [];
  };

  const formateDataForGraph = (data) => {
    if (data) {
      return data.map((d) => ({
        awsServiceName: d.dimensions[0],
        cost: Number(d.metrics[0].sum),
      }));
    }
    return [];
  };

  useEffect(() => {
    setDataArray(formatDataForTable(awsCstDt).map(Object.values));
  }, [awsCstDt]);

  useEffect(() => {
    setAwsCstDet(details);
  }, [details]);

  // For MUI tables
  const onChangeRowsPerPage = (rowsPerPagee) => {
    setRowsPerPage(rowsPerPagee);
  };
  const columns = [
    {
      name: 'Service Name',
    },
    {
      name: 'Cost',
      options: {
        setCellHeaderProps: () => ({
          className: 'centeredHeaderCell1',
        }),
        setCellProps: () => ({
          style: {
            textAlign: 'right',
            width: '175px',
          },
        }),
      },
    },
  ];
  const options = {
    filter: true,
    filterType: 'multiselect',
    fixedHeader: true,
    rowsPerPage,
    rowsPerPageOptions: [5, 10, 15, 20],
    onChangeRowsPerPage,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    downloadOptions: { filename: 'AwsServiceByCost.csv' },
    responsive: 'standard',
    customSort: (data, colIndex, order) => data.sort((a, b) => {
      if (a.data[colIndex].includes('$')) {
        // Sort cost values
        // eslint-disable-next-line radix
        const lhs = Number(
          a.data[colIndex].replace('$', '').replace(/,/g, ''),
        );
          // eslint-disable-next-line radix
        const rhs = Number(
          b.data[colIndex].replace('$', '').replace(/,/g, ''),
        );
        return (lhs < rhs ? -1 : 1) * (order === 'desc' ? 1 : -1);
      }
      // Sort aws service name values
      return (
        (a.data[colIndex] < b.data[colIndex] ? -1 : 1)
          * (order === 'desc' ? 1 : -1)
      );
    }),
  };

  return (
    <div className="row mb-2">
      <div className="col-12">
        <div className="card card-custom mb-4">
          <div className="card-header align-items-center">
            <div className="card-title align-items-center justify-content-end w-100">
              <ul className="nav nav-pills bg-nav-pills nav-justified mt-2 align-items-center justify-content-center h-100">
                <li className="nav-item">
                  <div
                    onClick={() => setActiveTab('graph')}
                    data-toggle="tab"
                    aria-expanded="false"
                    className={`nav-link font-weight-normal font-size-lg ${
                      activeTab === 'graph' ? 'active' : ''
                    }`}
                  >
                    Graph
                  </div>
                </li>
                <li className="nav-item">
                  <div
                    onClick={() => setActiveTab('table')}
                    data-toggle="tab"
                    aria-expanded="true"
                    className={`nav-link font-weight-normal font-size-lg ${
                      activeTab === 'table' ? 'active' : ''
                    }`}
                  >
                    Table
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="card-body">
            <div className="row justify-content-center justify-content-end-xs">
              <div className="col-12">
                <div className="tab-content p-0">
                  <div
                    className={`tab-pane ${
                      activeTab === 'graph' ? 'active' : ''
                    }`}
                    id="serviceCostingGraph"
                  >
                    <div className="row justify-content-center">
                      <div className="col-xl-6 col-lg-6 col-md-9 col-sm-10 col-12">
                        <DonutChart
                          pieChartCostData={formateDataForGraph(awsCstDt).map(
                            (a) => a.cost,
                          )}
                          pieChartLableData={formateDataForGraph(awsCstDt).map(
                            (a) => a.awsServiceName,
                          )}
                          title="Compare Aws Service Cost"
                          chartType="donut"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane ${
                      activeTab === 'table' ? 'active' : ''
                    }`}
                    id="serviceCostineTable"
                  >
                    <div className="table-responsive">
                      <MUIDataTable
                        data={dataArray}
                        columns={columns}
                        options={options}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AwsServiceCostDetails;
