import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import './signIn.css';

const SignIn = () => {
  const [pageName, setPageName] = useState('');

  useEffect(() => {
    const signOutTime = localStorage.getItem('signOutTime');
    const signOutDuration = new Date(signOutTime).getHours() * 60 + new Date(signOutTime).getMinutes() + (new Date(signOutTime).getSeconds() / 60);
    const currentDuration = new Date().getHours() * 60 + new Date().getMinutes() + (new Date().getSeconds() / 60);
    if ((currentDuration - signOutDuration) < 1) {
      setPageName(localStorage.getItem('pageName'));
    } else {
      localStorage.setItem('pageName', 'signin');
    }
  }, []);
  return (
    <div className="row justify-content-center">
      <div className="col-12 col-sm-10 col-md-5 col-lg-5 col-xl-4">
        {/* <!-- Begin::Login Section --> */}
        <div className="login-box login-sidebar">
          <div className="login-section">
            <div className="custom-d-flex">
              <div className="signin-section relative">

                <div className="h-100 py-3-5 singin-info">
                  <h2 className={`heading-text ${pageName === 'signout' ? '' : 'mb-10'}`}>Arcus Cloud Portal</h2>
                  {pageName === 'signout' ? (
                    <p className="logout-msg">
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="mr-3"
                      />
                      You are logged out successfully

                    </p>
                  ) : ''}
                  <div className="absolute-sec">
                    <p className="text-center mb-5">{`Sign in ${pageName === 'signout' ? 'again' : ''} to get started.`}</p>
                    <a
                      href="/api/auth/signin"
                      className="btn btn-primary btn-block"
                    >
                      Sign In
                      <span className="svg-icon svg-icon-primary svg-icon-2x header-icon">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                        >
                          <g
                            stroke="none"
                            strokeWidth="1"
                            fill="none"
                            fillRule="evenodd"
                          >
                            <rect x="0" y="0" width="24" height="24" />
                            <rect
                              fill="#000000"
                              opacity="0.3"
                              transform="translate(9.000000, 12.000000) rotate(-270.000000) translate(-9.000000, -12.000000) "
                              x="8"
                              y="6"
                              width="2"
                              height="12"
                              rx="1"
                            />
                            <path
                              d="M20,7.00607258 C19.4477153,7.00607258 19,6.55855153 19,6.00650634 C19,5.45446114 19.4477153,5.00694009 20,5.00694009 L21,5.00694009 C23.209139,5.00694009 25,6.7970243 25,9.00520507 L25,15.001735 C25,17.2099158 23.209139,19 21,19 L9,19 C6.790861,19 5,17.2099158 5,15.001735 L5,8.99826498 C5,6.7900842 6.790861,5 9,5 L10.0000048,5 C10.5522896,5 11.0000048,5.44752105 11.0000048,5.99956624 C11.0000048,6.55161144 10.5522896,6.99913249 10.0000048,6.99913249 L9,6.99913249 C7.8954305,6.99913249 7,7.89417459 7,8.99826498 L7,15.001735 C7,16.1058254 7.8954305,17.0008675 9,17.0008675 L21,17.0008675 C22.1045695,17.0008675 23,16.1058254 23,15.001735 L23,9.00520507 C23,7.90111468 22.1045695,7.00607258 21,7.00607258 L20,7.00607258 Z"
                              fill="#000000"
                              fillRule="nonzero"
                              opacity="0.3"
                              transform="translate(15.000000, 12.000000) rotate(-90.000000) translate(-15.000000, -12.000000) "
                            />
                            <path
                              d="M16.7928932,9.79289322 C17.1834175,9.40236893 17.8165825,9.40236893 18.2071068,9.79289322 C18.5976311,10.1834175 18.5976311,10.8165825 18.2071068,11.2071068 L15.2071068,14.2071068 C14.8165825,14.5976311 14.1834175,14.5976311 13.7928932,14.2071068 L10.7928932,11.2071068 C10.4023689,10.8165825 10.4023689,10.1834175 10.7928932,9.79289322 C11.1834175,9.40236893 11.8165825,9.40236893 12.2071068,9.79289322 L14.5,12.0857864 L16.7928932,9.79289322 Z"
                              fill="#000000"
                              fillRule="nonzero"
                              transform="translate(14.500000, 12.000000) rotate(-90.000000) translate(-14.500000, -12.000000) "
                            />
                          </g>
                        </svg>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SignIn;
