import React from 'react';
import PropTypes from 'prop-types';

const Spinner = (props) => {
  const { bootstrapCssColorClass, centerSpinner } = props;

  return (
    <div className={centerSpinner}>
      .
      <div
        className={`spinner-border text-${bootstrapCssColorClass}`}
        role="status"
      >
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

Spinner.propTypes = {
  bootstrapCssColorClass: PropTypes.oneOf([
    'warning',
    'info',
    'danger',
    'success',
    'primary',
    'secondary',
  ]),
};
Spinner.defaultProps = {
  bootstrapCssColorClass: 'primary',
};

export default Spinner;
