import React from 'react';
import PropTypes from 'prop-types';
import Alert from '../../Common/Alert';

const NetworkSubnetTab = (props) => {
  const { vnSubnets, getNameTag } = props;

  const subnetsMarkup = vnSubnets && vnSubnets.map((s) => {
    const {
      AvailabilityZone,
      SubnetId,
      CidrBlock,
      Tags,
      AvailableIpAddressCount,
    } = s;
    return (
      <div className="div-row" key={SubnetId}>
        <div className="div-column">{SubnetId}</div>
        <div className="div-column">{getNameTag(Tags)}</div>
        <div className="div-column">{AvailabilityZone}</div>
        <div className="div-column">{CidrBlock}</div>
        <div className="div-column">{AvailableIpAddressCount}</div>
      </div>
    );
  });

  return (
    <div className="custom-table">
      {vnSubnets && vnSubnets.length > 0 ? (
        <div className="respnsive-custom-table">
          <div className="div-table" width="100%">
            <div className="div-thead">
              <div className="div-row">
                <div className="div-column-th">Id</div>
                <div className="div-column-th">Name</div>
                <div className="div-column-th">AZ</div>
                <div className="div-column-th">CIDR</div>
                <div className="div-column-th">Available IPs</div>
              </div>
            </div>
            <div className="div-tbody">
              {subnetsMarkup}
            </div>
          </div>
        </div>
      ) : (
        <Alert message="No subnets found." alertClass="info" />
      )}
    </div>
  );
};

NetworkSubnetTab.propTypes = {
  vnSubnets: PropTypes.arrayOf(PropTypes.shape({
    SubnetId: PropTypes.string.isRequired,
    AvailabilityZone: PropTypes.string.isRequired,
    CidrBlock: PropTypes.string.isRequired,
    Tags: PropTypes.arrayOf(PropTypes.shape({
      Key: PropTypes.string.isRequired,
      Value: PropTypes.string,
    })),
  })).isRequired,
  getNameTag: PropTypes.func.isRequired,
};

export default NetworkSubnetTab;
