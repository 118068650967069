import React, { useState } from 'react';

const Tab = (props) => {
  const [navigators] = useState(props.tabList);
  const [selectedTab, setSelectedTab] = useState(props.selected);

  function handleClick(value) {
    setSelectedTab(value);
    props.onClick(value);
  }

  return (
    <div>
      <>
        <div className="nav nav-tabs tabs-left sideways">
          {navigators.map((navigator) => (
            <div className="horizontal-tab-step" key={navigator.value}>
              <div
                className={`horizontal-tab-wrapper ${
                  selectedTab === navigator.value ? 'active' : ''
                }`}
                data-toggle="tab"
                onClick={() => handleClick(navigator.value)}
              >
                <div className="horizontal-tab-label">
                  <h3 className="horizontal-tab-title">{navigator.label}</h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    </div>
  );
};

export default Tab;
