import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Alert from '../Components/Common/Alert';
import Loader from '../Components/Loader';
import Tab from '../Components/Common/Tab';
import VirtualNetworkInfo from '../Components/Network/InfoTab';
import NetworkSubnetTab from '../Components/Network/SubnetTab';
import NetworkComputeTab from '../Components/Network/ComputeTab';
import NetworkInterfaceTab from '../Components/Network/InterfaceTab';
import { Context as MenuContext } from '../Context/MenuContext';

const VirtualNetworkProfile = (props) => {
  const { vnId } = props;
  const [tab, setTab] = useState('vnInfo');
  const tabList = [
    { value: 'vnInfo', label: 'Information' },
    { value: 'subnet', label: 'Subnets' },
    { value: 'compute', label: 'Compute' },
    { value: 'interface', label: 'Interfaces' },
  ];
  const [loaded, setLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [vnSubnets, setSubnets] = useState([]);
  const [vnNetworkInterfaces, setNetworkInterfaces] = useState([]);
  const [vnDetails, setDetails] = useState({});

  // Get selectedMenu data from Menu Context
  const [, setSelectedMenu] = useContext(MenuContext);
  // Change menu css with peope page is loaded.
  useEffect(() => {
    setSelectedMenu('networks');
  }, []);
  const getNameTag = (tags) => {
    const nameTag = tags && tags.find((t) => t.Key.toLowerCase() === 'name');
    const { Value } = nameTag || {};
    return Value || <span className="text-warning">Not Set</span>;
  };
  const updatePage = () => {
    setLoaded(false);
    axios
      .get(`/api/virtualnetwork/${vnId}`)
      .then((response) => {
        if (Object.keys(response.data).length > 0) {
          const {
            Subnets,
            Vpc,
            NetworkInterfaces,
            RegionName,
          } = response.data || {};
          setSubnets(Subnets);
          setNetworkInterfaces(NetworkInterfaces);
          const {
            Tags,
            IsDefault,
            VpcId,
            CidrBlock,
            OwnerId,
          } = Vpc || {};
          setDetails({
            VnId: VpcId,
            VnName: getNameTag(Tags),
            VnRegion: RegionName,
            VnCidr: CidrBlock,
            VnOwnerId: OwnerId,
            VnIsDefualt: IsDefault,
          });
        }
        setLoaded(true);
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          setErrorMessage(err.message);
          setLoaded(true);
        }
      });
  };

  useEffect(() => {
    updatePage();
  }, []);

  useEffect(() => {
    updatePage();
  }, [vnId]);

  function vnDetailTab(tabName) {
    switch (tabName) {
      case 'vnInfo':
        return Object.keys(vnDetails).length > 0 && <VirtualNetworkInfo data={vnDetails} />;
      case 'subnet':
        return <NetworkSubnetTab vnSubnets={vnSubnets} getNameTag={getNameTag} />;
      case 'compute':
        return <NetworkComputeTab vnId={vnId} />;
      case 'interface':
        return <NetworkInterfaceTab subnets={vnSubnets} interfaces={vnNetworkInterfaces} />;
      default:
        return '';
    }
  }

  return (
    <div className="row gutter-b">
      {loaded ? (
        <>
          <div className="col-12">
            <div className="card card-custom gutter-b">
              <div className="card-header align-items-center">
                <div className="card-title align-items-start flex-column">
                  <h3 className="card-label">
                    {Object.keys(vnDetails).length > 0 && typeof vnDetails.VnName === 'string'
                      ? vnDetails.VnName
                      : 'Virtual Network Specific Page'}
                  </h3>
                </div>
              </div>
              {errorMessage ? (
                <div className="card-body">
                  <Alert
                    message={errorMessage}
                    alertClass="light-danger"
                    onClose={(val) => setErrorMessage(val)}
                  />
                </div>
              ) : (
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-12">
                      <div className="horizontal-tab">
                        <Tab
                          onClick={(e) => setTab(e)}
                          tabList={tabList}
                          selected="vnInfo"
                        />
                        <div className="tab-content">
                          {vnDetailTab(tab)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

VirtualNetworkProfile.propTypes = {
  vnId: PropTypes.string,
};

export default VirtualNetworkProfile;
