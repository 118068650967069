import React, { useContext } from 'react';
import { Link } from '@reach/router';
import { FaPlus } from 'react-icons/fa';
import { Context } from '../../../Context/RequestsContext';

const RequestInfo = () => {
  // Model popup open from Request Context
  const [modelPopup, setModelPopup] = useContext(Context);
  // eslint-disable-next-line no-unused-vars
  const { showAccntReqModal, showAddExistingModel } = modelPopup;
  const onAccntReqModal = () => {
    setModelPopup({ ...modelPopup, showAccntReqModal: true });
  };

  return (
    <div className="row">
      <div className="col-12">
        <h4 className="custom-tooltip-text">
          By default only account requests that don&lsquo;t have an account
          number assigned will show up.
          <ul className="pl-5">
            <li>Use the filters or search above to refine the criteria.</li>
            <li>
              Click the
              {' '}
              <strong className="p-0 m-0">
                <FaPlus />
                {' '}
                Add Existing
              </strong>
              {' '}
              Account Request button in the upper right to enter data for
              existing accounts.
            </li>
            <li>
              <strong>
                {' '}
                Alternatively if you are really requesting a new account you can
                go to the
                {' '}
                <Link
                  className="text-info"
                  to="/accounts"
                  onClick={onAccntReqModal}
                >
                  {' '}
                  New Account
                </Link>
                {' '}
                screen to begin the workflow.
              </strong>
            </li>
          </ul>
        </h4>
      </div>
    </div>
  );
};

export default RequestInfo;
