import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { navigate } from '@reach/router';
import axios from 'axios';
import HeaderWithInfo from '../Components/Common/HeaderWithInfo';
import Loader from '../Components/Loader';
import EditEmployeeModal from '../Components/People/EditEmpModal';
import PeopleSearch from '../Components/People/Search';
import PeopleResult from '../Components/People/Results';
import Alert from '../Components/Common/Alert';
import { Context } from '../Context/MenuContext';
import { Context as PageContext } from '../Context/PageContext';
import { saveUserPageVisitHistory } from '../store/actions/peopleAction';

const initialState = {
  results: [],
  loaded: true,
  scrolling: false,
  matchCount: 0,
  showingTotal: 0,
  count: 0,
  index: 0,
  currentSearch: {
    trainingLevels: [],
  },
  errorMessage: '',
};

const People = (props) => {
  const [showEditEmpModal, setShowEditEmpModal] = useState(false);
  const [results, setResults] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [scrolling, setScrolling] = useState(false);
  const [matchCount, setMatchCount] = useState(0);
  const [showingTotal, setShowingTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [currentSearch, setCurrentSearch] = useState({ trainingLevels: [] });
  const [errorMessage, setErrorMessage] = useState('');
  const [pageContextState, setPageContextState] = useContext(PageContext);
  const { peoplePage } = pageContextState;
  const dispatch = useDispatch();
  const { user } = props;

  // Get user login details
  useEffect(() => {
    if (peoplePage === '') {
      setPageContextState({
        ...pageContextState,
        peoplePage: 'people',
      });
      const apiUrl = `/api/Auth/SaveUserPageVisit/${user.employeeId}/people`;
      dispatch(saveUserPageVisitHistory(apiUrl));
    }
  }, [peoplePage]);

  // Get selectedMenu data from Menu Context
  const [, setSelectedMenu] = useContext(Context);

  // Change menu css with peope page is loaded.
  useEffect(() => {
    setSelectedMenu('people');
  }, []);

  useEffect(() => {
    const { searchType, criteria, trainingLevels } = currentSearch;

    // search only if criteria or trainingLevel is selected
    if (criteria !== undefined) {
      axios
        .get(
          `/api/people/search?t=${searchType}&q=${criteria}&l=${trainingLevels}&i=${pageIndex}`,
        )
        .then((response) => {
          const result = response.data;
          setResults(results.concat(result.items || []));
          setMatchCount(result.matchCount);
          setShowingTotal(result.showingTotal);
          setCount(result.count);
          setPageIndex(result.index);
          setLoaded(true);
          setScrolling(false);
        })
        .catch((err) => {
          if (err.name !== 'AbortError') {
            setErrorMessage(err.message);
          }
        });
    }
  }, [currentSearch, pageIndex]);

  const parseQueryString = () => {
    const { location } = props;
    const { search } = location;
    return queryString.parse(search);
  };

  const nextPage = () => {
    if (scrolling !== true && matchCount > showingTotal) {
      setScrolling(true);
      setPageIndex(pageIndex + 1);
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (Math.round(scrollTop) + clientHeight === scrollHeight) {
      nextPage();
    }
  };

  const executeSearch = (searchType, criteria, trainingLevels) => {
    if (
      currentSearch.searchType !== searchType
      || currentSearch.criteria !== criteria
      || currentSearch.trainingLevels.length !== trainingLevels.length
      || !currentSearch.trainingLevels.every((e) => trainingLevels.includes(e))
    ) {
      setResults([]);
      setLoaded(false);
      setPageIndex(0);
      setCurrentSearch({
        searchType,
        criteria,
        trainingLevels,
      });
      const stateVar = {
        count,
        currentSearch: {
          searchType,
          criteria,
          trainingLevels,
        },
        loaded: false,
        pageIndex: 0,
        results: [],
        scrolling,
        showingTotal,
      };
      navigate(`/people?t=${searchType}&q=${criteria}&l=${trainingLevels}`, {
        state: stateVar,
        replace: true,
      });
    }
  };

  const clearSearch = (trainingLevels) => {
    const defaultState = initialState;
    const currentSearchClr = {
      ...defaultState.currentSearch,
      trainingLevels: trainingLevels || [],
    };
    setResults([]);
    setLoaded(true);
    setScrolling(false);
    setMatchCount(0);
    setShowingTotal(0);
    setCount(0);
    setCurrentSearch(currentSearchClr);
    setErrorMessage('');
  };

  const onEmpModalCancel = (newValue) => {
    setShowEditEmpModal(newValue);
  };
  const qs = parseQueryString();

  return (
    <>
      <div className="row gutter-b">
        <div className="col-12">
          {/* <!-- Begin::Page Content --> */}
          <div className="card card-custom mb-4">
            <div className="card-header align-items-center">
              <div className="card-title align-items-center justify-content-between w-100">
                <HeaderWithInfo headerName="People" />
                {user.isAdmin && (
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => setShowEditEmpModal(true)}
                >
                  <span className="svg-icon svg-light">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth="0"
                        // eslint-disable-next-line react/no-unknown-property
                      t="1551322312294"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      height="1.2em"
                      width="1.2em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <defs />
                      <path d="M474 152m8 0l60 0q8 0 8 8l0 704q0 8-8 8l-60 0q-8 0-8-8l0-704q0-8 8-8Z" />
                      <path d="M168 474m8 0l672 0q8 0 8 8l0 60q0 8-8 8l-672 0q-8 0-8-8l0-60q0-8 8-8Z" />
                    </svg>
                  </span>
                  {' '}
                  Edit Employee Training
                </button>
                )}
              </div>
            </div>
            <div className="card-body people-search">
              <div>
                <PeopleSearch
                  selectedValue={qs.t}
                  search={qs.q}
                  levels={qs.l}
                  executeSearch={executeSearch}
                  clearSearch={clearSearch}
                />
              </div>
            </div>
          </div>
          <div className="row">
            {errorMessage && (
            <div className="col-12">
              <Alert
                message={errorMessage}
                alertClass="light-danger"
                onClose={(val) => setErrorMessage(val)}
              />
            </div>
            )}
          </div>
          {/* <!-- Begin::Search list --> */}
          {loaded ? (
            <div>
              <h6 className="text-inverse h6">
                {matchCount > 0 ? (
                  <span>
                    {showingTotal}
                    {' '}
                    of
                    {' '}
                    {matchCount}
                  </span>
                ) : null}
              </h6>
              <PeopleResult people={results} onScroll={handleScroll} />
              {scrolling ? <Loader /> : null}
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </div>
      {showEditEmpModal && (
      <>
        <div className="log-out-layer" />
        <EditEmployeeModal onCancel={onEmpModalCancel} />
      </>
      )}
    </>
  );
};

export default People;
