import React from 'react';
import noData from '../../Images/no-data.png';

const NoRecordFound = () => (
  <>
    <div className="card mb-3">
      <div className="card-body">
        <div className="text-center">
          <div className="width-300px m-auto">
            <img src={noData} className="img-fluid d-block m-auto w-50" alt="No Record Data" />
          </div>
          <p className="mt-3 mb-0">No Record Found</p>
        </div>
      </div>
    </div>
  </>
);

export default NoRecordFound;
