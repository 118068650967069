import axios from 'axios';
import {
  GET_CLOUDABILITY_COST,
  ERROR_CLOUDABILITY_COST,
  GET_CLOUDABILITY_CUSTOMER_COST,
  ERROR_CLOUDABILITY_CUSTOMER_COST,
  GET_CUSTOMER_CSV,
  ERROR_CUSTOMER_CSV,
  GET_THIRTY_DAYS_COST,
  ERROR_THIRTY_DAYS_COST,
  GET_SIXTY_DAYS_COST,
  ERROR_SIXTY_DAYS_COST,
  GET_ASCD_STATS_COUNT,
  GET_ASCD_STATS_EXEC_TIME,
  GET_ASCD_STATS_DATA,
  ERROR_ASCD_STATS,
} from '../types';

export const getCloudabilityCost = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_CLOUDABILITY_COST,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_CLOUDABILITY_COST,
      payload: error,
    });
  }
};

export const getCustomerCost = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_CLOUDABILITY_CUSTOMER_COST,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_CLOUDABILITY_CUSTOMER_COST,
      payload: error,
    });
  }
};

export const getCustomerCsvData = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_CUSTOMER_CSV,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_CUSTOMER_CSV,
      payload: error,
    });
  }
};

export const getThirtyDaysCostData = (url) => async (dispatch) => {
  try {
    const res = await axios.get(url);
    dispatch({
      type: GET_THIRTY_DAYS_COST,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_THIRTY_DAYS_COST,
      payload: error,
    });
  }
};

export const getSixtyDaysCostData = (url) => async (dispatch) => {
  try {
    const res = await axios.get(url);
    dispatch({
      type: GET_SIXTY_DAYS_COST,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_SIXTY_DAYS_COST,
      payload: error,
    });
  }
};

export const getStatsApiCount = (url) => async (dispatch) => {
  try {
    const res = await axios.get(url);
    dispatch({
      type: GET_ASCD_STATS_COUNT,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_ASCD_STATS,
      payload: error,
    });
  }
};

export const getStatsExecTime = (url) => async (dispatch) => {
  try {
    const res = await axios.get(url);
    dispatch({
      type: GET_ASCD_STATS_EXEC_TIME,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_ASCD_STATS,
      payload: error,
    });
  }
};

export const getStatsData = (url) => async (dispatch) => {
  try {
    const res = await axios.get(url);
    dispatch({
      type: GET_ASCD_STATS_DATA,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_ASCD_STATS,
      payload: error,
    });
  }
};
