import React, { useState } from 'react';
import ComputeInstances from './Instances';
import ComputeServerless from './Serverless';
import ComputeDatabases from './Databases';
import ComputeContainers from './Container';

const NetworkComputeTab = (props) => {
  const { vnId } = props;
  const [activeInstances, setActiveInstances] = useState(true);
  const [activeServerless, setActiveServerless] = useState(false);
  const [activeDatabases, setActiveDatabases] = useState(false);
  const [activeContainers, setActiveContainers] = useState(false);

  function collapseSection(button) {
    switch (button) {
      case 'instances':
        setActiveInstances(!activeInstances);
        setActiveServerless(false);
        setActiveDatabases(false);
        setActiveContainers(false);
        break;
      case 'serverless':
        setActiveServerless(!activeServerless);
        setActiveInstances(false);
        setActiveDatabases(false);
        setActiveContainers(false);
        break;
      case 'databases':
        setActiveDatabases(!activeDatabases);
        setActiveInstances(false);
        setActiveServerless(false);
        setActiveContainers(false);
        break;
      case 'containers':
        setActiveContainers(!activeContainers);
        setActiveInstances(false);
        setActiveServerless(false);
        setActiveDatabases(false);
        break;
      default:
        return '';
    }
    return '';
  }

  return (
    <div className="tab-pane active" id="messages-v">
      <div className="accordion" id="accordion">
        <ComputeInstances
          isOpen={activeInstances}
          vnId={vnId}
          onClick={(e) => collapseSection(e)}
        />
        <ComputeServerless
          isOpen={activeServerless}
          vnId={vnId}
          onClick={(e) => collapseSection(e)}
        />
        <ComputeDatabases
          isOpen={activeDatabases}
          vnId={vnId}
          onClick={(e) => collapseSection(e)}
        />
        <ComputeContainers
          isOpen={activeContainers}
          vnId={vnId}
          onClick={(e) => collapseSection(e)}
        />
      </div>
    </div>
  );
};
export default NetworkComputeTab;
