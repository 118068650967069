import React from 'react';
import { Link } from '@reach/router';

const NetworkTable = ({ networkDataList }) => (
  <div className="card-body">
    <div className="network-table network-responsive">
      <div className="d-flex- justify-content-between- network-table-row row">
        <div className="col-12">
          <div className="d-flex">
            <div className="network-table-data col-sm-5 col-9">Id</div>
            <div className="network-table-data col-sm-2 col-3 pl-6">Region</div>
            <div className="network-table-data col-sm-4 col-8 text-right">
              Account
            </div>
            <div className="network-table-data col-sm-1 d-none d-sm-block" />
          </div>
        </div>
      </div>
      {/* Show max 4 list */}
      {networkDataList.length > 0
        && networkDataList.slice(0, 4).map((data) => (
          <Link
            key={data.id}
            to={`/virtualnetworks/${data.id}`}
            className="d-flex networkBorder tooltip-custom align-items-center-"
            data-title="Click to know more..."
          >
            <div className="col-md-5 col-sm-5 col-9 pl-0">
              <div className="d-flex flex-column align-items-start network-table-td">
                <span className="text-dark text-hover-primary font-size-lg font-weight-500 mr-2">
                  <span className="text-primary mr-1">{data.name}</span>
                  <span>{data.id}</span>
                </span>
                <span className="text-muted font-weight-normal font-size-xs">
                  {data.cidr}
                </span>
              </div>
            </div>
            <div className="col-md-2 col-sm-2 col-3">
              <div className="d-flex flex-column network-table-td">
                <div className="text-nowrap">{data.region}</div>
              </div>
            </div>
            <div className="col-md-4 col-sm-4 col-8">
              <div className="d-flex flex-column align-items-end text-xs-right network-table-td">
                <span className="text-dark text-hover-primary font-size-lg font-weight-500">
                  <span>{data.owner}</span>
                </span>
                <div className="font-weight-normal text-right">
                  <span className="text-inverse">{data.accountName}</span>
                  <span className="text-muted">{data.accountNumber}</span>
                </div>
              </div>
            </div>
            <div className="col-sm-1 d-none d-sm-block text-right">
              <div className="navi-icon network-table-td">
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  {/* begin::Svg Icon */}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                        fill="#000000"
                        fillRule="nonzero"
                      />
                      <path
                        d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        opacity="0.3"
                        transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                      />
                    </g>
                  </svg>
                  {/* end::Svg Icon */}
                </span>
              </div>
            </div>
          </Link>
        ))}
    </div>
    {/* ./ Table Section */}
  </div>
);

export default NetworkTable;
