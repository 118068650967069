import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import {
  getStatsApiCount,
  getStatsExecTime,
  getStatsData,
} from '../../store/actions/ascendonAction';
import { Context } from '../../Context/ApiStatsContext';
import ApiDropDown from '../Common/ApiDropdown';
import Loader from '../Loader';
import DonutChart from '../Common/DonutChart';
import Alert from '../Common/Alert';

const ApiStats = (props) => {
  const { statType } = props;
  const [loaded, setLoaded] = useState(false);
  const [activeTab, setActiveTab] = useState('graph');
  const [errorMessage, setErrorMessage] = useState('');
  const [statsExecutionTimeValues, setStatsExecTimeVal] = useState(null);
  const [statsExecutionTimeLabels, setStatsExecTimeLabel] = useState(null);
  const [statsCountLabels, setStatsCntLabel] = useState(null);
  const [statsCountValues, setStatsCntVal] = useState(null);
  const [totalExecutionTime, setTotalExecTime] = useState(null);
  const [totalApiCalls, setTotalApiCalls] = useState(null);
  const [headers, setHeaders] = useState(null);
  const [tableData, setTableData] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Get filter data from ApiStats Context
  const [apiStatsFilter, setApiStatsFilter] = useContext(Context);
  const { environment, statTypeLoaded } = apiStatsFilter;

  const dispatch = useDispatch();
  const statsState = useSelector((state) => state.cloudabilityList);
  const {
    error, statsCount, statsExecTime, statsData,
  } = statsState;

  useEffect(() => {
    setApiStatsFilter({ ...apiStatsFilter, statTypeLoaded: false });

    const queryUrl = `?environment=${environment}&statType=${statType.toLowerCase()}`;
    dispatch(
      getStatsApiCount(`/api/AscendonCost/GetAscendonAPIStatsCount${queryUrl}`),
    );
    dispatch(
      getStatsExecTime(
        `/api/AscendonCost/GetAscendonAPIStatsExecutionTime${queryUrl}`,
      ),
    );
    dispatch(getStatsData(`/api/AscendonCost/GetAscendonAPIStats${queryUrl}`));
  }, [statType, environment, dispatch]);

  useEffect(() => {
    if (statsCount && statsCount.length > 0) {
      setStatsCntLabel(statsCount.map((x) => x.businessUnitName));
      setStatsCntVal(statsCount.map((x) => x.sumOfCount));
      setTotalApiCalls(
        statsCount.map((o) => o.sumOfCount).reduce((a, b) => a + b, 0),
      );
    }
  }, [statsCount]);

  useEffect(() => {
    if (statsExecTime && statsExecTime.length > 0) {
      setStatsExecTimeVal(statsExecTime.map((x) => x.executionTime));
      setStatsExecTimeLabel(statsExecTime.map((x) => x.businessUnitName));
      setTotalExecTime(
        statsExecTime.map((o) => o.executionTime).reduce((a, b) => a + b, 0),
      );
    }
  }, [statsExecTime]);

  function formatMoney(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  function tableDataFormat(data) {
    let result = data
      .map(({ environment, ...item }) => item)
      .map((o) => {
        o.executionTime = o.executionTime > 0
          ? `${o.executionTime.toLocaleString()} ms`
          : '-';
        o.apiCalls = o.apiCalls > 0 ? formatMoney(o.apiCalls) : '-';
        return o;
      });
    result = result.filter((o) => o.businessUnitName !== 'Unknown');
    return result;
  }

  useEffect(() => {
    if (statsData && statsData.length > 0) {
      setHeaders([
        'Business Unit ID',
        'Business Unit Name',
        `${environment} API Calls (${statType})`,
        `${environment} Execution Time (${statType})`,
      ]);
      setTableData(tableDataFormat(statsData).map(Object.values));
      setLoaded(true);
      setApiStatsFilter({ ...apiStatsFilter, statTypeLoaded: true });
    }
  }, [statsData]);

  useEffect(() => {
    if (error) {
      setLoaded(true);
      setErrorMessage(error);
    }
  }, [error]);

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    // handle change works only for PRD1 and PRD3 currently
    if (name === 'environment' && value.includes('PRD')) {
      setApiStatsFilter({
        environment: value,
        statTypeLoaded: false,
      });
    }
  };

  const onChangeRowsPerPage = (rowsPerPagee) => {
    setRowsPerPage(rowsPerPagee);
  };

  const options = {
    filter: true,
    filterType: 'dropdown',
    rowsPerPage,
    rowsPerPageOptions: [5, 10],
    onChangeRowsPerPage,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    downloadOptions: { filename: 'AscendonApiStatsData.csv' },
    responsive: 'standard',
  };

  return (
    <>
      {loaded ? (
        <div className="tab-pane active" id="home-v">
          <div className="form-group">
            <div className="row justify-content-center justify-content-end-xs">
              <label htmlFor={1} className="col-12 col-sm-4 col-md-4 col-lg-3 col-xl-2 col-form-label py-2 text-left text-left-xs text-nowrap mt-2">
                Select the Environment:
              </label>
              <div className="col-md-5 col-sm-4 col-12">
                <div className="form-group">
                  <ApiDropDown
                    name="environment"
                    value={environment}
                    url="/api/AscendonCost/GetFilterItems?value=environment"
                    onChange={handleChange}
                    required
                    disabled
                  />
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-3 col-sm-4 col-12">
                <ul className="nav nav-pills bg-nav-pills nav-justified mt-2 justify-content-center h-100">
                  <li className="nav-item">
                    <div
                      onClick={() => setActiveTab('graph')}
                      data-toggle="tab"
                      aria-expanded="false"
                      className={`nav-link ${activeTab === 'graph' ? 'active' : ''}`}
                    >
                      Graph
                    </div>
                  </li>
                  <li className="nav-item">
                    <div
                      onClick={() => setActiveTab('table')}
                      data-toggle="tab"
                      aria-expanded="true"
                      className={`nav-link ${activeTab === 'table' ? 'active' : ''}`}
                    >
                      Table
                    </div>
                  </li>
                </ul>
              </div>
              {errorMessage && (
                <div className="col-12">
                  <Alert
                    message={errorMessage}
                    alertClass="light-danger"
                    onClose={(val) => setErrorMessage(val)}
                  />
                </div>
              )}
            </div>

            <div className="row mt-8">
              <div className="col-12">
                {statTypeLoaded ? (
                  <div className="tab-content p-0 mt-8">
                    <div
                      className={`tab-pane ${activeTab === 'graph' ? 'active' : ''}`}
                    >
                      <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-6 col-12 gutter-b">
                          <div className="ascendon-section h-100">
                            <h5 className="text-left">{`${environment} API Calls (${statType})`}</h5>
                            <span className="text-muted">
                              {formatMoney(totalApiCalls)}
                            </span>
                            <div className="mt-3">
                              <DonutChart
                                pieChartCostData={statsCountValues}
                                pieChartLableData={statsCountLabels}
                                chartType="donut"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-6 col-12 gutter-b">
                          <div className="ascendon-section h-100">
                            <h5 className="text-left">{`${environment} Execution Time (${statType})`}</h5>
                            <span className="text-muted">
                              {formatMoney(totalExecutionTime)}
                            </span>
                            <div className="mt-3">
                              <DonutChart
                                pieChartCostData={statsExecutionTimeValues}
                                pieChartLableData={statsExecutionTimeLabels}
                                chartType="donut"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`tab-pane ${activeTab === 'table' ? 'active' : ''}`}
                    >
                      <div className="ascendon-section">
                        <div className="table-responsive">
                          <MUIDataTable
                            data={tableData}
                            columns={headers}
                            options={options}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ApiStats;
