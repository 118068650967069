import React from 'react';
import Chart from 'react-apexcharts';

const LineChart = (props) => {
  const options = {
    chart: {
      id: 'candlestick',
      width: '100%',
    },
    tooltip: {
      y: {
        formatter(value, { seriesIndex, dataPointIndex, w }) {
          const c = w.globals.seriesCandleC[seriesIndex][dataPointIndex];
          w.globals.seriesCandleC[seriesIndex][dataPointIndex] = `$${c.replace(
            '$',
            '',
          )}`;
          const h = w.globals.seriesCandleH[seriesIndex][dataPointIndex];
          w.globals.seriesCandleH[seriesIndex][dataPointIndex] = `$${h.replace(
            '$',
            '',
          )}`;
          const l = w.globals.seriesCandleL[seriesIndex][dataPointIndex];
          w.globals.seriesCandleL[seriesIndex][dataPointIndex] = `$${l.replace(
            '$',
            '',
          )}`;
          const o = w.globals.seriesCandleO[seriesIndex][dataPointIndex];
          w.globals.seriesCandleO[seriesIndex][dataPointIndex] = `$${o.replace(
            '$',
            '',
          )}`;
          return value;
        },
      },
    },
    theme: {
      mode: 'light',
      palette: 'palette1',
      monochrome: {
        enabled: false,
        color: '#255aee',
        shadeTo: 'light',
        shadeIntensity: 0.65,
      },
    },
    plotOptions: {
      candlestick: {
        wick: {
          useFillColor: true,
        },
      },
    },
    yaxis: {
      labels: {
        formatter(value) {
          return `$${value}`;
        },
      },
    },
    responsive: [
      {
        breakpoint: 767,
        options: {
          title: {
            style: {
              fontSize: '0px',
            },
          },
          style: {
            width: '500px',
          },
        },
      },
    ],
  };
  const series = [
    {
      data: props.stockData,
    },
  ];
  return (
    <div>
      <Chart
        options={options}
        series={series}
        type="candlestick"
        height="300"
      />
    </div>
  );
};

export default LineChart;
