import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import './infoTab.css';

const AccountInfo = ({ accountInfo }) => {
  const iTags = accountInfo?.iTag?.split(',');

  return (
    <div>
      {Object.keys(accountInfo).length > 0 && (
        <div className="tab-pane" id="home-v">
          <div className="row justify-content-center justify-content-end-xs">
            <div className="col-md-12 col-sm-12 col-12">
              <div className="d-flex screen-md-d-flex">
                {/* begin::Pic */}
                <div className="flex-shrink-0 mr-2 screen-md-flex-shrink">
                  <div className="symbol symbol-50 symbol-lg-120 symbol-light-primary">
                    <div className="font-size-h5 symbol-label font-weight-boldest text-center p-4 text-break d-flex flex-column aling-items-center">
                      {accountInfo.accountName}
                    </div>
                  </div>
                </div>
                {/* end::Pic */}

                {/* begin: Info */}
                <div className="w-100">
                  <>
                    {accountInfo.suspended === false
                      && accountInfo.securityContext
                      && accountInfo.securityContext.isAdmin === true ? (
                        <a
                          href={`https://signin.aws.amazon.com/switchrole?account=${accountInfo.accountNumber}&roleName=OrganizationAccountAccessRole&displayName=${accountInfo.accountName}&region=us-east-1`}
                          className="font-size-h4 text-primary col pr-1"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {accountInfo.accountNumber}
                        </a>
                      ) : accountInfo.suspended === true ? (
                        <span
                          className="font-size-h4 col pr-1"
                          style={{ textDecoration: 'line-through' }}
                        >
                          {accountInfo.accountNumber}
                          {' '}
                        </span>
                      ) : (
                        <span className="font-size-h4 col pr-1">
                          {accountInfo.accountNumber}
                          {' '}
                        </span>
                      )}
                  </>
                  {accountInfo.suspended === true && (
                    <div className="mt-3 col-12 col-md-6">
                      <div className="messageDispaly">
                        This account has been suspended.
                      </div>
                    </div>
                  )}
                  <div className="d-flex justify-content-start aling-items-center mt-4 flex-wrap">
                    {accountInfo.directorName && (
                      <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                        <div className="d-flex flex-column font-size-h5">
                          <span className="text-muted font-size-sm text-nowrap">
                            VP Software Dev
                          </span>
                          {accountInfo.directorName}
                        </div>
                      </div>
                    )}
                    {accountInfo.ownerName && (
                      <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                        <div className="d-flex flex-column font-size-h5">
                          <span className="text-muted font-size-sm text-nowrap">
                            AWS Account Contact/Owner
                          </span>
                          {accountInfo.ownerName}
                        </div>
                      </div>
                    )}
                    {accountInfo.requestorName && (
                      <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                        <div className="d-flex flex-column font-size-h5">
                          <span className="text-muted font-size-sm text-nowrap">
                            Requestor
                          </span>
                          {accountInfo.requestorName}
                        </div>
                      </div>
                    )}
                    {accountInfo.requestedAccountName && (
                      <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                        <div className="d-flex flex-column font-size-h5">
                          <span className="text-muted font-size-sm text-nowrap">
                            Requested Account Name
                          </span>
                          {accountInfo.requestedAccountName}
                        </div>
                      </div>
                    )}
                    {accountInfo.remedyGroup && (
                      <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                        <div className="d-flex flex-column font-size-h5">
                          <span className="text-muted font-size-sm text-nowrap">
                            Remedy Group
                          </span>
                          {accountInfo.remedyGroup}
                        </div>
                      </div>
                    )}
                    {accountInfo.teamEmail && (
                      <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                        <div className="d-flex flex-column font-size-h5">
                          <span className="text-muted font-size-sm text-nowrap">
                            Support Email
                          </span>
                          <span className="text-primary">
                            <a href={`mailto:${accountInfo.teamEmail}`}>
                              {accountInfo.teamEmail}
                            </a>
                          </span>
                        </div>
                      </div>
                    )}
                    {accountInfo.iTag && (
                      <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                        <div className="d-flex flex-column font-size-h5">
                          <span className="text-muted font-size-sm text-nowrap">
                            iTag
                          </span>
                          <div className="multi-itags">
                            {' '}
                            {iTags.map((item) => (
                              <a
                                className="itag-pills"
                                target="_blank"
                                rel="noopener noreferrer"
                                href={`https://itag.csgicorp.com/PortfolioManagement/#/epics/${item}`}
                              >
                                {item}
                              </a>
                            ))}
                            {' '}
                          </div>
                        </div>
                      </div>
                    )}
                    {accountInfo.regionNames && (
                      <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                        <div className="d-flex flex-column font-size-h5">
                          <span className="text-muted font-size-sm text-nowrap">
                            Regions
                          </span>
                          {accountInfo.regionNames}
                        </div>
                      </div>
                    )}
                    {accountInfo.environmentNames && (
                      <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                        <div className="d-flex flex-column font-size-h5">
                          <span className="text-muted font-size-sm text-nowrap">
                            Environments
                          </span>
                          {accountInfo.environmentNames}
                        </div>
                      </div>
                    )}
                    {accountInfo.managerName && (
                      <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                        <div className="d-flex flex-column font-size-h5">
                          <span className="text-muted font-size-sm text-nowrap">
                            Account Manager/Director
                          </span>
                          {accountInfo.managerName}
                        </div>
                      </div>
                    )}
                    {accountInfo.csgServiceOwnerName && (
                      <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                        <div className="d-flex flex-column font-size-h5">
                          <span className="text-muted font-size-sm text-nowrap">
                            CSG Service Owner
                          </span>
                          {accountInfo.csgServiceOwnerName}
                        </div>
                      </div>
                    )}
                    {accountInfo.csgProduct && (
                      <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                        <div className="d-flex flex-column font-size-h5">
                          <span className="text-muted font-size-sm text-nowrap">
                            CSG Product
                          </span>
                          {accountInfo.csgProduct}
                        </div>
                      </div>
                    )}
                    {accountInfo.costCenter && (
                      <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                        <div className="d-flex flex-column font-size-h5">
                          <span className="text-muted font-size-sm text-nowrap">
                            Cost Center
                          </span>
                          {accountInfo.costCenter}
                        </div>
                      </div>
                    )}
                    {accountInfo.executiveLevelName && (
                      <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                        <div className="d-flex flex-column font-size-h5">
                          <span className="text-muted font-size-sm text-nowrap">
                            Account Executive Level
                          </span>
                          {accountInfo.executiveLevelName}
                        </div>
                      </div>
                    )}
                    {accountInfo.vpName && (
                      <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                        <div className="d-flex flex-column font-size-h5">
                          <span className="text-muted font-size-sm text-nowrap">
                            Account VP/EVP
                          </span>
                          {accountInfo.vpName}
                        </div>
                      </div>
                    )}
                    {accountInfo.productCode && (
                      <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                        <div className="d-flex flex-column font-size-h5">
                          <span className="text-muted font-size-sm text-nowrap">
                            Product
                          </span>
                          {accountInfo.productCode}
                        </div>
                      </div>
                    )}
                    {accountInfo.subfamily && (
                      <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                        <div className="d-flex flex-column font-size-h5">
                          <span className="text-muted font-size-sm text-nowrap">
                            Subfamily
                          </span>
                          {accountInfo.subfamily}
                        </div>
                      </div>
                    )}
                    {accountInfo.family && (
                      <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                        <div className="d-flex flex-column font-size-h5">
                          <span className="text-muted font-size-sm text-nowrap">
                            Family
                          </span>
                          {accountInfo.family}
                        </div>
                      </div>
                    )}
                    <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                      <div className="d-flex flex-column font-size-h5">
                        <span className="text-muted font-size-sm text-nowrap">
                          Needs Direct Connect
                        </span>
                        <span
                          className="font-size-h3 ml-1"
                          title="Needs Direct Connect"
                        >
                          {accountInfo.directConnectNeeded ? (
                            <span>
                              <FontAwesomeIcon
                                icon={faThumbsUp}
                                style={{ color: '#28a745' }}
                              />
                            </span>
                          ) : (
                            <span>
                              <FontAwesomeIcon
                                icon={faThumbsDown}
                                style={{ color: '#6c757d' }}
                              />
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* end::Info */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountInfo;
