import React, { useContext } from 'react';
import HeaderWithInfo from '../../Common/HeaderWithInfo';
import { Context } from '../../../Context/RequestsContext';

const RequestHeader = () => {
  // Model popup open from Request Context
  const [modelPopup, setModelPopup] = useContext(Context);
  // eslint-disable-next-line no-unused-vars
  const { editMode } = modelPopup;
  const onAddExistingModel = () => {
    setModelPopup({ ...modelPopup, editMode: 'add' });
  };
  return (
    <div className="card-title align-items-center justify-content-between w-100">
      <HeaderWithInfo headerName="Manage Requests" />
      <button
        type="button"
        className="btn btn-sm btn-primary"
        onClick={onAddExistingModel}
      >
        <span className="svg-icon svg-light">
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth="0"
            // eslint-disable-next-line react/no-unknown-property
            t="1551322312294"
            viewBox="0 0 1024 1024"
            version="1.1"
            height="1.2em"
            width="1.2em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs />
            <path d="M474 152m8 0l60 0q8 0 8 8l0 704q0 8-8 8l-60 0q-8 0-8-8l0-704q0-8 8-8Z" />
            <path d="M168 474m8 0l672 0q8 0 8 8l0 60q0 8-8 8l-672 0q-8 0-8-8l0-60q0-8 8-8Z" />
          </svg>
        </span>
        {' '}
        Add Existing
      </button>
    </div>
  );
};

export default RequestHeader;
