import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../Components/Common/Alert';
import Loader from '../Components/Loader';
import { getAccountProfile } from '../store/actions/accountAction';
import Tab from '../Components/Common/Tab';
import AccountInfo from '../Components/Account/InfoTab';
import AccountUserTab from '../Components/Account/UsersTab';
import AccountComplianceTab from '../Components/Account/ComplianceTab';
import AccountCostTab from '../Components/Account/CostTab';
import AccountNetworkTab from '../Components/Account/NetworkTab';
import AccountComputeTab from '../Components/Account/ComputeTab';
import { Context as MenuContext } from '../Context/MenuContext';

const AccountProfile = (props) => {
  const [tab, setTab] = useState('accountInfo');
  const tabList = [
    { value: 'accountInfo', label: 'Account Info' },
    { value: 'users', label: 'Users' },
    { value: 'cost', label: 'Cost' },
    { value: 'compliance', label: 'Compliance' },
    { value: 'network', label: 'Network' },
    { value: 'compute', label: 'Compute' },
  ];

  const dispatch = useDispatch();
  const accountData = useSelector((state) => state.accountProfile);
  const { loading, errInfo, accountInfo } = accountData;
  const [errorMessage, setErrorMessage] = useState('');
  // Get selectedMenu data from Menu Context
  const [, setSelectedMenu] = useContext(MenuContext);
  // Change menu css with peope page is loaded.
  useEffect(() => {
    setSelectedMenu('accounts');
  }, []);

  useEffect(() => {
    const apiUrl = `/api/account/${props.accountId}/InitialRequest`;
    dispatch(getAccountProfile(apiUrl));
  }, [dispatch]);

  useEffect(() => {
    if (errInfo !== '') {
      setErrorMessage(errInfo);
    }
  }, [errInfo]);

  function accountDetailTab(tabName) {
    switch (tabName) {
      case 'accountInfo':
        return <AccountInfo accountInfo={accountInfo} />;
      case 'users':
        return <AccountUserTab accountId={props.accountId} />;
      case 'cost':
        return <AccountCostTab accountId={props.accountId} />;
      case 'compliance':
        return <AccountComplianceTab accountId={props.accountId} />;
      case 'network':
        return <AccountNetworkTab accountId={props.accountId} />;
      case 'compute':
        return <AccountComputeTab accountId={props.accountId} />;
      default:
        return '';
    }
  }

  return (
    <div className="row gutter-b">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="col-12">
            <div className="card card-custom gutter-b">
              <div className="card-header align-items-center">
                <div className="card-title align-items-start flex-column">
                  <h3 className="card-label">
                    {Object.keys(accountInfo).length > 0
                      ? accountInfo.accountName
                      : 'Account Specific Page'}
                  </h3>
                </div>
              </div>
              {errorMessage ? (
                <div className="card-body">
                  <Alert
                    message={errorMessage}
                    alertClass="light-danger"
                    onClose={(val) => setErrorMessage(val)}
                  />
                </div>
              ) : (
                <>
                  {accountInfo && (
                    <div className="card-body p-0">
                      <div className="row">
                        <div className="col-12">
                          <div className="horizontal-tab">
                            <Tab
                              onClick={(e) => setTab(e)}
                              tabList={tabList}
                              selected="accountInfo"
                            />
                            <div className="tab-content">
                              {accountDetailTab(tab)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AccountProfile;
