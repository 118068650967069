import React from 'react';
import Chart from 'react-apexcharts';

const BarChart = (props) => {
  const { series, categories } = props;
  const options = {
    chart: {
      type: 'bar',
      width: '100%',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories,
    },
    legend: {
      show: true,
      position: 'top',
      fontSize: '8px',
      horizontalAlign: 'center',
    },
    responsive: [
      {
        breakpoint: 767,
        options: {
          xaxis: {
            labels: {
              show: false,
            },
          },
          yaxis: {
            labels: {
              show: false,
            },
          },
        },
      },
    ],
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter(val) {
          return `$ ${val}`;
        },
      },
    },
  };
  return (
    <div>
      <Chart options={options} series={series} type="bar" height="300" />
    </div>
  );
};
export default BarChart;
