import {
  GET_POST_EVENTS_DATA,
  ERROR_POST_EVENTS_DATA,
  GET_STOCK_WIDGETS_DATA,
  ERROR_STOCK_WIDGETS_DATA,
  GET_HEALTH_EVENTS_DATA,
  ERROR_HEALTH_EVENTS_DATA,
  GET_REGION_SERVICES_DATA,
  ERROR_REGION_SERVICES_DATA,
  GET_UNAVAILABLE_SERVICES_DATA,
  ERROR_UNAVAILABLE_SERVICES_DATA,
} from '../types';

const initialState = {
  summaries: {},
  summariesLoading: true,
  errorSummaries: '',
  stockWidgets: [],
  stockWidgetLoading: true,
  errorStockWidget: '',
  healthEvents: [],
  healthEventsLoading: true,
  errorHealthEvents: '',
  regionServices: [],
  regionServicesLoading: true,
  errorRegionServices: '',
  unavailableServices: [],
  unavailableServicesLoading: true,
  errorUnavailableServices: '',
  loading: true,
  error: '',
};

/* eslint-disable func-names */
export default function (state = initialState, action) {
  /* eslint-enable */
  switch (action.type) {
    case GET_POST_EVENTS_DATA:
      return {
        ...state,
        summaries: action.payload,
        summariesLoading: false,
      };
    case ERROR_POST_EVENTS_DATA:
      return {
        ...state,
        summariesLoading: false,
        errorSummaries: action.payload.message,
      };
    case GET_STOCK_WIDGETS_DATA:
      return {
        ...state,
        stockWidgets: action.payload,
        stockWidgetLoading: false,
      };
    case ERROR_STOCK_WIDGETS_DATA:
      return {
        ...state,
        stockWidgetLoading: false,
        errorStockWidget: action.payload.message,
      };
    case GET_HEALTH_EVENTS_DATA:
      return {
        ...state,
        healthEvents: action.payload,
        healthEventsLoading: false,
      };
    case ERROR_HEALTH_EVENTS_DATA:
      return {
        ...state,
        healthEventsLoading: false,
        errorHealthEvents: action.payload.message,
      };
    case GET_REGION_SERVICES_DATA:
      return {
        ...state,
        regionServices: action.payload,
        regionServicesLoading: false,
      };
    case ERROR_REGION_SERVICES_DATA:
      return {
        ...state,
        regionServicesLoading: false,
        errorRegionServices: action.payload.message,
      };
    case GET_UNAVAILABLE_SERVICES_DATA:
      return {
        ...state,
        unavailableServices: action.payload,
        unavailableServicesLoading: false,
      };
    case ERROR_UNAVAILABLE_SERVICES_DATA:
      return {
        ...state,
        unavailableServicesLoading: false,
        errorUnavailableServicesLoading: action.payload.message,
      };
    default: return state;
  }
}
