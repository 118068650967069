import React from 'react';

const Loader = () => (
  <div className="log-out-layer">
    <div className="loader">
      <div className="pre-loader">
        <div className="line" />
        <div className="line" />
        <div className="line" />
        <div className="line" />
      </div>
    </div>
  </div>
);

export default Loader;
