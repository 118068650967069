import React, {
  useEffect, useState, useRef, useContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSignOutAlt,
  faQuestionCircle,
  faInbox,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from '@reach/router';
import Logo from '../../Images/logo.png';
import Loader from '../Loader';
import Feedback from '../Feedback';
import LogOutModel from '../LogOutModel';
import Announcement from '../Announcement';
import { updateUserAnnouncementMapping } from '../../store/actions/announcementAction';
// context
import { Context } from '../../Context/AnnouncementContext';

const Header = ({ user }) => {
  const [showUserDrpDwn, setShowUserDrpDown] = useState(false);
  const [showLogOutModel, setShowLogOtModel] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showFeedbackModel, setShowFeedbackModel] = useState(false);
  const sideBarRef = useRef(null);
  const logOutRef = useRef(null);
  const announcementIdsRef = useRef(null);
  const allAnnouncementsRef = useRef(null);

  const dispatch = useDispatch();
  const announcementInfo = useSelector((state) => state.announcementInfo);
  const { updateUserAnnouncement } = announcementInfo;

  // Announcement Model popup open from Request Context
  const [anouncementPopup, setAnouncementPopup] = useContext(Context);
  // eslint-disable-next-line no-unused-vars
  const { showWhatsNew, newAnnouncementCount, allAnnouncements } = anouncementPopup;

  // CB function from LogOutModel. Closes LogOutModel when confirm log out
  const handleLogOutChange = (newValue) => {
    setShowLogOtModel(newValue);
  };
  const handleFeedbackChange = () => {
    setShowFeedbackModel(false);
  };
  // Close What's New when clicked outside the sideBar
  useEffect(() => {
    function handleClickOutside(event) {
      if (sideBarRef.current && !sideBarRef.current.contains(event.target)) {
        if (event.currentTarget.activeElement.id === 'show-sidebar') {
          setAnouncementPopup({
            newAnnouncementCount: 0,
            showWhatsNew: false,
            allAnnouncements: allAnnouncementsRef.current,
          });
          announcementIdsRef.current = [];
        } else {
          setAnouncementPopup({
            newAnnouncementCount,
            showWhatsNew: false,
            allAnnouncements: announcementIdsRef.current && announcementIdsRef.current.length === 0
              ? allAnnouncementsRef.current.map((item) => { item.isAlertSeen = true; return item; }).sort((a, b) => {
                if (a.id > b.id) return 1; if (a.id < b.id) return -1; return 0;
              }) : allAnnouncementsRef.current,
          });
        }
      }

      if (logOutRef.current && !logOutRef.current.contains(event.target)) {
        setShowUserDrpDown(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [newAnnouncementCount]);
  const onClickNotification = () => {
    setAnouncementPopup({ ...anouncementPopup, newAnnouncementCount: 0, showWhatsNew: true });
    if (updateUserAnnouncement !== 'success') {
      const apiUrl = `/api/announcement/UpdateUserAnnouncementMapping/${user.employeeId}`;
      dispatch(updateUserAnnouncementMapping(apiUrl, announcementIdsRef.current));
    }
  };

  useEffect(() => {
    if (updateUserAnnouncement === 'success') {
      setAnouncementPopup({ ...anouncementPopup, newAnnouncementCount: 0 });
    }
  }, [updateUserAnnouncement]);

  return (
    <div>
      {/* Show Loader until logo isn't loaded */}
      {loading && !user && <Loader />}

      <nav className="navbar navbar-expand navbar-light bg-theme topbar mb-4 fixed-top left-130 shadow">
        {/* <!-- CSG - Logo to display only for SignIn page when user not loggedIn --> */}
        {!user && (
          <Link
            to="/"
            className="sidebar-brand d-flex align-items-center justify-content-center"
            style={!user ? { display: 'block' } : { display: 'none' }}
          >
            <img
              src={Logo}
              alt="csgLogo"
              className="img-fluid"
              width="100"
              onLoad={() => setLoading(false)}
            />
          </Link>
        )}

        {/* <!-- Portal Name --> */}
        <div className="portal-heading mr-auto ml-md-3 my-2 my-md-0 mw-100">
          <div className="input-group">
            <div className="portal-heading-text">
              <Link to="/">Arcus Cloud Portal</Link>
            </div>
          </div>
        </div>

        {/* <!-- Topbar Navbar --> */}
        {user && (
          <ul className="navbar-nav ml-auto">
            {/* <!-- Nav Item - What's New --> */}
            <li
              className={`nav-item dropdown no-arrow mx-1 sidebar-wrapper-section ${showWhatsNew ? 'toggled show' : ''}`}
            >
              <div
                className="nav-link dropdown-toggle"
                id="show-sidebar"
                role="button"
                tabIndex={0}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded={showWhatsNew}
                onClick={onClickNotification}
              >
                {/* <!--begin::Svg Icon --> */}
                <span className="svg-icon svg-icon-primary svg-icon-2x header-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <circle
                        fill="#000000"
                        opacity="0.3"
                        cx="12"
                        cy="9"
                        r="8"
                      />
                      <path
                        d="M14.5297296,11 L9.46184488,11 L11.9758349,17.4645458 L14.5297296,11 Z M10.5679953,19.3624463 L6.53815512,9 L17.4702704,9 L13.3744964,19.3674279 L11.9759405,18.814912 L10.5679953,19.3624463 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        opacity="0.3"
                      />
                      <path
                        d="M10,22 L14,22 L14,22 C14,23.1045695 13.1045695,24 12,24 L12,24 C10.8954305,24 10,23.1045695 10,22 Z"
                        fill="#000000"
                        opacity="0.3"
                      />
                      <path
                        d="M9,20 C8.44771525,20 8,19.5522847 8,19 C8,18.4477153 8.44771525,18 9,18 C8.44771525,18 8,17.5522847 8,17 C8,16.4477153 8.44771525,16 9,16 L15,16 C15.5522847,16 16,16.4477153 16,17 C16,17.5522847 15.5522847,18 15,18 C15.5522847,18 16,18.4477153 16,19 C16,19.5522847 15.5522847,20 15,20 C15.5522847,20 16,20.4477153 16,21 C16,21.5522847 15.5522847,22 15,22 L9,22 C8.44771525,22 8,21.5522847 8,21 C8,20.4477153 8.44771525,20 9,20 Z"
                        fill="#000000"
                      />
                    </g>
                  </svg>
                  { newAnnouncementCount > 0 && (
                  <span className="notification notification-status">
                    <span>{newAnnouncementCount}</span>
                  </span>
                  )}
                </span>
                {/* <!--end::Svg Icon--> */}
                {/* <!-- Counter - What's New --> */}
              </div>
              {/* <!-- Dropdown - Alerts --> */}
              <Announcement
                user={user}
                sideBarRef={sideBarRef}
                announcementIdsRef={announcementIdsRef}
                allAnnouncementsRef={allAnnouncementsRef}
              />
            </li>

            <div className="topbar-divider d-none d-sm-block" />

            {/* <!-- Nav Item - User Information --> */}
            <li
              role="presentation"
              className={`nav-item dropdown no-arrow ${showUserDrpDwn ? 'show' : ''}`}
              onClick={() => setShowUserDrpDown(!showUserDrpDwn)}
              ref={logOutRef}
            >
              <div
                className="nav-link dropdown-toggle"
                id="userDropdown"
                role="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded={showUserDrpDwn}
              >
                <span className="mr-2 d-none d-lg-inline text-white small">
                  Hi,
                  {' '}
                  {user.firstName}
                </span>
                {/* <!--begin::Svg Icon --> */}
                <span className="svg-icon svg-icon-primary svg-icon-2x header-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        opacity="0.3"
                      />
                      <path
                        d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                        fill="#000000"
                        fillRule="nonzero"
                      />
                    </g>
                  </svg>
                  {/* <!--end::Svg Icon--> */}
                </span>
              </div>
              {/* <!-- Dropdown - User Information --> */}
              <div
                className={`dropdown-menu dropdown-menu-right shadow animated--grow-in ${showUserDrpDwn ? 'show' : ''}`}
                aria-labelledby="userDropdown"
              >
                <div
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#logoutModal"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowLogOtModel(true)}
                  ref={logOutRef}
                >
                  <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                  Logout
                </div>
                <hr />
                <div
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#help"
                  style={{ cursor: 'pointer' }}
                >
                  <FontAwesomeIcon icon={faQuestionCircle} className="mr-2" />
                  <a style={{ color: 'black' }} target="_blank" href="https://confluence.csgicorp.com/display/AWS/Arcus+Help+Page" rel="noreferrer">Help</a>
                </div>
                <hr />
                <div
                  className="dropdown-item"
                  data-toggle="modal"
                  data-target="#logoutModal"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowFeedbackModel(true)}
                >
                  <FontAwesomeIcon icon={faInbox} className="mr-2" />
                  Feedback
                </div>
              </div>
            </li>
          </ul>
        )}
      </nav>
      {showFeedbackModel && <div className="log-out-layer" />}
      <Feedback isOpen={showFeedbackModel} onChange={handleFeedbackChange} />
      {/* Shows a opaque div layer and the LogOutModel on top incase logOut is selected */}
      {showLogOutModel && <div className="log-out-layer" />}
      <LogOutModel isOpen={showLogOutModel} onChange={handleLogOutChange} />
    </div>
  );
};

export default Header;
