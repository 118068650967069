import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAws } from '@fortawesome/free-brands-svg-icons';
import azureIcon from '../../Images/azure.png';
import azureIconSelected from '../../Images/azure_white.png';

const CloudTab = (props) => {
  const [navigators] = useState(props.tabList);
  const [selectedTab, setSelectedTab] = useState(props.selected);

  function handleClick(value) {
    setSelectedTab(value);
    props.onClick(value);
  }

  function setCloudImage(val) {
    switch (val) {
      case 'aws':
        return <FontAwesomeIcon icon={faAws} />;
      case 'azure':
        if (selectedTab === 'azure') return <img src={azureIconSelected} style={{ height: '2rem', width: '2rem' }} alt="Azure" />;
        return <img src={azureIcon} style={{ height: '2rem', width: '2rem' }} alt="Azure" />;
      default:
        return '';
    }
  }

  return (
    <div>
      <>
        <div className="nav nav-tabs tabs-left sideways">
          {navigators.map((navigator) => (
            <div className="horizontal-tab-step" key={navigator.value}>
              <div
                className={`horizontal-tab-wrapper ${selectedTab === navigator.value ? `${navigator.value}active` : ''
                }`}
                data-toggle="tab"
                onClick={() => handleClick(navigator.value)}
              >
                <div className="horizontal-tab-label">
                  <h3 className="horizontal-tab-title">
                    <span className="svg-icon svg-icon-2x svg-icon-primary" style={{ padding: 5, height: '16px' }}>{setCloudImage(navigator.value)}</span>
                    {navigator.label !== 'AWS'
                      ? (navigator.label) : ''}
                  </h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    </div>
  );
};

export default CloudTab;
