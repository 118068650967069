import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindows, faLinux } from '@fortawesome/free-brands-svg-icons';
import Loader from '../../Loader';
import { getNetworkCompute } from '../../../store/actions/virtualNetworkAction';
import Alert from '../../Common/Alert';
import {
  ERROR_VN_COMPUTE_INST,
  GET_VN_COMPUTE_INST,
} from '../../../store/types';

const NetworkComputeInstances = (props) => {
  const { vnId, onClick, isOpen } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [showErrMsg, setShowErrMsg] = useState(false);

  const dispatch = useDispatch();
  const vnData = useSelector((state) => state.vnProfile);
  const { compInstanceLoading, errCompInstance, compInstance } = vnData;

  useEffect(() => {
    dispatch(
      getNetworkCompute(
        `/api/virtualnetwork/${vnId}/computeinstances`,
        GET_VN_COMPUTE_INST,
        ERROR_VN_COMPUTE_INST,
      ),
    );
  }, [dispatch]);

  useEffect(() => {
    if (errCompInstance !== '') {
      setErrorMessage(errCompInstance);
      setShowErrMsg(true);
    }
  }, [errCompInstance, isOpen]);

  const resourceMarkup = (list) => list.map((rs) => (
    <div className="div-row" key={rs.id}>
      <div className="div-column">{rs.id}</div>
      <div className="div-column">{rs.name ? rs.name : '-'}</div>
      <div className="div-column">{rs.state}</div>
      <div className="div-column">
        {rs.platform === 'windows' ? (
          <FontAwesomeIcon icon={faWindows} size="lg" />
        ) : (
          <FontAwesomeIcon icon={faLinux} size="lg" />
        )}
      </div>
      <div className="div-column">{rs.availabilityZone}</div>
      <div className="div-column">{rs.privateIpAddress}</div>
    </div>
  ));

  return (
    <div className={`card border-0 ${isOpen ? 'card-active' : ''}`}>
      <div className="card-header card-header2" id="headingOne">
        <h5 className="mb-0">
          <button
            type="button"
            className={`btn btn-link ${isOpen ? '' : 'collapsed'}`}
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="false"
            aria-controls="collapseOne"
            onClick={() => onClick('instances')}
          >
            Compute Instances
          </button>
        </h5>
      </div>
      <div
        id="collapseOne"
        className={`collapse ${isOpen ? 'show' : ''}`}
        aria-labelledby="headingOne"
        data-parent="#accordion"
      >
        <div className="card-body px-0">
          {compInstanceLoading ? (
            <Loader />
          ) : showErrMsg ? (
            <div className="col-12">
              <Alert
                message={errorMessage}
                alertClass="light-danger"
                onClose={() => {
                  setShowErrMsg(false);
                  onClick('instances');
                }}
              />
            </div>
          ) : (
            !errorMessage && (
              <>
                {compInstance.length === 0 ? (
                  <div className="col-12">
                    <Alert
                      message="No compute instances found."
                      alertClass="info"
                    />
                  </div>
                ) : (
                  <div className="custom-table">
                    <div className="respnsive-custom-table">
                      <div className="div-table" width="100%">
                        <div className="div-thead">
                          <div className="div-row">
                            <div className="div-column-th">Id</div>
                            <div className="div-column-th">Name</div>
                            <div className="div-column-th">State</div>
                            <div className="div-column-th">Platform</div>
                            <div className="div-column-th">AZ</div>
                            <div className="div-column-th">IP</div>
                          </div>
                        </div>
                        <div className="div-tbody">
                          {resourceMarkup(compInstance)}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
};
export default NetworkComputeInstances;
