import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MUIDataTable from 'mui-datatables';
import React, { useState, useEffect } from 'react';
import AccessReqHistotoryPopup from './AccessReqHistotoryPopup';

const AccessReqHistory = (props) => {
  const { data } = props;

  const [tableData, setTableData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const openPopupBox = (rowIndex) => {
    setSelectedRow(rowIndex.rowIndex);
    setOpenPopup(true);
  };

  function getColumn() {
    const columns = [
      {
        name: 'Subscription Name',
        options: {
          display: true,
          download: true,
          filter: false,
          print: false,
          viewColumns: false,
        },
      },
    ];
    columns.push({
      name: 'Subscription Owner',
      options: {
        display: true,
        download: true,
        filter: false,
        print: false,
        viewColumns: false,
      },
    });
    columns.push({
      name: 'Employee Name',
      options: {
        display: true,
        download: true,
        filter: false,
        print: false,
        viewColumns: false,
      },
    });
    columns.push({
      name: 'Role',
      options: {
        display: true,
        download: true,
        filter: false,
        print: false,
        viewColumns: false,
      },
    });
    columns.push({
      name: 'Training',
      options: {
        display: true,
        download: true,
        filter: false,
        print: false,
        viewColumns: false,
      },
    });
    columns.push({
      name: 'Date of Request',
      options: {
        display: true,
        download: true,
        filter: false,
        print: false,
        viewColumns: false,
      },
    });
    columns.push({
      name: 'View',
      options: {
        download: false,
        filter: false,
        viewColumns: true,
        sort: false,

        customBodyRender: (dataIndex, rowIndex) => (
          <div className="text-center">
            <button
              type="button"
              className="btn text-primary me-1"
              onClick={() => openPopupBox(rowIndex)}
            >
              <FontAwesomeIcon icon={faEye} />
            </button>
          </div>
        ),
      },
    });
    return [...columns];
  }

  const onCancel = () => {
    setOpenPopup(false);
  };

  const onChangeRowsPerPage = (rowsPerPagee) => {
    setRowsPerPage(rowsPerPagee);
  };

  useEffect(() => {
    if (data) {
      const formattedData = data
        .map((d) => ({
          subscriptionName: d.subscriptionName,
          subscriptionOwner: d.subscriptionOwnerName,
          EmployeeName: d.employeeName,
          Role: d.role,
          Training: d.training,
          DateofRequest: new Date(d.createdDate).toLocaleDateString(),
        }));
      setTableData(formattedData.map(Object.values));
    }
  }, [data]);

  const options = {
    filter: true,
    filterType: 'dropdown',
    rowsPerPage,
    rowsPerPageOptions: [10, 20],
    onChangeRowsPerPage,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    downloadOptions: { filename: 'Az-Subs-Access-Req-History.csv' },
    responsive: 'standard',
  };

  return (
    <>
      <MUIDataTable
        data={tableData}
        columns={getColumn()}
        options={options}
      />
      {openPopup ? (
        <>
          <div className="log-out-layer" />
          <AccessReqHistotoryPopup row={selectedRow} onCancel={onCancel} data={data} />
        </>
      ) : ''}
    </>
  );
};

export default AccessReqHistory;
