import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import ApiTypeAhead from '../Common/ApiTypeAhead';
import Input from '../Common/Input';
import Alert from '../Common/Alert';
import Loader from '../Loader';

const TrainingOverridePopup = (props) => {
  const defaultRequest = () => ({
    employeeId: '',
    mailId: '',
    certificationTitle: '',
    certificationCourse: '',
    courseId: 0,
    certificationNumber: '',
    certificationFileName: '',
    attachment: null,
  });
  const { label, title, vendor } = props;
  const [loader, setLoader] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [request, setRequest] = useState(defaultRequest());
  const [validation, setValidation] = useState({});
  const validEmail = (value) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);
  const [showMessage, setShowMessage] = useState(false);
  const [messageClass, setMessageClass] = useState('info');
  const [message, setMessage] = useState('');

  const [cources, setCources] = useState(null);
  const [allCources, setAllCources] = useState(null);

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;
    setRequest((ps) => {
      if (name === 'employeeId') {
        return {
          ...ps,
          employeeId: value,
        };
      }
      return {
        ...ps,
        [name]: value,
      };
    });
  };

  const handleCourseChange = (e, name) => {
    const { value } = e;
    const course = allCources.filter((i) => i.id === value)[0];
    setRequest((ps) => ({
      ...ps,
      [name]: course.title,
      certificationCourse: course.course,
      courseId: course.id,
    }));
  };

  const handleValidation = (e) => {
    const { target } = e;
    const { name, required, value } = target;
    if (required === true) {
      setValidation({
        ...validation,
        [name]: value ? '' : 'Required *',
      });
      if (name === 'mailId' && value) {
        setValidation({
          ...validation,
          [name]: validEmail(value) ? '' : 'Invalid email address',
        });
      }
    }
  };

  function getCourses() {
    axios
      .get(`/api/TrainingOverride/GetTrainingList/${vendor}`)
      .then((response) => {
        if (response.data !== '') {
          setAllCources(response.data);
          setCources(
            response.data.map((a) => ({
              value: a.id,
              label: a.course,
            })),
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getCourses();
  }, []);

  useEffect(() => {
    if (request.employeeId !== '') {
      axios
        .get(`/api/people/${request.employeeId}`)
        .then((response) => {
          if (response.data === '') {
            setValidation({
              ...validation,
              mailId: 'invalid email Id',
            });
          }
          setRequest({
            ...request,
            mailId: response.data.email,
          });
        })
        .catch((err) => {
          console.log(err);
          setValidation({
            ...validation,
            mailId: 'invalid email Id',
          });
        });
    }
  }, [request.employeeId]);

  const handleSubmit = async () => {
    const validation = {
      employeeId: request.employeeId
        ? ''
        : 'Please provide Employee Name',
      mailId: request.mailId
        ? validEmail(request.mailId)
          ? ''
          : 'Invalid email address'
        : "Please provide employee's email address.",
      certificationTitle: request.certificationTitle
        ? ''
        : `Please slect ${vendor} Certification Title`,
      certificationNumber: request.certificationNumber
        ? ''
        : `Please provide ${vendor} Certification Number`,
    };

    setValidation(validation);

    let validErrorExist = false;

    Object.keys(validation).forEach((o) => {
      if (validation[o]) {
        validErrorExist = true;
      }
    });

    if (!validErrorExist) {
      const formData = new FormData();
      formData.append('vendor', vendor);
      formData.append('employeeId', request.employeeId);
      formData.append('mailId', request.mailId);
      formData.append('certificationTitle', request.certificationTitle);
      formData.append('certificationCourse', request.certificationCourse);
      formData.append('courseId', request.courseId);
      formData.append('certificationNumber', request.certificationNumber);
      formData.append('attachment', selectedFile);

      const method = 'post';
      setLoader(true);

      axios({
        method,
        url: '/api/TrainingOverride/Save',
        data: formData,
        headers: { 'Content-Type': 'multipart/form-data' },
      })
        .then((response) => {
          if (response.data === 'success') {
            setShowMessage(true);
            setMessage('You have successfully Submitted the details for training override');
            setMessageClass('success');
            setRequest(defaultRequest());
          }
          if (response.data === 'failure') {
            setShowMessage(true);
            setMessage('Something went wrong!');
            setMessageClass('light-danger');
            setRequest(defaultRequest());
          }
        })
        .catch((err) => {
          if (err.name !== 'AbortError') {
            console.error(err);
            setShowMessage(true);
            setMessage('Something went wrong!');
            setMessageClass('light-danger');
            setRequest(defaultRequest());
          }
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };
  return (
    <>
      <div
        className="modal fade reqNewAccount show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        style={{ display: 'block' }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header px-4">
              <h4 className="modal-title" id="exampleModalLabel">
                {title}
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => props.onCancel(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {loader ? <Loader />
                : (
                  <form className="form">
                    <div className="row">
                      {showMessage ? (
                        <div className="col-12">
                          <Alert
                            message={message}
                            alertClass={messageClass}
                            onClose={() => setShowMessage(false)}
                          />
                        </div>
                      ) : ''}
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <ApiTypeAhead
                            name="employeeId"
                            defaultId="employeeId"
                            displayKey="employeeName"
                            query={request.employeeName}
                            title="Employee"
                            requiredSign="*"
                            placeholder="Start typing to search Employee"
                            url="/api/lookup/people"
                            onChange={handleChange}
                            required
                            errorText={validation.employeeId}
                            onBlur={handleValidation}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <Input
                            name="mailId"
                            defaultId="mailId"
                            displayKey="mailId"
                            title="Employee Email"
                            requiredSign="*"
                            placeholder="Employee Email"
                            value={request.mailId}
                            onChange={handleChange}
                            required
                            onBlur={handleValidation}
                            errorText={validation.mailId}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <label>
                          {vendor}
                          {' '}
                          Certification Title
                          {' '}
                          <label className="required-sign">*</label>
                        </label>
                        <div className="form-group">
                          <Select
                            name="certificationTitle"
                            className="react-select"
                            placeholder={`Select ${vendor} Certification Title`}
                            options={cources}
                            onChange={(v) => handleCourseChange(v, 'certificationTitle')}
                          />
                          {validation.certificationTitle
                            && (
                              <div className="text-danger small mt-1">
                                {validation.certificationTitle}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <Input
                            name="certificationNumber"
                            defaultId="certificationNumber"
                            displayKey="certificationNumber"
                            title={`${vendor} Certification Number`}
                            requiredSign="*"
                            placeholder={`${vendor} Certification Number`}
                            value={request.certificationNumber}
                            onChange={handleChange}
                            required
                            onBlur={handleValidation}
                            errorText={validation.certificationNumber}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <div className="form-group">
                          <label title="Attachment">Attachment</label>
                          <input
                            type="file"
                            onChange={(e) => setSelectedFile(e.target.files[0])}
                            id="fileUpload"
                            accept=".pdf"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                )}
            </div>
            <div className="modal-footer px-4">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={handleSubmit}
              >
                {label}
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => props.onCancel(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrainingOverridePopup;
