import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { getVendorAccountList, getServicesByAccount } from '../../../store/actions/awsServicesAction';
import DonutChart from '../../Common/DonutChart';
import Loader from '../../Loader';

const ServiceUsageDistribution = ({ payerAccount, startDate, endDate }) => {
  const dispatch = useDispatch();
  const awsServiceState = useSelector((state) => state.awsServicesInfo);
  const {
    accountList, accountListLoading, errorAccountList,
    servicesByAccount, errorServicesByAccount,
  } = awsServiceState;
  const [formattedAccountList, setFormattedAccountList] = useState({ multiValue: [], filterOptions: [] });
  const [formattedServicesByAccountData, setFormattedServicesByAccntData] = useState(null);
  // const [origAwsCntData, setOrigAwsCntData] = useState(null);
  const [loading, setLoading] = useState(true);
  const prevSelectData = useRef(null);

  const onPageLoadServiceByPayerAccount = () => {
    setLoading(true);
    const filtersQryStrg = `?filters=account_identifier==${payerAccount.value}`;
    dispatch(getServicesByAccount(`/api/AwsAnalytics/GetServicesByAccounts/${startDate}/${endDate}/custom${filtersQryStrg}`));
  };
  useEffect(() => {
    setLoading(true);
    if (startDate && endDate) {
      dispatch(getVendorAccountList('/api/AwsAnalytics/GetAccountNameByPayerAccount'));
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (accountList?.result?.length > 0) {
    // format account list to get only payeraccount vendor accounts.
      const vndAccList = accountList.result.filter((ac) => ac.parentAccountId && ac.parentAccountId === payerAccount.value)
        .sort((a, b) => ((a.vendorAccountName > b.vendorAccountName) ? 1 : ((b.vendorAccountName > a.vendorAccountName) ? -1 : 0)));
      setFormattedAccountList({
        multiValue: [],
        filterOptions: vndAccList?.map((a) => ({ value: a.vendorAccountId, label: a.vendorAccountName })),
      });
      setLoading(false);
      onPageLoadServiceByPayerAccount();
    }
  }, [payerAccount, accountList]);

  useEffect(() => {
    if (formattedAccountList.multiValue?.length > 0) {
      prevSelectData.current = formattedAccountList.multiValue;
      setLoading(true);
      let filtersQryStrg = '?';
      formattedAccountList.multiValue.forEach((element) => {
        filtersQryStrg += `filters=vendor_account_name==${element.label}&`;
      });
      dispatch(getServicesByAccount(`/api/AwsAnalytics/GetServicesByAccounts/${startDate}/${endDate}/custom${filtersQryStrg}`));
    } else if (prevSelectData.current !== null) {
      onPageLoadServiceByPayerAccount();
    }
  }, [formattedAccountList.multiValue]);

  useEffect(() => {
    if (servicesByAccount.length > 0) {
      setFormattedServicesByAccntData(servicesByAccount.map((z) => ({ label: z.dimensions[0], value: parseFloat(z.metrics[0].sum) })));
    } else {
      setFormattedServicesByAccntData([]);
      setLoading(false);
    }
  }, [servicesByAccount]);

  useEffect(() => {
    // set loading is false only when formattedServicesByAccountData is changed
    setLoading(false);
  }, [formattedServicesByAccountData]);

  const handleChange = (item) => {
    setFormattedAccountList({ ...formattedAccountList, multiValue: item });
  };

  return (
    <div className="tab-pane active" id="home-v">
      {accountListLoading || loading ? (
        <Loader />
      ) : (
        <div className="form-group">
          {formattedAccountList?.filterOptions?.length > 0 && !errorAccountList && (
          <div className="row justify-content-center justify-content-center-xs">
            <div className="col-12 col-md-6 col-sm-12">
              <Select
                name="accountListFilter"
                value={formattedAccountList.multiValue}
                options={formattedAccountList.filterOptions}
                onChange={handleChange}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder="Select AWS Account filter"
                isMulti
              />
            </div>
          </div>
          )}
          <div className="row justify-content-center">
            <div className="col-xl-6 col-lg-6 col-md-9 col-sm-10 col-12">
              {formattedServicesByAccountData && !errorServicesByAccount && (
              <DonutChart
                pieChartCostData={formattedServicesByAccountData.map((x) => x.value)}
                pieChartLableData={formattedServicesByAccountData.map((x) => x.label)}
                title=""
                type="count"
                chartType="donut"
              />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceUsageDistribution;
