import React, { useState, useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import PeopleInfo from '../People/Info';
import AccountInfo from '../Account/Info';
import EducationInfo from '../Education/Info';
import NetworkInfo from '../Network/Info';
import RequestInfo from '../Admin/Requests/Info';
import CloudGuruInfo from '../Admin/CloudGuru/Info';
import ProductAnnouncementInfo from '../Vendor/ProductAnnouncements/Info';
import PostEventSummriesInfo from '../Vendor/PostEventSummaries/Info';
import StockWidget from '../Vendor/StockWidget/Info';
import AwsHealthEvent from '../Vendor/HealthEvents/Info';
import VendorServices from '../Vendor/Services/Info';
import AzureSubscriptionInfo from '../AzureSubscription/Info';
import AzureSubscriptionEditRequestInfo from '../Admin/Azure/Requests/HeaderInfo';

const HeaderWithInfo = ({ headerName, infoIcon }) => {
  const [styleTheme, setStyleTheme] = useState({ display: 'none' });
  const infoBlockRef = useRef(null);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  // Close info modal when clicked outside it
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        infoBlockRef.current
        && !infoBlockRef.current.contains(event.target)
      ) {
        setStyleTheme({ display: 'none' });
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [infoBlockRef]);
  // Render different information based on page in.
  function pageInfo(pageName) {
    switch (pageName) {
      case 'People':
        return <PeopleInfo />;
      case 'Accounts':
        return <AccountInfo />;
      case 'Subscriptions':
        return <AzureSubscriptionInfo />;
      case 'Education':
        return <EducationInfo />;
      case 'Networks':
        return <NetworkInfo />;
      case 'Manage Requests':
        return <RequestInfo />;
      case 'Cloud Guru License Holder':
        return <CloudGuruInfo />;
      case 'AWS Post-Event Summaries':
        return <PostEventSummriesInfo />;
      case 'Cloud Stock Widgets':
        return <StockWidget />;
      case 'AWS Health Events':
        return <AwsHealthEvent />;
      case 'AWS Service Availability':
        return <VendorServices />;
      case 'Product Announcements':
        return <ProductAnnouncementInfo />;
      case 'Azure Subscriptions':
        return <AzureSubscriptionInfo />;
      case 'Azure Subscriptions Edit Request':
        return <AzureSubscriptionEditRequestInfo />;
      default:
        return '';
    }
  }
  // On click info icon
  const handleOnInfoClick = () => {
    // Display information as block only when on mobile view
    if (isMobile) {
      setStyleTheme({ display: 'block' });
    }
  };

  return (
    <h3 className="card-label col p-0">
      <div className="d-flex align-items-center justify-content-start">
        <span>
          {headerName}
          {' '}
        </span>
        <span
          className={`svg-icon svg-light ml-2 custom-tooltip-text ${infoIcon ? 'hide' : ''}`}
          onClick={handleOnInfoClick}
        >
          <svg
            stroke="currentColor"
            className="showModal"
            id="showModal"
            fill="#3699ff"
            strokeWidth="0"
            viewBox="0 0 16 16"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 108 0a8 8 0 000 16z"
              clipRule="evenodd"
            />
            <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
            <circle cx="8" cy="4.5" r="1" />
          </svg>
          {/* <!-- Hovered Info --> */}
          <div
            className="custom-tooltip-info showModalSection"
            id="showModalSection"
            style={isMobile ? styleTheme : {}}
          >
            <div ref={infoBlockRef} className="info-section">
              {pageInfo(headerName)}
            </div>
          </div>
        </span>
      </div>
    </h3>
  );
};

export default HeaderWithInfo;
