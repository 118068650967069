import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck, faUserTimes, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import HeaderWithInfo from '../Components/Common/HeaderWithInfo';
import Loader from '../Components/Loader';
import Alert from '../Components/Common/Alert';
import DisplayCloudGuru from '../Components/Admin/CloudGuru/Display';
import { Context } from '../Context/MenuContext';
import ManagerSeatAllocationPopup from '../Components/Admin/CloudGuru/ModalPopUp';
import DonutChart from '../Components/Common/DonutChart';

const CloudGuru = () => {
  const [showModalPopUp, setShowModalPopUp] = useState(false);
  const [activeTab, setActiveTab] = useState('graph');
  const [activeMembers, setActiveMembers] = useState([]);
  const [inactiveMembers, setInactiveMembers] = useState([]);
  const [linuxTableData, setLinuxTableData] = useState([]);
  const [selectedCard, setSelectedCard] = useState('');
  const [graphData, setGraphData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);

  // Get selectedMenu data from Menu Context
  const [, setSelectedMenu, , setSelectedSubMenu] = useContext(Context);
  // Change menu css with peope page is loaded.
  useEffect(() => {
    setSelectedMenu('admin');
    setSelectedSubMenu('cloudguru');
  }, []);

  useEffect(() => {
    axios.get('/api/cloudguru/GetLAActiveMember')
      .then((response) => {
        // logic for getting seperate list for active and inactive members
        const last30Date = new Date(new Date().setDate(new Date().getDate() - 30));
        const lstActiveMem = response.data.filter((x) => new Date(x.lastLogin.replace('\r', '')) >= last30Date);
        setActiveMembers(lstActiveMem);
        setInactiveMembers(response.data.filter((x) => new Date(x.lastLogin.replace('\r', '')) < last30Date));
        // eslint-disable-next-line no-unused-expressions
        lstActiveMem.length > 0 ? setSelectedCard('active') : setSelectedCard('inactive');
        setLoaded(true);
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          setErrorMessage(err.message);
          setShowError(true);
          setLoaded(true);
        }
      });
  }, []);

  useEffect(() => {
    if (selectedCard) {
      setActiveTab('graph');
      if (selectedCard === 'active') {
        setLinuxTableData(activeMembers);
      } else {
        setLinuxTableData(inactiveMembers);
      }
      setLoaded(false);
      axios.get(`api/cloudguru/GetManagerActiveInactiveMapping/${selectedCard}`)
        .then((response) => {
          setGraphData(response.data.filter((d) => d.supervisorId));
          setLoaded(true);
        })
        .catch((err) => {
          if (err.name !== 'AbortError') {
            setErrorMessage(err.message);
            setShowError(true);
            setLoaded(true);
          }
        });
    }
  }, [selectedCard]);

  return (
    <>
      <div className="row mb-2">
        <div className="col-12">
          <div className="card card-custom mb-4">
            <div className="card-header align-items-center">
              <div className="card-title align-items-center justify-content-between w-100">
                <HeaderWithInfo headerName="Cloud Guru License Holder" />
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  onClick={() => { setShowModalPopUp(true); }}
                >
                  <FontAwesomeIcon icon={faExternalLinkAlt} />
                  {' '}
                  Manager&lsquo;s Seat Allocated
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 gutter-b">
                  <div className={`cloudguru-section h-100 ${selectedCard === 'active' ? 'active-card' : ''}`} onClick={() => setSelectedCard('active')}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="flex-direction-coloumn">
                        <div className="cloud-guru-members">{activeMembers.length}</div>
                        <div className="h5 mb-0 font-weight-normal text-gray-800 text-xs">Active Members</div>
                      </div>
                      <div className="linux-member-icon active-member-icon">
                        <span>
                          <FontAwesomeIcon icon={faUserCheck} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 col-12 gutter-b">
                  <div className={`cloudguru-section h-100 ${selectedCard === 'inactive' ? 'active-card' : ''}`} onClick={() => setSelectedCard('inactive')}>
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="flex-direction-coloumn">
                        <div className="cloud-guru-members">{inactiveMembers.length}</div>
                        <div className="h5 mb-0 font-weight-normal text-gray-800 text-xs">In-Active Members</div>
                      </div>
                      <div className="linux-member-icon inactive-member-icon">
                        <span>
                          <FontAwesomeIcon icon={faUserTimes} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mb-2">
        <div className="col-12">
          <div className="card card-custom mb-4">
            <div className="card-header align-items-center">
              <div className="card-title align-items-center justify-content-between w-100">
                <h3 className="card-label text-capitalize">{`Cloud Guru ${selectedCard} Members`}</h3>
                <div>
                  <ul className="nav nav-pills bg-nav-pills nav-justified mt-2 justify-content-center h-100">
                    <li className="nav-item">
                      <div
                        onClick={() => setActiveTab('graph')}
                        data-toggle="tab"
                        aria-expanded="false"
                        className={`nav-link font-weight-normal font-size-lg ${activeTab === 'graph' ? 'active' : ''}`}
                      >
                        Graph
                      </div>
                    </li>
                    <li className="nav-item">
                      <div
                        onClick={() => setActiveTab('table')}
                        data-toggle="tab"
                        aria-expanded="true"
                        className={`nav-link font-weight-normal font-size-lg ${activeTab === 'table' ? 'active' : ''}`}
                      >
                        Table
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  {loaded && graphData.length > 0
                    ? (
                      <>
                        {showError
                          ? (
                            <Alert
                              message={errorMessage}
                              alertClass="light-danger"
                              onClose={() => setShowError(false)}
                            />
                          ) : (
                            !errorMessage && (
                              <div className="tab-content p-0">
                                <div
                                  className={`tab-pane ${activeTab === 'graph' ? 'active' : ''}`}
                                >
                                  <div className="row justify-content-center">
                                    <div className="col-xl-6 col-lg-6 col-md-9 col-sm-10 col-12">
                                      <DonutChart
                                        pieChartCostData={graphData.map((x) => x.memberCount)}
                                        pieChartLableData={graphData.map((x) => x.supervisorName)}
                                        title="Seat Allocated Distribution across Managers"
                                        type="number"
                                        chartType="donut"
                                      />
                                      <h5 className="h5 text-center mt-5 text-muted-custom">{`Manager to ${selectedCard} member distribution`}</h5>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className={`tab-pane ${activeTab === 'table' ? 'active' : ''}`}
                                >
                                  <DisplayCloudGuru laActiveMembers={linuxTableData} />
                                </div>
                              </div>
                            )
                          )}
                      </>
                    ) : <Loader />}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        showModalPopUp && (
          <>
            <div className="log-out-layer" />
            <ManagerSeatAllocationPopup
              onClose={() => setShowModalPopUp(false)}
            />
          </>
        )
      }
    </>
  );
};

export default CloudGuru;
