import React, { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import ServicesDisplay from '../Components/Vendor/Services/Display';
import { Context as PageContext } from '../Context/PageContext';
import { saveUserPageVisitHistory } from '../store/actions/peopleAction';

// Contexts
import { Context } from '../Context/MenuContext';
import { VendorServiceContext } from '../Context/VendorContext';

const VendorServices = (props) => {
  // Get selectedMenu data from Menu Context
  const [, setSelectedMenu, , setSelectedSubMenu] = useContext(Context);
  const { user } = props;
  const [pageContextState, setPageContextState] = useContext(PageContext);
  const { awsServicesPage } = pageContextState;
  const dispatch = useDispatch();

  // Get user login details
  useEffect(() => {
    if (awsServicesPage === '') {
      setPageContextState({
        ...pageContextState,
        awsServicesPage: 'awsServices',
      });
      const apiUrl = `/api/Auth/SaveUserPageVisit/${user.employeeId}/awsServices`;
      dispatch(saveUserPageVisitHistory(apiUrl));
    }
  }, [awsServicesPage]);

  useEffect(() => {
    // Change menu css with peope page is loaded.
    setSelectedMenu('vendor');
    setSelectedSubMenu('awsServices');
  }, []);
  return (
    <VendorServiceContext>
      <ServicesDisplay />
    </VendorServiceContext>
  );
};

export default VendorServices;
