import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import ApiDropDown from '../Common/ApiDropdown';
import {
  getThirtyDaysCostData,
  getSixtyDaysCostData,
} from '../../store/actions/ascendonAction';
import Barchart from '../Common/BarChart';
import Loader from '../Loader';

const CloudabilityMonthlyReport = () => {
  const [currentView, setCurrentView] = useState('Ascendon');
  const [loaded, setLoaded] = useState(false);
  const [thirtyDayCost, setThirtyDayCost] = useState(null);
  const [sixtyDayCost, setSixtyDayCost] = useState(null);
  const [thirtyDayHeader, setThirtyDayHeader] = useState('');
  const [sixtyDayHeader, setSixtyDayHeader] = useState('');
  const [categories, setCategories] = useState([]);
  const [series, setSeries] = useState([]);
  const dispatch = useDispatch();
  const monthylyData = useSelector((state) => state.cloudabilityList);
  const { monthlyCostLoading, thirtydaysCosting, sixtydaysCosting } = monthylyData;
  const [tpSpend, setTpSpend] = useState({});
  const rightSliderScroll = useRef(null);

  // this function will calculate all the similar type of services (spenders) irrespective of uage family and region and push in one array
  function getServicesCost(data) {
    const topSpendService = [];
    data.serviceCosts.forEach((o) => {
      const found = topSpendService.some((el) => el.name === o.name);
      if (found) {
        const foundIndex = topSpendService.findIndex((x) => x.name === o.name);
        topSpendService[foundIndex].cost += Math.round(o.cost);
      } else {
        topSpendService.push({ name: o.name, cost: Math.round(o.cost) });
      }
    });
    return topSpendService;
  }

  function getTopSpendServices(data, thirtyDayCostData, isSixtyDayData) {
    // calculate start and end dates with month
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    /* eslint-disable radix */
    const intStartMonth = parseInt(data.startDate.split('-')[1]) - 1;
    const intEndMonth = parseInt(data.endDate.split('-')[1]) - 1;
    /* eslint-enable */
    const startDate = `${monthNames[intStartMonth]} ${
      data.startDate.split('-')[2]
    }`;
    const endDate = `${monthNames[intEndMonth]} ${data.endDate.split('-')[2]}`;
    const servicesCostData = getServicesCost(data);
    const newArr = {
      totalCost: data.totalCost,
      dataHead: `${startDate} to ${endDate}`,
      serviceCosts: [],
    };
    if (isSixtyDayData) {
      for (let i = 0; i < thirtyDayCostData.serviceCosts.length; i++) {
        for (let j = 0; j < servicesCostData.length; j++) {
          if (
            thirtyDayCostData.serviceCosts[i].name === servicesCostData[j].name
          ) {
            newArr.serviceCosts.push(servicesCostData[j]);
          }
        }
      }
    } else {
      let stop = 0;
      for (let h = 0; h < servicesCostData.length; h++) {
        if (servicesCostData[h].name !== 'AWS Savings Plan' && stop < 5) {
          newArr.serviceCosts.push(servicesCostData[h]);
          stop++;
        }
        if (servicesCostData[h].name === 'AWS Savings Plan') {
          newArr.totalCost -= servicesCostData[h].cost;
        }
      }
    }
    return newArr;
  }

  function monthlyPostmortemFinal(thirtyDayData, sixtyDayData) {
    setCategories(
      thirtyDayData.serviceCosts.map((s) => s.name.replace('AWS ', '')),
    );
    const seriesData = [
      {
        name: thirtyDayData.dataHead,
        data: thirtyDayData.serviceCosts.map((t) => t.cost),
      },
      {
        name: sixtyDayData.dataHead,
        data: sixtyDayData.serviceCosts.map((s) => s.cost),
      },
    ];
    setSeries(seriesData);
    setThirtyDayCost(Math.round(thirtyDayData.totalCost));
    setSixtyDayCost(Math.round(sixtyDayData.totalCost));
    setThirtyDayHeader(thirtyDayData.dataHead);
    setSixtyDayHeader(sixtyDayData.dataHead);
    setLoaded(true);
  }

  useEffect(() => {
    const url = `/api/AscendonCost/GetServiceCloudabilityCost?viewName=${currentView}_Services&filterItem=last_30_days`;
    dispatch(getThirtyDaysCostData(url));
  }, [currentView]);

  useEffect(() => {
    if (thirtydaysCosting) {
      const thirtyDaysSpend = getTopSpendServices(
        thirtydaysCosting,
        null,
        false,
      );
      setTpSpend(thirtyDaysSpend);
      const url2 = `/api/AscendonCost/GetServiceCloudabilityCost?viewName=${currentView}_Services&filterItem=last_60_to_30_days`;
      dispatch(getSixtyDaysCostData(url2));
    }
  }, [thirtydaysCosting]);

  useEffect(() => {
    if (sixtydaysCosting && Object.keys(tpSpend).length !== 0) {
      const sixtyDaysSpend = getTopSpendServices(
        sixtydaysCosting,
        tpSpend,
        true,
      );
      monthlyPostmortemFinal(tpSpend, sixtyDaysSpend);
    }
  }, [sixtydaysCosting, tpSpend]);

  const handleChange = (e) => {
    const { target } = e;
    const { value } = target;
    if (!(value === '')) {
      setCurrentView(value);
      setLoaded(false);
    }
  };

  const scrollToRight = () => {
    rightSliderScroll.current.scrollLeft += 150;
  };

  const scrollToLeft = () => {
    rightSliderScroll.current.scrollLeft += -150;
  };

  return (
    <div>
      {loaded && !monthlyCostLoading ? (
        <>
          <div className="tab-pane" id="messages-v">
            <div className="form-group">
              <div className="row justify-content-center">
                <label className="col-12 col-sm-5 col-md-4 col-lg-3 col-xl-2 col-form-label py-2 text-right text-left-xs text-nowrap">
                  Select the Environment
                </label>
                <div className="col-12 col-sm-5 col-md-5 col-lg-4 col-xl-4">
                  <ApiDropDown
                    value={currentView}
                    placeholder="Pick One..."
                    title=""
                    url="/api/AscendonCost/GetFilterItems?value=environment"
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-8">
            <div className="col-12">
              <h3 className="text-center">
                Total Cost:
                <span>
                  $
                  {Math.round(thirtyDayCost + sixtyDayCost).toLocaleString()}
                </span>
              </h3>
            </div>
            <div className="col-12 ascendon-section mt-6">
              <h5 className="text-left">Top Usage Services</h5>
              <div className="mt-3">
                <Barchart categories={categories} series={series} />
              </div>
            </div>
          </div>
          <div className="row mt-8 justify-content-center">
            <div className="col-12 col-sm-10 col-md-9">
              <div className="horizontal-timeline">
                <div className="swiper-container text-center">
                  <div className="swiper-wrapper" ref={rightSliderScroll}>
                    <div className="swiper-slide">
                      <div className="timeline-amount">
                        <span className="span-amount">
                          $
                          {Math.round(thirtyDayCost).toLocaleString()}
                          {' '}
                          {sixtyDayCost < thirtyDayCost ? (
                            <FontAwesomeIcon
                              icon={faArrowUp}
                              className="ml-3"
                              style={{ color: 'red' }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={faArrowDown}
                              className="ml-3"
                              style={{ color: 'green' }}
                            />
                          )}
                        </span>
                      </div>
                      <div className="status">
                        <span>
                          {thirtyDayHeader}
                          {' '}
                          Spend
                          <br />
                          (Last 30 Days)
                        </span>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="timeline-amount">
                        <span className="span-amount">
                          $
                          {Math.round(sixtyDayCost).toLocaleString()}
                        </span>
                      </div>
                      <div className="status">
                        <span>
                          {sixtyDayHeader}
                          {' '}
                          Spend
                          <br />
                          (Previous 30 Days)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <button type="button" className="arrow arrow__prev" onClick={scrollToLeft}>
                  <span className="svg-icon svg-icon-primary svg-icon-2x">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                          d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                          fill="#000000"
                          fillRule="nonzero"
                          transform="translate(12.000003, 11.999999) scale(-1, 1) rotate(-270.000000) translate(-12.000003, -11.999999) "
                        />
                      </g>
                    </svg>
                  </span>
                </button>
                <button type="button" className="arrow arrow__next" onClick={scrollToRight}>
                  <span className="svg-icon svg-icon-primary svg-icon-2x">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      width="24px"
                      height="24px"
                      viewBox="0 0 24 24"
                      version="1.1"
                    >
                      <g
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <polygon points="0 0 24 0 24 24 0 24" />
                        <path
                          d="M6.70710678,15.7071068 C6.31658249,16.0976311 5.68341751,16.0976311 5.29289322,15.7071068 C4.90236893,15.3165825 4.90236893,14.6834175 5.29289322,14.2928932 L11.2928932,8.29289322 C11.6714722,7.91431428 12.2810586,7.90106866 12.6757246,8.26284586 L18.6757246,13.7628459 C19.0828436,14.1360383 19.1103465,14.7686056 18.7371541,15.1757246 C18.3639617,15.5828436 17.7313944,15.6103465 17.3242754,15.2371541 L12.0300757,10.3841378 L6.70710678,15.7071068 Z"
                          fill="#000000"
                          fillRule="nonzero"
                          transform="translate(12.000003, 11.999999) rotate(-270.000000) translate(-12.000003, -11.999999) "
                        />
                      </g>
                    </svg>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};
export default CloudabilityMonthlyReport;
