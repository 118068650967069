import React, { useEffect, useState } from 'react';

const ProfileTab = (props) => {
  const [navigators] = useState(props.tabList);
  const [selectedTab, setSelectedTab] = useState(props.selected);

  useEffect(() => {
    setSelectedTab(props.selected);
  }, [props.selected]);

  function handleClick(value) {
    setSelectedTab(value);
    props.onClick(value);
  }
  return (
    <div>
      <>
        <div className="nav nav-tabs tabs-left sideways">
          {navigators.map((navigator) => (
            <div className="horizontal-tab-step" key={navigator.value}>
              <div
                className={`horizontal-tab-wrapper ${selectedTab === navigator.value ? `${props.cloudEnv}active` : ''
                }`}
                data-toggle="tab"
                onClick={() => handleClick(navigator.value)}
              >
                <div className="horizontal-tab-label">
                  <h3 className="horizontal-tab-title">
                    {props.cloudEnv !== 'aws'
                   && navigator.label === 'Accounts' ? 'Subscriptions' : navigator.value}
                  </h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    </div>
  );
};

export default ProfileTab;
