import React from 'react';
import PropTypes from 'prop-types';
import Alert from '../../Common/Alert';

const NetworkInterfaceTab = (props) => {
  const { interfaces, subnets } = props;

  const interfaceMarkup = interfaces && interfaces.map((s) => {
    const {
      NetworkInterfaceId,
      SubnetId,
      Status,
      Description,
      MacAddress,
      PrivateIpAddress,
      Association,
    } = s;
    const { PublicIp } = Association || {};
    const subnet = subnets.find((s) => s.SubnetId === SubnetId);
    return (
      <div className="div-row" key={NetworkInterfaceId}>
        <div className="div-column">{NetworkInterfaceId}</div>
        <div className="div-column">{Description || '-'}</div>
        <div className="div-column">{Status}</div>
        <div className="div-column">{SubnetId}</div>
        <div className="div-column">{subnet.CidrBlock}</div>
        <div className="div-column">{subnet.AvailabilityZone}</div>
        <div className="div-column">{PrivateIpAddress}</div>
        <div className="div-column">{PublicIp || '-'}</div>
        <div className="div-column">{MacAddress}</div>
      </div>
    );
  });

  return (
    <>
      {interfaces && interfaces.length > 0 ? (
        <div className="custom-table">
          <div className="respnsive-custom-table">
            <div className="div-table" width="100%">
              <div className="div-thead">
                <div className="div-row">
                  <div className="div-column-th">Id</div>
                  <div className="div-column-th">Description</div>
                  <div className="div-column-th">Status</div>
                  <div className="div-column-th">Subnet</div>
                  <div className="div-column-th">CIDR</div>
                  <div className="div-column-th">AZ</div>
                  <div className="div-column-th">Private IP</div>
                  <div className="div-column-th">Public IP</div>
                  <div className="div-column-th">MacAddress</div>
                </div>
              </div>
              <div className="div-tbody">
                {interfaceMarkup}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Alert message="No interfaces found." alertClass="info" />
      )}
    </>
  );
};

NetworkInterfaceTab.propTypes = {
  interfaces: PropTypes.arrayOf(PropTypes.shape({
    SubnetId: PropTypes.string.isRequired,
    NetworkInterfaceId: PropTypes.string.isRequired,
    Status: PropTypes.string.isRequired,
    Description: PropTypes.string.isRequired,
    MacAddress: PropTypes.string.isRequired,
    PrivateIpAddress: PropTypes.string.isRequired,
    Association: PropTypes.shape({
      PublicIP: PropTypes.string,
    }),
  })).isRequired,
  subnets: PropTypes.arrayOf(PropTypes.shape({
    SubnetId: PropTypes.string.isRequired,
    AvailabilityZone: PropTypes.string.isRequired,
    CidrBlock: PropTypes.string.isRequired,
  })).isRequired,
};

export default NetworkInterfaceTab;
