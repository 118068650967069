import axios from 'axios';
import {
  UPDATE_USER_ANNOUNCEMENT_MAAPING,
  ERROR_UPDATE_USER_ANNOUNCEMENT_MAAPING,
} from '../types';

/* eslint-disable import/prefer-default-export */
export const updateUserAnnouncementMapping = (apiUrl, payLoad) => async (dispatch) => {
  try {
    const method = 'post';
    const res = await axios({
      method,
      url: apiUrl,
      data: JSON.stringify(payLoad),
      headers: { 'Content-Type': 'application/json' },
    });
    dispatch({
      type: UPDATE_USER_ANNOUNCEMENT_MAAPING,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_UPDATE_USER_ANNOUNCEMENT_MAAPING,
      payload: error,
    });
  }
};
