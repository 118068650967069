import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const renderStars = (number) => {
  const lis = [];
  for (let i = 0; i < number; i++) {
    lis.push(<FontAwesomeIcon key={i} icon={faStar} />);
  }
  return lis;
};
const LicenseList = [
  'Passenger',
  'Flight Crew',
  'Co-Pilot',
  'Pilot',
  'Flying Ace',
];

const EducationInfo = () => (
  <div className="row">
    <div className="col-12">
      {LicenseList.map((license, index) => (
        <div key={license} className="d-flex mb-3">
          <span className="d-flex align-items-center text-secondary font-size-sm">
            {renderStars(index)}
            <span className="text-muted">{renderStars(4 - index)}</span>
          </span>
          <span className="ml-3">{license}</span>
        </div>
      ))}
    </div>
  </div>
);

export default EducationInfo;
