import React from 'react';
import PropTypes from 'prop-types';
import BigSearch from '../Common/BigSearch';

const AccountSearch = (props) => {
  const {
    selectedValue, search, environments, statuses,
  } = props;

  const searchTypes = [
    {
      key: 'Account',
      display: 'Account',
      placeholder: 'The account name or number',
    },
    {
      key: 'Owner',
      display: 'Contact / Owner',
      placeholder: 'The name of the account contact / owner',
    },
    {
      key: 'Mine',
      display: 'Mine',
      placeholder: 'The account name or number - only mine',
      alwaysSearch: true,
    },
    {
      key: 'Director',
      display: 'Executive / Director',
      placeholder:
        'The name of the director or executive the account owner is under',
    },
    {
      key: 'Product',
      display: 'Product',
      placeholder: 'The product code, name, subfamily or family',
    },
  ];

  const filters = [
    {
      key: 'status',
      url: '/api/lookup/accountStatus',
      label: 'Statuses',
    },
    {
      key: 'environment',
      url: '/api/lookup/environments',
      label: 'Environments',
    },
  ];

  const findIndex = (value) => searchTypes.findIndex((p) => p.key === (value || 'Account')) || 0;

  const executeSearch = (e) => {
    const { executeSearch } = props;
    const { bigSearch } = e;
    const { search, selectedValue, selectedFilters } = bigSearch;
    const { key } = selectedValue;
    const { status, environment } = selectedFilters;
    executeSearch(key, search, status || [], environment || []);
  };

  const clearSearch = (e) => {
    const { clearSearch } = props;
    const { bigSearch } = e;
    const { selectedFilters } = bigSearch;
    const { status, environment } = selectedFilters;
    clearSearch(status || [], environment || []);
  };

  const statusIndex = filters.findIndex((p) => p.key === 'status');
  const environmentIndex = filters.findIndex((p) => p.key === 'environment');

  if (statusIndex >= 0 && statuses !== undefined && String(statuses) !== '') {
    filters[statusIndex].initialSelection = statuses.split(',');
  }

  if (
    environmentIndex >= 0
    && environments !== undefined
    && String(environments) !== ''
  ) {
    filters[environmentIndex].initialSelection = environments.split(',');
  }

  return (
    <BigSearch
      srLabelTitle="Account Search"
      selectedIndex={findIndex(selectedValue)}
      search={search}
      executeSearch={executeSearch}
      clearSearch={clearSearch}
      searchTypes={searchTypes}
      filters={filters}
    />
  );
};

AccountSearch.propTypes = {
  selectedValue: PropTypes.string,
  search: PropTypes.string,
  statuses: PropTypes.string,
  environments: PropTypes.string,
  executeSearch: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
};

export default AccountSearch;
