import React, { useState, useEffect, useRef } from 'react';
import { FaSearch } from 'react-icons/fa';
import PropTypes from 'prop-types';
import axios from 'axios';
import Input from './Input';
import Spinner from './Spinner';

const ApiTypeAhead = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [showing, setShowing] = useState(false);
  const [options, setOptions] = useState({});
  const [active, setActive] = useState(0);
  const [query, setQuery] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const resultRef = useRef(null);

  useEffect(() => {
    const { query } = props;
    setQuery(query || '');
  }, []);

  // Close search result list when clicked outside the search result list
  useEffect(() => {
    function handleClickOutside(event) {
      if (resultRef.current && !resultRef.current.contains(event.target)) {
        setShowing(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [resultRef]);

  function handleEventFromCaller(id, display) {
    const { onChange, name, displayKey } = props;

    if (onChange && onChange instanceof Function) {
      onChange({
        target: {
          name,
          value: id,
          type: 'search',
        },
      });

      onChange({
        target: {
          name: displayKey,
          value: display,
          type: 'search',
        },
      });
    }
  }

  function handleClick(e, key, value) {
    setShowing(false);
    setQuery(value);
    setOptions({});
    setActive(0);
    handleEventFromCaller(key, value);
  }

  const checkKeyDown = (e) => {
    const { id, query } = props;
    const { keyCode, which } = e;
    const code = keyCode || which;
    if (code === 27) {
      // 27 is the escape keycode
      e.preventDefault();
      setShowing(false);
      setQuery(query || '');
      setOptions({});
      setActive(0);
      handleEventFromCaller(id, query);
    } else if (code === 38) {
      // 38 is up arrow
      e.preventDefault();
      setActive(active - 1);
    } else if (code === 40) {
      // 40 is down arrow
      e.preventDefault();
      setActive(active + 1);
    }
  };

  const checkKeyPress = (e) => {
    const { keyCode, which } = e;
    const code = keyCode || which;
    if (code === 13) {
      // 13 is the enter keycode
      e.preventDefault();
      const keys = Object.keys(options || {});
      const key = keys[active];
      const query = options.length > 0 ? options[key] : e.target.value;
      setShowing(false);
      setQuery(query);
      setOptions({});
      setActive(0);
      handleEventFromCaller(key, query);
    }
  };

  const executeSearch = (e) => {
    const { url, searchType } = props;
    const { target } = e;
    const { value } = target;
    setLoaded(false);
    setActive(0);
    setOptions([]);
    setShowing(true);
    setQuery(value);

    axios
      .get(`${url}?q=${value}&t=${searchType}`)
      .then((response) => {
        setOptions(response.data);
        setLoaded(true);
        setErrorMessage('');
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          console.error(err);
          setErrorMessage(err);
        }
      });
  };

  const {
    name,
    title,
    placeholder,
    tabIndex,
    required,
    errorText,
    onBlur,
    large,
    requiredSign,
  } = props;
  const markup = Object.keys(options || {}).map((option, index) => {
    const label = options[option];
    const activeClass = index === active ? 'active' : '';
    return (
      <li
        key={option}
        className={`list-group-item ${activeClass}`}
        style={{ cursor: 'pointer' }}
        onClick={(e) => handleClick(e, option, label)}
      >
        {label}
      </li>
    );
  });

  return (
    <div>
      <Input
        large={large}
        disableAutoComplete
        name={name}
        title={title}
        requiredSign={requiredSign}
        value={query}
        placeholder={placeholder}
        onChange={executeSearch}
        onKeyPress={checkKeyPress}
        onKeyDown={checkKeyDown}
        tabIndex={tabIndex}
        type="search"
        errorText={errorMessage || errorText}
        prepend={(
          <span className="input-group-text">
            <FaSearch />
          </span>
        )}
        required={required === true}
        onBlur={onBlur}
      />
      {showing === true && (
        <div
          ref={resultRef}
          style={{
            position: 'relative',
            marginTop: '-1rem',
            marginLeft: '2.5rem',
          }}
          className="d-flex"
        >
          <div style={{ position: 'absolute', zIndex: 1000 }}>
            {loaded === true ? (
              <div>
                <ul className="list-group">
                  {options.length > 0 && (
                    <li className="list-group-item">
                      <em>No Results.</em>
                    </li>
                  )}
                  {markup}
                </ul>
              </div>
            ) : (
              <div style={{ margin: '5px' }}>
                <Spinner bootstrapCssColorClass="warning" />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
/* eslint-disable */
ApiTypeAhead.propTypes = {
  defaultId: PropTypes.any,
  query: PropTypes.string,
  large: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  displayKey: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  tabIndex: PropTypes.number,
  required: PropTypes.bool,
  errorText: PropTypes.string,
  onBlur: PropTypes.func,
  requiredSign: PropTypes.string,
  selected: PropTypes.string,
};

export default ApiTypeAhead;
