import React, { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import AWSHealthEvents from '../Components/Vendor/HealthEvents/AWSHealthEvents';
import HeaderWithInfo from '../Components/Common/HeaderWithInfo';
import { Context } from '../Context/MenuContext';
import { Context as PageContext } from '../Context/PageContext';
import { saveUserPageVisitHistory } from '../store/actions/peopleAction';

const HealthEvents = (props) => {
  // Get selectedMenu data from Menu Context
  const [, setSelectedMenu, , setSelectedSubMenu] = useContext(Context);
  const { user } = props;
  const [pageContextState, setPageContextState] = useContext(PageContext);
  const { awsHealthEventPage } = pageContextState;
  const dispatch = useDispatch();

  useEffect(() => {
    // Change menu css with peope page is loaded.
    setSelectedMenu('vendor');
    setSelectedSubMenu('awsHealthEvent');
  }, []);

  // Get user login details
  useEffect(() => {
    if (awsHealthEventPage === '') {
      setPageContextState({
        ...pageContextState,
        awsHealthEventPage: 'awsHealthEvent',
      });
      const apiUrl = `/api/Auth/SaveUserPageVisit/${user.employeeId}/awsHealthEvent`;
      dispatch(saveUserPageVisitHistory(apiUrl));
    }
  }, [awsHealthEventPage]);

  return (
    <div className="row mb-2">
      <div className="col-12">
        <div className="card card-custom mb-4">
          <div className="card-header align-items-center">
            <div className="card-title">
              <HeaderWithInfo headerName="AWS Health Events" />
            </div>
          </div>
          <div className="card-body">
            <AWSHealthEvents />
          </div>
        </div>
      </div>
    </div>
  );
};
export default HealthEvents;
