import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { decode } from 'html-entities';
import Alert from '../../Common/Alert';

import './cloudGuru.css';

const DisplayCloudGuru = (props) => {
  const [sortDesc, setSortDesc] = useState([]);
  /* eslint-disable */
  const sortFunc = (a, b) => {
    let i = 1;
    if (sortDesc)
        i = -1;
    if (a > b)
        return i;
    if (a < b)
        return -i;
    return 0;
  };
  /* eslint-enable */

  const { laActiveMembers } = props;
  const [data, setData] = useState([]);
  const descArrowCode = '&#8595;';
  const ascArrowCode = '&#8593;';
  const [nameSortArrow, setNameSortArrow] = useState([]);
  const [emailSortArrow, setEmailSortArrow] = useState([]);
  const [trainingSortArrow, setTrainingSortArrow] = useState([]);
  const [loginSortArrow, setLoginSortArrow] = useState('&#8593;');
  const assignTrainingToValue = (training) => {
    training = training.toLowerCase();
    if (training.includes('flying ace')) {
      return 0;
    } if (training.includes('copilots')) {
      return 2;
    } if (training.includes('pilots')) {
      return 1;
    }
    return 3;
  };

  const toggle = (s) => {
    const orig = data;
    let sorted = [];
    let sortDescBool = sortDesc;
    let sortArr = '';
    if (sortDescBool === undefined) {
      sortDescBool = true;
      sortArr = descArrowCode;
    } else if (sortDescBool) {
      sortDescBool = false;
      sortArr = ascArrowCode;
    } else {
      sortDescBool = true;
      sortArr = descArrowCode;
    }

    setNameSortArrow('');
    setEmailSortArrow('');
    setTrainingSortArrow('');
    setLoginSortArrow('');

    /* eslint-disable */
    switch (s)
    {
        case 'Name':
            sorted = orig.sort((a, b) => sortFunc(a.lastName.toUpperCase(), b.lastName.toUpperCase()))
            setNameSortArrow(sortArr);
            break

        case 'Email':
            sorted = orig.sort((a, b) => sortFunc(a.emailId, b.emailId))
            setEmailSortArrow(sortArr);
            break

        case 'Training':
            sorted = orig.sort((a, b) => sortFunc(assignTrainingToValue(a.group), assignTrainingToValue(b.group)))
            setTrainingSortArrow(sortArr);
            break

        case 'LastLogin':
            sorted = orig.sort((a, b) => sortFunc(a.lastLogin, b.lastLogin))
            setLoginSortArrow(sortArr);
            break
    }
    setSortDesc(sortDescBool);
    setData(sorted);
  };/* eslint-enable */

  const renderStars = (number) => {
    const lis = [];
    for (let i = 0; i < number; i++) {
      lis.push(<FontAwesomeIcon key={i} icon={faStar} />);
    }
    return lis;
  };

  const renderTrainingWithStars = (training, star) => (
    <span title="Training Level">
      <span>
        {training}
      </span>
      <span className="ml-1 text-secondary">
        {renderStars(star)}
        <span className="text-muted">{renderStars(4 - star)}</span>
      </span>
    </span>
  );

  const getGraphic = (training) => {
    let levelArray = [];
    training = training.toLowerCase();
    let name = '';
    let code = '';
    if (training.includes('flying ace')) {
      code = 'flyingace';
      name = 'Flying Ace';
    } else if (training.includes('pilots')) {
      levelArray = training.toLowerCase().split(',').map((item) => item.split(' '));
      if (levelArray.map((item) => item.includes('pilots')).includes(true)) {
        name = 'Pilots';
        code = 'pilot';
      } else if (levelArray.map((item) => item.includes('copilots')).includes(true)) {
        name = 'CoPilots';
        code = 'copilot';
      }
    } else if (training.includes('flight crew')) {
      name = 'Flight Crew';
      code = 'flightcrew';
    } else if (training.includes('everyone')) {
      name = 'Not Set';
      code = 'notset';
    }
    if (!training) return (<em className="text-warning">Not Set</em>);

    switch (code) {
      case 'flightcrew':
        return renderTrainingWithStars(name, 1);
      case 'copilot':
        return renderTrainingWithStars(name, 2);
      case 'pilot':
        return renderTrainingWithStars(name, 3);
      case 'flyingace':
        return renderTrainingWithStars(name, 4);
      default:
        return (<em className="text-warning">Not Set</em>);
    }
  };

  useEffect(() => {
    setData(laActiveMembers.sort((a, b) => sortFunc(b.lastLogin, a.lastLogin)));
  }, [laActiveMembers]);

  return (
    <>
      <div className="custom-table">
        {data && data.length > 0 ? (
          <div className="respnsive-custom-table cloud-guru-users">
            <div className="div-table" width="100%">
              <div className="div-thead">
                <div className="div-row">
                  <div className="div-column-th" onClick={() => toggle('Name')}>
                    Name
                    {decode(nameSortArrow)}
                  </div>
                  <div className="div-column-th" onClick={() => toggle('Email')}>
                    Email
                    {decode(emailSortArrow)}
                  </div>
                  <div className="div-column-th" onClick={() => toggle('Training')}>
                    Training
                    {decode(trainingSortArrow)}
                  </div>
                  <div className="div-column-th" onClick={() => toggle('LastLogin')}>
                    Last Login
                    {decode(loginSortArrow)}
                  </div>
                </div>
              </div>
              <div className="div-tbody">
                {data.map((d, i) => (/* eslint-disable react/no-array-index-key */
                  <div className="div-row" key={i}>
                    <div className="div-column">{`${d.firstName} ${d.lastName}`}</div>
                    <div className="div-column">{d.emailId}</div>
                    <div className="div-column">{getGraphic(d.group)}</div>
                    <div className="div-column">{d.lastLogin}</div>
                  </div>
                )/* eslint-enable */)}
              </div>
            </div>
          </div>
        ) : (
          <Alert message="No data found." alertClass="info" />
        )}
      </div>
    </>
  );
};

export default DisplayCloudGuru;
