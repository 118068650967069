import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faInfoCircle,
  faCheckCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

const Alert = (props) => {
  const { alertClass, message, onClose } = props;

  function displayIcon() {
    switch (alertClass) {
      case 'light-danger':
        return (
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            className="font-size-h1"
            style={{ color: '#721c24' }}
          />
        );
      case 'info':
        return (
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="font-size-h1"
            style={{ color: '#004085' }}
          />
        );
      case 'success':
        return (
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="font-size-h1"
            style={{ color: '#0c5460' }}
          />
        );
      default:
        return null;
    }
  }
  return (
    <div
      className={`alert alert-custom alert-${alertClass} fade show `}
      role="alert"
    >
      <div className="alert-icon">{displayIcon()}</div>
      <div className="alert-text">{message}</div>
      {alertClass !== 'info' && (
        <div className="alert-close">
          <button
            type="button"
            className="close"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => onClose('')}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      )}
    </div>
  );
};

export default Alert;
