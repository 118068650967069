import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAmountUp } from '@fortawesome/free-solid-svg-icons';

const HighestAndLowestCostByAccount = (props) => {
  const data = props.filterServiceCostByAcc;
  function formatMoney(number) {
    return Number(number).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  }
  return (
    <>
      { data.length > 0 && (
      <div className="row mb-2">
        <div className="col-12">
          <div className="card card-custom mb-4">
            <div className="card-header align-items-center bg-primary border-0">
              <div className="card-title align-items-center justify-content-between w-100">
                <h3 className="card-label col p-0">
                  <div className="d-flex align-items-center justify-content-start">
                    <span className="text-white">
                      {`${props.showHighCostByAcc ? 'Highest' : 'Lowest'} consuming service By account`}
                    </span>
                  </div>
                </h3>
                <div className={`toggle-sort-icon ${props.showHighCostByAcc ? '' : 'rotate'}`} title="Highest consuming service By account">
                  <input type="checkbox" href="#" onClick={props.onClickHighConsumeCostByAcc} className="toggle-icon" name="toggle-icon" />
                  <label htmlFor="toggle-icon" className="sort-icon-label">
                    <FontAwesomeIcon
                      icon={faSortAmountUp}
                    />
                  </label>
                </div>
              </div>
            </div>
            <div className="card-body p-0">
              <div className="analytics-service-cards bg-primary" />
              <div className="card-spacer">
                <div className="row justify-content-around">
                  { data.length > 0 && data.map((item) => (
                    <div className="col-xl-3 col-md-4 col-sm-4 col-12 mb-4">
                      <div className="card border-left-primary shadow h-100">
                        <div className="card-body p-6">
                          <div className="row no-gutters align-items-center justify-content-between">
                            <div className="col-8">
                              <div className="text-xs font-weight-normal text-primary text-uppercase my-2">
                                {item.dimensions[0]}
                              </div>
                              <div className="h5 mb-0 font-weight-normal text-gray-800 text-xs">{`${formatMoney(item.metrics[0].sum)}`}</div>
                            </div>
                            <div className="col text-right">
                              <span className="svg-icon svg-icon-xl svg-icon-primary">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24" />
                                    <rect fill="#000000" opacity="0.3" x="13" y="4" width="3" height="16" rx="1.5" />
                                    <rect fill="#000000" x="8" y="9" width="3" height="11" rx="1.5" />
                                    <rect fill="#000000" x="18" y="11" width="3" height="9" rx="1.5" />
                                    <rect fill="#000000" x="3" y="13" width="3" height="7" rx="1.5" />
                                  </g>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      ) }
    </>
  );
};
export default HighestAndLowestCostByAccount;
