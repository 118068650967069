import React, { useEffect, useState } from 'react';

import Chart from 'react-apexcharts';
import noData from '../../Images/no-data.png';

const DonutChart = (props) => {
  const { pieChartLableData, pieChartCostData } = props;
  const initialChartOptions = {
    labels: pieChartLableData,
    tooltip: {
      enabled: true,
      y: {
        formatter(val) {
          if (props.type === 'number') {
            return `${val} Member`;
          } if (props.type === 'count') {
            return `${val}`;
          }
          return `$ ${val
            .toFixed(2)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
        },
      },
    },
    plotOptions: {
      chart: {
        id: 'DonutChart',
        width: '100%',
      },
    },
    legend: {
      show: true,
      position: 'right',
      fontSize: '8px',
      horizontalAlign: 'left',
      width: 140,
    },
    responsive: [
      {
        breakpoint: 991,
        options: {
          legend: {
            show: true,
            width: 200,
          },
        },
      },
      {
        breakpoint: 767,
        options: {
          legend: {
            show: false,
          },
        },
      },
    ],
  };
  const [chartOptions, setChartOptions] = useState(initialChartOptions);
  const [series, setSeries] = useState(pieChartCostData);
  const [dataFound, setDataFound] = useState(pieChartCostData.length > 0);

  useEffect(() => {
    setSeries(pieChartCostData);
    setDataFound(pieChartCostData.length > 0);
  }, [pieChartCostData]);

  useEffect(() => {
    setChartOptions({ ...chartOptions, label: pieChartLableData });
  }, [pieChartLableData]);

  return (
    <>
      {dataFound ? (
        <div className="donut-chart">
          <Chart
            options={chartOptions}
            series={series}
            type={props.chartType}
          />
        </div>
      ) : (
        <div>
          <img src={noData} className="img-fluid d-block m-auto w-50" alt="" />
          <p className="text-center mt-10 font-size-lg font-weight-normal text-muted">
            No Data available.
          </p>
        </div>
      )}
    </>
  );
};

export default DonutChart;
