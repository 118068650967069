import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from '@reach/router';
import { getAccountNetwork } from '../../../store/actions/accountAction';
import Alert from '../../Common/Alert';
import Loader from '../../Loader';

const AccountNetworkTab = (props) => {
  const { accountId } = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrMsg, setShowErrMsg] = useState(false);

  const dispatch = useDispatch();
  const accountSpecificData = useSelector((state) => state.accountProfile);
  const { networkLoading, errNetwork, accountNetwork } = accountSpecificData;

  useEffect(() => {
    dispatch(getAccountNetwork(`/api/account/${accountId}/virtualnetworks`));
  }, [dispatch]);

  useEffect(() => {
    if (errNetwork !== '') {
      setErrorMessage(errNetwork);
      setShowErrMsg(true);
    }
  }, [errNetwork]);

  const vpcMarkup = (list) => list.map((vpc) => (
    <tr key={vpc.id}>
      <td>
        <Link to={`/virtualnetworks/${vpc.id}`}>{vpc.id}</Link>
      </td>
      <td>{vpc.name}</td>
      <td>{vpc.region}</td>
      <td>{vpc.cidr}</td>
      <td>
        <FontAwesomeIcon icon={vpc.default ? faThumbsUp : faThumbsDown} />
      </td>
    </tr>
  ));

  return (
    <>
      {networkLoading ? (
        <Loader />
      ) : showErrMsg ? (
        <div className="col-12">
          <Alert
            message={errorMessage}
            alertClass="light-danger"
            onClose={() => setShowErrMsg(false)}
          />
        </div>
      ) : (
        !errorMessage && (
          <>
            {accountNetwork.length > 0 ? (
              <div className="table-responsive">
                <table className="table table-custom table-hover table-no-cursor">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Name</th>
                      <th>Region</th>
                      <th>CIDR</th>
                      <th>Default</th>
                    </tr>
                  </thead>
                  <tbody>{vpcMarkup(accountNetwork)}</tbody>
                </table>
              </div>
            ) : (
              <Alert message="No Virtual Networks found." alertClass="info" />
            )}
          </>
        )
      )}
    </>
  );
};

export default AccountNetworkTab;
