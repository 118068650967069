import React, { useEffect, useContext, useState } from 'react';
import { Link } from '@reach/router';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTimes,
  faSquare,
  faBullhorn,
} from '@fortawesome/free-solid-svg-icons';
import Alert from '../Common/Alert';

// Context
import { Context } from '../../Context/AnnouncementContext';

const Announcement = (props) => {
  const {
    user, sideBarRef, announcementIdsRef, allAnnouncementsRef,
  } = props;
  const [errorMessage, setErrorMessage] = useState('');

  // Announcement Model popup open from Request Context
  const [anouncementPopup, setAnouncementPopup] = useContext(Context);
  // eslint-disable-next-line no-unused-vars
  const { showWhatsNew, newAnnouncementCount, allAnnouncements } = anouncementPopup;

  const filterUnSeenAnnouncements = (announcements, userAnnouncementsMapping) => {
    if (announcements.length > 0) {
      let filterAnnouncements = [];
      const announcementData = announcements;
      for (let i = 0; i < announcements.length; i++) {
        announcementData[i].isAlertSeen = false;
      }
      if (userAnnouncementsMapping.length > 0) {
        let count = 0;
        userAnnouncementsMapping.forEach((item) => {
          if (
            announcementData.filter((a) => a.id === item.announcementId)
              .length > 0
          ) {
            count++;
            announcementData[
              announcementData.findIndex((a) => a.id === item.announcementId)
            ].isAlertSeen = true;
          }
        });
        filterAnnouncements = announcementData
          .filter((item) => !item.isAlertSeen)
          .map((a) => a.id);
        setAnouncementPopup({
          ...anouncementPopup,
          newAnnouncementCount: announcementData.length - count,
          allAnnouncements: announcementData.sort((a) => {
            if (!a.isAlertSeen) return -1;
            if (a.isAlertSeen) return 1;
            return 0;
          }),
        });
      } else {
        filterAnnouncements = announcementData
          .filter((item) => !item.isAlertSeen)
          .map((a) => a.id);
        setAnouncementPopup({
          ...anouncementPopup,
          newAnnouncementCount: announcementData.length,
          allAnnouncements: announcementData.sort((a) => {
            if (!a.isAlertSeen) return -1;
            if (a.isAlertSeen) return 1;
            return 0;
          }),
        });
      }
      announcementIdsRef.current = filterAnnouncements;
      allAnnouncementsRef.current = announcementData.sort((a) => {
        if (!a.isAlertSeen) return -1;
        if (a.isAlertSeen) return 1;
        return 0;
      });
    }
  };
  function getAnnouncements() {
    axios
      .get('/api/announcement/GetAnnouncements')
      .then((response) => {
        if (response.data) {
          const annoucementsData = response.data;
          axios
            .get(`/api/announcement/GetUserAnnouncementMapping/${user.employeeId}`)
            .then((response) => {
              if (response.data) {
                filterUnSeenAnnouncements(annoucementsData, response.data);
              }
            })
            .catch((e) => {
              if (e.name !== 'AbortError') {
                setErrorMessage(e.message);
              }
            });
        }
      })
      .catch((e) => {
        if (e.name !== 'AbortError') {
          setErrorMessage(e.message);
        }
      });
  }
  useEffect(() => {
    getAnnouncements();
  }, []);

  function renderAnnoucementList() {
    try {
      return allAnnouncements && allAnnouncements.length > 0
        && allAnnouncements.filter((an) => an.status === 'active').map((ann) => (ann.type !== 'external' ? (
          <Link
            to={ann.url}
            key={ann.id}
            className={`navi-link ${!ann.isAlertSeen ? 'active' : ''}`}
            onClick={() => setAnouncementPopup({
              ...anouncementPopup,
              showWhatsNew: false,
            })}
          >
            <div className="d-flex align-items-center justify-content-start navi-item">
              <div className="navi-icon ml-3">
                <div className="navi-icon-label">
                  <FontAwesomeIcon
                    icon={faBullhorn}
                    style={{ color: '#3699ff' }}
                  />
                </div>
              </div>
              <div className="navi-text">
                <h6 className="navi-text-head">{ann.header}</h6>
                <p className="navi-text-para">{ann.paragraph}</p>
              </div>
              {!ann.isAlertSeen && (
              <div className="unread-notification">
                <span />
              </div>
              )}
            </div>
          </Link>
        ) : (
          <a
            key={ann.id}
            className={`navi-link ${!ann.isAlertSeen ? 'active' : ''}`}
            href={ann.url}
            target="_blank"
            onClick={() => setAnouncementPopup({
              ...anouncementPopup,
              showWhatsNew: false,
            })}
            rel="noreferrer"
          >
            <div className="d-flex align-items-center justify-content-start navi-item">
              <div className="navi-icon ml-3">
                <div className="navi-icon-label">
                  <FontAwesomeIcon
                    icon={faBullhorn}
                    style={{ color: '#3699ff' }}
                  />
                </div>
              </div>
              <div className="navi-text">
                <h6 className="navi-text-head">{ann.header}</h6>
                <p className="navi-text-para">{ann.paragraph}</p>
              </div>
              {!ann.isAlertSeen && (
              <div className="unread-notification">
                <span />
              </div>
              )}
            </div>
          </a>
        )));
    } catch (e) {
      return (
        <Alert
          message={e.message}
          alertClass="light-danger"
          onClose={() => setErrorMessage('')}
        />
      );
    }
  }

  return (
    <div className="sidebar-wrapper-pannel" id="annDiv" ref={sideBarRef}>
      <h4 className="sidebar-brand">
        <span className="brand-text-info">What&apos;s New?</span>
        <div
          className="close-sidebar"
          onClick={() => setAnouncementPopup({ ...anouncementPopup, showWhatsNew: false })}
        >
          <span className="fa-layers fa-fw fa-1x" style={{ cursor: 'pointer' }}>
            <FontAwesomeIcon icon={faSquare} style={{ color: '#F3F6F9' }} />
            <FontAwesomeIcon
              icon={faTimes}
              inverse
              transform="shrink-5"
              style={{ color: '#7E8299' }}
            />
          </span>
        </div>
      </h4>
      <hr className="sidebar-divider my-0" />
      <ul className="navi-panel">
        {renderAnnoucementList()}
        {errorMessage && errorMessage !== ''
           && (
           <Alert
             message={errorMessage}
             alertClass="light-danger"
             onClose={() => setErrorMessage('')}
           />
           )}
      </ul>
    </div>
  );
};

export default Announcement;
