import React from 'react';
import { Link } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

const renderStarts = (number) => {
  const lis = [];
  for (let i = 0; i < number; i++) {
    lis.push(<FontAwesomeIcon key={i} icon={faStar} />);
  }
  return lis;
};

const PeopleResult = ({ people, onScroll }) => (
  <>
    {people.length > 0 && (
    <div className="card card-custom gutter-b">
      <div className="card-body">
        <div className="row">
          <div className="col-md-12 col-12">
            <div className="FilteredResult" onScroll={onScroll}>
              {people.length > 0 && people.map((data) => (
                <Link
                  key={data.employeeId}
                  to={`/userProfile/${data.employeeId}`}
                  className="FilteredResultList"
                >
                  <div className="d-flex">
                    <div className="col-md-3 col-sm-4 col-8 pl-0">
                      <div className="d-flex flex-column align-items-start">
                        <span className="text-dark text-hover-primary font-size-lg font-weight-500">
                          <span className="font-theme-color mr-1 font-size-lg">
                            {data.name}
                          </span>
                        </span>
                        <span className="text-muted text-break font-weight-normal font-size-md">
                          {data.email}
                        </span>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-2 col-4">
                      <div className="d-flex flex-column align-items-center text-xs-right">
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="text-aws text-center text-hover-primary font-size-lg mr-2- mb-1">
                            {data.awsMaxTrainingLevelAchieved}
                            (AWS)
                          </span>
                        </div>
                        <span className="d-flex align-items-center text-secondary font-size-xs">
                          {renderStarts(data.awsMaxTrainingLevelRankAchieved)}
                          <span className="text-muted">
                            {renderStarts(
                              4 - data.awsMaxTrainingLevelRankAchieved,
                            )}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-2 col-4">
                      <div className="d-flex flex-column align-items-center text-xs-right">
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="text-azure text-center text-hover-primary font-size-lg mr-2- mb-1">
                            {data.azMaxTrainingLevelAchieved}
                            (Azure)
                          </span>
                        </div>
                        <span className="d-flex align-items-center text-secondary font-size-xs">
                          {renderStarts(data.azMaxTrainingLevelRankAchieved)}
                          <span className="text-muted">
                            {renderStarts(
                              4 - data.azMaxTrainingLevelRankAchieved,
                            )}
                          </span>
                        </span>
                      </div>
                    </div>
                    <div className="col-md-5 col-sm-4 col-8">
                      <div className="d-flex flex-column align-items-end text-xs-right">
                        <span className="text-dark text-right text-break font-size-lg font-weight-500">
                          <span>{data.supervisorName}</span>
                        </span>
                        <div className="font-weight-normal text-right">
                          <span className="text-muted">
                            {data.directorName}
                            {' '}
                            -
                            {data.directorTitle}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
    )}
  </>
);

PeopleResult.propTypes = {
  people: PropTypes.instanceOf(Array).isRequired,
  onScroll: PropTypes.func.isRequired,
};

export default PeopleResult;
