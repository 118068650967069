import {
  GET_ACC_INFO,
  GET_ACC_COST,
  ERROR_ACC_COST,
  ERROR_ACC_INFO,
  GET_ACC_WITH_ROLE,
  ERROR_ACC_WITH_ROLE,
  GET_ACC_COMPLIANCE,
  ERROR_ACC_COMPLIANCE,
  GET_ACC_NETWORK,
  ERROR_ACC_NETWORK,
  GET_ACC_COMPUTE_INST,
  ERROR_ACC_COMPUTE_INST,
  GET_ACC_COMPUTE_SRVR,
  ERROR_ACC_COMPUTE_SRVR,
  GET_ACC_COMPUTE_CNTR,
  ERROR_ACC_COMPUTE_CNTR,
  GET_ACC_COMPUTE_STRG,
  ERROR_ACC_COMPUTE_STRG,
  GET_ACC_COMPUTE_LBCR,
  ERROR_ACC_COMPUTE_LBCR,
  GET_ACC_COMPUTE_DB,
  ERROR_ACC_COMPUTE_DB,
} from '../types';

const initialState = {
  errInfo: '',
  errCost: '',
  errCompliance: '',
  errWithRole: '',
  errNetwork: '',
  errCompInstance: '',
  errCompServerless: '',
  errCompContainer: '',
  errCompStorage: '',
  errCompLoadBalancer: '',
  errCompDb: '',
  accountInfo: {},
  accountCost: {},
  accountCompliance: [],
  accountWithRole: [],
  accountNetwork: [],
  compInstance: [],
  compServerless: [],
  compRelationalDb: [],
  compContainer: [],
  compStorage: [],
  compLoadBalancer: [],
  loading: true,
  roleLoading: true,
  complianceLoading: true,
  networkLoading: true,
  compInstanceLoading: true,
  compServerlessLoading: true,
  compContainerLoading: true,
  compStorageLoading: true,
  compLoadBalancerLoading: true,
  compDbLoading: true,
};

/* eslint-disable func-names */
export default function (state = initialState, action) {
  /* eslint-enable */
  switch (action.type) {
    case GET_ACC_INFO:
      return {
        ...state,
        accountInfo: action.payload,
        loading: false,
      };
    case ERROR_ACC_INFO:
      return {
        ...state,
        loading: false,
        errInfo: action.payload.message,
      };
    case GET_ACC_COST:
      return {
        ...state,
        accountCost: action.payload,
        loading: false,
      };
    case ERROR_ACC_COST:
      return {
        ...state,
        loading: false,
        errCost: action.payload.message,
      };
    case GET_ACC_WITH_ROLE:
      return {
        ...state,
        accountWithRole: action.payload,
        roleLoading: false,
      };
    case ERROR_ACC_WITH_ROLE:
      return {
        ...state,
        roleLoading: false,
        errWithRole: action.payload.message,
      };
    case GET_ACC_COMPLIANCE:
      return {
        ...state,
        accountCompliance: action.payload,
        complianceLoading: false,
      };
    case ERROR_ACC_COMPLIANCE:
      return {
        ...state,
        complianceLoading: false,
        errCompliance: action.payload.message,
      };
    case GET_ACC_NETWORK:
      return {
        ...state,
        accountNetwork: action.payload,
        networkLoading: false,
      };
    case ERROR_ACC_NETWORK:
      return {
        ...state,
        networkLoading: false,
        errNetwork: action.payload.message,
      };
    case GET_ACC_COMPUTE_INST:
      return {
        ...state,
        compInstance: action.payload,
        compInstanceLoading: false,
      };
    case ERROR_ACC_COMPUTE_INST:
      return {
        ...state,
        compInstanceLoading: false,
        errCompInstance: action.payload.message,
      };
    case GET_ACC_COMPUTE_SRVR:
      return {
        ...state,
        compServerlessLoading: false,
        compServerless: action.payload,
      };
    case ERROR_ACC_COMPUTE_SRVR:
      return {
        ...state,
        compServerlessLoading: false,
        errCompServerless: action.payload.message,
      };
    case GET_ACC_COMPUTE_CNTR:
      return {
        ...state,
        compContainerLoading: false,
        compContainer: action.payload,
      };
    case ERROR_ACC_COMPUTE_CNTR:
      return {
        ...state,
        compContainerLoading: false,
        errCompContainer: action.payload.message,
      };
    case GET_ACC_COMPUTE_STRG:
      return {
        ...state,
        compStorageLoading: false,
        compStorage: action.payload,
      };
    case ERROR_ACC_COMPUTE_STRG:
      return {
        ...state,
        compStorageLoading: false,
        errCompStorage: action.payload.message,
      };
    case GET_ACC_COMPUTE_LBCR:
      return {
        ...state,
        compLoadBalancerLoading: false,
        compLoadBalancer: action.payload,
      };
    case ERROR_ACC_COMPUTE_LBCR:
      return {
        ...state,
        compLoadBalancerLoading: false,
        errCompLoadBalancer: action.payload.message,
      };
    case GET_ACC_COMPUTE_DB:
      return {
        ...state,
        compDbLoading: false,
        compRelationalDb: action.payload,
      };
    case ERROR_ACC_COMPUTE_DB:
      return {
        ...state,
        compDbLoading: false,
        errCompDb: action.payload.message,
      };
    default: return state;
  }
}
