import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStar,
  faEdit,
  faSave,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import Input from '../../Common/Input';
import Select from '../../Common/Select';
import Loader from '../../Loader';
import Alert from '../../Common/Alert';
import './Roles.css';

const RoleView = (props) => {
  const { levels } = props;
  const [readMode, setReadMode] = useState(true);
  const [role, setRole] = useState(props.role);
  const [original, setOriginal] = useState(props.role);
  const [errorMessage, setErrMessage] = useState('');
  const [loaded, setLoaded] = useState(true);

  useEffect(() => {
    setRole(props.role);
    setOriginal(props.role);
  }, []);

  const renderStars = (number) => {
    const lis = [];
    for (let i = 0; i < number; i++) {
      lis.push(<FontAwesomeIcon key={i} icon={faStar} />);
    }
    return lis;
  };

  const renderTrainingWithStars = (training, star) => (
    <span title="Training Level">
      <span>
        {training}
      </span>
      <span className="ml-1 text-secondary">
        {renderStars(star)}
        <span className="text-muted">{renderStars(4 - star)}</span>
      </span>
    </span>
  );

  const getGraphic = (code) => {
    if (!code) return (<em className="text-warning">Not Set</em>);

    const name = levels[code] || code;
    switch (code) {
      case 'flightcrew':
      case 'az-flightcrew':
        return renderTrainingWithStars(name, 1);
      case 'copilot':
      case 'az-copilot':
        return renderTrainingWithStars(name, 2);
      case 'pilot':
      case 'az-pilot':
        return renderTrainingWithStars(name, 3);
      case 'flyingace':
      case 'az-flyingace':
        return renderTrainingWithStars(name, 4);
      default:
        return (<strong>{name}</strong>);
    }
  };

  const setWrite = () => {
    setReadMode(false);
  };

  const saveEdits = () => {
    const data = {
      minimumTrainingLevel: role.minimumTrainingLevel !== '' ? role.minimumTrainingLevel : null,
      name: role.name,
    };
    setLoaded(false);
    axios({
      method: 'PUT',
      url: `/api/role/${role.code}`,
      data: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(() => {
        setReadMode(true);
        setOriginal(role);
        setLoaded(true);
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          setErrMessage(err.message);
          setLoaded(true);
        }
      });
  };

  const cancelWrite = () => {
    setReadMode(true);
    setRole(original);
  };

  const handleChange = (e) => {
    const { target } = e;
    setRole({
      ...role,
      [target.name]: target.value,
    });
  };

  return (
    <>
      {loaded
        ? (
          <>
            <div className="div-row" key={role.code}>
              <div className="div-column">{role.code}</div>
              <div className={`div-column ${readMode ? '' : 'edit-column'}`}>
                {readMode
                  ? (role.name ? role.name : '-')
                  : (
                    <Input name="name" value={role.name} title="" placeholder="Name" onChange={handleChange} />)}
              </div>
              <div className={`div-column ${readMode ? '' : 'edit-column'}`}>
                {readMode ? getGraphic(role.minimumTrainingLevel)
                  : (
                    <Select name="minimumTrainingLevel" value={role.minimumTrainingLevel} placeholder="Choose One..." options={levels} onChange={handleChange} />)}
              </div>
              <div className="div-column">{role.numberOfAccounts}</div>
              <div className="div-column">{role.numberOfEmployees}</div>
              <div className="div-column">
                {readMode
                  ? (
                    <button type="button" className="btn btn-link text-dark" onClick={setWrite}>
                      <FontAwesomeIcon icon={faEdit} size="sm" />
                    </button>
                  ) : (
                    <div className="btn-toolbar">
                      <button type="button" className="btn btn-link text-dark" onClick={saveEdits}>
                        <FontAwesomeIcon icon={faSave} size="sm" />
                      </button>
                      <button type="button" className="btn btn-link text-dark" onClick={cancelWrite}>
                        <FontAwesomeIcon icon={faTimes} size="sm" />
                      </button>
                    </div>
                  )}
              </div>
            </div>
            {errorMessage
              && (
                <div className="div-row alert-div-row">
                  <div className="div-column alert-div-column">
                    <Alert alertClass="light-danger" message={errorMessage} onClose={(val) => setErrMessage(val)} />
                  </div>
                </div>
              )}
          </>
        ) : <Loader />}
    </>
  );
};

export default RoleView;
