import React from 'react';

const Header = () => (
  <div className="card-header align-items-center">
    <div className="card-title align-items-start flex-column">
      <h3 className="card-label">Ascendon Cloudability Costs</h3>
    </div>
  </div>
);

export default Header;
