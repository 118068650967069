import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const BigSearchType = (props) => {
  const [searchTypes] = useState(props.searchTypes);
  const [selectedIndex, setSelectedIndex] = useState(props.selectedIndex);
  const [toggleVisible, setToggleVisible] = useState(false);
  const drpDwnMenuRef = useRef(null);

  // Close search result list when clicked outside the search result list
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        drpDwnMenuRef.current
        && !drpDwnMenuRef.current.contains(event.target)
      ) {
        setToggleVisible(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [drpDwnMenuRef]);

  function click(e, index) {
    const { onChange } = props;
    const isDifferent = index !== selectedIndex;
    setSelectedIndex(index);
    setToggleVisible(false);
    if (isDifferent === true) {
      onChange({
        event: e,
        index,
        from: 'BigSearchType',
      });
    }
  }

  function toggle(e) {
    const { type } = e;
    const toggVisiValue = type === 'mouseleave' ? false : !toggleVisible;
    setToggleVisible(toggVisiValue);
  }

  const selectedValue = searchTypes[selectedIndex];
  const { display } = selectedValue;
  return (
    <button
      ref={drpDwnMenuRef}
      type="button"
      className="btn btn-secondary dropdown-toggle big-search-type"
      onClick={(e) => toggle(e)}
    >
      <span>{display}</span>
      <div
        className="dropdown-menu"
        role="menu"
        // eslint-disable-next-line react/no-unknown-property
        x-placement="bottom-start"
        tabIndex="-1"
        style={{
          position: 'absolute',
          transform: 'translate3d(0px, 48px, 0px)',
          top: '0px',
          left: '0px',
          willChange: 'transform',
          display: toggleVisible === true ? 'block' : 'none',
        }}
        onMouseLeave={(e) => toggle(e)}
      >
        {searchTypes
          && searchTypes.map((t, i) => (
            <span
              key={t.key}
              className="dropdown-item"
              onClick={(e) => click(e, i)}
            >
              {t.display}
            </span>
          ))}
      </div>
    </button>
  );
};

BigSearchType.propTypes = {
  selectedIndex: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  searchTypes: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default BigSearchType;
