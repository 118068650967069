import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLevelDownAlt,
} from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import axios from 'axios';
import ResultRows from './ResultRows';

const EducationResults = (props) => {
  const {
    reports,
    reloadResults,
    changeSupervisor,
    isContentEditable,

  } = props;

  const onTrainingLevelChange = (e, value, employeeId) => {
    axios({
      method: 'patch',
      url: `api/people/${employeeId}`,
      data: JSON.stringify([
        { op: 'replace', path: '/trainingLevelGoalRank', value },
      ]),
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        reloadResults();
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          console.error(err);
        }
      });
  };

  return (
    <>
      <div className="card-body people-search education-aws-azure">
        <div className="row">
          <div className="col-12">
            <div className="timeline timeline-3">
              <div className="timeline-items">
                {reports.map((rep) => {
                  const achievedAwsClass = rep.awsMaxTrainingLevelRankAchieved
                                        >= rep.trainingLevelGoalRank
                                        && rep.awsMaxTrainingLevelRankAchieved > 0
                    ? 'success-font'
                    : '';
                  const currentAwsClass = rep.awsMaxTrainingLevelRankAchieved
                                        < rep.trainingLevelGoalRank
                                        && rep.currentTrainingLevelRank < rep.trainingLevelGoalRank
                    ? 'danger-font'
                    : '';

                  const achievedAzureClass = rep.azMaxTrainingLevelRankAchieved
                                        >= rep.trainingLevelGoalRank
                                        && rep.azMaxTrainingLevelRankAchieved > 0
                    ? 'success-font'
                    : '';

                  const currentAzureClass = rep.azMaxTrainingLevelRankAchieved
                                        < rep.trainingLevelGoalRank
                                        && rep.currentTrainingLevelRank < rep.trainingLevelGoalRank
                    ? 'danger-font'
                    : '';

                  return (
                    <div key={rep.employeeId} className="timeline-item">
                      {rep.hasReports ? (
                        <div
                          className="timeline-name"
                          onClick={() => changeSupervisor(rep.employeeId)}
                        >
                          {rep.firstName}
                                                    &nbsp;
                          <span className="text-nowrap">
                            {rep.lastName}
                            <FontAwesomeIcon icon={faLevelDownAlt} />
                          </span>
                        </div>
                      ) : (
                        <div className="timeline-name-black">
                          {rep.firstName}
                          {' '}
                          {rep.lastName}
                        </div>
                      )}
                      <Link
                        to={`/userProfile/${rep.employeeId}`}
                        className="timeline-media"
                      >
                        <span className="svg-icon svg-icon-xl svg-icon-primary">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="20px"
                            height="20px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <polygon points="0 0 24 0 24 24 0 24" />
                              <path
                                d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                                fill="#000000"
                                fillRule="nonzero"
                                opacity="0.3"
                              />
                              <path
                                d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                                fill="#000000"
                                fillRule="nonzero"
                              />
                            </g>
                          </svg>
                        </span>
                      </Link>
                      <div className="w-100">
                        <ResultRows
                          type="aws"
                          isSeatAssigned={rep.isSeatAssigned}
                          MaxTrainingLevelRankAchieved={rep.awsMaxTrainingLevelRankAchieved}
                          MaxTrainingLevelAchieved={rep.awsMaxTrainingLevelAchieved}
                          class={achievedAwsClass}
                          onTrainingLevelChange={onTrainingLevelChange}
                          currentClass={currentAwsClass}
                          isContentEditabl={isContentEditable}
                          suggestedTrainingLevel={rep.suggestedTrainingLevel}
                          suggestedTrainingLevelRank={rep.suggestedTrainingLevelRank}
                          lastLogin={rep.awsLastLogin}
                          CurrentTrainingLevelRank={rep.awsCurrentTrainingLevelRank}
                          CurrentTrainingLevel={rep.awsCurrentTrainingLevel}
                          CurrentTrainingLevelPercent={rep.awsCurrentTrainingLevelPercent}
                          trainingLevelGoal={rep.trainingLevelGoal}
                          employeeId={rep.employeeId}
                          trainingLevelGoalRank={rep.trainingLevelGoalRank}
                        />
                        <ResultRows
                          type="az"
                          isSeatAssigned={rep.isAzureSeatAssigned}
                          MaxTrainingLevelRankAchieved={rep.azMaxTrainingLevelRankAchieved}
                          MaxTrainingLevelAchieved={rep.azMaxTrainingLevelAchieved}
                          class={achievedAzureClass}
                          onTrainingLevelChange={onTrainingLevelChange}
                          currentClass={currentAzureClass}
                          isContentEditabl={isContentEditable}
                          suggestedTrainingLevel={rep.azureSuggestedTrainingLevel}
                          suggestedTrainingLevelRank={rep.azureSuggestedTrainingLevelRank}
                          lastLogin="N/A"
                          CurrentTrainingLevelRank={rep.azCurrentTrainingLevelRank}
                          CurrentTrainingLevel={rep.azCurrentTrainingLevel}
                          CurrentTrainingLevelPercent={rep.azCurrentTrainingLevelPercent}
                          trainingLevelGoal={rep.azureTrainingLevelGoal}
                          employeeId={rep.employeeId}
                          trainingLevelGoalRank={rep.azureTrainingLevelGoalRank}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
EducationResults.propTypes = {
  isContentEditable: PropTypes.bool.isRequired,
  changeSupervisor: PropTypes.func.isRequired,
  reports: PropTypes.arrayOf(
    PropTypes.shape({
      lastLogin: PropTypes.string,
      name: PropTypes.string.isRequired,
      employeeId: PropTypes.number.isRequired,
      isSeatAssigned: PropTypes.bool.isRequired,
      trainingLevelGoalRank: PropTypes.number,
      trainingLevelGoal: PropTypes.string,
      awsCurrentTrainingLevelRank: PropTypes.number,
      awsCurrentTrainingLevel: PropTypes.string,
      awsCurrentTrainingLevelPercent: PropTypes.number,
      azCurrentTrainingLevelRank: PropTypes.number,
      azCurrentTrainingLevel: PropTypes.string,
      azCurrentTrainingLevelPercent: PropTypes.number,
      awsMaxTrainingLevelAchieved: PropTypes.string,
      awsMaxTrainingLevelRankAchieved: PropTypes.number,
      azMaxTrainingLevelAchieved: PropTypes.string,
      azMaxTrainingLevelRankAchieved: PropTypes.number,
      suggestedTrainingLevel: PropTypes.string,
      suggestedTrainingLevelRank: PropTypes.number,
    }),
  ).isRequired,
};

export default EducationResults;
