import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DonutChart from '../../Common/DonutChart';
import Loader from '../../Loader';
import Alert from '../../Common/Alert';

const ManagerSeatAllocationPopup = (props) => {
  const { onClose } = props;
  const [loaded, setLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [managerSeatData, setManagerSeatData] = useState([]);

  useEffect(() => {
    setLoaded(false);
    axios.get('/api/people/GetManagerSeatAllocation')
      .then((response) => {
        setManagerSeatData(response.data.filter((d) => d.supervisorId));
        setLoaded(true);
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          setErrorMessage(err.message);
          setShowError(true);
          setLoaded(true);
        }
      });
  }, []);

  return (
    <div
      className="modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      style={{ display: 'block' }}
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header px-4">
            <h4 className="modal-title" id="exampleModalLabel">
              Seat Allocated Distribution across Manager&lsquo;s
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => onClose(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            {loaded
              ? (
                <>
                  {showError
                    ? (
                      <Alert
                        message={errorMessage}
                        alertClass="light-danger"
                        onClose={() => setShowError(false)}
                      />
                    ) : (
                      !errorMessage && (
                        <div className="cloudguru-section p-5">
                          <DonutChart
                            pieChartCostData={managerSeatData.map((x) => x.seatAllocatedCount)}
                            pieChartLableData={managerSeatData.map((x) => x.supervisorName)}
                            title="Seat Allocated Distribution across Managers"
                            type="number"
                            chartType="donut"
                          />
                        </div>
                      )
                    )}
                </>
              ) : <Loader />}
          </div>
          <div className="modal-footer px-4">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => onClose(false)}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ManagerSeatAllocationPopup;
