import axios from 'axios';
import {
  GET_USER_PROFILE,
  ERROR_USER_PROFILE,
  GET_USER_TRAINING,
  ERROR_USER_TRAINING,
  GET_ACCOUNT_WITH_ROLE,
  ERROR_ACCOUNT_WITH_ROLE,
  SAVE_USER_PAGE_VISIT_HISTORY,
  ERROR_USER_PAGE_VISIT_HISTORY,
} from '../types';

export const getUserProfile = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_USER_PROFILE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_USER_PROFILE,
      payload: error,
    });
  }
};
export const getUserTraining = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_USER_TRAINING,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_USER_TRAINING,
      payload: error,
    });
  }
};
export const getAccountWithRole = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_ACCOUNT_WITH_ROLE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_ACCOUNT_WITH_ROLE,
      payload: error,
    });
  }
};
export const saveUserPageVisitHistory = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.post(apiUrl);
    dispatch({
      type: SAVE_USER_PAGE_VISIT_HISTORY,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_USER_PAGE_VISIT_HISTORY,
      payload: error,
    });
  }
};
