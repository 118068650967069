import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from '@reach/router';
import Alert from '../../Common/Alert';
import { getAccountCompute } from '../../../store/actions/accountAction';
import Loader from '../../Loader';
import {
  ERROR_ACC_COMPUTE_LBCR,
  GET_ACC_COMPUTE_LBCR,
} from '../../../store/types';

const ComputeLoadBalancer = (props) => {
  const { accountId, onClick, isOpen } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [showErrMsg, setShowErrMsg] = useState(false);

  const dispatch = useDispatch();
  const accountData = useSelector((state) => state.accountProfile);
  const { compLoadBalancerLoading, errCompLoadBalancer, compLoadBalancer } = accountData;

  useEffect(() => {
    dispatch(
      getAccountCompute(
        `/api/account/${accountId}/LoadBalancer`,
        GET_ACC_COMPUTE_LBCR,
        ERROR_ACC_COMPUTE_LBCR,
      ),
    );
  }, [dispatch]);

  useEffect(() => {
    if (errCompLoadBalancer !== '') {
      setErrorMessage(errCompLoadBalancer);
      setShowErrMsg(true);
    }
  }, [errCompLoadBalancer]);

  const resourceMarkup = (list) => list.map((rs) => (
    <div className="div-row" key={rs.loadBalancerName}>
      <div className="div-column">{rs.loadBalancerName}</div>
      <div className="div-column">
        {rs.type === 'NA' ? 'N/A' : rs.type}
      </div>
      <div className="div-column">
        {rs.state === 'NA' ? 'N/A' : rs.state}
      </div>
      <div className="div-column">{rs.region}</div>
      <div className="div-column">{rs.dnsName}</div>
      <div className="div-column">{rs.scheme}</div>
      <div className="div-column">
        <Link to={`/virtualnetworks/${rs.vpcId}`}>{rs.vpcId}</Link>
      </div>
      <div className="div-column">
        {rs.type !== 'NA'
          ? rs.createdTime
          : rs.createdTime.replace(/[',]/g, '')}
      </div>
    </div>
  ));

  return (
    <div className={`card border-0 ${isOpen ? 'card-active' : ''}`}>
      <div className="card-header card-header2" id="headingOne">
        <h5 className="mb-0">
          <button
            type="button"
            className={`btn btn-link ${isOpen ? '' : 'collapsed'}`}
            data-toggle="collapse"
            data-target="#collapseSeven"
            aria-expanded="false"
            aria-controls="collapseSeven"
            onClick={() => onClick('loadBalancer')}
          >
            LoadBalancer
          </button>
        </h5>
      </div>
      <div
        id="collapseSeven"
        className={`collapse ${isOpen ? 'show' : ''}`}
        aria-labelledby="headingOne"
        data-parent="#accordion"
      >
        <div className="card-body px-0">
          {compLoadBalancerLoading ? (
            <Loader />
          ) : showErrMsg ? (
            <div className="col-12">
              <Alert
                message={errorMessage}
                alertClass="light-danger"
                onClose={() => {
                  setShowErrMsg(false);
                  onClick('instances');
                }}
              />
            </div>
          ) : (
            !errorMessage && (
              <>
                {compLoadBalancer.length === 0 ? (
                  <div className="col-12">
                    <Alert
                      message="No Storage compute found."
                      alertClass="info"
                    />
                  </div>
                ) : (
                  <div className="custom-table">
                    <div className="respnsive-custom-table">
                      <div className="div-table" width="100%">
                        <div className="div-thead">
                          <div className="div-row">
                            <div className="div-column-th">Name</div>
                            <div className="div-column-th">Type</div>
                            <div className="div-column-th">State</div>
                            <div className="div-column-th">Region</div>
                            <div className="div-column-th">DNSName</div>
                            <div className="div-column-th">Scheme</div>
                            <div className="div-column-th">VPCID</div>
                            <div className="div-column-th">CreationTime</div>
                          </div>
                        </div>
                        <div className="div-tbody">
                          {resourceMarkup(compLoadBalancer)}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
};
export default ComputeLoadBalancer;
