import { Link } from '@reach/router';
import React from 'react';

const SubscriptionInfo = ({ data }) => {
  const iTags = data?.iTag?.split(',');
  return (
    <div>
      <div className="tab-pane" id="home-v">
        <div className="row justify-content-center justify-content-end-xs">
          <div className="col-md-12 col-sm-12 col-12">
            <div className="d-flex screen-md-d-flex">
              {/* begin::Pic */}
              <div className="flex-shrink-0 mr-2 screen-md-flex-shrink">
                <div className="symbol symbol-50 symbol-lg-120 symbol-light-primary">
                  <div className="font-size-h5 symbol-label font-weight-boldest text-center p-4 text-break d-flex flex-column aling-items-center">
                    {data.azureSubName}
                  </div>
                </div>
              </div>
              {/* end::Pic */}

              {/* begin: Info */}
              <div className="w-100">
                <Link
                  to="/"
                  className="font-size-h4 text-primary col pr-1"
                >
                  {data.id}
                </Link>
                <div className="d-flex justify-content-start aling-items-center mt-4 flex-wrap">
                  {data.ownerName !== ' ' && (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                    <div className="d-flex flex-column font-size-h5">
                      <span className="text-muted font-size-sm text-nowrap">
                        Subscription Contact/Owner
                      </span>
                      {data.ownerName}
                    </div>
                  </div>
                  )}
                  {data.requestorName !== ' ' && (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                    <div className="d-flex flex-column font-size-h5">
                      <span className="text-muted font-size-sm text-nowrap">
                        Requestor
                      </span>
                      {data.requestorName}
                    </div>
                  </div>
                  )}
                  {data.azureSubName && (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                    <div className="d-flex flex-column font-size-h5">
                      <span className="text-muted font-size-sm text-nowrap">
                        Subscription Name
                      </span>
                      {data.azureSubName}
                    </div>
                  </div>
                  )}
                  {data.tenant && (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                    <div className="d-flex flex-column font-size-h5">
                      <span className="text-muted font-size-sm text-nowrap">
                        Tenant
                      </span>
                      {data.tenant}
                    </div>
                  </div>
                  )}
                  {data.remedyGroup && (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                    <div className="d-flex flex-column font-size-h5">
                      <span className="text-muted font-size-sm text-nowrap">
                        Remedy Group
                      </span>
                      {data.remedyGroup}
                    </div>
                  </div>
                  )}
                  {data.iTag && (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                    <div className="d-flex flex-column font-size-h5">
                      <span className="text-muted font-size-sm text-nowrap">
                        iTag
                      </span>
                      <div className="multi-itags">
                        {iTags.map((item) => (
                          <a
                            className="itag-pills"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://itag.csgicorp.com/PortfolioManagement/#/epics/${item}`}
                          >
                            {item}
                          </a>
                        ))}
                        {' '}
                      </div>
                    </div>
                  </div>
                  )}
                  {data.teamEmail && (
                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                    <div className="d-flex flex-column font-size-h5">
                      <span className="text-muted font-size-sm text-nowrap">
                        Support Group
                      </span>
                      {data.teamEmail}
                    </div>
                  </div>
                  )}
                  {data.productCodes && (

                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                    <div className="d-flex flex-column font-size-h5">

                      <span className="text-muted font-size-sm text-nowrap">
                        Product
                      </span>
                      {data.productCodes.toString()}
                    </div>
                  </div>
                  )}
                  {data.productFamily && (

                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                    <div className="d-flex flex-column font-size-h5">

                      <span className="text-muted font-size-sm text-nowrap">
                        Product Family
                      </span>
                      {data.productFamily.toString()}
                    </div>
                  </div>
                  )}
                  {data.productSubfamily && (

                  <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
                    <div className="d-flex flex-column font-size-h5">

                      <span className="text-muted font-size-sm text-nowrap">
                        Product Sub Family
                      </span>
                      {data.productSubfamily.toString()}
                    </div>
                  </div>
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionInfo;
