import React, { useState } from 'react';
import nextId from 'react-id-generator';
import PropTypes from 'prop-types';

const Checkbox = (props) => {
  const {
    name, title, tabIndex, required, errorText,
  } = props;
  const [id] = useState(nextId());
  const [checked, setChecked] = useState(props.checked === true);

  function checkboxChange(e) {
    const { onChange } = props;
    setChecked(!checked);

    if (onChange && onChange instanceof Function) {
      onChange(e);
    }
  }

  let className = 'form-check-input';
  if (errorText) {
    className += ' is-invalid';
  }

  const opts = {
    id,
    name,
    className,
    checked,
    value: true,
    onChange: checkboxChange,
    type: 'checkbox',
  };

  if (tabIndex) {
    opts.tabIndex = tabIndex;
  }

  if (required === true) {
    opts.required = true;
  }

  return (
    <div className="from-group">
      <div className="form-check">
        <input {...opts} />
        <label className="form-check-label" htmlFor={opts.id}>
          {title}
        </label>
        {errorText && <div className="invalid-feedback">{errorText}</div>}
      </div>
    </div>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  tabIndex: PropTypes.number,
  required: PropTypes.bool,
  errorText: PropTypes.string,
};

export default Checkbox;
