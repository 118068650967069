import React, { useState } from 'react';
import { FaTrash } from 'react-icons/fa';
import { Link } from '@reach/router';
import './requests.css';

const RequestSearchResult = ({
  account, onScroll, beginDelete, beginEdit,
}) => {
  const [active, setActive] = useState(null);
  return (
    <>
      {account.length > 0 && (
        <div className="card card-custom mb-4">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="FilteredResult" onScroll={onScroll}>
                  {account.length > 0
                    /* eslint-disable react/no-array-index-key */
                    && account.map((data, index) => (
                      <Link
                        key={index}
                        to={
                          data.accountNumber !== undefined
                            ? data.accountNumber
                            : ''
                        }
                        onMouseOver={() => setActive(data)}
                        onMouseLeave={() => setActive(null)}
                        className="FilteredResultList position-relative"
                        onClick={(e) => beginEdit(e, data.id)}
                      >

                        <div className="row">
                          <div className="col-md-5 col-sm-5 col-8">
                            <span className="text-dark text-hover-primary font-size-lg font-weight-500">
                              <span className="text-primary mr-1 font-size-lg">
                                {data.desiredName}
                                <br />
                                <span
                                  className="text-dark"
                                  title={
                                    data.suspended === true ? 'suspended' : ''
                                  }
                                >
                                  {data.accountNumber}
                                </span>
                              </span>
                            </span>
                          </div>
                          <div className="col-md-6 col-sm-6 col-8">
                            <div className="account-cost-section pr-5">
                              <small
                                className={`badge badge-${data.suspended === true ? 'warning' : 'info'} badge-pill`}
                              >
                                {data.accountEmail}
                              </small>
                              <span
                                className="text-dark text-hover-primary font-size-lg font-weight-500"
                                title="Account Contact / Owner"
                              >
                                <span>{data.owner}</span>
                              </span>
                              <div
                                className="font-weight-normal text-right"
                                title="Executive / Director over account"
                              >
                                <span className="text-inverse">
                                  {data.director}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-1 col-sm-1 col-8 d-flex align-items-center justify-content-center">
                            <button
                              type="button"
                              className="btn btn-link text-danger"
                              title="Delete"
                              onClick={(e) => beginDelete(e, data.id)}
                            >
                              <FaTrash />
                            </button>
                          </div>
                        </div>
                        <div id="collapseOne" className={`collapse mt-3 pt-3 border-top ${active === data ? 'show' : ''}`} aria-labelledby="headingOne" data-parent="#accordion">
                          <div to={data.number} className="row">
                            <div className="col-md-4">
                              <strong>Product</strong>
                              {' '}
                              -
                              {' '}
                              {data.productCode}
                            </div>
                            <div className="col-md-4">
                              <strong>Subfamily</strong>
                              {' '}
                              -
                              {data.subfamily}
                            </div>
                            <div className="col-md-4">
                              <strong>Family</strong>
                              {' '}
                              -
                              {' '}
                              {data.family}
                            </div>

                          </div>
                        </div>
                      </Link>
                      /* eslint-enable */
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RequestSearchResult;
