import React from 'react';

const AzureSubscriptionEditRequestInfo = () => (
  <div className="row">
    <div className="col-12">
      <h4 className="custom-tooltip-text mb-0">
        Update Azure Subscription Request by clicking on the search result
      </h4>
    </div>
  </div>
);

export default AzureSubscriptionEditRequestInfo;
