import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../Components/Loader';
import CloudabilityRegionCost from '../Components/Ascendon/CloudabilityRegionCost';
import CloudabilityServiceCost from '../Components/Ascendon/CloudabilityServiceCost';
import CloudabilityCustomerCost from '../Components/Ascendon/CloudabilityCustomerCost';
import Header from '../Components/Ascendon/Header';
import { getCloudabilityCost } from '../store/actions/ascendonAction';
import { CloudabilityContext } from '../Context/CloudabilityContext';
import { Context as MnContext } from '../Context/MenuContext';
import CloudabilityMonthlyReport from '../Components/Ascendon/CloudabityMonthlyReport';
import Tab from '../Components/Common/Tab';
import { Context as PageContext } from '../Context/PageContext';
import { saveUserPageVisitHistory } from '../store/actions/peopleAction';

const AscendonCloudabilityCost = (props) => {
  const [tab, setTab] = useState('service');
  const tabList = [
    { value: 'service', label: 'Service Costing' },
    { value: 'region', label: 'Region Costing' },
    { value: 'monthly', label: 'Monthly Comparison' },
    { value: 'customer', label: 'Customer Costing' },
  ];
  const [params, setParams] = useState({
    product: 'all',
    filterItem: 'last_week',
  });

  const cloudabilityData = useSelector((state) => state.cloudabilityList);
  const { loading, error, cloudabilityCosting } = cloudabilityData;
  const { user } = props;
  const [pageContextState, setPageContextState] = useContext(PageContext);
  const { cloudabilityCostingPage } = pageContextState;
  // Get selectedMenu data from Menu Context
  const [, setSelectedMenu, , setSelectedSubMenu] = useContext(MnContext);
  const dispatch = useDispatch();

  // In case the page is loaded directly from url then change selectedMenu state
  useEffect(() => {
    setSelectedMenu('cost report');
    setSelectedSubMenu('cloudabilityCosting');
  }, []);

  // Get user login details
  useEffect(() => {
    if (cloudabilityCostingPage === '') {
      setPageContextState({
        ...pageContextState,
        cloudabilityCostingPage: 'cloudabilityCosting',
      });
      const apiUrl = `/api/Auth/SaveUserPageVisit/${user.employeeId}/cloudabilityCosting`;
      dispatch(saveUserPageVisitHistory(apiUrl));
    }
  }, [cloudabilityCostingPage]);

  const handleChange = (newParams) => {
    setParams(newParams);
  };
  function costingTab(tabName) {
    switch (tabName) {
      case 'service':
        return !error ? (
          <CloudabilityServiceCost
            serviceCosts={cloudabilityCosting.serviceCosts}
            onChange={handleChange}
          />
        ) : (
          <div style={{ textAlign: 'center' }}>Data not available!</div>
        );
      case 'monthly':
        return !error ? (
          <CloudabilityMonthlyReport />
        ) : (
          <div style={{ textAlign: 'center' }}>Data not available!</div>
        );
      case 'region':
        return !error ? (
          <CloudabilityRegionCost
            serviceCosts={cloudabilityCosting.serviceCosts}
            totalCost={cloudabilityCosting.totalCost}
            onChange={handleChange}
          />
        ) : (
          <div style={{ textAlign: 'center' }}>Data not available!</div>
        );
      case 'customer':
        return !error ? (
          <CloudabilityCustomerCost />
        ) : (
          <div style={{ textAlign: 'center' }}>Data not available!</div>
        );
      default:
        return '';
    }
  }

  useEffect(() => {
    let apiFunc = 'GetServiceProductCloudabilityCost?product';
    if (params.product.includes('_Services')) {
      apiFunc = 'GetServiceCloudabilityCost?viewName';
    }
    const apiUrl = `/api/AscendonCost/${apiFunc}=${params.product}&filterItem=${params.filterItem}`;
    dispatch(getCloudabilityCost(apiUrl));
  }, [params, dispatch]);

  return (
    <div>
      {loading && cloudabilityCosting ? (
        <Loader />
      ) : (
        <>
          <div className="row gutter-b">
            <div className="col-12">
              <div className="card card-custom gutter-b">
                {/* Ascendon header */}
                <Header />
                <div className="card-body p-0">
                  <div className="row">
                    <div className="col-12">
                      <div className="horizontal-tab">
                        <Tab
                          onClick={(e) => setTab(e)}
                          tabList={tabList}
                          selected="service"
                        />
                        <CloudabilityContext>
                          <div className="tab-content">{costingTab(tab)}</div>
                        </CloudabilityContext>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default AscendonCloudabilityCost;
