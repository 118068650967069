import React, { useEffect, useState } from 'react';
import axios from 'axios';
import HeaderWithInfo from '../Components/Common/HeaderWithInfo';
import Loader from '../Components/Loader';
import Message from '../Components/Messages/Message';

const Health = () => {
  const [loaded, setLoaded] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [health, setHealth] = useState({});
  const [errorMessage, setErrorMessage] = useState('');

  function makeCall(showSettings) {
    axios
      .get(`/api/health/?settings=${showSettings}`)
      .then((response) => {
        setLoaded(true);
        setHealth(response.data);
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          setLoaded(true);
          setErrorMessage(err.message);
        }
      });
  }
  useEffect(() => {
    makeCall(true);
  }, []);
  function displaySetting() {
    setShowSettings(true);
    setLoaded(false);
    makeCall(true);
  }
  return (
    <>
      <div className={`row ${!health.whoAmI?.isAuthenticated ? 'mt-10p' : ''}`}>
        <div className="col-12">
          <div className="card card-custom mb-4">
            <div className="card-header align-items-center">
              <div className="card-title align-items-center justify-content-between w-100">
                <HeaderWithInfo headerName="Health Status" infoIcon />
              </div>
            </div>
            <div className="card-body accout-search">
              { loaded
                ? errorMessage
                  ? <Message level="danger" message={errorMessage} />
                  : (
                    <div className="col-12 col-lg-4 col-md-6 cols-m-8 offset-lg-4 offset-md-3 offset-sm-2">
                      <h3>Version</h3>
                      <code>{health.version}</code>
                      <hr className="mb-2" />
                      <h3>Aurora</h3>
                      <code>{JSON.stringify(health.rds)}</code>
                      <hr className="mb-2" />
                      <h3>Who</h3>
                      <code>{JSON.stringify(health.whoAmI)}</code>
                      <hr className="mb-2" />
                      { showSettings && health.settings
                        ? (
                          <div>
                            <h3>Settings</h3>
                            <code>
                              {health.settings.map((i) => (<p key={i}>{i}</p>))}
                            </code>
                          </div>
                        )
                        : health.whoAmI?.isAdmin
                          ? <button type="button" className="btn-primary btn" onClick={displaySetting}>Show Settings</button>
                          : ''}
                    </div>
                  )
                : <Loader />}
            </div>
          </div>
        </div>
      </div>
    </>

  );
};
export default Health;
