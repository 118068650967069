import React, { useState, useEffect } from 'react';
import { FaSearch } from 'react-icons/fa';
import PropTypes from 'prop-types';
import BigSearchFilter from './BigSearchFilter';
import BigSearchType from './BigSearchType';
import Input from './Input';

const BigSearch = (props) => {
  const { filters, srLabelTitle } = props;
  const selectedFilterVar = {};
  if (filters && filters.length) {
    filters.forEach(
      (filter) => { selectedFilterVar[filter.key] = filter.initialSelection || []; },
    );
  }
  const [searchTypes] = useState(props.searchTypes);
  const [selectedIndex, setSelectedIndex] = useState(props.selectedIndex);
  const [search, setSearch] = useState(props.search || '');
  const [selectedFilters, setSelectedFilters] = useState(selectedFilterVar);
  const executeSearch = (e) => {
    const { executeSearch, clearSearch } = props;
    // const { searchTypes, selectedIndex, search, selectedFilters } = this.state
    const selectedValue = searchTypes[selectedIndex];
    const someFiltersSelected = Object.keys(selectedFilters || {})
      .map((key) => selectedFilters[key].length > 0)
      .some((b) => b === true);

    const newE = {
      ...e,
      bigSearch: {
        selectedIndex,
        selectedValue,
        search,
        selectedFilters,
      },
    };

    if (
      search
      || someFiltersSelected === true
      || selectedValue.alwaysSearch === true
    ) {
      executeSearch(newE);
    } else {
      clearSearch(newE);
    }
  };

  useEffect(() => {
    executeSearch({ event: null, from: 'BigSearchMount' });
  }, []);

  const checkEnterKey = (e) => {
    const { keyCode, which } = e;
    const code = keyCode || which;
    if (code === 13) {
      // 13 is the enter keycode
      executeSearch(e);
    }
  };

  const filterChange = (e) => {
    const { key, selected } = e;
    selectedFilters[key] = selected;
    setSelectedFilters(selectedFilters);
    executeSearch(e);
  };

  const searchTypeChange = (e) => {
    const { index } = e;
    setSelectedIndex(index);
    setSearch('');
    executeSearch(e);
  };

  const inputBlur = (e) => {
    executeSearch(e);
  };

  const inputChange = (e) => {
    const { target } = e;
    const { value } = target;
    setSearch(value);
  };
  const selectedValue = searchTypes[selectedIndex] || searchTypes[0];
  const filterMarkup = (filters || []).map((filter) => (
    <BigSearchFilter
      key={filter.key}
      filterKey={filter.key}
      url={filter.url}
      label={filter.label}
      initialSelection={filter.initialSelection}
      onChange={filterChange}
    />
  ));
  const prepend = (
    <BigSearchType
      searchTypes={searchTypes}
      selectedIndex={selectedIndex}
      onChange={searchTypeChange}
    />
  );
  const append = (
    <button className="btn btn-primary" type="button">
      <span className="sr-only">Search</span>
      <FaSearch />
    </button>
  );

  const inputMarkup = (
    <Input
      large
      type="search"
      name="search"
      prepend={prepend}
      append={append}
      title={srLabelTitle}
      placeholder={selectedValue.placeholder}
      hideLabel
      value={search}
      disableAutoComplete
      onChange={inputChange}
      onBlur={inputBlur}
      onKeyPress={checkEnterKey}
    />
  );
  return filters && filters.length ? (
    <div className="row">
      <div className="col-md-auto pr-0">
        <div className="d-flex flex-wrap mb-4">{filterMarkup}</div>
      </div>
      <div className="big-search-input col">{inputMarkup}</div>
    </div>
  ) : (
    inputMarkup
  );
};

BigSearch.propTypes = {
  srLabelTitle: PropTypes.string.isRequired,
  search: PropTypes.string,
  selectedIndex: PropTypes.number.isRequired,
  executeSearch: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  searchTypes: PropTypes.arrayOf(
    PropTypes.exact({
      key: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
      placeholder: PropTypes.string.isRequired,
      alwaysSearch: PropTypes.bool,
    }),
  ).isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.exact({
      key: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      initialSelection: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
};

export default BigSearch;
