import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  getCumulativeServiceCost,
  getAccountList,
  getTimePeriods,
  getAwsServiceCostDetails,
} from '../store/actions/awsServicesAction';
import Loader from '../Components/Loader';
import Alert from '../Components/Common/Alert';
import { Context as MenuContext } from '../Context/MenuContext';
import HighestAndLowestCosts from '../Components/AwsServiceDashboard/HighestAndLowestCosts';
import HighestAndLowestCostByAccount from '../Components/AwsServiceDashboard/HighestAndLowestCostByAccount';
import AwsServiceCostDetails from '../Components/AwsServiceDashboard/Details';
import { Context as PageContext } from '../Context/PageContext';
import { saveUserPageVisitHistory } from '../store/actions/peopleAction';

const AwsServiceDashboard = (props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [showSumCostErr, setShowSumCostErr] = useState(false);
  const [showErrMsg, setShowErrMsg] = useState(false);
  const [showDetailsErr, setShowDetailsErr] = useState(false);
  const [selectedTimePeriod, setSelectedTimePeriod] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [formattedAccountList, setFormattedAccList] = useState([]);
  const [formattedTimePeriodList, setFormattedTpList] = useState([]);
  const [validateAcct, setValidateAcct] = useState(true);
  const [validateTp, setValidateTp] = useState(true);
  const [filterServiceCost, setFilterServiceCost] = useState([]);
  const [filterServiceCostByAcc, setFilterServiceCostByAcc] = useState([]);
  const [showHighCostByOrg, setShowHighCostByOrg] = useState(true);
  const [showHighCostByAcc, setShowHighCostByAcc] = useState(true);
  const [conditionMatch, setConditionMatch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dateJson, setDateJson] = useState([]);
  const { user } = props;
  const [pageContextState, setPageContextState] = useContext(PageContext);
  const { awsServiceDashboardPage } = pageContextState;

  const dispatch = useDispatch();

  // Get user login dtails
  useEffect(() => {
    if (awsServiceDashboardPage === '') {
      setPageContextState({
        ...pageContextState,
        awsServiceDashboardPage: 'awsServiceDashboard',
      });
      const apiUrl = `/api/Auth/SaveUserPageVisit/${user.employeeId}/awsServiceDashboard`;
      dispatch(saveUserPageVisitHistory(apiUrl));
    }
  }, [awsServiceDashboardPage]);

  // Get selectedMenu data from Menu Context
  const [, setSelectedMenu, , setSelectedSubMenu] = useContext(MenuContext);
  const statsState = useSelector((state) => state.awsServicesInfo);
  const {
    accountViewlist,
    filtersLoading,
    errorFilters,
    timePeriodsList,
    cumulativeServiceCost,
    cumulativeServiceCostLoading,
    errorCumulativeServiceCost,
    awsServiceCostList,
    errorAwsServiceCost,
  } = statsState;

  function dateFormat(date) {
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December',
    ];
    const month = monthNames[date.getMonth()];
    return `${date.getFullYear()}-${date.getMonth() > 9 || month === 'October' ? '' : '0'}${date.getMonth() + 1}-${date.getDate() > 9 ? '' : '0'}${date.getDate()}`;
  }
  function getDateDuration() {
    const jsonResult = [];
    const date = new Date();
    const today = date.toISOString().slice(0, 10);
    jsonResult.push({ filterDay: 'today', filterDate: { startDate: today, endDate: today } });
    const yesterday = new Date(new Date().setDate(date.getDate() - 1)).toISOString().slice(0, 10);
    jsonResult.push({ filterDay: 'yesterday', filterDate: { startDate: yesterday, endDate: yesterday } });
    // this week
    const startOfWeek = dateFormat(new Date(new Date().setDate(date.getDate() - date.getDay())));
    const endOfWeek = today;
    jsonResult.push({ filterDay: 'this_week', filterDate: { startDate: startOfWeek, endDate: endOfWeek } });
    // last week
    const startOfLastweek = dateFormat(new Date(new Date(startOfWeek).setDate(new Date(startOfWeek).getDate() - 7)));
    const endOfLastweek = dateFormat(new Date(new Date(startOfWeek).setDate(new Date(startOfWeek).getDate() - 1)));
    jsonResult.push({ filterDay: 'last_week', filterDate: { startDate: startOfLastweek, endDate: endOfLastweek } });
    // this month
    const startOfThismonth = dateFormat(new Date(date.getFullYear(), date.getMonth(), 1));
    const endOfThismonth = today;
    jsonResult.push({ filterDay: 'this_month', filterDate: { startDate: startOfThismonth, endDate: endOfThismonth } });
    // last month
    const startOfLastmonth = dateFormat(new Date(date.getFullYear(), date.getMonth() - 1, 1));
    const endOfLastmonth = dateFormat(new Date(date.getFullYear(), date.getMonth(), 0));
    jsonResult.push({ filterDay: 'last_month', filterDate: { startDate: startOfLastmonth, endDate: endOfLastmonth } });
    // this quarter
    const currQuarter = Math.floor((date.getMonth() + 3) / 3);
    const startOfThisQuarter = dateFormat(new Date(date.getFullYear(), 3 * currQuarter - 3, 1));
    const endOfThisQuarter = today;
    jsonResult.push({ filterDay: 'this_quarter', filterDate: { startDate: startOfThisQuarter, endDate: endOfThisQuarter } });
    // last quarter
    const lastQuarter = Math.floor((date.getMonth() + 3) / 3) - 1;
    const startOfLastQuarter = dateFormat(new Date(date.getFullYear(), 3 * lastQuarter - 3, 1));
    const endOfLastQuarter = dateFormat(new Date(date.getFullYear(), 3 * lastQuarter, 0));
    jsonResult.push({ filterDay: 'last_quarter', filterDate: { startDate: startOfLastQuarter, endDate: endOfLastQuarter } });
    // this year
    const startOfThisYear = dateFormat(new Date(date.getFullYear(), 0, 1));
    const endOfThisYear = today;
    jsonResult.push({ filterDay: 'this_year', filterDate: { startDate: startOfThisYear, endDate: endOfThisYear } });
    // last year
    const startOfLastYear = dateFormat(new Date(date.getFullYear() - 1, 0, 1));
    const endOfLastYear = dateFormat(new Date(date.getFullYear() - 1, 11, 31));
    jsonResult.push({ filterDay: 'last_year', filterDate: { startDate: startOfLastYear, endDate: endOfLastYear } });
    // last particuler date
    const startOfLast7Days = dateFormat(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7));
    jsonResult.push({ filterDay: 'last_7_days', filterDate: { startDate: startOfLast7Days, endDate: today } });
    const startOfLast14Days = dateFormat(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 14));
    jsonResult.push({ filterDay: 'last_14_days', filterDate: { startDate: startOfLast14Days, endDate: today } });
    const startOfLast30Days = dateFormat(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 30));
    jsonResult.push({ filterDay: 'last_30_days', filterDate: { startDate: startOfLast30Days, endDate: today } });
    const startOfLast60Days = dateFormat(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 60));
    jsonResult.push({ filterDay: 'last_60_days', filterDate: { startDate: startOfLast60Days, endDate: today } });
    const startOfLast90Days = dateFormat(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 90));
    jsonResult.push({ filterDay: 'last_90_days', filterDate: { startDate: startOfLast90Days, endDate: today } });
    setDateJson(jsonResult);
  }

  useEffect(() => {
    getDateDuration();
    dispatch(getAccountList('/api/AwsAnalytics/GetAccountViewList'));
    dispatch(getTimePeriods('/api/AscendonCost/TimePeriods'));
    dispatch(
      getCumulativeServiceCost('/api/AwsAnalytics/GetCumulativeServiceCost'),
    );
  }, [dispatch]);

  useEffect(() => {
    setLoading(false);
    if (awsServiceCostList[0] !== undefined) {
      setFilterServiceCostByAcc(awsServiceCostList.slice(0, 3));
    }
  }, [awsServiceCostList]);

  useEffect(() => {
    if (errorFilters !== '') {
      setErrorMessage(errorFilters);
      setShowErrMsg(true);
    } else if (errorAwsServiceCost !== '') {
      setLoading(false);
      setShowDetailsErr(true);
    }
  }, [errorFilters, errorAwsServiceCost]);

  useEffect(() => {
    if (cumulativeServiceCost.length > 0) {
      setFilterServiceCost(cumulativeServiceCost.slice(0, 3));
    }
    if (errorCumulativeServiceCost !== '') {
      setShowSumCostErr(true);
    }
  }, [cumulativeServiceCost, errorCumulativeServiceCost]);

  useEffect(() => {
    if (accountViewlist.length > 0) {
      setFormattedAccList(
        accountViewlist.map((a) => ({
          value: a.viewId,
          label: a.accountName,
        })),
      );
    }
  }, [accountViewlist]);

  useEffect(() => {
    if (Object.keys(timePeriodsList).length > 0) {
      setFormattedTpList(
        Object.keys(timePeriodsList).map((key) => ({
          value: key,
          label: timePeriodsList[key],
        })),
      );
    }
  }, [timePeriodsList]);

  const handleSubmit = () => {
    setValidateAcct(!!selectedAccount);
    setValidateTp(!!selectedTimePeriod);
    if (selectedAccount && selectedTimePeriod) {
      setLoading(true);
      setConditionMatch(true);
      setShowHighCostByAcc(true);
      const { startDate } = dateJson.filter((a) => a.filterDay === selectedTimePeriod.value)[0]?.filterDate;
      const { endDate } = dateJson.filter((a) => a.filterDay === selectedTimePeriod.value)[0]?.filterDate;
      const api = `/api/AwsAnalytics/GetAwsServiceByAccountTimePeriod?viewId=${selectedAccount.value}&timePeriod=${selectedTimePeriod.value}&startDate=${startDate}&endDate=${endDate}`;
      dispatch(getAwsServiceCostDetails(api));
    }
  };

  const onHighLowButtonClick = () => {
    if (showHighCostByOrg) {
      const val = cumulativeServiceCost.filter((a) => (Math.round(a.cost * 100) / 100) > 0.00);
      setFilterServiceCost(val.slice(val.length - 3));
    } else {
      setFilterServiceCost(cumulativeServiceCost.slice(0, 3));
    }
    setShowHighCostByOrg(!showHighCostByOrg);
  };

  const onClickHighConsumeCostByAcc = () => {
    if (showHighCostByAcc) {
      const val = awsServiceCostList.filter((a) => (Math.round(a.metrics[0].sum * 100) / 100) > 0.00);
      setFilterServiceCostByAcc(val.slice(val.length - 3));
    } else {
      setFilterServiceCostByAcc(awsServiceCostList.slice(0, 3));
    }
    setShowHighCostByAcc(!showHighCostByAcc);
  };

  // In case the page is loaded directly from url then change selectedMenu state
  useEffect(() => {
    setSelectedMenu('cost report');
    setSelectedSubMenu('awsservicedashboard');
  }, []);

  return (
    <>
      {filtersLoading || cumulativeServiceCostLoading ? (
        <Loader />
      ) : (
        <>
          {showSumCostErr ? (
            <Alert
              message={errorCumulativeServiceCost}
              alertClass="light-danger"
              onClose={() => setShowSumCostErr(false)}
            />
          ) : (
            !errorCumulativeServiceCost && (
              <HighestAndLowestCosts
                filterServiceCost={filterServiceCost}
                onHighLowButtonClick={onHighLowButtonClick}
                showHighCostByOrg={showHighCostByOrg}
              />
            )
          )}
          <div className="row mb-2">
            <div className="col-12">
              <div className="card card-custom mb-4">
                <div className="card-header align-items-center">
                  <div className="card-title align-items-center justify-content-between w-100">
                    <h3 className="card-label col p-0">
                      <div className="d-flex align-items-center justify-content-start">
                        <span>AWS Service Analytics </span>
                      </div>
                    </h3>
                  </div>
                </div>
                <div className="card-body">
                  {showErrMsg ? (
                    <div className="col-12">
                      <Alert
                        message={errorMessage}
                        alertClass="light-danger"
                        onClose={() => setShowErrMsg(false)}
                      />
                    </div>
                  ) : (
                    !errorMessage && (
                      <div className="row justify-content-center justify-content-end-xs align-items-end">
                        <div className="col-xl-4 col-lg-3 col-md-4 col-sm-4 col-12">
                          <div
                            className={`form-group ${
                              validateAcct ? '' : 'required-select'
                            }`}
                          >
                            <Select
                              value={selectedAccount}
                              options={formattedAccountList}
                              onChange={(item) => {
                                setSelectedAccount(item);
                                setValidateAcct(!!item);
                              }}
                              isClearable="true"
                              className=""
                              placeholder="Select Account"
                            />
                          </div>
                        </div>
                        <div className="col-xl-4 col-lg-3 col-md-4 col-sm-4 col-12">
                          <div
                            className={`form-group ${
                              validateTp ? '' : 'required-select'
                            }`}
                          >
                            <Select
                              value={selectedTimePeriod}
                              options={formattedTimePeriodList}
                              onChange={(item) => {
                                setSelectedTimePeriod(item);
                                setValidateTp(!!item);
                              }}
                              isClearable="true"
                              className=""
                              placeholder="Select Time Period"
                            />
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-2 col-sm-5 col-12">
                          <div className="form-group">
                            <button
                              type="button"
                              className="btn btn-sm btn-primary text-nowrap"
                              onClick={handleSubmit}
                            >
                              <span className="svg-icon svg-light">
                                <i className="fa fa-search mr-1" />
                              </span>
                              Search
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>

          {conditionMatch
            && (loading ? (
              <Loader />
            ) : (
              <>
                {showDetailsErr ? (
                  <Alert
                    message={errorAwsServiceCost}
                    alertClass="light-danger"
                    onClose={() => setShowDetailsErr(false)}
                  />
                ) : (
                  !errorAwsServiceCost && (
                    <>
                      <HighestAndLowestCostByAccount
                        filterServiceCostByAcc={filterServiceCostByAcc}
                        onClickHighConsumeCostByAcc={
                          onClickHighConsumeCostByAcc
                        }
                        showHighCostByAcc={showHighCostByAcc}
                      />
                      <AwsServiceCostDetails details={awsServiceCostList} />
                    </>
                  )
                )}
              </>
            ))}
        </>
      )}
    </>
  );
};

export default AwsServiceDashboard;
