import React from 'react';
import { Link } from '@reach/router';
import noData from '../../Images/no-data.png';
import AccountList from './AccountList';

const AccountWidget = ({ accountList, accountType, user }) => {
  const accountOwnLink = `/accounts?t=Owner&q=${`${user.firstName} ${user.lastName}`}`;
  const accountAccessLink = `/userProfile/${user.employeeId}`;

  let customMsg = '';
  switch (accountType) {
    case 'own':
      customMsg = `The accounts you own (${accountList.length}). `;
      break;
    case 'access':
      customMsg = `The accounts you have access to (${accountList.length}). `;
      break;
    default:
      customMsg = '';
  }

  return (
    <div className="col-lg-6 gutter-b">
      <div className="card card-custom gutter-b h-100">
        <div className="card-header align-items-center border-0 mt-4">
          <div className="card-title align-items-start flex-column">
            <h3 className="card-label">Account List</h3>
            {accountType !== 'none' && (
              <span className="text-muted mt-2 font-weight-500 font-size-sm">
                {customMsg}
                To view more
                <Link
                  to={
                    accountType === 'own' ? accountOwnLink : accountAccessLink
                  }
                >
                  {' '}
                  click here.
                </Link>
              </span>
            )}
          </div>
        </div>
        {accountType !== 'none' ? (
          <div className="card-body">
            {accountList.slice(0, 4).map((acct) => (
              <div key={acct.accountNumber}>
                <AccountList
                  acctName={acct.accountName}
                  acctNum={acct.accountNumber}
                  link={`/accounts/${acct.accountNumber}`}
                />
              </div>
            ))}
          </div>
        ) : (
          <div className="card-body card-empty">
            <img src={noData} className="img-fluid d-block m-auto w-50" alt="No Data" />
            <p className="text-center mt-10 font-size-lg font-weight-normal text-muted">
              You don&#39;t own or have access to any account.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AccountWidget;
