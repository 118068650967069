import React from 'react';

const VendorServicesInfo = () => (
  <div className="row">
    <div className="col-12">
      <h4 className="custom-tooltip-text">
        Provide the AWS Service selection By region and By service option
      </h4>
    </div>
  </div>
);

export default VendorServicesInfo;
