import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';
import Loader from '../Loader';
import AccessByRole from './AccessByRole';
import AccessByAccount from './AccessByAccount';
import Alert from '../Common/Alert';
import { getAccountWithRole } from '../../store/actions/peopleAction';

const PeopleAccount = (props) => {
  const [activeByRole, setActiveByRole] = useState(true);
  const [activeByAccount, setActiveByAccount] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrMsg, setShowErrMsg] = useState(false);
  const [subscriptionWithRole, setSubscriptionWithRole] = useState([]);

  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userProfile);
  const { accountWithRoleLoading, error, accountWithRole } = userData;

  useEffect(() => {
    const apiUrl = `/api/people/${props.userId}/accounts`;
    dispatch(getAccountWithRole(apiUrl));
  }, [dispatch, props.cloudEnv]);

  useEffect(() => {
    axios
      .get(`/api/people/${props.userId}/Subscriptions`)
      .then((response) => {
        setSubscriptionWithRole(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [props.cloudEnv]);

  useEffect(() => {
    if (error !== '') {
      setErrorMessage(error);
      setShowErrMsg(true);
    }
  }, [error]);

  function collapseSection(button) {
    switch (button) {
      case 'byRole':
        setActiveByRole(!activeByRole);
        setActiveByAccount(false);
        break;
      case 'byAccount':
        setActiveByAccount(!activeByAccount);
        setActiveByRole(false);
        break;
      default:
        return '';
    }
    return '';
  }
  return (
    <div>
      {accountWithRoleLoading || subscriptionWithRole.length === 0 ? (
        <Loader />
      ) : showErrMsg ? (
        <div className="col-12">
          <Alert
            message={errorMessage}
            alertClass="light-danger"
            onClose={() => setShowErrMsg(false)}
          />
        </div>
      ) : (
        !errorMessage && (
        <>
          {(accountWithRole.length > 0 && props.cloudEnv === 'aws')
          || (props.cloudEnv === 'azure') ? (

            <div className="tab-pane active" id="messages-v">
              <div className="accordion" id="accWordion">
                <AccessByRole
                  isOpen={activeByRole}
                  accountWithRole={accountWithRole}
                  subscriptionWithRole={subscriptionWithRole}
                  env={props.cloudEnv}
                  onClick={(e) => collapseSection(e)}
                />
                <AccessByAccount
                  isOpen={activeByAccount}
                  accountWithRole={accountWithRole}
                  subscriptionWithRole={subscriptionWithRole}
                  env={props.cloudEnv}
                  onClick={(e) => collapseSection(e)}
                />
              </div>
            </div>
            ) : (
              <div className="col-12">
                <Alert
                  message="No account details found for this user."
                  alertClass="info"
                />
              </div>

            )}
        </>
        )
      )}
    </div>
  );
};

PeopleAccount.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default PeopleAccount;
