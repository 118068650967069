import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Accordion from '../../Common/Accordion';
import Gauge from '../../Common/Gauge';
import Alert from '../../Common/Alert';
import Loader from '../../Loader';
import Popup from '../../Common/Popup';

const SubscriptionComplianceTab = (props) => {
  const { id } = props;
  const [data, setData] = useState();
  const [detail, setDetail] = useState();
  const [showDetail, setShowDetail] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    axios
      .get(
        `/api/azureSubscription/${id}/Compliance`,
      )
      .then((response) => {
        const result = response.data;
        setData(result.azureCloudUsageFramework);
        setShowDetail(true);
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          console.log(err);
        }
      });
  }, []);

  /* eslint-disable react/no-array-index-key */
  const fixNewLine = (text) => text.split('\n').map((i, x) => <p key={x}>{i}</p>);
  /* eslint-enable */

  function renderModal() {
    const { rule, entityResults } = detail;
    return (
      <Popup
        title={rule.name}
        size="xl"
        onClose={() => setModalOpen(false)}
        onSubmit={() => setModalOpen(false)}
      >
        <code>{rule.ruleId}</code>
        <p>{rule.description}</p>
        <h5 className="text-info">Remediation</h5>
        <blockquote className="text-muted">
          {fixNewLine(rule.remediation)}
        </blockquote>
        <div className="list-group list-group-flush scroll-list-group">
          {entityResults.map((r, i) => (
            /* eslint-disable react/no-array-index-key */
            <li key={i} className="list-group-item">
              {r.testObj.id}
              <span className="badge badge-dark text-white badge-pill float-right">
                {r.testObj.entityType}
              </span>
            </li>
            /* eslint-enable */
          ))}
        </div>
      </Popup>
    );
  }

  function renderFailedTests(complianceTests) {
    return complianceTests.map((c) => (
      <tr key={c.rule.ruleId}>
        <td>{c.rule.name}</td>
        <td>{c.exclusionCount}</td>
        <td>{c.nonComplyingCount}</td>
        <td>{c.testedCount}</td>
        <td>
          <button
            type="button"
            className="btn btn-link m-0 p-0"
            onClick={(e) => { setDetail(c, e); setModalOpen(true); }}
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              {/* <!--begin::Svg Icon --> */}
              <svg
                stroke="currentColor"
                fill="#3699ff"
                strokeWidth="0"
                viewBox="0 0 16 16"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M1.5 13A1.5 1.5 0 003 14.5h8a1.5 1.5 0 001.5-1.5V9a.5.5 0 00-1 0v4a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5V5a.5.5 0 01.5-.5h4a.5.5 0 000-1H3A1.5 1.5 0 001.5 5v8zm7-11a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v5a.5.5 0 01-1 0V2.5H9a.5.5 0 01-.5-.5z"
                  clipRule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M14.354 1.646a.5.5 0 010 .708l-8 8a.5.5 0 01-.708-.708l8-8a.5.5 0 01.708 0z"
                  clipRule="evenodd"
                />
              </svg>
              {/* <!--end::Svg Icon--> */}
            </span>
          </button>
        </td>
      </tr>
    ));
  }

  return (
    showDetail ? (
      <>
        <Accordion
          title="Cloud Usage Framework"
          eventKey="cuf"
          defaultOpen="true"
        >
          {data.score ? (
            <>
              <Gauge data={data.score} name="Cloud Usage Framework" />
              {data.failedTests && data.failedTests.length ? (
                <div className="table-responsive">
                  <table className="table arcus-table table-responsive-sm">
                    <thead>
                      <tr>
                        <th>Rule</th>
                        <th>Exception</th>
                        <th>Failed</th>
                        <th>Total</th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>{renderFailedTests(data.failedTests)}</tbody>
                  </table>
                </div>
              ) : (
                <Alert
                  alertClass="info"
                  message={<strong>100% Compliant</strong>}
                />
              )}
            </>
          ) : (
            ''
          )}
        </Accordion>
        {isModalOpen && renderModal()}
      </>
    )
      : <Loader />
  );
};
export default SubscriptionComplianceTab;
