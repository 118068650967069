import React, { useState } from 'react';
import Alert from '../../Common/Alert';
import Gauge from '../../Common/Gauge';
import Accordion from '../../Common/Accordion';
import Popup from '../../Common/Popup';

const ComplianceAccordion = (props) => {
  const {
    data, type, defaultOpen,
  } = props;

  const [detail, setDetail] = useState();
  const [isModalOpen, setModalOpen] = useState(false);

  function renderFailedTests(complianceTests) {
    return complianceTests.map((c) => (
      <tr key={c.rule.ruleId}>
        <td>{c.rule.name}</td>
        <td>{c.exclusionCount}</td>
        <td>{c.nonComplyingCount}</td>
        <td>{c.testedCount}</td>
        <td>
          <button
            type="button"
            onClick={(e) => { setDetail(c, e); setModalOpen(true); }}
            className="btn btn-link m-0 p-0"
          >
            <span className="svg-icon svg-icon-xl svg-icon-primary">
              {/* <!--begin::Svg Icon --> */}
              <svg
                stroke="currentColor"
                fill="#3699ff"
                strokeWidth="0"
                viewBox="0 0 16 16"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M1.5 13A1.5 1.5 0 003 14.5h8a1.5 1.5 0 001.5-1.5V9a.5.5 0 00-1 0v4a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5V5a.5.5 0 01.5-.5h4a.5.5 0 000-1H3A1.5 1.5 0 001.5 5v8zm7-11a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v5a.5.5 0 01-1 0V2.5H9a.5.5 0 01-.5-.5z"
                  clipRule="evenodd"
                />
                <path
                  fillRule="evenodd"
                  d="M14.354 1.646a.5.5 0 010 .708l-8 8a.5.5 0 01-.708-.708l8-8a.5.5 0 01.708 0z"
                  clipRule="evenodd"
                />
              </svg>
              {/* <!--end::Svg Icon--> */}
            </span>
          </button>
        </td>
      </tr>
    ));
  }
  /* eslint-disable react/no-array-index-key */
  const fixNewLine = (text) => text.split('\n').map((i, x) => <p key={x}>{i}</p>);
  /* eslint-enable */

  function renderModal() {
    const { rule, entityResults } = detail;
    return (
      <Popup
        title={rule.name}
        size="xl"
        onClose={() => setModalOpen(false)}
      >
        <code>{rule.ruleId}</code>
        <p>{rule.description}</p>
        <h5 className="text-info">Remediation</h5>
        <blockquote className="text-muted">
          {fixNewLine(rule.remediation)}
        </blockquote>
        <table className="table">
          <tr>
            <th>Id</th>
            <th>Entity Type</th>
            <th>ARN</th>
          </tr>
          {entityResults.map((r, i) => (
            /* eslint-disable react/no-array-index-key */
            <tr key={i}>
              <td>
                {r.testObj.id}
              </td>
              <td>
                <span className="badge badge-dark text-white badge-pill">
                  {r.testObj.entityType}
                </span>
              </td>
              <td>
                {r.testObj.vendorIdentifier}
              </td>
            </tr>
            /* eslint-enable */
          ))}
        </table>
      </Popup>
    );
  }

  return (
    <>
      <Accordion
        title={type.long}
        eventKey="cuf"
        defaultOpen={defaultOpen}
      >
        {data.score ? (
          <div>
            <Gauge data={data.score} name={type.long} />
            {data.failedTests && data.failedTests.length ? (
              <div className="table-responsive">
                <table className="table arcus-table table-responsive-sm">
                  <thead>
                    <tr>
                      <th>Rule</th>
                      <th>Exception</th>
                      <th>Failed</th>
                      <th>Total</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>{renderFailedTests(data.failedTests)}</tbody>
                </table>
              </div>
            ) : (
              <Alert
                alertClass="info"
                message={<strong>100% Compliant</strong>}
              />
            )}
          </div>
        ) : (
          ''
        )}
      </Accordion>
      {isModalOpen && renderModal()}
    </>
  );
};
export default ComplianceAccordion;
