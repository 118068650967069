/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'

const Message = (props) => {
    const { level, message } = props

    let display = message instanceof Error ? `Oops. An Unknown error occurred. Please make sure you are connected to the internet. Details : ${message.name}::${message.message}` : message

    return (
        <div className={`alert alert-${level}`} role="alert">
            {display}
        </div>
    )
}

Message.propTypes = {
    level: PropTypes.oneOf(['warning', 'info', 'danger', 'success', 'primary', 'secondary']).isRequired,
    message: PropTypes.any.isRequired
}

export default Message
