import axios from 'axios';

/* eslint-disable import/prefer-default-export */
export const getNetworkCompute = (apiUrl, getType, errType) => async (dispatch) => {
  /* eslint-enable */
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: getType,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: errType,
      payload: error,
    });
  }
};
