import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import Alert from '../../Common/Alert';

const CostMtd = (props) => {
  const [chart, setChart] = useState(null);
  const {
    summaryData, detailData, accountId, asOf, isOpen, onClick,
  } = props;

  const createPieSeries = () => {
    const combineThreshold = 50.0;
    const sums = {};

    detailData.forEach((d) => {
      if (sums[d.serviceName]) {
        sums[d.serviceName] += d.mtdSpend;
      } else {
        sums[d.serviceName] = d.mtdSpend;
      }
    });

    let pieData = Object.keys(sums).map((key) => ({
      name: key,
      y: Math.round(sums[key] * 100) / 100,
    }));

    if (pieData.length >= 5) {
      const other = pieData
        .filter((d) => d.y <= combineThreshold)
        .reduce((a, b) => ({ name: 'Other', y: a.y + b.y }));
      pieData = pieData.filter((d) => d.y > combineThreshold);
      pieData.push({
        name: 'Other',
        y: Math.round(other.y * 100) / 100,
      });
    }

    pieData = pieData.sort((a, b) => (a.name > b.name ? 1 : 0));

    return {
      type: 'pie',
      name: 'MTD By Service',
      data: pieData,
      center: ['15%', '20%'],
      size: '50%',
      showInLegend: true,
      dataLabels: {
        enabled: false,
      },
    };
  };

  const createAreaSeries = () => ({
    name: 'Month to Date Spend',
    showInLegend: false,
    color: '#28a745',
    data: summaryData.map((gd) => Math.round(gd.spend * 100) / 100),
  });

  const createCategories = () => summaryData.map((gd) => gd.date);

  const chartLabelFormatter = (e) => {
    const { value } = e;
    return `$${value}`;
  };

  const createOptions = () => {
    const categories = createCategories();
    if (categories.length === 0) {
      return;
    }

    const pieSeries = createPieSeries();
    const areaSeries = createAreaSeries();
    setChart({
      chart: {
        type: 'area',
        style: {
          fontFamily: 'Poppins, Helvetica, sans-serif',
        },
        scrollablePlotArea: {
          minWidth: 700,
        },
      },
      legend: {
        align: 'left',
        // verticalAlign: 'top',
        alignColumns: false,
        x: 40,
        itemWidth: 140,
        width: '100%',
        itemStyle: {
          fontWeight: 'normal',
        },
      },
      xAxis: {
        text: 'Date',
        categories,
      },
      title: {
        text: '',
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Spend',
        },
        labels: {
          formatter: chartLabelFormatter,
        },
      },
      credits: {
        enabled: true,
        href: 'https://account.activedirectory.windowsazure.com/applications/redirecttofederatedapplication.aspx?Operation=SignIn&applicationId=b6d225fa-39e9-47c4-b4e0-524827757361&ApplicationConstName=customappsso&SingleSignOnType=Federated&ApplicationDisplayName=CSG%20Cloudability&tenantId=194a267b-82ba-4fa6-b6b9-f95966ab3815',
        text: `Cloudability Cumulative MTD for ${accountId}`,
      },
      series: [areaSeries, pieSeries],
      /* eslint-disable */
      tooltip: {
        formatter() {
          return this.x === undefined
            ? `${this.point.name}<br/>`
                + `<span style="color:${this.color};">●</span> `
                + `MTD By Service: <b>$ ${this.y.toLocaleString()}<b/>`
            : `${this.x}<br/>`
                + '<span style="color:#28a745;">●</span> '
                + `Month to Date Spend: <b>$ ${this.y.toLocaleString()}<b/>`;
        },
      },
      /* eslint-disable */
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                enabled: false,
              },
              yAxis: {
                title: {
                  text: '',
                },
              },
            },
          },
        ],
      },
    });
  };

  useEffect(() => {
    if (
      summaryData
      && detailData
      && summaryData.length > 0
      && detailData.length > 0
    ) {
      createOptions();
    }
  }, [summaryData, detailData]);

  useEffect(() => {
    if (asOf) {
      createOptions();
    }
  }, [accountId, asOf]);

  return (
    <>
      <div className={`card border-0 ${isOpen ? 'card-active' : ''}`}>
        <div className="card-header card-header2" id="headingOne">
          <h5 className="mb-0">
            <button
              type="button"
              className={`btn btn-link ${isOpen ? '' : 'collapsed'}`}
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
              onClick={() => onClick('mtd')}
            >
              Cumulative MTD
            </button>
          </h5>
        </div>
        <div
          id="collapseOne"
          className={`collapse ${isOpen ? 'show' : ''}`}
          aria-labelledby="headingOne"
          data-parent="#accordion"
        >
          <div className="card-body px-0">
            {chart ? (
              <HighchartsReact
                constructorType="chart"
                highcharts={Highcharts}
                options={chart}
              />
            ) : (
              <>
                <Alert
                  message="No MTD is available to graph."
                  alertClass="info"
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

CostMtd.propTypes = {
  accountId: PropTypes.string.isRequired,
  asOf: PropTypes.string.isRequired,
  summaryData: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      spend: PropTypes.number.isRequired,
    }),
  ).isRequired,
  detailData: PropTypes.arrayOf(
    PropTypes.shape({
      usageFamily: PropTypes.string.isRequired,
      serviceName: PropTypes.string.isRequired,
      previousMonthSpend: PropTypes.number.isRequired,
      mtdSpend: PropTypes.number.isRequired,
      estimatedSpend: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default CostMtd;
