import React from 'react';
import { Link } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';

const AccessByAccount = (props) => {
  const {
    isOpen, accountWithRole, subscriptionWithRole, onClick, env,
  } = props;

  const renderStarts = (number) => {
    const lis = [];
    for (let i = 0; i < number; i++) {
      lis.push(<FontAwesomeIcon key={i} icon={faStar} />);
    }
    return lis;
  };

  function handleClick(value) {
    onClick(value);
  }
  function mapSubscriptionList(key, mapper) {
    const list = subscriptionWithRole;
    const ret = {};
    list.forEach((i) => {
      const index = i[key];
      ret[index] = mapper(i, ret[index]);
    });
    return ret;
  }

  function mapAccountList(key, mapper) {
    const list = accountWithRole;
    const ret = {};
    list.forEach((i) => {
      const index = i[key];
      ret[index] = mapper(i, ret[index]);
    });
    return ret;
  }

  function mapAccount() {
    const key = env === 'aws' ? 'accountNumber' : 'subscriptionId';
    return env === 'aws' ? mapAccountList(key, (i, current) => {
      if (!current) {
        current = {
          roles: [],
          accountNumber: i[key],
          name: i.accountName,
          maxTrainingLevelAchieved: i.maxTrainingLevelAchieved,
          maxTrainingLevelRankAchieved: i.maxTrainingLevelRankAchieved,
        };
      }
      current.roles.push({
        code: i.roleCode,
        name: i.roleName || i.roleCode,
        minimumTrainingLevel: i.roleMinimumTrainingLevel,
        minimumTrainingLevelRank: i.roleMinimumTrainingLevelRank,
      });
      return current;
    }) : (
      mapSubscriptionList(key, (i, current) => {
        if (!current) {
          current = {
            roles: [],
            subscriptionId: i[key],
            name: i.subscriptionName,
            maxTrainingLevelAchieved: i.maxTrainingLevelAchieved,
            maxTrainingLevelRankAchieved: i.maxTrainingLevelRankAchieved,
          };
        }
        current.roles.push({
          code: i.roleCode,
          name: i.roleName || i.roleCode,
          minimumTrainingLevel: i.roleMinimumTrainingLevel,
          minimumTrainingLevelRank: i.roleMinimumTrainingLevelRank,
        });
        return current;
      })
    );
  }

  function accountMarkup() {
    const byAccount = mapAccount();
    if (Object.keys(byAccount).length > 0) {
      return env === 'aws'
        ? Object.keys(byAccount).map((accountNumber) => {
          const account = byAccount[accountNumber];
          const { roles } = account;
          return (
            <div className="by-account" key={accountNumber}>
              <div className="by-account-header">
                <div className="d-flex align-items-center justify-content-between font-size-lg xs-byAccount">
                  <div className="m-0 mr-5 font-size-md font-weight-500 ">
                    {account.name}
                  </div>
                  <Link
                    to={`/accounts/${account.accountNumber}`}
                    className="text-primary font-weight-500 ml-xs-5"
                  >
                    {account.accountNumber}
                  </Link>
                </div>
              </div>
              <div className="by-account-body">
                {roles.map((item) => {
                  const achieved = account.maxTrainingLevelRankAchieved >= item.minimumTrainingLevelRank;
                  const color = achieved ? 'success' : 'danger';
                  const achievedColor = achieved ? 'warning' : 'white';
                  return (
                    <div
                      key={account.accountNumber}
                      className="d-flex align-items-center justify-content-between font-size-lg pd-btm-5"
                    >
                      <div className="m-0 mr-5 text-dark text-hover-primary mb-1 font-size-lg font-weight-500">
                        {item.name}
                      </div>
                      <div className={`badge badge-${color} accounts-role-rank`}>
                        <span className="d-flex align-items-center font-size-lg font-weight-500 mr-2 text-white">
                          {item.minimumTrainingLevel}
                          <span
                            className={`star-${achievedColor} d-flex align-items-center text-secondary font-size-sm ml-2`}
                          >
                            {renderStarts(item.minimumTrainingLevelRank)}
                          </span>
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }) : (
          Object.keys(byAccount).map((subscriptionId) => {
            const subscription = byAccount[subscriptionId];
            const { roles } = subscription;
            return (
              <div className="by-account" key={subscriptionId}>
                <div className="by-account-header">
                  <div className="font-size-lg xs-byAccount">
                    <div className="m-0 mr-5 font-size-md font-weight-500 ">
                      {subscription.name}
                    </div>
                    <Link
                      to={`/subscriptions/${subscription.subscriptionId}`}
                      className="text-primary font-weight-500 ml-xs-5 small"
                    >
                      {subscription.subscriptionId}
                    </Link>
                  </div>
                </div>
                <div className="by-account-body">
                  {roles.map((item) => {
                    const achieved = subscription.maxTrainingLevelRankAchieved >= item.minimumTrainingLevelRank;
                    const color = achieved ? 'success' : 'danger';
                    const achievedColor = achieved ? 'warning' : 'white';
                    return (
                      <div
                        key={subscription.name}
                        className="d-flex align-items-center justify-content-between font-size-lg pd-btm-5"
                      >
                        <div className="m-0 mr-5 text-dark text-hover-primary mb-1 font-size-lg font-weight-500">
                          {item.name}
                        </div>
                        <div className={`badge badge-${color} accounts-role-rank`}>
                          <span className="d-flex align-items-center font-size-lg font-weight-500 mr-2 text-white">
                            {item.minimumTrainingLevel}
                            <span
                              className={`star-${achievedColor} d-flex align-items-center text-secondary font-size-sm ml-2`}
                            >
                              {renderStarts(item.minimumTrainingLevelRank)}
                            </span>
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })
        );
    }
    return '';
  }

  return (
    <div className={`card border-0 ${isOpen ? 'card-active' : ''}`}>
      <div className="card-header card-header2" id="headingTwo">
        <h5 className="mb-0">
          <button
            type="button"
            className={`btn btn-link ${!isOpen ? 'collapsed' : ''}`}
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="false"
            aria-controls="collapseOne"
            onClick={() => handleClick('byAccount')}
          >
            {env === 'aws' ? 'By Account' : 'By Subscription'}
          </button>
        </h5>
      </div>
      <div
        id="collapseTwo"
        className={`collapse ${isOpen ? 'show' : ''}`}
        aria-labelledby="headingTwo"
        data-parent="#accordion"
      >
        <div className="card-body px-0">
          <div className="account-section-tab">{accountMarkup()}</div>
        </div>
      </div>
    </div>
  );
};

AccessByAccount.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  accountWithRole: PropTypes.instanceOf(Array).isRequired,
  subscriptionWithRole: PropTypes.instanceOf(Array).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default AccessByAccount;
