import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import noData from '../../Images/no-data.png';

const BasicBarChart = (props) => {
  const { barChartSeries, barChartCategories, valueName } = props;

  const barCharWidth = (num) => {
    switch (true) {
      case num < 50:
        return '100%';
      case num < 100:
        return '2000';
      case num < 150:
        return '4000';
      case num < 300:
        return '5000';
      default:
        return '6000';
    }
  };
  const initialChartOptions = {
    chart: {
      type: 'basic-bar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '50%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    yaxis: {
      title: {
        text: valueName,
      },
      xaxis: {
        categories: barChartCategories,
        labels: {
          trim: true,
          hideOverlappingLabels: false,
          style: {
            fontSize: '10px',
          },
        },
      },

      legend: {
        show: true,
        position: 'top',
        fontSize: '8px',
        horizontalAlign: 'center',
      },
      responsive: [
        {
          breakpoint: 767,
          options: {
            xaxis: {
              labels: {
                show: false,
              },
            },
            yaxis: {
              labels: {
                show: false,
              },
              title: {
                text: valueName,
              },
            },
          },
        },
      ],
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter(val) {
            return `${val}`;
          },
        },
      },
    },
  };
  const [chartOptions, setChartOptions] = useState(initialChartOptions);
  const [series, setSeries] = useState([{ name: valueName, data: barChartSeries }]);
  const [dataFound, setDataFound] = useState(barChartSeries.length > 0);

  useEffect(() => {
    setSeries([{ name: valueName, data: barChartSeries }]);
    setDataFound(barChartSeries.length > 0);
  }, [barChartSeries]);

  useEffect(() => {
    setChartOptions({
      ...chartOptions,
      xaxis: {
        categories: barChartCategories,
        labels: {
          trim: true,
          hideOverlappingLabels: false,
          style: {
            fontSize: '10px',
          },
        },
      },
    });
  }, [barChartCategories]);

  return (
    <>
      {dataFound ? (
        <div className="sankey-graph">
          <Chart options={chartOptions} series={series} type="bar" height="400" width={barCharWidth(barChartSeries.length)} />
        </div>
      ) : (
        <div>
          <img src={noData} className="img-fluid d-block m-auto w-10" alt="" />
          <p className="text-center mt-10 font-size-lg font-weight-normal text-muted">
            No Data available.
          </p>
        </div>
      )}
    </>
  );
};
export default BasicBarChart;
