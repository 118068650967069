import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRegionService } from '../../../store/actions/vendorAction';
import HeaderWithInfo from '../../Common/HeaderWithInfo';
import Loader from '../../Loader';
import SearchFilter from './Filters';
import ServicesResults from './Results';
import Alert from '../../Common/Alert';

// Contexts
import { Context } from '../../../Context/VendorContext';

const ServicesDisplay = () => {
  const dispatch = useDispatch();
  const vendorInfo = useSelector((state) => state.vendorInfo);
  const { regionServices, regionServicesLoading, errorRegionServices } = vendorInfo;

  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  // Get filter data from Vendor Services Context
  const [serviceFilter, setServiceFilter] = useContext(Context);
  const { tempUnavailableList, displayMode, loadedResult } = serviceFilter;

  useEffect(() => {
    const apiUrl = '/api/Vendor/GetRegionService';
    dispatch(getRegionService(apiUrl));
  }, [dispatch]);
  useEffect(() => {
    if (errorRegionServices !== '' && errorRegionServices !== undefined) {
      setErrorMessage(errorRegionServices);
      setShowError(true);
    }
  }, [errorRegionServices]);
  function populateData() {
    const list = regionServices;
    const servicesOptions = [];
    for (let i = 0; i < list.length; i++) {
      if (servicesOptions.filter((j) => j.label === list[i].serviceName).length === 0) {
        servicesOptions.push({
          value: list[i].serviceName,
          label: list[i].serviceName,
          url: list[i].serviceUrl,
        });
      }
    }

    const regionsOptions = [];
    for (let r = 0; r < list.length; r++) {
      if (regionsOptions.filter((item) => item.value === list[r].regionCode).length === 0) {
        regionsOptions.push({
          value: list[r].regionCode,
          label: list[r].regionName,
        });
      }
    }
    setServiceFilter({
      ...serviceFilter,
      regionServices,
      regions: regionsOptions,
      services: servicesOptions,
      filteredRegions: regionsOptions,
      filteredService: servicesOptions,
      loadedResult: true,
    });
  }
  useEffect(() => {
    if (regionServices.length > 0) {
      populateData();
    }
  }, [regionServices]);

  const infoMessage = (
    <>
      <p className="m-0">All services are up and running in selected Region</p>
    </>
  );
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card card-custom mb-4">
            <div className="card-header align-items-center">
              <div className="card-title">
                <HeaderWithInfo headerName="AWS Service Availability" />
              </div>
            </div>
            <div className="card-body">
              {regionServicesLoading ? <Loader /> : (!showError && errorMessage === '' && <SearchFilter />)}
            </div>
          </div>
        </div>
      </div>
      { (displayMode === 'region' && tempUnavailableList.length === 0 && loadedResult)
      && (
      <div className="row">
        <div className="col-12">
          <div className="card card-custom mb-4 alert-m-0">
            <Alert
              message={infoMessage}
              alertClass="info"
            />
          </div>
        </div>
      </div>
      )}
      {regionServicesLoading ? '' : (!showError && errorMessage === '' && <ServicesResults />)}
      {showError && (
      <Alert
        message={errorMessage}
        alertClass="light-danger"
        onClose={() => setShowError(false)}
      />
      )}
    </>
  );
};

export default ServicesDisplay;
