import React from 'react';

const PeopleInfo = () => (
  <div className="row">
    <div className="col-12">
      All account access requires Flight Crew training to have been completed.
      See the
      {' '}
      <a
        href="https://confluence.csgicorp.com/display/AWS/Training+for+Cloud"
        target="_blank"
        rel="noopener noreferrer"
      >
        training guidelines
      </a>
      {' '}
      for more information.
    </div>
    <div className="col-12">
      <h6 className="custom-tooltip-heading">
        Some special level search filters are defined below:
      </h6>
      <ul className="custom-tooltip-info-list">
        <li>
          <strong>No training</strong>
          {' '}
          - no training of any kind has been
          completed. (ignores all other filters)
        </li>
        <li>
          <strong>Missing Required</strong>
          {' '}
          -Either Passenger training has not
          completed or the person has access to an account and Flight Crew
          training as not been completed.
        </li>
        <li>
          <strong>Under-trained</strong>
          {' '}
          - granted access to accounts via roles
          that require a higher level of access than the current completed
          training.
        </li>
      </ul>
    </div>
  </div>
);

export default PeopleInfo;
