import React, { useState } from 'react';
import PropTypes from 'prop-types';
import nextId from 'react-id-generator';

const CheckboxList = (props) => {
  const [id] = useState(nextId());
  const {
    name,
    title,
    onChange,
    options,
    handleChecked,
    tabIndexStart,
    errorText,
    disabled,
  } = props;

  let className = 'form-check-input';
  if (errorText) {
    className += ' is-invalid';
  }

  const optDefaults = {
    className,
    type: 'checkbox',
    name,
  };

  if (onChange && onChange instanceof Function) {
    optDefaults.onChange = onChange;
  }

  const keys = Object.keys(options || {});
  const lastIndex = keys.length - 1;

  const markup = keys.map((option, index) => {
    const label = options[option];

    const opts = {
      id: `${id}+${index}`,
      value: option,
    };
    if (handleChecked && handleChecked instanceof Function) {
      opts.checked = handleChecked(name, option);
    }

    if (tabIndexStart) {
      opts.tabIndex = tabIndexStart + index;
    }

    if (disabled) {
      opts.disabled = disabled;
    }

    const inErr = errorText && lastIndex === index;

    return (
      <div className="form-check" key={option}>
        <input {...opts} {...optDefaults} />
        <label htmlFor={opts.id} className="form-check-label">
          {label}
        </label>
        {inErr && <div className="invalid-feedback">{errorText}</div>}
      </div>
    );
  });
  return (
    <div>
      <label className="form-label">{title}</label>
      <div className="form-group">{markup}</div>
    </div>
  );
};
/* eslint-disable */
CheckboxList.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  handleChecked: PropTypes.func,
  tabIndexStart: PropTypes.number,
  errorText: PropTypes.string,
};

export default CheckboxList;
