import React from 'react';
import { Link } from '@reach/router';
import noData from '../../Images/no-data.png';

const HealthEventWidget = ({ awsHealthEventList }) => (
  <div className="col-lg-12">
    <div className="card card-custom gutter-b h-100">
      <div className="card-header align-items-center border-0 mt-4">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label">AWS Health Event</h3>
          {awsHealthEventList.length > 0 ? (
            <span className="text-muted mt-2 font-weight-500 font-size-sm">
              {`List of (${awsHealthEventList.length}) AWS Health Event. To view more`}
              <Link to="/awshealthevents"> click here.</Link>
            </span>
          ) : ''}
        </div>
      </div>
      <div className="card-body">
        {awsHealthEventList.length > 0 ? (
          <div className="network-table network-responsive aws-health-responsive">
            <div className="d-flex- justify-content-between- network-table-row row">
              <div className="col-12">
                <div className="d-flex">
                  <div className="network-table-data col-md-2 col-sm-3 col-4">Event Type</div>
                  <div className="network-table-data col-md-2 col-sm-3 col-4">Event Code</div>
                  <div className="network-table-data col-md-2 col-sm-3 col-3">Region</div>
                  <div className="network-table-data col-md-2 col-sm-2 col-5">Status Code</div>
                  <div className="network-table-data col-md-2 col-sm-2 col-4">Start Time</div>
                  <div className="network-table-data col-md-2 col-sm-2 col-5">End Time</div>
                </div>
              </div>
            </div>
            {awsHealthEventList.length > 0
            && awsHealthEventList.slice(0, 4).map((x) => (
              <div className="d-flex networkBorder" key={x.eventTypeCode}>
                <div className="col-md-2 col-sm-3 col-4 pl-0">
                  <div className="network-table-td pl-3">
                    <span className="font-weight-500 font-size-sm">{x.eventTypeCategory.value}</span>
                  </div>
                </div>
                <div className="col-md-2 col-sm-3 col-4">
                  <div className="network-table-td">
                    <span className="font-weight-500 font-size-sm">{x.eventTypeCode}</span>
                  </div>
                </div>
                <div className="col-md-2 col-sm-3 col-3">
                  <div className="network-table-td">
                    <span className="font-weight-500 font-size-sm">{x.region}</span>
                  </div>
                </div>
                <div className="col-md-2 col-sm-2 col-5">
                  <div className="network-table-td">
                    <span className="font-weight-500 font-size-sm">{x.statusCode.value}</span>
                  </div>
                </div>
                <div className="col-md-2 col-sm-2 col-4">
                  <div className="network-table-td">
                    <span className="font-weight-500 font-size-sm">{x.startTime}</span>
                  </div>
                </div>
                <div className="col-md-2 col-sm-2 col-5">
                  <div className="network-table-td">
                    <span className="font-weight-500 font-size-sm">{x.endTime === undefined ? '-' : x.endTime}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )
          : (
            <div className="card-body card-empty">
              <img src={noData} className="img-fluid d-block m-auto w-50-" width="200" alt="" />
              <p className="text-center mt-10 font-size-lg font-weight-normal text-muted">You don&apos;t have any AWS Health Event List available</p>
            </div>
          )}
      </div>
    </div>
  </div>
);

export default HealthEventWidget;
