import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import Alert from '../../Common/Alert';

const ProductAnnouncementsDisplay = ({ list }) => {
  const [allCateg, setAllCateg] = useState([]);
  const [announcByCat, setAnnouncByCat] = useState([]);
  const [selectedCateg, setSelectedCateg] = useState({ code: 'all', id: 0 });
  const [selectedAnnoun, setSelectedAnnoun] = useState('');
  const scrollToTopAnnoun = useRef(null);

  const rssErrorDisplay = (
    <div>
      Error with AWS announcements RSS feed
      <br />
      <span>
        RSS feed can be accessed at
        <a href="https://aws.amazon.com/about-aws/whats-new/recent/feed/" target="blank">
          this link
        </a>
      </span>
    </div>
  );

  useEffect(() => {
    // List of all category types, no repeat entries
    const allCategories = ['all'];
    // Will be a 2D array with each entry being a list of announcements.
    // Each list will have a category in common, and the index of the
    // List in this 2D array will match the index of the shared category in the allCategories array
    const announcementsByCategory = [list];
    /* eslint-disable vars-on-top, no-var */

    for (var i = 0; i < list.length; i++) {
      for (var j = 0; j < list[i].categories.length; j++) {
        // Flag to check if this announcement gets added to the list for the current category being iterated over
        var wasAddedToList = false;
        for (var k = 0; k < allCategories.length; k++) {
          if (list[i].categories[j] === allCategories[k]) {
            wasAddedToList = true;
            announcementsByCategory[k].push(list[i]);
          }
        }
        // This being triggered means this is the first time this category has appeared and a new list must be initialized
        // With a corresponding category in the list
        if (!wasAddedToList) {
          allCategories.push(list[i].categories[j]);
          /* eslint-disable block-scoped-var */
          announcementsByCategory[k] = [list[i]];
          /* eslint-enable */
        }
      }
    }
    /* eslint-enable vars-on-top, no-var */
    setAllCateg(allCategories);
    setAnnouncByCat(announcementsByCategory);
    if (announcementsByCategory.length > 0) {
      setSelectedAnnoun(announcementsByCategory[0][0].id);
    }
  }, [list]);

  return (
    <>
      {list.length > 0
        ? (
          <div className="product-announcement-section product-announcement-style1 ">
            {/* eslint-disable react/no-array-index-key */}
            <div className="product-list-card nav nav-tabs tabs-left hover-scroll-overlay-y">
              {allCateg.length > 0 && allCateg.map((cat, index) => (
                <div
                  key={index}
                  className={`aside-list ${selectedCateg.code === cat ? 'active' : ''}`}
                  data-toggle="tab"
                  onClick={() => {
                    scrollToTopAnnoun.current.scrollTo({ top: 0, behavior: 'smooth' });
                    setSelectedCateg({ code: cat, id: index });
                    setSelectedAnnoun(announcByCat[index][0].id);
                  }}
                >
                  <div className="aside-catg text-capitalize">{cat.replaceAll('-', ' ')}</div>
                  <span className="aside-list-badge">{announcByCat[index].length}</span>
                </div>
              ))}
            </div>
            <div ref={scrollToTopAnnoun} className="product-detail-card tab-content product-detail-hover-scroll-overlay-y">
              {announcByCat.length > 0 && announcByCat.map((announ, index) => (
                <div className={`tab-pane ${selectedCateg.id === index ? 'active' : ''}`} key={`ann ${index}`}>
                  <div className="row">
                    <div className="col-12">
                      <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">

                        {announ.length > 0 && announ.map((ele) => (
                          <div className="panel panel-default" key={ele.id}>
                            <div className="panel-heading" role="tab">
                              <h4 className="panel-title">
                                <div
                                  role="button"
                                  className={`data-toggle-btn ${selectedAnnoun !== ele.id ? 'collapsed' : ''}`}
                                  onClick={() => setSelectedAnnoun(ele.id === selectedAnnoun ? 0 : ele.id)}
                                  data-toggle="collapse"
                                  data-parent="#accordion"
                                  aria-expanded="true"
                                  tabIndex={0}
                                  aria-controls="collapseOne"
                                >
                                  <span>{ele.title}</span>
                                  <span className="date-text-label font-size-sm text-muted">{ele.date}</span>
                                </div>
                              </h4>
                            </div>
                            <div
                              id="collapseOne"
                              className={`panel-collapse collapse ${selectedAnnoun === ele.id ? 'show' : ''}`}
                              role="tabpanel"
                              aria-labelledby="headingOne"
                            >
                              <div className="panel-body">
                                <div className="p-5">
                                  <div className="d-flex flex-wrap">
                                    {ele.tags.length > 0 && ele.tags.map((tg, ind) => (
                                      <span key={ind} className="announcement-tags">{tg}</span>
                                    ))}
                                  </div>
                                  <p className="my-3">{ele.description}</p>
                                  <a href={ele.link} className="annoucement-link font-size-lg" target="_blank" rel="noreferrer">
                                    Enternal link&nbsp;
                                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {/* eslint-enable react/no-array-index-key */}

          </div>
        )
        : (
          <Alert
            message={rssErrorDisplay}
            alertClass="info"
          />
        )}
    </>
  );
};

export default ProductAnnouncementsDisplay;
