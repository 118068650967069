import React, { useState, useEffect, useContext } from 'react';
import Chart from 'react-google-charts';
import ApiDropDown from '../Common/ApiDropdown';
import Checkbox from '../Common/Checkbox';
import Loader from '../Loader';
import { Context } from '../../Context/CloudabilityContext';
import noData from '../../Images/no-data.png';

import './Cloudability.css';

const CloudabilityRegionCost = (props) => {
  function formatMoney(number) {
    return Math.round(number)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
  const { serviceCosts, totalCost } = props;
  const [regionData, setRegionData] = useState(serviceCosts);
  const [totalRegionCost, setTotalRegionCost] = useState(formatMoney(totalCost));
  const [srvCostByRegion, setSrvCostByRegion] = useState();
  const [loaded, setloaded] = useState(false);
  const [usageFamilyNeed, setUsageFamilyNeed] = useState(true);

  const [cloudabilityFilter, setCloudabilityFilter] = useContext(Context);
  const {
    currentView, timePeriods, region, filterLoaded,
  } = cloudabilityFilter;

  // This function will add total cost spend by perticular services and usage family and region and concatinate with their label
  function addTotalCostLabel(data) {
    const nodes = [0, 1];
    /* eslint-disable */
    for (var a in nodes) {
      data.forEach((o) => {
        if (o[0] !== 'From') {
          const newArray = data
            .filter((e) => e[a].split(':')[0] === o[a])
            .map((e) => e[2]);
          let result = 0;
          for (const v in newArray) {
            result = parseFloat(result) + parseFloat(newArray[v]);
          }

          o[a] = `${o[a]}: $${Math.round(result.toFixed(2))
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
        }
      });
    }
    /* eslint-enable */
    return data;
  }

  // This function adding three node in Sankey graph (Services, Usage Family and Region) along with total costing in label
  function serviceUsageFamilyRegionNodes(arr) {
    let order = [['From', 'to', 'Weight']];
    // this loop for first two node of Sankey Graph from service to usage family.
    arr.forEach((o) => {
      if (o.cost > 0.5) {
        const found = order.some(
          (el) => el[0].replace('AWS ', '') === o.name.replace('AWS ', '')
            && el[1] === o.type,
        );
        if (found) {
          const foundIndex = order.findIndex(
            (x) => x[0].replace('AWS ', '') === o.name.replace('AWS ', '')
              && x[1] === o.type,
          );
          order[foundIndex][2] = parseFloat(order[foundIndex][2]) + parseFloat(o.cost);
        } else {
          const cost = parseFloat(o.cost.toFixed(2));
          order.push([o.name.replace('AWS ', ''), o.type, cost]);
        }
      }
    });
    // this loop for second two node of Sankey Graph from usage family to region.
    arr.forEach((o) => {
      if (o.cost > 0.5) {
        const found = order.some((el) => el[0] === o.type && el[1] === o.region);
        if (found) {
          const foundIndex = order.findIndex(
            (x) => x[0] === o.type && x[1] === o.region,
          );
          order[foundIndex][2] = parseFloat(order[foundIndex][2]) + parseFloat(o.cost);
        } else {
          const cost = parseFloat(o.cost.toFixed(2));
          order.push([o.type, o.region, cost]);
        }
      }
    });
    order = addTotalCostLabel(order);
    setSrvCostByRegion(order);
    setloaded(order.length > 1);
  }

  // This function adding two node in Sankey graph (Services and Region) along with total costing in label
  function serviceRegionNodes(arr) {
    let order = [['From', 'to', 'Weight']];
    arr.forEach((o) => {
      if (o.cost > 0.5) {
        const found = order.some(
          (el) => el[0].replace('AWS ', '') === o.name.replace('AWS ', '')
            && el[1] === o.region,
        );
        if (found) {
          const foundIndex = order.findIndex(
            (x) => x[0].replace('AWS ', '') === o.name.replace('AWS ', '')
              && x[1] === o.region,
          );
          order[foundIndex][2] = parseFloat(order[foundIndex][2]) + parseFloat(o.cost);
        } else {
          const cost = parseFloat(o.cost.toFixed(2));
          order.push([o.name.replace('AWS ', ''), o.region, cost]);
        }
      }
    });
    order = addTotalCostLabel(order);
    setSrvCostByRegion(order);
    setloaded(order.length > 1);
  }

  // In this function will get all top 15 cost spending services and their respective usage family and will push in one array
  function topSpendServices(data, usageFamilyNeed) {
    let topSpendService = [];
    data.forEach((o) => {
      const found = topSpendService.some((el) => el.name === o.name);
      if (found && typeof o.cost === 'number') {
        const foundIndex = topSpendService.findIndex((x) => x.name === o.name);
        topSpendService[foundIndex].cost = parseFloat(
          (
            parseFloat(topSpendService[foundIndex].cost)
            + parseFloat(o.cost.toFixed(2))
          ).toFixed(2),
        );
      } else if (typeof o.cost === 'number') {
        const cost = parseFloat(o.cost.toFixed(2));
        topSpendService.push({ name: o.name, cost });
      }
    });
    topSpendService = topSpendService.slice(0, 12);
    const result = data.filter((o1) => topSpendService.some((o2) => o1.name === o2.name));

    if (usageFamilyNeed) {
      serviceUsageFamilyRegionNodes(result);
    } else {
      serviceRegionNodes(result);
    }
  }

  useEffect(() => {
    const data = regionData.map((x) => {
      x.cost = Number.isNaN(x.cost) ? parseFloat(x.cost.replace('$', '')) : x.cost;
      return x;
    });
    topSpendServices(data, usageFamilyNeed);
    setCloudabilityFilter({ ...cloudabilityFilter, filterLoaded: true });
  }, [regionData, usageFamilyNeed]);

  useEffect(() => {
    setTotalRegionCost(formatMoney(totalCost));
  }, [totalCost]);

  // Handle region filter on change of region dropdown and on tab switch
  function regionFilterHandle(value) {
    let data = null;
    if (value === 'all_region') {
      data = serviceCosts.filter((event) => event);
    } else {
      data = serviceCosts.filter((event) => event.region === value);
    }

    let totalCost = 0;
    const newArray = data.map((event) => event.cost);
    /* eslint-disable */
    for (const v in newArray) {
      totalCost += newArray[v];
    }
    setCloudabilityFilter({ ...cloudabilityFilter, region: value });
    var dataNew = data.map((x) => {
      x.cost = Number.isNaN(x.cost) ? parseFloat(x.cost.replace('$', '')) : x.cost;
      return x;
    });
    /* eslint-enable */
    setRegionData(dataNew);
    setTotalRegionCost(formatMoney(totalCost));
  }

  useEffect(() => {
    if (region === 'all_region') {
      // On load of tab
      setRegionData(serviceCosts);
    } else {
      // On tab switch when region filter is selected
      regionFilterHandle(region);
    }
  }, [serviceCosts]);

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    let params = {};
    if (value === '') {
      return;
    }
    if (name === 'businessUnit' && !filterLoaded) {
      return;
    }
    if (name === 'agreed') {
      setUsageFamilyNeed(!usageFamilyNeed);
    }
    if (name === 'currentView') {
      setCloudabilityFilter({
        ...cloudabilityFilter,
        currentView: value,
        region: 'all_region',
        businessUnit: '',
        filterLoaded: false,
      });
      params = {
        product: `${value}_Services`,
        filterItem: timePeriods,
      };
      props.onChange(params);
    }
    if (name === 'timePeriod') {
      setCloudabilityFilter({
        ...cloudabilityFilter,
        timePeriods: value,
        businessUnit: '',
        region: 'all_region',
        filterLoaded: false,
      });
      params = {
        product: 'all',
        filterItem: value,
      };
      props.onChange(params);
    }
    if (name === 'region') {
      regionFilterHandle(value);
    }
  };

  const options = {
    sankey: {
      node: {
        width: 20,
        height: 150,
        nodePadding: 12,
        label: {
          fontSize: 14,
          color: '#871b47',
          bold: true,
          text: 'hello',
        },
      },
      link: {
        colorMode: 'gradient',
        colors: [
          '#a6cee3',
          '#b2df8a',
          '#fb9a99',
          '#fdbf6f',
          '#cab2d6',
          '#ffff99',
          '#1f78b4',
          '#33a02c',
        ],
      },
    },
  };

  return (
    <div>
      <>
        <div className="tab-pane" id="profile-v">
          <div className="row justify-content-center justify-content-end-xs">
            <div className="col-md-3 col-sm-4 col-12">
              <div className="form-group">
                <ApiDropDown
                  name="currentView"
                  value={currentView}
                  placeholder="Pick One..."
                  title="Current View"
                  url="/api/AscendonCost/CurrentViews"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-3 col-sm-4 col-12">
              <div className="form-group">
                <ApiDropDown
                  name="timePeriod"
                  value={timePeriods}
                  placeholder="Pick One..."
                  title="Pick A Time Period"
                  url="/api/AscendonCost/TimePeriods"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-3 col-sm-4 col-12">
              <div className="form-group">
                <ApiDropDown
                  name="region"
                  value={region}
                  placeholder="Pick One..."
                  title="Current Regions"
                  url="/api/AscendonCost/Regions"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-2 col-sm-5 col-12">
              <div className="custom-control custom-checkbox text-right">
                <Checkbox
                  name="agreed"
                  required
                  onChange={handleChange}
                  title="Usage Family"
                  checked={usageFamilyNeed}
                />
              </div>
            </div>
          </div>
          <div className="row mt-8">
            <div className="col-12">
              <h3 className="text-center">
                AWS Services Total Cost:
                <span>{filterLoaded ? `$${totalRegionCost}` : ''}</span>
              </h3>
            </div>
            <div className="col-12 mt-8">
              {filterLoaded ? (
                loaded ? (
                  <div className="sankey-graph">
                    <Chart
                      options={options}
                      width={1050}
                      node={15}
                      height="350px"
                      chartType="Sankey"
                      loader={<Loader />}
                      data={srvCostByRegion}
                      rootProps={{ 'data-testid': '2' }}
                    />
                  </div>
                ) : (
                  <div>
                    <img
                      alt="noDataImg"
                      src={noData}
                      className="img-fluid d-block m-auto w-50"
                    />
                    <p className="text-center mt-10 font-size-lg font-weight-normal text-muted">
                      Data not available for this Region.
                    </p>
                  </div>
                )
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default CloudabilityRegionCost;
