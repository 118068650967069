/* eslint-disable */
import React from 'react'
import Message from '../Components/Messages/Message'

const AccessDenied = () => (
    <div>
        <Message level="danger" message="You are not allowed access to this section.  Try signing in as a different user." />
    </div>
)

export default AccessDenied