import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import queryString from 'query-string';
import { Context } from '../Context/MenuContext';
import SubscriptionSearch from '../Components/AzureSubscription/Search';
import Loader from '../Components/Loader';
import NoRecordFound from '../Components/Common/NoRecordFound';
import Alert from '../Components/Common/Alert';
import HeaderWithInfo from '../Components/Common/HeaderWithInfo';
import SubscriptionResult from '../Components/Admin/Azure/Requests/Results';
import EditSubscription from '../Components/AzureSubscription/EditSubscription';

const initialState = {
  currentSearch: {
    tenants: [],
    status: [],
  },
};

const AzureSubscriptionEditRequests = (props) => {
  const { user } = props;
  const [loaded, setLoaded] = useState(true);
  const [matchCount, setMatchCount] = useState(0);
  const [showingTotal, setShowingTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [scrolling, setScrolling] = useState(false);
  const [response, setResponse] = useState(false);
  const [results, setResults] = useState([]);
  const [currentSearch, setCurrentSearch] = useState({
    tenants: [],
    status: [],
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [showSubscriptionEditModal, setShowSubscriptionEditModal] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState(null);
  const [subRequestId, setSubRequestId] = useState(null);
  const [subscriptionName, setSubscriptionName] = useState('');
  const [title, setTitle] = useState('Edit an Azure Subscription');

  const [, setSelectedMenu, , setSelectedSubMenu] = useContext(Context);

  useEffect(() => {
    setSelectedMenu('admin');
    setSelectedSubMenu('azuresubscriptioneditrequests');
  }, []);

  const parseQueryString = () => {
    const { location } = props;
    const { search } = location;
    return queryString.parse(search);
  };

  const doSearch = (clear, pgIndex) => {
    const {
      searchType, criteria, tenants, status,
    } = currentSearch;

    if (clear) { setLoaded(false); }
    // search only if criteria or tenants and status is selected
    if (criteria !== undefined) {
      axios
        .get(
          `/api/azuresubscription/search?t=${searchType}&q=${criteria}&e=${tenants}&s=${status}&i=${clear ? 0 : pgIndex}`,
        )
        .then((response) => {
          const result = response.data;
          setResults(clear ? result.items : results.concat(result.items || []));
          setMatchCount(result.matchCount);
          setShowingTotal(result.showingTotal);
          setCount(result.count);
          setPageIndex(result.index);
          setLoaded(true);
          setScrolling(false);
          setResponse(true);
        })
        .catch((err) => {
          if (err.name !== 'AbortError') {
            setErrorMessage(err.message);
            setResponse(true);
          }
        });
    }
  };

  useEffect(() => {
    doSearch(false, pageIndex);
  }, [currentSearch, pageIndex]);

  const nextPage = () => {
    if (scrolling !== true && matchCount > showingTotal) {
      setScrolling(true);
      setPageIndex(pageIndex + 1);
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (Math.round(scrollTop) + clientHeight === scrollHeight) {
      nextPage();
    }
  };

  const executeSearch = (searchType, criteria, status, tenants) => {
    if (
      currentSearch.searchType !== searchType
      || currentSearch.criteria !== criteria
      || currentSearch.status.length !== status.length
      || !currentSearch.status.every((e) => status.includes(e))
      || currentSearch.tenants.length !== tenants.length
      || !currentSearch.tenants.every((e) => tenants.includes(e))
    ) {
      setResults([]);
      setLoaded(false);
      setPageIndex(0);
      setCurrentSearch({
        searchType,
        criteria,
        status,
        tenants,
      });
    }
  };

  const clearSearch = (status, tenants) => {
    const defaultState = initialState;
    const currentSearchClr = {
      ...defaultState.currentSearch,
      status: status || [],
      tenants: tenants || [],
    };
    setResults([]);
    setLoaded(true);
    setScrolling(false);
    setMatchCount(0);
    setShowingTotal(0);
    setCount(0);
    setCurrentSearch(currentSearchClr);
    setErrorMessage('');
    setResponse(false);
  };

  const beginEdit = (e, subReqId, subId, subName) => {
    e.preventDefault();
    setShowSubscriptionEditModal(true);
    setSubRequestId(subReqId);
    setSubscriptionId(subId);
    setSubscriptionName(subName);
    setTitle('Edit an Azure Subscription');
  };

  const qs = parseQueryString();
  return (
    <>
      <div className="row">
        <div className="col-12">
          {/* <!-- Begin::Page Content --> */}
          <div className="card card-custom mb-4">
            <div className="card-header align-items-center">
              <div className="card-title align-items-center justify-content-between w-100">
                <HeaderWithInfo headerName="Azure Subscriptions Edit Request" />
              </div>
            </div>
            <div className="card-body accout-search">
              <div>
                <SubscriptionSearch
                  selectedValue={qs.t}
                  search={qs.q}
                  tenants={qs.e}
                  statuses={qs.s}
                  executeSearch={executeSearch}
                  clearSearch={clearSearch}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        {errorMessage && (
          <div className="col-12">
            <Alert
              message={errorMessage}
              alertClass="light-danger"
              onClose={(val) => setErrorMessage(val)}
            />
          </div>
        )}
      </div>

      {/* <!-- Begin::Search list --> */}
      {loaded ? (
        <>
          {count > 0 && (
            <>
              <div className="row gutter-b">
                <div className="col-12">
                  <div>
                    <h6 className="text-inverse h6">
                      {matchCount > 0 ? (
                        <span>
                          {showingTotal}
                          {' '}
                          of
                          {' '}
                          {matchCount}
                        </span>
                      ) : null}
                    </h6>
                    <SubscriptionResult subscription={results} onScroll={handleScroll} user={user} beginEdit={beginEdit} />
                    {scrolling ? <Loader /> : null}
                  </div>
                </div>
              </div>
            </>
          )}
          {count === 0 && response
            ? (
              <NoRecordFound />
            ) : ''}
        </>
      ) : ''}
      {showSubscriptionEditModal && (
        <>
          <div className="log-out-layer" />
          <EditSubscription
            saveUrl={`/api/AzureSubscription/${subRequestId || ''}`}
            saveVerb="PUT"
            label="Request Edit Subscription"
            onCancel={() => setShowSubscriptionEditModal(false)}
            title={title}
            subscriptionId={subscriptionId}
            user={user}
            subscriptionName={subscriptionName}
          />
        </>
      )}
    </>
  );
};

export default AzureSubscriptionEditRequests;
