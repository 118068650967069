import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import ApiStats from '../Components/Ascendon/ApiStats';
import Tab from '../Components/Common/Tab';
import { ApiStatsContext } from '../Context/ApiStatsContext';
import { Context as PageContext } from '../Context/PageContext';
import { Context as MnContext } from '../Context/MenuContext';
import { saveUserPageVisitHistory } from '../store/actions/peopleAction';

const AscendonApiStats = (props) => {
  const [tab, setTab] = useState('All');
  const [tabList] = useState([
    { value: 'All', label: 'All' },
    { value: 'Public', label: 'Public' },
    { value: 'Internal', label: 'Internal' },
  ]);

  // Get selectedMenu data from Menu Context
  const [, setSelectedMenu, , setSelectedSubMenu] = useContext(MnContext);
  const { user } = props;
  const dispatch = useDispatch();
  const [pageContextState, setPageContextState] = useContext(PageContext);
  const { apiStatsPage } = pageContextState;

  // Get user login details
  useEffect(() => {
    if (apiStatsPage === '') {
      setPageContextState({
        ...pageContextState,
        apiStatsPage: 'apiStats',
      });
      const apiUrl = `/api/Auth/SaveUserPageVisit/${user.employeeId}/apiStats`;
      dispatch(saveUserPageVisitHistory(apiUrl));
    }
  }, [apiStatsPage]);

  // In case the page is loaded directly from url then change selectedMenu state
  useEffect(() => {
    setSelectedMenu('cost report');
    setSelectedSubMenu('apiStats');
  }, []);

  return (
    <>
      <div className="row gutter-b">
        <div className="col-12">
          <div className="card card-custom gutter-b">
            <div className="card-header align-items-center">
              <div className="card-title align-items-start flex-column">
                <h3 className="card-label">Ascendon API Stats</h3>
              </div>
            </div>

            <div className="card-body p-0">
              <div className="row">
                <div className="col-12">
                  <div className="horizontal-tab">
                    <Tab
                      onClick={(e) => setTab(e)}
                      tabList={tabList}
                      selected="All"
                    />
                    <ApiStatsContext>
                      <div className="tab-content">
                        <ApiStats statType={tab} />
                      </div>
                    </ApiStatsContext>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AscendonApiStats;
