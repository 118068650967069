import React from 'react';
import awsCloudGuru from '../../Images/cloud-guru-aws.jpg';
import azureCloudGuru from '../../Images/cloud-guru-azure.jpg';

const AwsAzureWidget = () => (
  <>
    <div className="col-lg-6 gutter-b">
      <div className="card card-custom gutter-b- h-100 aws-this-week">
        <div className="cloudguru-this-week">
          <div className="cloudguru-img">
            <img
              src={awsCloudGuru}
              className="img-fluid"
              style={{ width: '100%' }}
              alt="awsCloudGuru"
            />
          </div>
        </div>
        <div className="p-3">
          <div className="d-flex align-items-center justify-content-between">
            <div className="cloudguru-content m-0">
              <h3 className="font-size-h6 m-0">AWS This Week</h3>
            </div>
            <a
              href="https://learn.acloud.guru/series/aws-this-week"
              target="_blank"
              className="text-nowrap ml-2"
              rel="noreferrer"
            >
              Click to view series
              <div className="navi-icon">
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                        fill="#000000"
                        fillRule="nonzero"
                      />
                      <path
                        d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        opacity="0.3"
                        transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                      />
                    </g>
                  </svg>
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div className="col-lg-6 gutter-b">
      <div className="card card-custom gutter-b- h-100 azure-this-week">
        <div className="cloudguru-this-week">
          <div className="cloudguru-img">
            <img
              src={azureCloudGuru}
              className="img-fluid"
              style={{ width: '100%' }}
              alt="azureCloudGuru"
            />
          </div>
        </div>
        <div className="p-3">
          <div className="d-flex align-items-center justify-content-between">
            <div className="cloudguru-content m-0">
              <h3 className="font-size-h6 m-0">Azure This Week</h3>
            </div>
            <a
              href="https://learn.acloud.guru/series/azure-this-week"
              target="_blank"
              className="text-nowrap ml-2"
              rel="noreferrer"
            >
              Click to view series
              <div className="navi-icon">
                <span className="svg-icon svg-icon-xl svg-icon-primary">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <polygon points="0 0 24 0 24 24 0 24" />
                      <path
                        d="M12.2928955,6.70710318 C11.9023712,6.31657888 11.9023712,5.68341391 12.2928955,5.29288961 C12.6834198,4.90236532 13.3165848,4.90236532 13.7071091,5.29288961 L19.7071091,11.2928896 C20.085688,11.6714686 20.0989336,12.281055 19.7371564,12.675721 L14.2371564,18.675721 C13.863964,19.08284 13.2313966,19.1103429 12.8242777,18.7371505 C12.4171587,18.3639581 12.3896557,17.7313908 12.7628481,17.3242718 L17.6158645,12.0300721 L12.2928955,6.70710318 Z"
                        fill="#000000"
                        fillRule="nonzero"
                      />
                      <path
                        d="M3.70710678,15.7071068 C3.31658249,16.0976311 2.68341751,16.0976311 2.29289322,15.7071068 C1.90236893,15.3165825 1.90236893,14.6834175 2.29289322,14.2928932 L8.29289322,8.29289322 C8.67147216,7.91431428 9.28105859,7.90106866 9.67572463,8.26284586 L15.6757246,13.7628459 C16.0828436,14.1360383 16.1103465,14.7686056 15.7371541,15.1757246 C15.3639617,15.5828436 14.7313944,15.6103465 14.3242754,15.2371541 L9.03007575,10.3841378 L3.70710678,15.7071068 Z"
                        fill="#000000"
                        fillRule="nonzero"
                        opacity="0.3"
                        transform="translate(9.000003, 11.999999) rotate(-270.000000) translate(-9.000003, -11.999999) "
                      />
                    </g>
                  </svg>
                </span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </>
);

export default AwsAzureWidget;
