import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import Loader from '../Loader';
import { getUserTraining } from '../../store/actions/peopleAction';
import Alert from '../Common/Alert';

const UserDetail = ({ userId, cloudEnv }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userProfile);
  const { userTrainingLoading, userTraining, errTraining } = userData;
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrMsg, setShowErrMsg] = useState(false);

  useEffect(() => {
    const apiUrl = `/api/people/${userId}/${cloudEnv}training`;
    dispatch(getUserTraining(apiUrl));
  }, [dispatch, cloudEnv]);

  useEffect(() => {
    if (errTraining !== '') {
      setErrorMessage(errTraining);
      setShowErrMsg(true);
    }
  }, [errTraining]);

  const renderStarts = (number) => {
    const lis = [];
    for (let i = 0; i < number; i++) {
      lis.push(<FontAwesomeIcon key={i} icon={faStar} />);
    }
    return lis;
  };

  function rowsData() {
    const rows = userTraining.map((training) => (
      <tr key={training.levelCode}>
        <td>
          <span className="d-flex align-items-center text-user-profile font-size-lg font-weight-500 mr-2 text-nowrap">
            {training.description}
            <span className="d-flex align-items-center text-secondary font-size-sm ml-2">
              {renderStarts(training.rank)}
              <span className="text-muted">
                {renderStarts(4 - training.rank)}
              </span>
            </span>
          </span>
        </td>
        <td>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: training.progress }}
              aria-valuenow={training.progress.replace('%', '')}
              aria-valuemin="0"
              aria-valuemax="100"
            >
              <span>{training.progress}</span>
            </div>
          </div>
        </td>
        <td>
          <span
            className={`text-user-profile font-size-lg font-weight-500 mr-2 ${training.completedOn === undefined ? 'ml-8' : ''
            }`}
          >
            <span>
              {training.completedOn === undefined ? '-' : training.completedOn}
            </span>
          </span>
        </td>
        { cloudEnv === 'aws' && (
        <td>
          <span
            className={`text-user-profile font-size-lg font-weight-500 mr-2 ${training.lastLogin === undefined ? 'ml-8' : ''
            }`}
          >
            <span>
              {training.lastLogin === undefined ? '-' : training.lastLogin}
            </span>
          </span>
        </td>
        ) }
        <td>
          <div
            className={`text-user-profile font-size-lg font-weight-500 mr-2 ${training.startedOn === undefined ? 'ml-8' : ''
            }`}
          >
            <span>
              {training.startedOn === undefined ? '-' : training.startedOn}
            </span>
          </div>
        </td>
      </tr>
    ));
    return rows;
  }

  return (
    <div>
      {userTrainingLoading ? (
        <Loader />
      ) : showErrMsg ? (
        <div className="col-12">
          <Alert
            message={errorMessage}
            alertClass="light-danger"
            onClose={() => setShowErrMsg(false)}
          />
        </div>
      ) : (
        !errorMessage && (
        <>
          {userTraining.length > 0 ? (
            <div className="tab-pane" id="profile-v">
              <div className="row justify-content-center justify-content-end-xs">
                <div className="col-12">
                  <div className="table-responsive">
                    <table className="table table-custom table-vertical-center table-hover">
                      <thead>
                        <tr>
                          <th>Description</th>
                          <th>Progress</th>
                          <th>Completed</th>
                          {cloudEnv === 'aws' && <th>Last Logged In</th> }
                          <th>Started</th>
                        </tr>
                      </thead>
                      <tbody>{rowsData()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Alert
              message="No training details found for this user."
              alertClass="info"
            />
          )}
        </>
        )
      )}
    </div>
  );
};

UserDetail.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default UserDetail;
