import React from 'react';
import PropTypes from 'prop-types';
import BigSearch from '../Common/BigSearch';

const SubscriptionSearch = (props) => {
  const {
    selectedValue, search, tenants, statuses,
  } = props;

  const searchTypes = [
    {
      key: 'Subscription',
      display: 'Subscription',
      placeholder: 'The Subscription name or number',
    },
    {
      key: 'Owner',
      display: 'Contact / Owner',
      placeholder: 'The name of the account contact / owner',
    },
    {
      key: 'Mine',
      display: 'Mine',
      placeholder: 'The account name or number - only mine',
      alwaysSearch: true,
    },
    {
      key: 'Director',
      display: 'Executive / Director',
      placeholder:
        'The name of the director or executive the account owner is under',
    },
    {
      key: 'Product',
      display: 'Product',
      placeholder: 'The product code, name, subfamily or family',
    },

  ];

  const filters = [
    {
      key: 'status',
      url: '/api/lookup/subscriptionStatus',
      label: 'Statuses',
    },
    {
      key: 'tenant',
      url: '/api/lookup/tenants',
      label: 'Tenants',
    },
  ];

  const findIndex = (value) => searchTypes.findIndex((p) => p.key === (value || 'Subscription')) || 0;

  const executeSearch = (e) => {
    const { executeSearch } = props;
    const { bigSearch } = e;
    const { search, selectedValue, selectedFilters } = bigSearch;
    const { key } = selectedValue;
    const { status, tenant } = selectedFilters;
    executeSearch(key, search, status || [], tenant || []);
  };

  const clearSearch = (e) => {
    const { clearSearch } = props;
    const { bigSearch } = e;
    const { selectedFilters } = bigSearch;
    const { statuses, tenant } = selectedFilters;
    clearSearch(statuses || [], tenant || []);
  };

  const statusIndex = filters.findIndex((p) => p.key === 'status');
  const tenantIndex = filters.findIndex((p) => p.key === 'tenant');

  if (statusIndex >= 0 && statuses !== undefined && String(statuses) !== '') {
    filters[statusIndex].initialSelection = statuses.split(',');
  }

  if (
    tenantIndex >= 0
    && tenants !== undefined
    && String(tenants) !== ''
  ) {
    filters[tenantIndex].initialSelection = tenants.split(',');
  }
  return (
    <BigSearch
      srLabelTitle="Subscription Search"
      selectedIndex={findIndex(selectedValue)}
      search={search}
      executeSearch={executeSearch}
      clearSearch={clearSearch}
      searchTypes={searchTypes}
      filters={filters}
    />
  );
};

SubscriptionSearch.propTypes = {
  selectedValue: PropTypes.string,
  search: PropTypes.string,
  statuses: PropTypes.string,
  tenants: PropTypes.string,
  executeSearch: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
};

export default SubscriptionSearch;
