import React from 'react';
import { Link } from '@reach/router';
import TrainingTable from './TrainingTable';
import noData from '../../Images/no-data.png';

const TrainingManagerWidget = ({ trainingInfo }) => (
  <div className="col-lg-6 gutter-b">
    <div className="card card-custom gutter-b h-100">
      <div className="card-header align-items-center border-0 mt-4">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label">Training Details</h3>
          <span className="text-muted mt-2 font-weight-500 font-size-sm">
            {`List of team members(${trainingInfo.length}) training details. To view more`}
            <Link to="/education"> click here.</Link>
          </span>
        </div>
      </div>
      {trainingInfo.length > 0
        ? (
          <div className="card-body">
            <div className="training-card-scrollable">
              {/* Show only max 4 team members */}
              {trainingInfo.slice(0, 3).map((training) => (
                <div key={training.employeeId}>
                  <TrainingTable trainingData={training} />
                </div>
              ))}
            </div>
          </div>
        )
        : (
          <div className="card-body card-empty">
            <img src={noData} className="img-fluid d-block m-auto w-50" alt="" />
            <p className="text-center mt-10 font-size-lg font-weight-normal text-muted">You don&apos;t have any training details to show.</p>
          </div>
        )}
    </div>
  </div>
);

export default TrainingManagerWidget;
