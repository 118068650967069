import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Checkbox from './Checkbox';
import Loader from '../Loader';

const BigSearchFilter = (props) => {
  const {
    initialSelection, url, label, filterKey,
  } = props;
  const [toggleVisible, setToggleVisbile] = useState(false);
  const [all, setAll] = useState({});
  const [selected, setSelected] = useState([]);
  const [labelS, setLabelS] = useState('All');
  const [allCount, setAllCount] = useState(0);
  const [loaded, setLoaded] = useState(0);
  const drpDwnMenuRef = useRef(null);

  // check if props changes does it enter useEffect.
  useEffect(() => {
    const propSelected = initialSelection || [];
    axios
      .get(url)
      .then((response) => {
        const count = Object.keys(response).length;
        const selCount = propSelected.length;
        setSelected(propSelected);
        setAll(response.data);
        setLabelS(
          selCount > 0 && selCount < count
            ? `${selCount} ${label}`
            : `All ${label}`,
        );
        setLoaded(true);
        setAllCount(count);
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          console.error(err);
        }
      });
  }, []);

  // Close search result list when clicked outside the search result list
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        drpDwnMenuRef.current
        && !drpDwnMenuRef.current.contains(event.target)
      ) {
        setToggleVisbile(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [drpDwnMenuRef]);

  const toggle = (e) => {
    const { type } = e;
    setToggleVisbile(type === 'mouseleave' ? false : !toggleVisible);
  };

  const update = (e) => {
    const { onChange } = props;
    const { target } = e;
    const { checked, name } = target;

    const arr = selected.filter((p) => p !== name);
    if (checked === true) {
      arr.push(name);
    }

    const { length } = arr;
    const newLabel = length > 0 && length < allCount ? `${length} ${label}` : `All ${label}`;

    setSelected(arr);
    setLabelS(newLabel);
    onChange({
      event: e,
      key: filterKey,
      selected: arr,
      from: 'BigSearchFilter',
    });
  };

  return loaded === true ? (
    <div ref={drpDwnMenuRef} className="btn-group btn-group-lg col mr-3 p-0">
      <button
        type="button"
        className={`btn btn-outline-secondary dropdown-toggle big-search-filter-${filterKey}`}
        onClick={toggle}
      >
        <span>{labelS}</span>
      </button>
      <ul
        className="dropdown-menu"
        role="menu"
        // eslint-disable-next-line react/no-unknown-property
        x-placement="bottom-start"
        style={{
          position: 'absolute',
          transform: 'translate3d(0px, 48px, 0px)',
          top: '0px',
          left: '0px',
          willChange: 'transform',
          zIndex: 2000,
          display: toggleVisible === true ? 'block' : 'none',
        }}
        onMouseLeave={toggle}
      >
        {Object.keys(all || {}).map((one) => (
          <li key={one} className="dropdown-item p-1">
            <Checkbox
              name={one}
              value="true"
              title={all[one]}
              onChange={update}
              checked={selected.some((p) => p === one)}
            />
          </li>
        ))}
      </ul>
    </div>
  ) : (
    <Loader />
  );
  // : <Spinner bootstrapCssColorClass="warning" />
};

BigSearchFilter.propTypes = {
  url: PropTypes.string.isRequired,
  filterKey: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  initialSelection: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
};

export default BigSearchFilter;
