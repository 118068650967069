import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import queryString from 'query-string';
import { navigate } from '@reach/router';
import axios from 'axios';
import HeaderWithInfo from '../Components/Common/HeaderWithInfo';
import Loader from '../Components/Loader';
import NetworkSearch from '../Components/Network/Search';
import NetworkResult from '../Components/Network/Results';
import Alert from '../Components/Common/Alert';
import { Context as PageContext } from '../Context/PageContext';
import { Context as MenuContext } from '../Context/MenuContext';
import { saveUserPageVisitHistory } from '../store/actions/peopleAction';

const initialState = {
  results: [],
  loaded: true,
  scrolling: false,
  matchCount: 0,
  showingTotal: 0,
  count: 0,
  index: 0,
  estimatedMonthCost: 0,
  previousMonthCost: 0,
  currentSearch: {
    environments: [],
    type: [],
  },
  errorMessage: '',
};

const Networks = (props) => {
  const { user } = props;
  const [results, setResults] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [scrolling, setScrolling] = useState(false);
  const [matchCount, setMatchCount] = useState(0);
  const [showingTotal, setShowingTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageContextState, setPageContextState] = useContext(PageContext);
  const { networkPage } = pageContextState;
  const [currentSearch, setCurrentSearch] = useState({
    environments: [],
    type: [],
  });
  const [errorMessage, setErrorMessage] = useState('');
  // Get selectedMenu data from Menu Context
  const [, setSelectedMenu] = useContext(MenuContext);

  const dispatch = useDispatch();

  // Get user login details
  useEffect(() => {
    if (networkPage === '') {
      setPageContextState({
        ...pageContextState,
        networkPage: 'network',
      });
      const apiUrl = `/api/Auth/SaveUserPageVisit/${user.employeeId}/network`;
      dispatch(saveUserPageVisitHistory(apiUrl));
    }
  }, [networkPage]);
  // Change menu css with peope page is loaded.
  useEffect(() => {
    setSelectedMenu('networks');
  }, []);
  const bindUniqueValues = (result) => {
    const filteredList = [];
    result.forEach((o) => {
      const found = results.some((el) => el.id === o.id);
      if (!found) {
        filteredList.push(o);
      }
    });
    setResults(results.concat(filteredList || []));
  };

  useEffect(() => {
    const {
      searchType, criteria, environments, type,
    } = currentSearch;
    // search only if criteria or environments and status is selected
    if (criteria !== undefined) {
      axios
        .get(
          `/api/virtualnetwork/search?t=${searchType}&q=${criteria}&e=${environments}&d=${type}&i=${pageIndex}`,
        )
        .then((response) => {
          const result = response.data;
          bindUniqueValues(result.items);
          setMatchCount(result.matchCount);
          setShowingTotal(result.showingTotal);
          setCount(result.count);
          setPageIndex(result.index);
          setLoaded(true);
          setScrolling(false);
        })
        .catch((err) => {
          if (err.name !== 'AbortError') {
            console.error(err);
            setErrorMessage(err.message);
            setLoaded(true);
          }
        });
    }
  }, [currentSearch, pageIndex]);
  const parseQueryString = () => {
    const { location } = props;
    const { search } = location;
    return queryString.parse(search);
  };

  const nextPage = () => {
    if (scrolling !== true && matchCount > showingTotal) {
      setScrolling(true);
      setPageIndex(pageIndex + 1);
    }
  };

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (Math.round(scrollTop) + clientHeight === scrollHeight) {
      nextPage();
    }
  };

  const executeSearch = (searchType, criteria, type, environments) => {
    if (
      currentSearch.searchType !== searchType
            || currentSearch.criteria !== criteria
            || currentSearch.type.length !== type.length
            || !currentSearch.type.every((e) => type.includes(e))
            || currentSearch.environments.length !== environments.length
            || !currentSearch.environments.every((e) => environments.includes(e))
    ) {
      setResults([]);
      setLoaded(false);
      setPageIndex(0);
      setCurrentSearch({
        searchType,
        criteria,
        type,
        environments,
      });
      const stateVar = {
        count,
        currentSearch: {
          searchType,
          criteria,
          type,
          environments,
        },
        loaded: false,
        pageIndex: 0,
        results: [],
        scrolling,
        showingTotal,
      };
      navigate(
        `/virtualnetworks?t=${searchType}&q=${criteria}&e=${environments}&s=${type}`,
        { state: stateVar, replace: true },
      );
    }
  };

  const clearSearch = (status, environments) => {
    const defaultState = initialState;
    const currentSearchClr = {
      ...defaultState.currentSearch,
      status: status || [],
      environments: environments || [],
    };
    setResults([]);
    setLoaded(true);
    setScrolling(false);
    setMatchCount(0);
    setShowingTotal(0);
    setCount(0);
    setCurrentSearch(currentSearchClr);
    setErrorMessage('');
  };

  const qs = parseQueryString();

  return (
    <>
      <div className="row">
        <div className="col-12">
          {/* <!-- Begin::Page Content --> */}
          <div className="card card-custom mb-4">
            <div className="card-header align-items-center">
              <div className="card-title align-items-center justify-content-between w-100">
                <HeaderWithInfo headerName="Networks" />
              </div>
            </div>
            <div className="card-body accout-search">
              <div>
                <NetworkSearch
                  executeSearch={executeSearch}
                  clearSearch={clearSearch}
                  selectedValue={qs.t}
                  search={qs.q}
                  environments={qs.e}
                  types={qs.d}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        {errorMessage && (
        <div className="col-12">
          <Alert
            message={errorMessage}
            alertClass="light-danger"
            onClose={(val) => setErrorMessage(val)}
          />
        </div>
        )}
      </div>

      {/* <!-- Begin::Search list --> */}
      {loaded ? (
        <>
          {count > 0 && (
          <>
            <div className="row gutter-b">
              <div className="col-12">
                <div>
                  <h6 className="text-inverse h6">
                    {matchCount > 0 ? (
                      <span>
                        {showingTotal}
                        {' '}
                        of
                        {' '}
                        {matchCount}
                      </span>
                    ) : null}
                  </h6>
                  <NetworkResult network={results} onScroll={handleScroll} />
                  {scrolling ? <Loader /> : null}
                </div>
              </div>
            </div>
          </>
          )}
        </>
      ) : (
        <Loader />
      )}

    </>
  );
};

export default Networks;
