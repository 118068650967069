import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faAws } from '@fortawesome/free-brands-svg-icons';
import Rating from '@material-ui/lab/Rating';
import azurelogo from '../../Images/azure.png';

const ResultRows = (props) => {
  const {
    isContentEditable, type,
    onTrainingLevelChange, currentClass, classes, MaxTrainingLevelAchieved,
    MaxTrainingLevelRankAchieved, isSeatAssigned, suggestedTrainingLevel, suggestedTrainingLevelRank,
    lastLogin, trainingLevelGoal, CurrentTrainingLevelRank, CurrentTrainingLevel, CurrentTrainingLevelPercent,
    employeeId, trainingLevelGoalRank,
  } = props;
  const renderStars = (number) => {
    const lis = [];
    for (let i = 0; i < number; i++) {
      lis.push(<FontAwesomeIcon key={i} icon={faStar} />);
    }
    return lis;
  };

  return (
    <div className={`timeline-content ${type === 'aws' ? 'mb-3' : ''}`}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex flex-wrap justify-content-between w-100 mr-2 license-section">
          <div className="timeline-content-label">
            {type === 'aws' ? (

              <span>
                <FontAwesomeIcon icon={faAws} />
              </span>
            ) : (
              <>
                <span className="text-center">
                  <img src={azurelogo} style={{ height: '24px' }} alt="Azure" />
                  <p style={{ color: 'white', fontSize: '12px', margin: 0 }}>Azure</p>
                </span>
              </>
            )}
          </div>
          <div className="d-flex flex-column align-items-start justify-content-center custom-wid col-lg-2 col-xl-2 px-0">
            <span className="d-flex flex-column text-dark font-size-lg font-weight-500 mr-2 flex-row-xs ">
              <span
                className={`${MaxTrainingLevelAchieved === 'No Training Completed'
                  ? 'text-break max-w-100'
                  : ''
                }
              mr-1 license-text ${classes}`}
              >
                {MaxTrainingLevelAchieved}
              </span>
              {MaxTrainingLevelAchieved === 'Passenger' ? (
                <span className="text-muted mr-1 l-h-11">
                  -
                </span>
              ) : (
                <span className="d-flex align-items-center text-secondary font-size-sm">
                  {renderStars(
                    MaxTrainingLevelRankAchieved,
                  )}
                </span>
              )}
            </span>
            <span className="text-muted font-weight-normal font-size-xs">
              Current License
            </span>
          </div>
          <div className="d-flex flex-column align-items-start justify-content-center custom-wid col-lg-2 col-xl-2 px-0">
            <span className="d-flex flex-column text-dark font-size-lg font-weight-500 mr-2 flex-row-xs">
              <span className="mr-1 license-text">
                {suggestedTrainingLevel}
              </span>
              {suggestedTrainingLevelRank > 0 ? (
                <span className="d-flex align-items-center text-secondary font-size-sm">
                  {renderStars(
                    suggestedTrainingLevelRank,
                  )}
                </span>
              ) : (
                <span className="text-muted mr-1 l-h-11">
                  -
                </span>
              )}
            </span>
            <span className="text-muted font-weight-normal font-size-xs">
              Expected License
            </span>
          </div>
          <div className="d-flex flex-column align-items-start justify-content-center custom-wid col-lg-2 col-xl-2 px-0">
            <span className="d-flex flex-column text-dark font-size-lg font-weight-500 mr-2 flex-row-xs license-progress-col">
              {trainingLevelGoal ? (
                <span className="mr-1 license-text">
                  {trainingLevelGoal}
                </span>
              ) : (
                <span className="mr-1">&nbsp;</span>
              )}
              <Rating
                data-employee-id={employeeId}
                size="small"
                icon={<FontAwesomeIcon icon={faStar} />}
                name={`${employeeId}`}
                max={4}
                readOnly={!isContentEditable}
                value={trainingLevelGoalRank}
                onChange={(e, value) => onTrainingLevelChange(
                  e,
                  value,
                  employeeId,
                )}
              />
            </span>
            <span className="text-muted font-weight-normal font-size-xs">
              License Goal
            </span>
          </div>
          <div className="d-flex flex-column align-items-start justify-content-center custom-wid col-lg-2 col-xl-2 px-0">
            <span className="d-flex flex-column text-dark font-size-lg font-weight-500 mr-2 flex-row-xs license-progress-col">
              <span
                className={`mr-1 license-text ${currentClass}`}
              >
                {CurrentTrainingLevel}
                <span className="text-primary font-weight-500">
                  {' '}
                  {CurrentTrainingLevelPercent}
                  %
                </span>
              </span>
              {CurrentTrainingLevel !== 'None' ? (
                <span className="d-flex align-items-center text-secondary font-size-sm">
                  {renderStars(CurrentTrainingLevelRank)}
                </span>
              ) : (
                <span className="text-muted mr-1 l-h-11">
                  -
                </span>
              )}
            </span>
            <span className="text-muted font-weight-normal font-size-xs ">
              License Progress
            </span>
          </div>
          <div className="d-flex flex-column align-items-start justify-content-center custom-wid justify-content-between col-lg-2 col-xl-2 px-0">
            <span className="d-flex flex-column text-dark font-size-lg font-weight-500 mr-2 flex-row-xs">
              <span className="mr-1 license-text">
                {lastLogin ?? '-'}
              </span>
            </span>
            <span className="text-muted font-weight-normal font-size-xs">
              Last Login
            </span>
          </div>
        </div>
        <div
          className="training-seat font-size-h3"
          title={`Seat ${isSeatAssigned ? '' : 'Not'
          } Assigned`}
        >
          {isSeatAssigned && (
          <span className="seat-assigned-sign">
            <FontAwesomeIcon icon={faCheckCircle} />
          </span>
          )}
          <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 64 64"
            height="1.2em"
            width="1.2em"
          >
            <title>
              {`Seat ${isSeatAssigned ? '' : 'Not'
              } Assigned`}
            </title>
            <path
              className={`${isSeatAssigned ? 'cls-2' : 'cls-1'
              }`}
              d="M21.27 26.87a2.66 2.66 0 0 1 .73 1.77v10l1.75.14a104.48 104.48 0 0 0 16.59 0l1.76-.14v-10a2.61 2.61 0 0 1 .69-1.77 4.19 4.19 0 0 0 .94-3 .17.17 0 0 1 0-.07v-9.51a58.88 58.88 0 0 0-1.13-8.43S42.51 2.63 32 2.63c-10.3 0-10.59 3.23-10.59 3.23a78.73 78.73 0 0 0-1.08 8.43v9.61a4.19 4.19 0 0 0 .94 3zM17.47 37.92l2.29.42a.66.66 0 0 0 .69-.63v-8.1a.77.77 0 0 0-.81-.74 3.21 3.21 0 0 0-3.38 3.54l.42 4.89c.06.35.42.53.79.62zM44.36 28.87a.77.77 0 0 0-.81.74v8.1a.66.66 0 0 0 .69.63l2.29-.42c.37-.08.73-.27.79-.62l.42-4.89a3.21 3.21 0 0 0-3.38-3.54zM45.18 39.79l-3.28.32-1.54.15q-4.2.35-8.42.34t-8.42-.34l-4.82-.47a2.28 2.28 0 0 0-2.1 2.43V45s0 3.67 3 4l1.05.06-.8 7.46v.13A4.49 4.49 0 0 0 21.08 60a5.32 5.32 0 0 0 3.55 1.38h14.63A5.34 5.34 0 0 0 42.94 60a4.49 4.49 0 0 0 1.26-3.41.57.57 0 0 0 0-.13L43.4 49l.91-.05a1.51 1.51 0 0 0 .47-.09c2.49-.61 2.49-3.87 2.49-3.87v-2.77a2.56 2.56 0 0 0 0-.49 2.19 2.19 0 0 0-2.09-1.94zm-4.33 18.08a2.37 2.37 0 0 1-1.54.52H24.66a2.3 2.3 0 0 1-1.49-.51 1.52 1.52 0 0 1-.38-1.17l.82-7.7H40.4l.82 7.7a1.52 1.52 0 0 1-.37 1.16z"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default ResultRows;
