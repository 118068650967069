import React from 'react';

const ProductAnnouncementInfo = () => (
  <div className="row">
    <div className="col-12">
      <h4 className="custom-tooltip-text">
        Click on an&nbsp;
        <strong>announcement category</strong>
        &nbsp;to view list of announcements.
        <br />
        Click on any&nbsp;
        <strong>announcement header</strong>
        &nbsp;to view more information about it.
        <br />
        Click on &nbsp;
        <strong>External Link</strong>
        &nbsp;to view AWS information page.
      </h4>
    </div>
  </div>
);

export default ProductAnnouncementInfo;
