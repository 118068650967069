import React from 'react';

const AccountInfo = () => (
  <div className="row">
    <div className="col-12">
      <h4 className="custom-tooltip-text">
        Search for accounts using a specific
        <strong> search type</strong>
        {' '}
        and optional
        <strong> search filters. </strong>
      </h4>
    </div>
  </div>
);

export default AccountInfo;
