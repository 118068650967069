import React, { useState, useEffect, useContext } from 'react';
import MUIDataTable from 'mui-datatables';
import ApiDropDown from '../Common/ApiDropdown';
import { Context } from '../../Context/CloudabilityContext';
import Loader from '../Loader';
import DonutChart from '../Common/DonutChart';
import CloudabilityLineChart from './CloudabilityLineChart';

import './Cloudability.css';

const CloudabilityServiceCost = (props) => {
  const { serviceCosts } = props;
  const [serviceCostData, setServiceCostData] = useState(serviceCosts);
  const [pieChartCostData, setPieChartCostData] = useState(null);
  const [pieChartLableData, setPieChartLableData] = useState(null);
  const [servicesTotalCost, setServicesTotalCost] = useState(null);
  const [activeTab, setActiveTab] = useState('graph');
  const [loaded, setloaded] = useState(false);
  const [dataArray, setDataArray] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Get filter data from Cloudability Context
  const [cloudabilityFilter, setCloudabilityFilter] = useContext(Context);
  const {
    currentProduct, businessUnit, timePeriods, filterLoaded,
  } = cloudabilityFilter;

  // Format Cost into currency for table
  function formatDataForTable(srvCostDt) {
    if (srvCostDt) {
      return srvCostDt.map((data) => ({
        ...data,
        cost: data.cost.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        }),
      }));
    }
    return [];
  }

  // Calculate total cost of each data
  function calcTotal(data) {
    let total = 0;
    for (let i = 0; i < data.length; i++) {
      total += data[i].cost;
    }
    return total;
  }

  // Bind costing data and find total cost for services
  function bindUniqueCostingData(data) {
    const order = [];
    data.forEach((o) => {
      let cost = 0;
      const found = order.some((el) => el.name === o.name && el.type === o.type);
      if (found) {
        const foundIndex = order.findIndex(
          (x) => x.name === o.name && x.type === o.type,
        );
        cost = Number.isNaN(o.cost) ? parseFloat(o.cost.replace('$', '')) : o.cost;
        const orderCost = Number.isNaN(order[foundIndex].cost)
          ? parseFloat(order[foundIndex].cost.replace('$', ''))
          : order[foundIndex].cost;

        order[foundIndex].cost = orderCost + cost;
      } else {
        cost = Number.isNaN(o.cost) ? parseFloat(o.cost.replace('$', '')) : o.cost;
        order.push({ name: o.name, type: o.type, cost });
      }
    });

    order.sort((a, b) => b.cost - a.cost);
    const servTotal = calcTotal(order);
    setServicesTotalCost(servTotal);
    return order;
  }

  useEffect(() => {
    setDataArray(formatDataForTable(serviceCostData).map(Object.values));
    const order = bindUniqueCostingData(serviceCostData);
    setloaded(true);
    setCloudabilityFilter({ ...cloudabilityFilter, filterLoaded: true });
    setPieChartCostData(order.map((x) => x.cost).slice(0, 15));
    setPieChartLableData(order.map((x) => `${x.name} (${x.type})`).slice(0, 15));
  }, [serviceCostData]);

  useEffect(() => {
    setServiceCostData(serviceCosts);
  }, [serviceCosts]);

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    if (value === '') {
      return;
    }
    if (name === 'businessUnit' && !filterLoaded) {
      return;
    }
    let params = {};

    if (name === 'currentProduct') {
      params = {
        product: value,
        filterItem: timePeriods,
      };
      setCloudabilityFilter({
        ...cloudabilityFilter,
        currentProduct: value,
        businessUnit: '',
        region: 'all_region',
        filterLoaded: false,
      });
      props.onChange(params);
    }
    if (name === 'businessUnit') {
      let data = [];
      if (value === 'all') {
        data = serviceCosts;
      } else {
        data = serviceCosts.filter(
          (a) => a.environmentInstance === value.replace(/_/g, ' '),
        );
      }
      setCloudabilityFilter({
        ...cloudabilityFilter,
        businessUnit: value,
        region: 'all_region',
        filterLoaded: true,
      });
      setServiceCostData(data);
    }
    if (name === 'timePeriod') {
      params = {
        product: 'all',
        filterItem: value,
      };
      setCloudabilityFilter({
        ...cloudabilityFilter,
        timePeriods: value,
        region: 'all_region',
        businessUnit: '',
        filterLoaded: false,
      });
      props.onChange(params);
    }
  };

  const currentDate = new Date();

  // For MUI tables
  const onChangeRowsPerPage = (rowsPerPagee) => {
    setRowsPerPage(rowsPerPagee);
  };
  const columns = [
    'Environment Instance',
    'Product',
    'AWS Service Name',
    'Type',
    'Region',
    'Cost',
  ];
  const options = {
    filter: true,
    filterType: 'dropdown',
    rowsPerPage,
    rowsPerPageOptions: [5, 10, 15, 20],
    onChangeRowsPerPage,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    downloadOptions: { filename: 'AscendonCloudabilityData.csv' },
    responsive: 'standard',
  };

  return (
    <div className="tab-pane active" id="home-v">
      {loaded ? (
        <>
          <div className="row justify-content-center justify-content-end-xs">
            <div className="col-md-3 col-sm-4 col-12">
              <div className="form-group">
                <ApiDropDown
                  name="currentProduct"
                  value={currentProduct}
                  placeholder="Pick One..."
                  title="Product"
                  url={`/api/AscendonCost/GetProductFilter?timePeriod=${timePeriods}`}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-3 col-sm-4 col-12">
              <div className="form-group">
                <ApiDropDown
                  name="businessUnit"
                  value={businessUnit}
                  placeholder="Pick One..."
                  title="Environment Instance"
                  url={`/api/AscendonCost/GetEnvironmentInstance?view=${currentProduct}&timePeriod=${timePeriods === 'last_year'
                    || (timePeriods === 'last_quarter'
                      && currentDate
                      >= new Date(currentDate.getFullYear(), 0, 1)
                      && currentDate <= new Date(currentDate.getFullYear(), 2, 31))
                    ? 'last_year'
                    : 'this_year'}`}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-md-3 col-sm-4 col-12">
              <div className="form-group">
                <ApiDropDown
                  name="timePeriod"
                  value={timePeriods}
                  placeholder="Pick One..."
                  title="Pick A Time Period"
                  url="/api/AscendonCost/TimePeriods"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="col-xl-2 col-lg-3 col-md-3 col-sm-5 col-12">
              <ul className="nav nav-pills bg-nav-pills nav-justified mt-2 align-items-center justify-content-center h-100">
                <li className="nav-item">
                  <div
                    onClick={() => setActiveTab('graph')}
                    data-toggle="tab"
                    aria-expanded="false"
                    className={`nav-link ${activeTab === 'graph' ? 'active' : ''}`}
                  >
                    Graph
                  </div>
                </li>
                <li className="nav-item">
                  <div
                    onClick={() => setActiveTab('table')}
                    data-toggle="tab"
                    aria-expanded="true"
                    className={`nav-link ${activeTab === 'table' ? 'active' : ''}`}
                  >
                    Table
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="row mt-8">
            <div className="col-12">
              <h3 className="text-center">
                AWS Services Total Cost:
                <span>
                  {filterLoaded
                    ? ` $${Math.round(servicesTotalCost).toLocaleString()}`
                    : ''}
                </span>
              </h3>
            </div>
            <div className="col-12">
              {filterLoaded ? (
                <div className="tab-content p-0 mt-8">
                  <div
                    className={`tab-pane ${activeTab === 'graph' ? 'active' : ''}`}
                    id="serviceCostingGraph"
                  >
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-sm-6 col-12 gutter-b">
                        <div className="ascendon-section h-100">
                          <h5 className="text-left">
                            Compare Service Cost Percents
                          </h5>
                          <div className="mt-3">
                            <DonutChart
                              pieChartCostData={pieChartCostData}
                              pieChartLableData={pieChartLableData}
                              title="Compare Service Cost Percents"
                              chartType="donut"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-6 col-12 gutter-b">
                        <div className="ascendon-section h-100">
                          <h5 className="text-left">
                            Daily Costs by Business Mapping
                          </h5>
                          <div className="mt-3">
                            <CloudabilityLineChart
                              currentProduct={currentProduct}
                              serviceCosts={serviceCostData}
                              timePeriods={timePeriods}
                              businessUnit={businessUnit}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`tab-pane ${activeTab === 'table' ? 'active' : ''}`}
                    id="serviceCostineTable"
                  >
                    <div className="ascendon-section">
                      <div className="table-responsive">
                        <MUIDataTable
                          data={dataArray}
                          columns={columns}
                          options={options}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default CloudabilityServiceCost;
