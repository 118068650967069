/* eslint func-names: ["error", "never"] */
import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import Alert from '../../Common/Alert';

const CostCompare = (props) => {
  const [chart, setChart] = useState(null);
  const [handle, setHandle] = useState();
  const {
    accountId, asOf, data, isOpen, onClick,
  } = props;

  const clearHandle = () => {
    if (handle) {
      window.clearTimeout(handle);
    }
  };

  const legendMouseOver = (e) => {
    clearHandle();
    const { target } = e;
    const { name, chart } = target;
    const { series } = chart;
    const { each } = Highcharts;

    each(series, (seriesItem) => {
      const { initialType, points } = seriesItem;
      if (initialType === 'pie') {
        each(points, (point) => {
          if (name !== point.name) {
            point.graphic.attr('opacity', 0.25);
          } else {
            point.graphic.attr('opacity', 1);
          }
        });
      } else {
        each(['group', 'markerGroup'], (group) => {
          seriesItem[group].attr('opacity', seriesItem !== target ? 0.25 : 1);
        });
      }
    });
  };

  const legendMouseOut = (e) => {
    clearHandle();
    const { target } = e;
    const { chart } = target;
    const { series } = chart;
    const { each } = Highcharts;

    setHandle(
      window.setTimeout(() => {
        each(series, (seriesItem) => {
          const { initialType, points } = seriesItem;
          if (initialType === 'pie') {
            each(points, (point) => {
              point.graphic.attr('opacity', 1);
            });
          } else if (seriesItem !== target) {
            each(['group', 'markerGroup'], (group) => {
              seriesItem[group].attr('opacity', 1);
            });
          }
        });
      }, 200),
    );
  };

  const initializeHighcharts = () => {
    const { Legend, wrap } = Highcharts;
    wrap(Legend.prototype, 'renderItem', function (proceed, item) {
      proceed.apply(this, Array.prototype.slice.call(arguments, 1));

      const { legendItem } = item;
      const { group } = legendItem;
      const { element } = group;
      const { chart } = item;
      if (chart) {
        const { series } = chart;

        element.onmouseover = (e) => legendMouseOver({
          preventDefault: e.preventDefault,
          target: item,
          series,
          type: 'legendMouseOver',
        });

        element.onmouseout = (e) => legendMouseOut({
          preventDefault: e.preventDefault,
          target: item,
          series,
          type: 'legendMouseOut',
        });
      }
    });
  };

  const createSeries = () => {
    const sums = {};

    data.forEach((d) => {
      if (sums[d.serviceName]) {
        sums[d.serviceName] = {
          previousMonthSpend:
            d.previousMonthSpend + sums[d.serviceName].previousMonthSpend,
          estimatedSpend: d.estimatedSpend + sums[d.serviceName].estimatedSpend,
        };
      } else {
        sums[d.serviceName] = {
          previousMonthSpend: d.previousMonthSpend,
          estimatedSpend: d.estimatedSpend,
        };
      }
    });

    const keys = Object.keys(sums);

    const lastMonthPieData = keys
      .map((key) => ({
        name: key,
        y: Math.round(sums[key].previousMonthSpend * 100) / 100,
      }))
      .sort((a, b) => (a.name > b.name ? 1 : 0));

    const estPieData = keys
      .map((key) => ({
        name: key,
        y: Math.round(sums[key].estimatedSpend * 100) / 100,
      }))
      .sort((a, b) => (a.name > b.name ? 1 : 0));

    const series = keys.map((key) => ({
      name: key,
      data: [
        Math.round(sums[key].previousMonthSpend * 100) / 100,
        Math.round(sums[key].estimatedSpend * 100) / 100,
      ],
    }));

    series.push({
      type: 'pie',
      name: 'Last Month Spend',
      data: lastMonthPieData,
      center: ['30%', 50],
      size: '43%',
      showInLegend: false,
      dataLabels: {
        enabled: false,
      },
    });

    series.push({
      type: 'pie',
      name: "This Month's Estimated",
      data: estPieData,
      center: ['83%', 50],
      size: '43%',
      showInLegend: false,
      dataLabels: {
        enabled: false,
      },
    });

    return series;
  };

  const chartLabelFormatter = (e) => {
    const { value } = e;
    return `$${value}`;
  };

  const chartLegendOnClick = (e) => {
    const { target } = e;
    const { name, chart } = target;
    const { series } = chart;
    const { each } = Highcharts;

    each(series, (ob) => {
      each(ob.data, (p) => {
        if (p.name === name) {
          if (p.visible === true) {
            p.setVisible(false);
          } else {
            p.setVisible(true);
          }
        }
      });
    });
  };

  const createOptions = () => {
    const series = createSeries();
    setChart({
      chart: {
        type: 'column',
        height: 500,
        scrollablePlotArea: {
          minWidth: 700,
        },
        style: {
          fontFamily: 'Poppins, Helvetica, sans-serif',
        },
      },
      xAxis: {
        text: 'Spend by Service',
        categories: ['Last Month Spend', 'This Month`s Estimated'],
      },
      title: {
        text: '',
      },
      legend: {
        align: 'left',
        alignColumns: false,
        x: 40,
        itemWidth: 140,
        width: '100%',
        itemStyle: {
          fontWeight: 'normal',
        },
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Spend',
        },
        labels: {
          formatter: chartLabelFormatter,
        },
      },
      plotOptions: {
        series: {
          events: {
            legendItemClick: chartLegendOnClick,
          },
          // pointWidth: 10
        },
      },
      credits: {
        enabled: true,
        href: 'https://account.activedirectory.windowsazure.com/applications/redirecttofederatedapplication.aspx?Operation=SignIn&applicationId=b6d225fa-39e9-47c4-b4e0-524827757361&ApplicationConstName=customappsso&SingleSignOnType=Federated&ApplicationDisplayName=CSG%20Cloudability&tenantId=194a267b-82ba-4fa6-b6b9-f95966ab3815',
        text: `Cloudability Compare Months for ${accountId}`,
      },
      series,
      /* eslint-disable */
      tooltip: {
          formatter: function () {
              let something = this.x === undefined ? this.point.name : this.x;
              return something + '<br/>' + '<span style="color:' + this.color + ';">●</span> ' + this.series.name + ': <b>$ ' + this.y.toLocaleString() + '<b/>'
          }
      },
      /* eslint-disable */
      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              chart: {
                height: 350,
              },
              legend: {
                enabled: false,
              },
              yAxis: {
                // labels: '',
                title: {
                  text: '',
                },
              },
            },
          },
        ],
      },
    });
  };

  useEffect(() => {
    if (data) {
      initializeHighcharts();
      createOptions();
    }
    return () => {
      const { Legend, wrap } = Highcharts;
      clearHandle();
      wrap(Legend.prototype, 'renderItem', function (proceed) {
        proceed.apply(this, Array.prototype.slice.call(arguments, 1));
      });
    };
  }, [data]);

  useEffect(() => {
    createOptions();
  }, [accountId, asOf]);

  return (
    <>
      <div className={`card border-0 ${isOpen ? 'card-active' : ''}`}>
        <div className="card-header card-header2" id="headingOne">
          <h5 className="mb-0">
            <button
              type="button"
              className={`btn btn-link ${isOpen ? '' : 'collapsed'}`}
              data-toggle="collapse"
              data-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
              onClick={() => onClick('compare')}
            >
              Compare Months
            </button>
          </h5>
        </div>
        <div
          id="collapseTwo"
          className={`collapse ${isOpen ? 'show' : ''}`}
          aria-labelledby="headingOne"
          data-parent="#accordion"
        >
          <div className="card-body px-0">
            {chart ? (
              <HighchartsReact
                constructorType="chart"
                highcharts={Highcharts}
                options={chart}
              />
            ) : (
              <>
                <Alert
                  message="No comparison data is available to graph."
                  alertClass="info"
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

CostCompare.propTypes = {
  accountId: PropTypes.string.isRequired,
  asOf: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      usageFamily: PropTypes.string.isRequired,
      serviceName: PropTypes.string.isRequired,
      previousMonthSpend: PropTypes.number.isRequired,
      mtdSpend: PropTypes.number.isRequired,
      estimatedSpend: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default CostCompare;
