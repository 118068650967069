import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';

const JiraSRDetails = ({ jiraSRList, requestType }) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [jiraSRData, setJiraSRData] = useState([]);
  const onChangeRowsPerPage = (rowsPerPagee) => {
    setRowsPerPage(rowsPerPagee);
  };
  useEffect(() => {
    if (jiraSRList.length > 0) {
      const formattedData = jiraSRList.map((item) => ({
        key: item.key,
        created: item.created?.split('T')[0],
        updated: item.updated?.split('T')[0],
        status: item.status,
      }));
      setJiraSRData(formattedData.map(Object.values));
    }
  }, []);
  const columns = [
    {
      name: 'SR Number',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <a href={`https://jira.csgicorp.com/browse/${value}`} target="_blank" rel="noopener noreferrer">
            {value}
          </a>
        ),
      },
    },
    {
      name: 'Created On',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Updated On',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Status',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];
  const options = {
    filter: true,
    filterType: 'dropdown',
    rowsPerPage,
    rowsPerPageOptions: [5, 10, 15, 20],
    onChangeRowsPerPage,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    downloadOptions: { filename: `${requestType}Requests.csv` },
    responsive: 'standard',
  };
  return (
    <>
      <div className="row mt-8">
        <div className="col-12">
          <div className="ascendon-section">
            <div className="table-responsive">
              { jiraSRData.length > 0
                ? (
                  <MUIDataTable
                    data={jiraSRData}
                    columns={columns}
                    options={options}
                  />
                ) : <div style={{ textAlign: 'center' }}>Data not available!</div>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JiraSRDetails;
