import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { getPostEventSummries } from '../../../store/actions/vendorAction';
import Alert from '../../Common/Alert';
import Loader from '../../Loader';

const PostEventSummaries = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const vendorInfo = useSelector((state) => state.vendorInfo);
  const { summariesLoading, summaries, errorSummaries } = vendorInfo;

  useEffect(() => {
    const apiUrl = '/api/Vendor/GetSummaries';
    dispatch(getPostEventSummries(apiUrl));
  }, [dispatch]);
  useEffect(() => {
    if (errorSummaries !== '' && errorSummaries !== undefined) {
      setErrorMessage(errorSummaries);
    }
  }, [errorSummaries]);
  return (
    <>
      {summariesLoading ? (
        <Loader />
      ) : (
        <>
          {summaries && summaries.length > 0 && (
            <div className="custom-table">
              <div className="respnsive-custom-table">
                <div className="div-table" width="100%">
                  <div className="div-thead">
                    <div className="div-row">
                      <div className="div-column-th">Event</div>
                      <div className="div-column-th" />
                    </div>
                  </div>
                  <div className="div-tbody">
                    {summaries.map((item) => (
                      <div className="div-row" key={item.summaryUrl}>
                        <div className="div-column">
                          {item.summaryText.replace(
                            'Summary of the Amazon ',
                            '',
                          )}
                        </div>
                        <div className="div-column text-right text-nowrap">
                          <a
                            className="detailsButton"
                            href={`${item.summaryUrl}#en_US`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <button type="button" className="detailsButton">
                              Read More
                              {' '}
                              <FontAwesomeIcon icon={faExternalLinkAlt} />
                            </button>
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
          {errorMessage && (
            <div className="col-12">
              <Alert
                message={errorMessage}
                alertClass="light-danger"
                onClose={(val) => setErrorMessage(val)}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};
export default PostEventSummaries;
