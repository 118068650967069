import React, { useEffect, useState, useContext } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import Tab from '../Components/Common/Tab';
import { Context as MenuContext } from '../Context/MenuContext';
import { AwsServiceAnalyticsContext } from '../Context/AwsServiceAnalyticsContext';
import CountAwsServices from '../Components/Admin/AwsServiceAnalytics/CountAwsServices';
import ServiceUsageDistribution from '../Components/Admin/AwsServiceAnalytics/ServiceUsageDistribution';
import ByAwsService from '../Components/Admin/AwsServiceAnalytics/ByAwsService';

import 'react-datepicker/dist/react-datepicker.css';

const AwsServiceAnalytics = () => {
  const payerAccountList = [
    {
      value: '509181061695',
      label: 'CSG Enterprise AWS Account',
    },
    {
      value: '663609477245',
      label: 'cogs-ascendon-root',
    },
    {
      value: '465282636839',
      label: 'csg-pca-ct-master',
    },
  ];
  const [tabList] = useState([
    { value: 'countAws', label: 'Count of AWS Services' },
    { value: 'byAccount', label: 'Services By Account' },
    { value: 'byService', label: 'By AWS Service' },
  ]);
  const [tab, setTab] = useState('countAws');
  // Get selectedMenu data from Menu Context
  const [, setSelectedMenu, , setSelectedSubMenu] = useContext(MenuContext);
  const [selectedPayerAccnt, setSelectedPayerAccnt] = useState(
    payerAccountList[0],
  );

  const getPrevMonthDate = () => {
    const s = new Date();
    s.setDate(1);
    s.setMonth(s.getMonth() - 1);
    const e = new Date();
    e.setDate(1);
    e.setHours(-1);
    return {
      start: s,
      end: e,
    };
  };

  const defaultStartDate = (new Date()).getDate() === 1 ? getPrevMonthDate().start : new Date((new Date()).getFullYear(), (new Date()).getMonth(), 1);
  const defaultEndDate = (new Date()).getDate() === 1 ? getPrevMonthDate().end : new Date();
  const [dateRange, setDateRange] = useState([defaultStartDate, defaultEndDate]);
  const [startDate, endDate] = dateRange;

  // In case the page is loaded directly from url then change selectedMenu state
  useEffect(() => {
    setSelectedMenu('admin');
    setSelectedSubMenu('awsserviceanalytics');
  }, []);

  const dateFormatter = (date) => {
    const yyyy = date.getFullYear();
    let mm = date.getMonth() + 1; // Months start at 0!
    let dd = date.getDate();

    if (dd < 10) dd = `0${dd}`;
    if (mm < 10) mm = `0${mm}`;

    return `${yyyy}-${mm}-${dd}`;
  };

  function analyticsTab(tabName) {
    switch (tabName) {
      case 'countAws':
        if (selectedPayerAccnt && startDate && endDate) {
          return (
            <CountAwsServices
              payerAccount={selectedPayerAccnt}
              startDate={dateFormatter(startDate)}
              endDate={dateFormatter(endDate)}
            />
          );
        }
        return '';
      case 'byAccount':
        if (selectedPayerAccnt && startDate && endDate) {
          return (
            <ServiceUsageDistribution
              payerAccount={selectedPayerAccnt}
              startDate={dateFormatter(startDate)}
              endDate={dateFormatter(endDate)}
            />
          );
        }
        return '';
      case 'byService':
        if (selectedPayerAccnt && startDate && endDate) {
          return (
            <ByAwsService
              payerAccount={selectedPayerAccnt}
              startDate={dateFormatter(startDate)}
              endDate={dateFormatter(endDate)}
            />
          );
        }
        return '';
      default:
        return '';
    }
  }

  return (
    <div className="row mb-2">
      <div className="col-12">
        <div className="card card-custom mb-4">
          <div className="card-header align-items-center">
            <div className="card-title align-items-center justify-content-between w-100">
              <h3 className="card-label col p-0">
                <div className="d-flex align-items-center justify-content-start">
                  <span>AWS Service Analytics </span>
                </div>
              </h3>
            </div>
          </div>
          <div className="card-body">
            <div className="row justify-content-center justify-content-center-xs align-items-center">
              <div className="col-12 col-md-2 col-sm-12">
                <label>Select Payer Account</label>
              </div>
              <div className="col-12 col-md-4 col-sm-12">
                <Select
                  value={selectedPayerAccnt}
                  options={payerAccountList}
                  onChange={(item) => {
                    setSelectedPayerAccnt(item);
                  }}
                  isClearable="true"
                  className=""
                  placeholder="Select Payer Account"
                />
              </div>
              <div className="col-12 col-md-2 col-sm-12">
                <label>Select Date Range</label>
              </div>
              <div className="col-12 col-md-4 col-sm-12">
                <DatePicker
                  selectsRange
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={new Date()}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="horizontal-tab">
                  <Tab
                    onClick={(e) => setTab(e)}
                    tabList={tabList}
                    selected="countAws"
                  />
                  <AwsServiceAnalyticsContext>
                    <div className="tab-content">{analyticsTab(tab)}</div>
                  </AwsServiceAnalyticsContext>
                </div>
              </div>
            </div>
            <div />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AwsServiceAnalytics;
