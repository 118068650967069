import React from 'react';
import { Link } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const AccessByUsers = (props) => {
  const { isOpen } = props;

  const renderStarts = (number) => {
    const lis = [];
    for (let i = 0; i < number; i++) {
      lis.push(<FontAwesomeIcon key={i} icon={faStar} />);
    }
    return lis;
  };

  function handleClick(value) {
    props.onClick(value);
  }

  function mapUserList(key, mapper) {
    const list = props.accountWithRole;
    const ret = {};
    list.forEach((i) => {
      const index = i[key];
      ret[index] = mapper(i, ret[index]);
    });
    return ret;
  }

  function mapUser() {
    const key = 'employeeId';
    return mapUserList(key, (i, current) => {
      if (!current) {
        current = {
          roles: [],
          employeeId: i[key],
          first: i.firstName,
          last: i.lastName,
          maxTrainingLevelAchieved: i.awsMaxTrainingLevelAchieved,
          maxTrainingLevelRankAchieved: i.awsMaxTrainingLevelRankAchieved,
        };
      }
      current.roles.push({
        code: i.roleCode,
        name: i.roleName || i.roleCode,
        minimumTrainingLevel: i.roleMinimumTrainingLevel,
        minimumTrainingLevelRank: i.roleMinimumTrainingLevelRank,
      });
      return current;
    });
  }

  function accountMarkup() {
    const byUser = mapUser();
    if (Object.keys(byUser).length > 0) {
      return Object.keys(byUser).map((employeeId) => {
        const user = byUser[employeeId];
        const { roles } = user;
        return (
          <div className="by-account" key={employeeId}>
            <div className="by-account-header">
              <div className="d-flex align-items-center justify-content-between font-size-lg xs-byAccount">
                <Link
                  to={`/userProfile/${user.employeeId}`}
                  className="text-primary font-weight-500 ml-xs-5 text-nowrap"
                >
                  {user.first}
                  {' '}
                  {user.last}
                </Link>
                <div className="accounts-role-rank">
                  <span className="d-flex align-items-center text-user-profile font-size-lg font-weight-500 mr-2 text-nowrap">
                    {user.maxTrainingLevelAchieved}
                    <span className="d-flex align-items-center text-secondary font-size-sm ml-2">
                      {renderStarts(user.maxTrainingLevelRankAchieved)}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="by-account-body">
              {roles.map((item) => {
                // Stars colors
                const achieved = user.maxTrainingLevelRankAchieved
                  >= item.minimumTrainingLevelRank;
                const color = achieved ? 'success' : 'danger';
                const achievedColor = achieved ? 'warning' : 'white';
                return (
                  <div
                    key={item.name}
                    className="d-flex align-items-center justify-content-between font-size-lg pd-btm-5"
                  >
                    <div className="d-flex justify-content-start align-items-center font-weight-500">
                      <div className="d-flex flex-column font-weight-500">
                        <div className="text-dark text-hover-primary mb-1 font-size-lg">
                          {item.name}
                        </div>
                      </div>
                    </div>

                    <div className={`badge badge-${color} accounts-role-rank`}>
                      <span className="d-flex align-items-center text-user-profile text-primary-hover font-size-lg font-weight-500 mr-2">
                        <span className="text-white">
                          {item.minimumTrainingLevel}
                        </span>
                        <span className={`star-${achievedColor}`}>
                          {user.maxTrainingLevelRankAchieved < 1 ? (
                            <span className="text-white-50">
                              {renderStarts(item.minimumTrainingLevelRank)}
                            </span>
                          ) : (
                            <span className="d-flex align-items-center text-secondary font-size-sm ml-2">
                              {renderStarts(item.minimumTrainingLevelRank)}
                            </span>
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      });
    }
    return '';
  }

  return (
    <div className={`card border-0 ${isOpen ? 'card-active' : ''}`}>
      <div className="card-header card-header2" id="headingTwo">
        <h5 className="mb-0">
          <button
            type="button"
            className={`btn btn-link ${!isOpen ? 'collapsed' : ''}`}
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="false"
            aria-controls="collapseOne"
            onClick={() => handleClick('byUsers')}
          >
            By User
          </button>
        </h5>
      </div>
      <div
        id="collapseTwo"
        className={`collapse ${isOpen ? 'show' : ''}`}
        aria-labelledby="headingTwo"
        data-parent="#accordion"
      >
        <div className="card-body px-0">
          <div className="account-section-tab">{accountMarkup()}</div>
        </div>
      </div>
    </div>
  );
};
export default AccessByUsers;
