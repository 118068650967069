import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeaderWithInfo from '../Components/Common/HeaderWithInfo';
import { getStockWidgets } from '../store/actions/vendorAction';
import BarChart from '../Components/Vendor/StockWidget/CloudServiceProviderCompare';
import LineChart from '../Components/Vendor/StockWidget/IndividualStockData';
import Alert from '../Components/Common/Alert';
import Loader from '../Components/Loader';
import { Context } from '../Context/MenuContext';
import { Context as PageContext } from '../Context/PageContext';
import { saveUserPageVisitHistory } from '../store/actions/peopleAction';

import '../Components/Vendor/StockWidget/StockWidget.css';

const StockWidget = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const [stockWidgetSate, setStockWidgetSate] = useState({
    amazonStockData: [],
    microsoftStockData: [],
    googleStockData: [],
    vendorCurrentStockPrice: [],
  });
  const [amazonData, setAmazonData] = useState([]);
  const [microsoftData, setMicrosoftData] = useState([]);
  const [googleData, setGoogleData] = useState([]);
  // Get selectedMenu data from Menu Context
  const [, setSelectedMenu, , setSelectedSubMenu] = useContext(Context);
  const [pageContextState, setPageContextState] = useContext(PageContext);
  const { stockWidgetPage } = pageContextState;
  const { user } = props;
  // Change menu css with peope page is loaded.
  useEffect(() => {
    setSelectedMenu('vendor');
    setSelectedSubMenu('cloudStockWidget');
  }, []);
  const dispatch = useDispatch();
  const vendorInfo = useSelector((state) => state.vendorInfo);
  const { stockWidgetLoading, stockWidgets, errorStockWidget } = vendorInfo;
  useEffect(() => {
    if (errorStockWidget !== '' && errorStockWidget !== undefined) {
      setErrorMessage(errorStockWidget);
      setLoaded(true);
      setShowError(true);
    }
  }, [errorStockWidget]);

  // Get user login details
  useEffect(() => {
    if (stockWidgetPage === '') {
      setPageContextState({
        ...pageContextState,
        stockWidgetPage: 'stockWidget',
      });
      const apiUrl = `/api/Auth/SaveUserPageVisit/${user.employeeId}/stockWidget`;
      dispatch(saveUserPageVisitHistory(apiUrl));
    }
  }, [stockWidgetPage]);

  function populateData() {
    const amazonStockData = stockWidgets.filter(
      (item) => item.vendorCode === 'amzn',
    )[0].stockWidgets;
    const googleStockData = stockWidgets.filter(
      (item) => item.vendorCode === 'googl',
    )[0].stockWidgets;
    const microsoftStockData = stockWidgets.filter(
      (item) => item.vendorCode === 'msft',
    )[0].stockWidgets;
    const vendorCurrentStockPrice = [
      { Date: amazonStockData[amazonStockData.length - 1].date },
      {
        AMZN: parseFloat(
          amazonStockData[amazonStockData.length - 1].close,
        ).toFixed(2),
      },
      {
        GOOGL: parseFloat(
          googleStockData[googleStockData.length - 1].close,
        ).toFixed(2),
      },
      {
        MSFT: parseFloat(
          microsoftStockData[microsoftStockData.length - 1].close,
        ).toFixed(2),
      },
    ];

    const amazonStocks = [];
    let i = 0;
    for (i = 0; i < amazonStockData.length; i++) {
      amazonStocks.push({
        x: new Date(amazonStockData[i].date).toLocaleDateString(),
        y: [
          parseFloat(amazonStockData[i].open).toFixed(2),
          parseFloat(amazonStockData[i].high).toFixed(2),
          parseFloat(amazonStockData[i].low).toFixed(2),
          parseFloat(amazonStockData[i].close).toFixed(2),
        ],
      });
    }

    const googleStocks = [];
    let j = 0;
    for (j = 0; j < googleStockData.length; j++) {
      googleStocks.push({
        x: new Date(googleStockData[j].date).toLocaleDateString(),
        y: [
          parseFloat(googleStockData[j].open).toFixed(2),
          parseFloat(googleStockData[j].high).toFixed(2),
          parseFloat(googleStockData[j].low).toFixed(2),
          parseFloat(googleStockData[j].close).toFixed(2),
        ],
      });
    }

    const microsoftStocks = [];
    let h = 0;
    for (h = 0; h < microsoftStockData.length; h++) {
      microsoftStocks.push({
        x: new Date(microsoftStockData[h].date).toLocaleDateString(),
        y: [
          parseFloat(microsoftStockData[h].open).toFixed(2),
          parseFloat(microsoftStockData[h].high).toFixed(2),
          parseFloat(microsoftStockData[h].low).toFixed(2),
          parseFloat(microsoftStockData[h].close).toFixed(2),
        ],
      });
    }

    setAmazonData(amazonStocks);
    setGoogleData(googleStocks);
    setMicrosoftData(microsoftStocks);

    setStockWidgetSate({
      vendorCurrentStockPrice,
      amazonStockData: amazonStocks.slice(amazonStocks.length - 15, amazonStocks.length),
      googleStockData: googleStocks.slice(googleStocks.length - 15, googleStocks.length),
      microsoftStockData: microsoftStocks.slice(microsoftStocks.length - 15, microsoftStocks.length),
    });
    setLoaded(true);
  }
  useEffect(() => {
    const apiUrl = '/api/Vendor/GetStockWidgets';
    dispatch(getStockWidgets(apiUrl));
  }, [dispatch]);

  useEffect(() => {
    if (stockWidgets?.length > 0 && errorMessage === '') {
      populateData();
    }
  }, [stockWidgets]);

  const {
    vendorCurrentStockPrice,
    amazonStockData,
    googleStockData,
    microsoftStockData,
  } = stockWidgetSate;

  const daysSelection = (e) => {
    const { name } = e.target;
    let value = parseInt(e.target.value, 10);
    value = value === 60 ? amazonData.length : value;
    if (name === 'amzn') {
      setStockWidgetSate({
        ...stockWidgetSate,
        amazonStockData: amazonData.slice(amazonData.length - value, amazonData.length),
      });
    } else if (name === 'msft') {
      setStockWidgetSate({
        ...stockWidgetSate,
        microsoftStockData: microsoftData.slice(microsoftData.length - value, microsoftData.length),
      });
    } else if (name === 'googl') {
      setStockWidgetSate({
        ...stockWidgetSate,
        googleStockData: googleData.slice(googleData.length - value, googleData.length),
      });
    }
  };
  return (
    <>
      {loaded ? (
        <div className="row mb-2">
          <div className="col-12">
            <div className="card card-custom mb-4">
              <div className="card-header align-items-center">
                <div className="card-title">
                  <HeaderWithInfo headerName="Cloud Stock Widgets" />
                </div>
              </div>
              <div className="card-body">
                {!stockWidgetLoading && errorMessage === '' && (
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-6 col-12 gutter-b">
                      <div className="stockwidget-section h-100">
                        <h5 className="text-left">Current Stock Price</h5>
                        <div className="mt-3">
                          <BarChart data={vendorCurrentStockPrice} />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-6 col-12 gutter-b">
                      <div className="stockwidget-section h-100">
                        <div className="d-flex justify-content-between align-items-center">
                          <h5 className="text-left">Amazon Stock Prices</h5>
                          <select name="amzn" id="amz" className="form-control-custom" onChange={daysSelection}>
                            <option value="15" defaultValue>15</option>
                            <option value="30">30</option>
                            <option value="60">60</option>
                          </select>
                        </div>
                        <div className="mt-3">
                          <LineChart
                            title="Amazon Stock Prices"
                            stockData={amazonStockData}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-6 col-12 gutter-b">
                      <div className="stockwidget-section h-100">
                        <div className="d-flex justify-content-between align-items-center">
                          <h5 className="text-left">Google Stock prices</h5>
                          <select name="googl" id="googl" className="form-control-custom" onChange={daysSelection}>
                            <option value="15" defaultValue>15</option>
                            <option value="30">30</option>
                            <option value="60">60</option>
                          </select>
                        </div>
                        <div className="mt-3">
                          <LineChart
                            title="Google Stock prices"
                            stockData={googleStockData}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-6 col-12 gutter-b">
                      <div className="stockwidget-section h-100">
                        <div className="d-flex justify-content-between align-items-center">
                          <h5 className="text-left">Microsoft Stock Prices</h5>
                          <select name="msft" id="msft" className="form-control-custom" onChange={daysSelection}>
                            <option value="15" defaultValue>15</option>
                            <option value="30">30</option>
                            <option value="60">60</option>
                          </select>
                        </div>
                        <div className="mt-3">
                          <LineChart
                            title="Microsoft Stock Prices"
                            stockData={microsoftStockData}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {showError && (
                  <Alert
                    message={errorMessage}
                    alertClass="light-danger"
                    onClose={() => setShowError(false)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default StockWidget;
