import axios from 'axios';

import {
  GET_CUMULATIVE_SERVICE_COST,
  ERROR_CUMULATIVE_SERVICE_COST,
  GET_ACCOUNT_VIEW_LIST,
  ERROR_ACCOUNT_VIEW_LIST,
  GET_ASCD_TIME_PERIODS,
  ERROR_ASCD_TIME_PERIODS,
  GET_AWS_SERVICE_COST_DETAILS,
  ERROR_AWS_SERVICE_COST_DETAILS,
  GET_SERVICE_LIST,
  ERROR_SERVICE_LIST,
  GET_COUNT_AWS_SERVICES,
  ERROR_COUNT_AWS_SERVICES,
  SET_SERVICE_LIST_LOADING,
  SET_AWS_COUNT_LOADING,
  GET_ACCOUNT_LIST,
  ERROR_ACCOUNT_LIST,
  SET_ACCOUNT_LIST_LOADING,
  GET_SERVICES_BY_ACCOUNT,
  SET_SERVICES_BY_ACCOUNT_LOADING,
  ERROR_SERVICES_BY_ACCOUNT,
  GET_ACCOUNT_SERVICES_MAPPING_USAGE,
  SET_ACCOUNT_SERVICES_MAPPING_USAGE_LOADING,
  ERROR_ACCOUNT_SERVICES_MAPPING_USAGE,
} from '../types';

/* eslint-disable import/prefer-default-export */
export const getCumulativeServiceCost = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_CUMULATIVE_SERVICE_COST,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_CUMULATIVE_SERVICE_COST,
      payload: error,
    });
  }
};

export const getAccountList = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_ACCOUNT_VIEW_LIST,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_ACCOUNT_VIEW_LIST,
      payload: error,
    });
  }
};

export const getTimePeriods = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_ASCD_TIME_PERIODS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_ASCD_TIME_PERIODS,
      payload: error,
    });
  }
};
export const getAwsServiceCostDetails = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_AWS_SERVICE_COST_DETAILS,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_AWS_SERVICE_COST_DETAILS,
      payload: error,
    });
  }
};
export const getServiceList = (apiUrl) => async (dispatch) => {
  dispatch({
    type: SET_SERVICE_LIST_LOADING,
    payload: true,
  });
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_SERVICE_LIST,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_SERVICE_LIST,
      payload: error,
    });
  }
};
export const getVendorAccountList = (apiUrl) => async (dispatch) => {
  dispatch({
    type: SET_ACCOUNT_LIST_LOADING,
    payload: true,
  });
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_ACCOUNT_LIST,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_ACCOUNT_LIST,
      payload: error,
    });
  }
};
export const getAwsServiceCount = (apiUrl) => async (dispatch) => {
  dispatch({
    type: SET_AWS_COUNT_LOADING,
    payload: true,
  });
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_COUNT_AWS_SERVICES,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_COUNT_AWS_SERVICES,
      payload: error,
    });
  }
};
export const getServicesByAccount = (apiUrl) => async (dispatch) => {
  dispatch({
    type: SET_SERVICES_BY_ACCOUNT_LOADING,
    payload: true,
  });
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_SERVICES_BY_ACCOUNT,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_SERVICES_BY_ACCOUNT,
      payload: error,
    });
  }
};
export const getAccountSerivesMappingUsage = (apiUrl) => async (dispatch) => {
  dispatch({
    type: SET_ACCOUNT_SERVICES_MAPPING_USAGE_LOADING,
    payload: true,
  });
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_ACCOUNT_SERVICES_MAPPING_USAGE,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_ACCOUNT_SERVICES_MAPPING_USAGE,
      payload: error,
    });
  }
};
