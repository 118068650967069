import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import addTreemapModule from 'highcharts/modules/treemap';
import HighchartsReact from 'highcharts-react-official';
import PropTypes from 'prop-types';
import Alert from '../../Common/Alert';

addTreemapModule(Highcharts);

const DetailedCost = (props) => {
  const [chart, setChart] = useState(null);
  const {
    accountId, asOf, data, isOpen, onClick,
  } = props;

  const getColor = () => `#${Math.floor(Math.random() * 16777215).toString(16)}`;

  const createParents = () => [...new Set(data.map((d) => d.serviceName))]
    .sort((a, b) => (a > b ? 1 : 0))
    .map((d) => ({ id: d, name: d, color: getColor() }));

  const createData = () => {
    const parents = createParents();

    const series = data.map((d) => ({
      name: d.usageFamily,
      value: Math.round(d.previousMonthSpend * 100) / 100,
      parent: d.serviceName,
    }));

    return parents.concat(series);
  };

  const createOptions = () => {
    const data = createData();
    setChart({
      chart: {
        height: 500,
        scrollablePlotArea: {
          minWidth: 600,
        },
        style: {
          fontFamily: 'Poppins, Helvetica, sans-serif',
        },
      },
      title: {
        text: '',
      },
      credits: {
        enabled: true,
        href: 'https://account.activedirectory.windowsazure.com/applications/redirecttofederatedapplication.aspx?Operation=SignIn&applicationId=b6d225fa-39e9-47c4-b4e0-524827757361&ApplicationConstName=customappsso&SingleSignOnType=Federated&ApplicationDisplayName=CSG%20Cloudability&tenantId=194a267b-82ba-4fa6-b6b9-f95966ab3815',
        text: `Cloudability Details for ${accountId}`,
      },
      series: [
        {
          type: 'treemap',
          layoutAlgorithm: 'stripes',
          alternateStartingDirection: true,
          levels: [
            {
              level: 1,
              layoutAlgorithm: 'sliceAndDice',
              dataLabels: {
                enabled: true,
                align: 'left',
                verticalAlign: 'top',
                style: {
                  fontSize: '15px',
                  fontWeight: 'bold',
                },
              },
            },
          ],
          data,
        },
      ],
      /* eslint-disable */
      tooltip: {
        formatter() {
          return `<b>${this.key}: <b/> $ ${this.point.value.toLocaleString()}`;
        },
      },
      /* eslint-disable */
    });
  };

  useEffect(() => {
    if (data) {
      createOptions();
    }
  }, [data]);

  useEffect(() => {
    createOptions();
  }, [accountId, asOf]);

  return (
    <>
      <div className={`card border-0 ${isOpen ? 'card-active' : ''}`}>
        <div className="card-header card-header2" id="headingOne">
          <h5 className="mb-0">
            <button
              type="button"
              className={`btn btn-link ${isOpen ? '' : 'collapsed'}`}
              data-toggle="collapse"
              data-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
              onClick={() => onClick('detailed')}
            >
              Detailed Cost for last month
            </button>
          </h5>
        </div>
        <div
          id="collapseThree"
          className={`collapse ${isOpen ? 'show' : ''}`}
          aria-labelledby="headingOne"
          data-parent="#accordion"
        >
          <div className="card-body px-0">
            {chart ? (
              <HighchartsReact
                constructorType="chart"
                highcharts={Highcharts}
                options={chart}
              />
            ) : (
              <>
                <Alert
                  message="No detail data is available to graph."
                  alertClass="info"
                />
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

DetailedCost.propTypes = {
  accountId: PropTypes.string.isRequired,
  asOf: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      usageFamily: PropTypes.string.isRequired,
      serviceName: PropTypes.string.isRequired,
      previousMonthSpend: PropTypes.number.isRequired,
      mtdSpend: PropTypes.number.isRequired,
      estimatedSpend: PropTypes.number.isRequired,
    }),
  ).isRequired,
};

export default DetailedCost;
