import React from 'react';
import PropTypes from 'prop-types';
import BigSearch from '../../Common/BigSearch';

const RequestSearch = (props) => {
  const {
    selectedValue,
    search,
    environments,
    requestStatuses,
    accountStatuses,
  } = props;

  const searchTypes = [
    {
      key: 'Account',
      display: 'Account',
      placeholder: 'The account name or number',
    },
    {
      key: 'Owner',
      display: 'Contact / Owner',
      placeholder: 'The name of the account contact / owner',
    },
    {
      key: 'Director',
      display: 'Executive / Director',
      placeholder:
        'The name of the director or executive the account owner is under',
    },
    {
      key: 'Product',
      display: 'Product',
      placeholder: 'The product code, name, subfamily or family',
    },
  ];

  const findIndex = (value) => searchTypes.findIndex((p) => p.key === (value || 'Account')) || 0;

  const filters = [
    {
      key: 'accountStatus',
      url: '/api/lookup/accountStatus',
      label: 'Statuses',
    },
    {
      key: 'environment',
      url: '/api/lookup/environments',
      label: 'Environments',
    },
    {
      key: 'requestStatus',
      url: '/api/lookup/requestStatus',
      label: 'Requests',
    },
  ];

  const executeSearch = (e) => {
    const { executeSearch } = props;
    const { bigSearch } = e;
    const { search, selectedValue, selectedFilters } = bigSearch;
    const { key } = selectedValue;
    const { accountStatus, requestStatus, environment } = selectedFilters;
    executeSearch(
      key,
      search,
      accountStatus || [],
      requestStatus || [],
      environment || [],
    );
  };

  const clearSearch = (e) => {
    const { clearSearch } = props;
    const { bigSearch } = e;
    const { selectedFilters } = bigSearch;
    const { accountStatus, requestStatus, environment } = selectedFilters;
    clearSearch(accountStatus || [], requestStatus || [], environment || []);
  };

  const accountStatusIndex = filters.findIndex((p) => p.key === 'accountStatus');
  const requestStatusIndex = filters.findIndex((p) => p.key === 'requestStatus');
  const environmentIndex = filters.findIndex((p) => p.key === 'environment');

  if (
    accountStatusIndex >= 0
    && accountStatuses !== undefined
    && String(accountStatuses) !== ''
  ) { filters[accountStatusIndex].initialSelection = accountStatuses.split(','); }

  if (
    requestStatusIndex >= 0
    && requestStatuses !== undefined
    && String(requestStatuses) !== ''
  ) { filters[requestStatusIndex].initialSelection = requestStatuses.split(','); }

  if (
    environmentIndex >= 0
    && environments !== undefined
    && String(environments) !== ''
  ) { filters[environmentIndex].initialSelection = environments.split(','); }

  return (
    <BigSearch
      srLabelTitle="Request Search"
      selectedIndex={findIndex(selectedValue)}
      search={search}
      executeSearch={executeSearch}
      filters={filters}
      clearSearch={clearSearch}
      searchTypes={searchTypes}
    />
  );
};

RequestSearch.propTypes = {
  selectedValue: PropTypes.string,
  search: PropTypes.string,
  requestStatuses: PropTypes.string,
  accountStatuses: PropTypes.string,
  environments: PropTypes.string,
  executeSearch: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
};
export default RequestSearch;
