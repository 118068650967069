import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../Common/Alert';
import Loader from '../Loader';

import {
  getCustomerCost,
  getCustomerCsvData,
} from '../../store/actions/ascendonAction';

const CloudabilityCustomerCost = React.memo(() => {
  const dispatch = useDispatch();
  const customerCostData = useSelector((state) => state.cloudabilityList);
  const {
    customerCostLoading,
    cloudabilityCustomerCosting,
    customerCsv,
    errorCloudabilityCustomerCosting,
  } = customerCostData;
  const customerCost = cloudabilityCustomerCosting;
  const [errorMessage, setErrorMessage] = useState('');
  const [filterColumn, setFilterColumn] = useState('');
  const [filterAction, setFilterAction] = useState('');
  useEffect(() => {
    dispatch(getCustomerCost('/api/AscendonCost/GetCostumerCostCsv'));
    dispatch(
      getCustomerCsvData('/api/AscendonCost/GetCustomerCostingBucketData'),
    );
  }, []);
  useEffect(() => {
    if (errorCloudabilityCustomerCosting !== '') {
      setErrorMessage(errorCloudabilityCustomerCosting);
    }
  }, [customerCostLoading]);

  function getColumns() {
    const columns = [
      {
        name: 'CustomerId',
        options: {
          display: false,
          download: true,
          filter: false,
          print: false,
          viewColumns: false,
          setCellProps: () => ({
            style: {
              background: '#f3f6f9',
            },
          }),
        },
      },
    ];
    columns.push({
      name: 'Customer',
      options: {
        download: true,
        filter: true,
        viewColumns: false,
        sort: true,
        setCellHeaderProps: () => ({
          style: {
            position: 'sticky',
            left: 0,
            background: '#f3f6f9',
            zIndex: 8,
          },
        }),
        setCellProps: () => ({
          className: 'xsBgColorCell',
          style: {
            position: 'sticky',
            left: 0,
            background: 'transparent',
            zIndex: 1,
          },
        }),
      },
    });
    /* eslint-disable */
    const months = customerCost[0].products[0].monthlyData
    for (const val in months) {
      columns.push({
        name: months[val].month,
        options: {
          download: true,
          filter: true,
          sort: true,
          display: !(
            months[val].month === filterColumn && filterAction === 'remove'
          ),
          setCellHeaderProps: () => ({
            className: 'centeredHeaderCell',
          }),
          setCellProps: () => ({
            style: {
              textAlign: 'center',
              width: '175px',
              paddingLeft: '35px',
            },
          }),
        },
      })
    }
    /* eslint-enable */
    columns.push({
      name: 'Total',
      options: {
        download: true,
        filter: false,
        sort: true,
        setCellHeaderProps: () => ({
          className: 'centeredHeaderCell',
        }),
        setCellProps: () => ({
          style: {
            textAlign: 'center',
            width: '175px',
            paddingLeft: '35px',
          },
        }),
      },
    });

    // dummy columns to pass for custom download
    const dummyArr = Array(50).fill({
      name: 'dummy',
      options: {
        viewColumns: false,
        filter: false,
        display: false,
        sort: false,
        print: false,
        download: true,
      },
    });
    return [...columns, ...dummyArr];
  }
  // Format Cost into currency for table
  function formatDataForTable(srvCost) {
    if (srvCost) {
      return srvCost.map((data) => data.map((val) => (typeof val === 'number' && val > 0
        ? val.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
        : val)));
    }
    return [];
  }
  function getMonths(monthList) {
    /* eslint-disable */
    const months = monthList
      ?.filter(function (m) {
        if (filterAction === 'remove' && filterColumn === m.month) {
          return false // skip
        }
        return true
      })
      .map(function (m) {
        return m.month
      })
    return months
  }
  // this function will create Customer costing data by Monthly for MuiTableData
  function getMonthlyCostByCustomer() {
    const customerAndMonthlyCosts = []
    const allMonths = customerCost[0]?.products[0]?.monthlyData
    for (const cust in customerCost) {
      for (const month in allMonths) {
        let cost = 0
        if (allMonths[month].month !== filterColumn || filterAction === 'add') {
          for (const pro in customerCost[cust].products) {
            const monthCost = customerCost[cust].products[
              pro
            ].monthlyData.filter(
              (item) => item.month === allMonths[month].month,
            )[0].cost
            cost += monthCost
          }
        }
        const found = customerAndMonthlyCosts.some(
          (el) => el[0] === customerCost[cust].customerName,
        )
        if (found) {
          const foundIndex = customerAndMonthlyCosts.findIndex(
            (x) => x[0] === customerCost[cust].customerName,
          )
          const indexItem =
            customerAndMonthlyCosts[foundIndex][parseInt(month) + 2]
          customerAndMonthlyCosts[foundIndex][parseInt(month) + 2] =
            (indexItem ? parseFloat(indexItem) : 0) +
            parseFloat(cost.toFixed(2))
        } else {
          const innerArray = []
          innerArray[0] = customerCost[cust].customerName
          innerArray[1] = customerCost[cust].customerName
          innerArray[parseInt(month) + 2] = cost
          customerAndMonthlyCosts.push(innerArray)
        }
      }
    }
    for (const v in customerAndMonthlyCosts) {
      let customerName = ''
      customerAndMonthlyCosts[v][1] =
        customerName.length > 0
          ? customerName
          : customerAndMonthlyCosts[v][0].toUpperCase()
      let cost = 0
      for (const month in allMonths) {
        if (allMonths[month].month !== filterColumn || filterAction === 'add') {
          cost += customerAndMonthlyCosts[v][parseInt(month) + 2] ?? 0
        }
        customerAndMonthlyCosts[v][parseInt(month) + 2] = parseFloat(
          customerAndMonthlyCosts[v][parseInt(month) + 2] ?? 0,
        )
      }
      customerAndMonthlyCosts[v][allMonths.length + 2] = cost
    }
    const customerAndMonthlyCostsSorted = formatDataForTable(
      sorting(customerAndMonthlyCosts, 5),
    )
    return customerAndMonthlyCostsSorted
  }
  // indexNo for which cost need sorting
  function sorting(data, indexNo){  
   return data.sort(function(a, b){
      if(a[indexNo]>b[indexNo]){
          return -1;
      }
      if(a[indexNo]<b[indexNo]){
          return 1;
      }
      return 0;
  });
 }
  // this function will create collapse row data for perticular customer in MuiTableData
  function getCollapseRowData(customerId) {
    const collapseRowData = []
    const months = getMonths(customerCost[0]?.products[0]?.monthlyData)
    /* eslint-disable */
    const productsCost = customerCost
      .filter((a) => a.customerName == customerId)
      .map((pro) => pro.products)[0]
    for (var val in productsCost) {
      const innerArray = []
      innerArray[0] = productsCost[val].productName
      let totalCost = 0
      for (var month in months) {
        const cost = parseFloat(
          productsCost[val].monthlyData
            .filter((a) => a.month == months[month])[0]
            .cost.toFixed(2),
        )
        totalCost += cost
        innerArray[parseInt(month) + 1] = parseFloat(cost)
      }
      if (
        filterColumn !== 'Total' ||
        (filterColumn === 'Total' && filterAction === 'add')
      ) {
        innerArray[months.length + 1] = parseFloat(totalCost.toFixed(2))
      }
      if (innerArray[innerArray.length - 1] != 0) {
        collapseRowData.push(innerArray);
      }
    }

    for (const v in collapseRowData) {
      for (const month in months) {
        collapseRowData[v][parseInt(month) + 1] = parseFloat(
          collapseRowData[v][parseInt(month) + 1],
        )
      }
    }
    const collapseRowDataSorted = formatDataForTable(
      sorting(collapseRowData, 4)
    )
    /* eslint-enable */
    return collapseRowDataSorted;
  }
  // this is options value which will provide all the product cost in collapse rows by customer
  const collapseOptions = {
    filter: true,
    filterType: 'multiselect',
    fixedHeader: true,
    fixedSelectColumn: true,
    responsive: 'scroll',
    rowsPerPage: 10,
    expandableRows: true,
    expandableRowsHeader: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    print: false,
    /* eslint-disable */
    customSort: (data, colIndex, order) => {
      return data.sort((a, b) => {
        if (a.data[colIndex] !== 0 && b.data[colIndex] !== 0) {
          if (Number(a.data[colIndex].replace(/[^0-9.-]+/g, '')) > 0) {
            return (
              (Number(a.data[colIndex].replace(/[^0-9.-]+/g, '')) <
              Number(b.data[colIndex].replace(/[^0-9.-]+/g, ''))
                ? -1
                : 1) * (order === 'desc' ? 1 : -1)
            )
          } else {
            return (
              (a.data[colIndex] < b.data[colIndex] ? -1 : 1) *
              (order === 'desc' ? 1 : -1)
            )
          }
        }
      })
    },
    downloadOptions: { filename: 'CustomerCostingData.csv' },
    onDownload: (buildHead, buildBody) => {
      let header = {}
      const formattedData = []
      customerCsv.split('\n').forEach((row, i) => {
        // header
        if (i === 0) {
          header = row.split(',').map((c) => ({
            name: c.trim(),
            download: true,
          }))
        } else {
          // column data
          formattedData.push({
            data: row.split(',').map((r) => r.trim()),
            index: i - 1,
          })
        }
      })
      return buildHead(header) + buildBody(formattedData)
    },
    renderExpandableRow: (rowData) => {
      const [firstRowData] = rowData
      const data = getCollapseRowData(firstRowData)
      return (
        <>
          <tr>
            <td colSpan={6}>
              <TableContainer component={Paper}>
                <Table style={{ minWidth: '650' }} aria-label="simple table">
                  <TableBody>
                    {data.map((row) => (
                      <TableRow key={row[0]}>
                        <TableCell style={{ width: '48px' }} />
                        <TableCell component="th" scope="row">
                          {row[0]}
                        </TableCell>
                        {row.map((item) => {
                          if (item != row[0]) {
                            return (
                              <TableCell
                                style={{ textAlign: 'center', width: '175px', paddingLeft: '35px' }}
                              >
                                {item}
                              </TableCell>
                            )
                          }
                        })}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>
        </>
      )
    },
    onViewColumnsChange: (column, action) => {
      setFilterColumn(column)
      setFilterAction(action)
    },
    page: 0,
  }
  return (
    <div className="mui-custom-colmn-table">
      {customerCostLoading ? (
        <Loader />
      ) : (
        customerCost &&
        customerCost.length > 0 && (
          <MUIDataTable
            data={getMonthlyCostByCustomer()}
            columns={getColumns()}
            options={collapseOptions}
          />
        )
      )}
      {errorMessage && (
        <div className="col-12">
          <Alert
            message={errorMessage}
            alertClass="light-danger"
            onClose={(val) => setErrorMessage(val)}
          />
        </div>
      )}
    </div>
  )
})

export default CloudabilityCustomerCost
