import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';

const TrainingOverrideHistory = (props) => {
  const { data, vendor } = props;

  const [tableData, setTableData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  function getColumn() {
    const columns = [
      {
        name: 'Employee Name',
        options: {
          display: true,
          download: true,
          filter: false,
          print: false,
          viewColumns: false,
        },
      },
    ];
    columns.push({
      name: 'Certification Title',
      options: {
        display: true,
        download: true,
        filter: false,
        print: false,
        viewColumns: false,
      },
    });
    columns.push({
      name: 'Certification Number',
      options: {
        display: true,
        download: true,
        filter: false,
        print: false,
        viewColumns: false,
      },
    });
    columns.push({
      name: 'Certification Uploaded',
      options: {
        display: true,
        download: true,
        filter: false,
        print: false,
        viewColumns: false,
      },
    });
    columns.push({
      name: 'Date of Request',
      options: {
        display: true,
        download: true,
        filter: false,
        print: false,
        viewColumns: false,
      },
    });
    return [...columns];
  }

  const onChangeRowsPerPage = (rowsPerPagee) => {
    setRowsPerPage(rowsPerPagee);
  };

  useEffect(() => {
    if (data) {
      const formattedData = data
        .map((d) => ({
          EmployeeName: d.employeeName,
          CertificationTitle: d.certificationTitle,
          CertificationNumber: d.certificationNumber,
          CertificationUploaded: d.certificationUploaded,
          RequestedDate: new Date(d.requestedDate).toLocaleDateString(),
        }));
      setTableData(formattedData.map(Object.values));
    }
  }, [data]);

  const options = {
    filter: true,
    filterType: 'dropdown',
    rowsPerPage,
    rowsPerPageOptions: [10, 20],
    onChangeRowsPerPage,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    downloadOptions: { filename: `${vendor}-Training-Override-History.csv` },
    responsive: 'standard',
  };

  return (
    <>
      <MUIDataTable
        data={tableData}
        columns={getColumn()}
        options={options}
      />
    </>
  );
};

export default TrainingOverrideHistory;
