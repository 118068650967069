import React, { useState } from 'react';

const initialState = {
  currentProduct: 'all',
  businessUnit: 'all',
  timePeriods: 'last_week',
  currentView: 'Ascendon',
  region: 'all_region',
  filterLoaded: false,
};

export const Context = React.createContext();

export const CloudabilityContext = ({ children }) => {
  const [cloudabilityFilter, setCloudabilityFilter] = useState(initialState);
  return (
    <Context.Provider value={[cloudabilityFilter, setCloudabilityFilter]}>
      {children}
    </Context.Provider>
  );
};
