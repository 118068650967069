import React, { useContext } from 'react';
import List from './List';
import Loader from '../../Loader';

// Contexts
import { Context } from '../../../Context/VendorContext';

const ServicesResults = () => {
  const [serviceFilter] = useContext(Context);
  const {
    displayMode,
    regions,
    services,
    availableList,
    unavailableList,
    tempUnavailableList,
    loadedResult,
  } = serviceFilter;

  const displayModes = {
    all: {
      titleFirstList: 'All Regions',
      titleSecondList: 'All Services',
      titleThirdList: '',
      classFirstList: 'col-lg-5 col-md-6 service-sec-avail',
      classSecondList: 'col-lg-5 col-md-6 service-sec-avail',
      classThirdList: '',
      showThirdList: false,
      availabilityCheck: false,
      notFoundMessage: 'Data not found.',
    },
    region: {
      titleFirstList: 'Service Available',
      titleSecondList: 'Service Not Available',
      titleThirdList: 'Services Outage',
      classFirstList: 'col-lg-4 col-md-5 service-sec-avail',
      classSecondList: 'col-lg-4 col-md-5 service-sec-notavail',
      classThirdList: 'col-lg-4 col-md-10 service-sec-unavail',
      showThirdList: tempUnavailableList.length > 0,
      availabilityCheck: true,
      notFoundMessage: 'This Region provides all the services.',
    },
    service: {
      titleFirstList: 'Region Available',
      titleSecondList: 'Region Not Available',
      titleThirdList: '',
      classFirstList: 'col-lg-5 col-md-6 service-sec-avail',
      classSecondList: 'col-lg-5 col-md-6 service-sec-notavail',
      classThirdList: '',
      showThirdList: false,
      availabilityCheck: true,
      notFoundMessage: 'This Service is available for all Regions.',
    },
  };
  return (
    <>
      {loadedResult
        ? (
          <div className="row">
            <div className="col-12">
              <div className="card card-custom mb-4">
                <div className="card-body">
                  <div className="row justify-content-center">
                    <List
                      title={displayModes[displayMode].titleFirstList}
                      className={displayModes[displayMode].classFirstList}
                      data={displayModes[displayMode].availabilityCheck ? availableList : regions}
                      countLabel={`Total Count: ${displayModes[displayMode].availabilityCheck ? availableList.length : regions.length}`}
                      notFoundMessage={displayModes[displayMode].notFoundMessage}
                    />
                    <List
                      title={displayModes[displayMode].titleSecondList}
                      className={displayModes[displayMode].classSecondList}
                      data={displayModes[displayMode].availabilityCheck ? unavailableList : services}
                      countLabel={`Total Count: ${displayModes[displayMode].availabilityCheck ? unavailableList.length : services.length}`}
                      notFoundMessage={displayModes[displayMode].notFoundMessage}
                    />
                    { displayModes[displayMode].showThirdList
              && (
              <List
                title={displayModes[displayMode].titleThirdList}
                className={displayModes[displayMode].classThirdList}
                data={tempUnavailableList}
                countLabel={`Total Count: ${tempUnavailableList.length}`}
                notFoundMessage={`${tempUnavailableList.length === 0 && 'Currently all services are available.'}`}
              />
              )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        : <Loader />}
    </>
  );
};

export default ServicesResults;
