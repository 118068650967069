import React from 'react';

const LogOutModel = (props) => {
  const { isOpen } = props;
  const modalStyle = {
    display: `${isOpen ? 'block' : 'none'}`,
    paddingRight: '4px',
  };
  const handleClick = () => {
    localStorage.setItem('pageName', 'signout');
    localStorage.setItem('signOutTime', new Date());
  };
  return (
    <div
      className={`modal fade ${isOpen ? 'show' : ''}`}
      id="logoutModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      style={modalStyle}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Ready to Leave?
            </h5>
            <button
              className="close"
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => props.onChange(false)}
            >
              <span aria-hidden="true">x</span>
            </button>
          </div>
          <div className="modal-body">
            Select &quot;Logout&quot; below if you are ready to end your current
            session.
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-secondary"
              type="button"
              data-dismiss="modal"
              onClick={() => props.onChange(false)}
            >
              Cancel
            </button>
            <a className="btn btn-primary" href="/api/auth/signout" onClick={() => handleClick()}>
              Logout
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogOutModel;
