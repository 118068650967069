import React from 'react';

const CloudGuruInfo = () => (
  <div className="row">
    <div className="col-12">
      <h4 className="custom-tooltip-text">
        <ul className="pl-4">
          <li>Displaying the active and inactive members based on the last login time frame of 30days.</li>
          <li>On click of active or inactive cards, detailed information of the respective members are displayed below in the form of graph and table. Please click on the Table/Graph pill to view the same.</li>
          <li>
            <strong>Table:</strong>
            {' '}
            shows the detailed information about the members
          </li>
          <li>
            <strong>Graph:</strong>
            {' '}
            shows the manager to active/ inactive members count distribution
          </li>
          <li>
            Click on
            {' '}
            <strong>Manager&lsquo;s Seat Allocated</strong>
            {' '}
            button to see the distribution of seats allocated across manager.
          </li>
        </ul>
      </h4>
    </div>
  </div>
);

export default CloudGuruInfo;
