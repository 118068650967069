import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import {
  getAccountSerivesMappingUsage,
  getVendorAccountList,
  getServicesByAccount,
} from '../../../store/actions/awsServicesAction';
import BasicBarChart from '../../Common/BasicBarChart';
import Loader from '../../Loader';
import Alert from '../../Common/Alert';

const ByAwsService = ({ payerAccount, startDate, endDate }) => {
  const dispatch = useDispatch();
  const awsServiceState = useSelector((state) => state.awsServicesInfo);
  const {
    errorAwsServiceCount,
    accountList,
    servicesByAccount,
    servicesByAccountLoading,
    errorServicesByAccount,
    accountServiceUsageMapping,
    accountServiceUsageMappingLoading,
    errorAccountServiceUsageMapping,
  } = awsServiceState;
  const [formattedServiceList, setFormattedServiceList] = useState({ multiValue: [], filterOptions: [] });
  const [formattedAccountList, setFormattedAccountList] = useState({ multiValue: [], filterOptions: [] });
  const [formattedAwsCountData, setFormattedAwsCountData] = useState(null);
  const [origAccSrvMappData, setOrigAccSrvMappData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [showError, setShowError] = useState(false);
  const prevAccSelectData = useRef(null);
  const prevSerSelectData = useRef(null);

  // fetch account and service usage on payer account level
  const onPageLoadServiceByPayerAccount = () => {
    setLoading(true);
    const filtersQryStrg = `?filters=account_identifier==${payerAccount.value}`;
    dispatch(getServicesByAccount(`/api/AwsAnalytics/GetTotalUsageBySerives/${startDate}/${endDate}/custom${filtersQryStrg}`));
    dispatch(getAccountSerivesMappingUsage(`/api/AwsAnalytics/GetAccountSerivesMappingUsage/${startDate}/${endDate}`));
    dispatch(getVendorAccountList('/api/AwsAnalytics/GetAccountNameByPayerAccount'));
  };
  useEffect(() => {
    if (startDate && endDate) {
      onPageLoadServiceByPayerAccount();
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (accountList?.result?.length > 0) {
      onPageLoadServiceByPayerAccount();
    }
  }, [payerAccount]);

  useEffect(() => {
    setErrorMessage(`${errorAwsServiceCount} ${errorServicesByAccount}${errorAccountServiceUsageMapping}`);
    setShowError(true);
    setLoading(false);
  }, [errorAwsServiceCount, errorServicesByAccount, errorAccountServiceUsageMapping]);

  useEffect(() => {
    if (accountList?.result?.length > 0) {
      const vndAccList = accountList.result.filter((ac) => ac.parentAccountId && ac.parentAccountId === payerAccount.value)
        .sort((a, b) => ((a.vendorAccountName > b.vendorAccountName) ? 1 : ((b.vendorAccountName > a.vendorAccountName) ? -1 : 0)));
      setFormattedAccountList({
        multiValue: [],
        filterOptions: vndAccList?.map((a) => ({ value: a.vendorAccountId, label: a.vendorAccountName })),
      });
    }
  }, [accountList]);

  useEffect(() => {
    const services = [];
    setLoading(true);
    if (accountServiceUsageMapping?.length > 0) {
      accountServiceUsageMapping.forEach((o) => {
        const found = services.some((el) => el.value === o.dimensions[1]);
        if (!found) {
          services.push({ value: o.dimensions[1], label: o.dimensions[1] });
        }
      });
      setFormattedServiceList({
        multiValue: [],
        filterOptions: services,
      });
      setOrigAccSrvMappData(accountServiceUsageMapping);
      setLoading(false);
    }
  }, [accountServiceUsageMapping]);

  const filterServicesByAccount = (filtertedAccount) => {
    const services = [];
    if (origAccSrvMappData?.length > 0) {
      origAccSrvMappData.forEach((o) => {
        const serviceFound = services.some((el) => el.value === o.dimensions[1]);
        const accountFound = filtertedAccount.some((el) => el.label === o.dimensions[0]);
        if (!serviceFound && accountFound) {
          services.push({ value: o.dimensions[1], label: o.dimensions[1] });
        }
      });
      setFormattedServiceList({
        multiValue: [],
        filterOptions: services,
      });
    }
  };
  useEffect(() => {
    // this api provide service usage data by account name
    if (servicesByAccount && servicesByAccount.length > 0) {
      const data = servicesByAccount.sort((a, b) => b.metrics[0].sum - a.metrics[0].sum);
      const reducedData = data.reduce((r, a) => {
        r[a.dimensions[0]] = parseFloat(a.metrics[0].sum).toFixed(2);
        return r;
      }, {});
      setFormattedAwsCountData(reducedData);
    } else if (!servicesByAccountLoading) {
      setFormattedAwsCountData([]);
      setLoading(false);
    }
  }, [servicesByAccount]);
  const accountLoadedByService = () => {
    // this api provide service account mapping usage data
    if (accountServiceUsageMapping && accountServiceUsageMapping.length > 0) {
      let accountServiceUsage = [];
      formattedServiceList.multiValue.forEach((element) => {
        accountServiceUsage = accountServiceUsageMapping
          .filter((a) => a.dimensions[1] === element.label);
      });
      const data = accountServiceUsage.sort((a, b) => b.metrics[0].sum - a.metrics[0].sum);
      const reducedData = data.reduce((r, a) => {
        r[a.dimensions[0]] = parseFloat(a.metrics[0].sum).toFixed(2);
        return r;
      }, {});
      setFormattedAwsCountData(reducedData);
      const accList = accountServiceUsage.map((s) => ({ value: s.dimensions[0], label: s.dimensions[0] }));
      setFormattedAccountList({
        multiValue: [],
        filterOptions: accList,
      });
      setOrigAccSrvMappData(accountServiceUsageMapping);
    }
  };

  useEffect(() => {
    // set loading is false only when formattedawscountdata is changed
    if (formattedServiceList?.filterOptions?.length > 0) {
      setLoading(false);
    }
  }, [formattedAwsCountData]);

  useEffect(() => {
    setErrorMessage('');
    setShowError(false);
    try {
      if (formattedServiceList.multiValue?.length > 0 && formattedAccountList.multiValue?.length > 0) {
        prevSerSelectData.current = formattedServiceList.multiValue;
        const services = formattedServiceList.multiValue.map((s) => (s.value));
        const accounts = formattedAccountList.multiValue.map((s) => (s.label));
        const result = [];
        // filter data from account and service mapping json data by accounts and service selection
        const accountServiceUsage = origAccSrvMappData
          .filter((a) => accounts.includes(a.dimensions[0]) && services.includes(a.dimensions[1])).map((a) => ({ name: a.dimensions[0], value: a.metrics[0].sum })).reduce((res, value) => {
            if (!res[value.name]) {
              res[value.name] = 0;
              result.push(res[value]);
            }
            res[value.name] = parseFloat(res[value.name]) + parseFloat(value.value);
            return res;
          }, {});
        setFormattedAwsCountData(accountServiceUsage);
      } else if (formattedServiceList.multiValue?.length > 0) {
      // fetch data on service filter
        prevSerSelectData.current = formattedServiceList.multiValue;
        accountLoadedByService();
      } else if (prevSerSelectData.current !== null) {
      // fetch again default data when remove filter from service dropdown data which is payer level all service and accounts usages
        prevSerSelectData.current = null;
        onPageLoadServiceByPayerAccount();
      }
    } catch (err) {
      setErrorMessage(err.message);
      setShowError(true);
      setLoading(false);
    }
  }, [formattedServiceList.multiValue]);

  const serviceHandleChange = (item) => {
    setFormattedServiceList({ ...formattedServiceList, multiValue: item });
  };
  useEffect(() => {
    if (formattedAccountList.multiValue?.length > 0 && formattedServiceList.multiValue?.length > 0) {
      prevAccSelectData.current = formattedAccountList.multiValue;
      const services = formattedServiceList.multiValue.map((s) => (s.value));
      const accounts = formattedAccountList.multiValue.map((s) => (s.label));
      const result = [];
      // filter data from account and service mapping json data by accounts and service selection
      const accountServiceUsage = origAccSrvMappData
        .filter((a) => accounts.includes(a.dimensions[0]) && services.includes(a.dimensions[1])).map((a) => ({ name: a.dimensions[0], value: a.metrics[0].sum })).reduce((res, value) => {
          if (!res[value.name]) {
            res[value.name] = 0;
            result.push(res[value]);
          }
          res[value.name] = parseFloat(res[value.name]) + parseFloat(value.value);
          return res;
        }, {});

      setFormattedAwsCountData(accountServiceUsage);
    } else if (formattedAccountList.multiValue?.length > 0) {
      // fetch data when account filter apply
      prevAccSelectData.current = formattedAccountList.multiValue;
      setLoading(true);
      let filtersQryStrg = '?';
      formattedAccountList.multiValue.forEach((element) => {
        filtersQryStrg += `filters=vendor_account_name==${element.label}&`;
      });
      dispatch(getServicesByAccount(`/api/AwsAnalytics/GetTotalUsageBySerives/${startDate}/${endDate}/custom${filtersQryStrg}`));
      filterServicesByAccount(formattedAccountList.multiValue);
    } else if (prevAccSelectData.current !== null) {
      // fetch again default data when remove filter data which is payer level all service and accounts usages
      prevAccSelectData.current = null;
      onPageLoadServiceByPayerAccount();
    }
  }, [formattedAccountList.multiValue]);

  const accountHandleChange = (item) => {
    setFormattedAccountList({ ...formattedAccountList, multiValue: item });
  };

  return (
    <>
      <div className="tab-pane active" id="home-v">
        {servicesByAccountLoading || loading || accountServiceUsageMappingLoading ? (
          <Loader />
        ) : (
          <div className="form-group">
            {formattedServiceList?.filterOptions?.length > 0 && (
            <div className="row justify-content-center justify-content-center-xs align-items-center">
              <div className="col-12 col-md-6 col-sm-12">
                <Select
                  name="accountListFilter"
                  value={formattedAccountList.multiValue}
                  options={formattedAccountList.filterOptions}
                  onChange={accountHandleChange}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select AWS Account filter"
                  isMulti
                />
              </div>
              <div className="col-12 col-md-6 col-sm-12">
                <Select
                  name="serviceListFilter"
                  value={formattedServiceList.multiValue}
                  options={formattedServiceList.filterOptions}
                  onChange={serviceHandleChange}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  placeholder="Select AWS Services filter"
                  isMulti
                />
              </div>
            </div>
            )}
            <div className="row mt-8">
              <div className="col-12">
                {showError
                  ? (
                    <Alert
                      message={errorMessage}
                      alertClass="light-danger"
                      onClose={() => setShowError(false)}
                    />
                  ) : (
                    !errorMessage && (
                      formattedAwsCountData && !errorAwsServiceCount && (
                      <BasicBarChart
                        barChartCategories={Object.keys(formattedAwsCountData)}
                        barChartSeries={Object.values(formattedAwsCountData)}
                        valueName=" Total AWS Usage Count"
                      />
                      )))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ByAwsService;
