import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, navigate } from '@reach/router';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faLevelUpAlt, faDownload } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import CsvDownloader from 'react-csv-downloader';
import ApiTypeAhead from '../Components/Common/ApiTypeAhead';
import HeaderWithInfo from '../Components/Common/HeaderWithInfo';
import Loader from '../Components/Loader';
import EducationResults from '../Components/Education/Results';
import Alert from '../Components/Common/Alert';
import { Context } from '../Context/MenuContext';
import noData from '../Images/no-data.png';
import { Context as PageContext } from '../Context/PageContext';
import { saveUserPageVisitHistory } from '../store/actions/peopleAction';

const Education = (props) => {
  const { user } = props;
  const [errorMessage, setErrorMessage] = useState('');
  const [isContentEditable, setIsContentEditable] = useState(false);
  const [request, setRequest] = useState({ supervisorId: undefined });
  const [results, setResults] = useState({ reports: [] });
  const [loaded, setLoaded] = useState(false);
  const [pageContextState, setPageContextState] = useContext(PageContext);
  const { educationPage } = pageContextState;
  // Get selectedMenu data from Menu Context
  const [, setSelectedMenu] = useContext(Context);
  const dispatch = useDispatch();

  // Get user login details
  useEffect(() => {
    if (educationPage === '') {
      setPageContextState({
        ...pageContextState,
        educationPage: 'education',
      });
      const apiUrl = `/api/Auth/SaveUserPageVisit/${user.employeeId}/education`;
      dispatch(saveUserPageVisitHistory(apiUrl));
    }
  }, [educationPage]);

  // Change menu css with peope page is loaded.
  useEffect(() => {
    setSelectedMenu('education');
  }, []);

  const parseQueryString = (parseString) => {
    const { location } = parseString;
    const { search } = location;
    return queryString.parse(search);
  };

  useEffect(() => {
    const qs = parseQueryString(props);
    let sid = user.employeeId;
    if (qs.s) {
      sid = qs.s;
    }
    setRequest({ supervisorId: sid });
  }, []);

  const executeSearch = () => {
    if (parseInt(request.supervisorId, 10)) {
      setLoaded(false);
      axios
        .get(`api/people/${request.supervisorId}/reports`)
        .then((response) => {
          setResults(response.data);
          setErrorMessage('');
          setLoaded(true);
          setIsContentEditable(
            user.isAdmin === true
            || user.employeeId === response.data.employeeId,
          );
          navigate(`/education?s=${response.data.employeeId}`, {
            replace: true,
          });
        })
        .catch((err) => {
          if (err.name !== 'AbortError') {
            setErrorMessage(err.message);
            setLoaded(true);
          }
        });
    } else {
      setResults({ report: [] });
    }
  };

  useEffect(() => {
    executeSearch();
  }, [request]);

  const changeSupervisor = (sid) => {
    setRequest({ supervisorId: sid });
  };

  const renderStars = (number) => {
    const lis = [];
    for (let i = 0; i < number; i++) {
      lis.push(<FontAwesomeIcon key={i} icon={faStar} />);
    }
    return lis;
  };

  const [inputValue, setInputValue] = useState('');

  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    if (name === 'supervisorId') {
      changeSupervisor(value);
    }
    // Get searched input value
    setInputValue(value);
  };

  const CSVData = () => {
    const trainingEducationList = [];
    for (let i = 0; i < results.reports.length; i++) {
      trainingEducationList.push({
        Name: results.reports[i].name,
        CurrentAWSLicense: results.reports[i].awsMaxTrainingLevelAchieved,
        CurrentAzureLicense: results.reports[i].azMaxTrainingLevelAchieved,
        ExpectedLicense: results.reports[i].suggestedTrainingLevel,
        AWSLicenseProgress: `${results.reports[i].awsCurrentTrainingLevelPercent}%`,
        AzureLicenseProgress: `${results.reports[i].azCurrentTrainingLevelPercent}%`,
        AWSLastLogin: results.reports[i].lastLogin !== null ? results.reports[i].lastLogin : '-',
        AzuremLastLogin: results.reports[i].lastLogin !== null ? results.reports[i].lastLogin : '-',
      });
    }
    return trainingEducationList;
  };

  return (
    <div className="row mb-2">
      <div className="col-12">
        <div className="card card-custom mb-4">
          <div className="card-header align-items-center">
            <div className="card-title align-items-center justify-content-between w-100">
              <HeaderWithInfo headerName="Education" />
            </div>
          </div>
          {loaded ? (
            <>
              <div className="card-body education-search">
                <div className="row">
                  <div className="col-12">
                    <div className="row justify-content-left align-items-end">
                      <div className="col-12 col-sm-8 col-md-7 col-lg-7 col-xl-8">
                        <div className="form-group">
                          <ApiTypeAhead
                            name="supervisorId"
                            large
                            defaultId={results.employeeId || user.employeeId}
                            displayKey="supervisorName"
                            query={`${results.firstName || user.firstName} ${results.lastName || user.lastName}`}
                            title="Manager Name"
                            placeholder="start typing to search"
                            url="/api/lookup/supervisors"
                            onChange={handleChange}
                            value={inputValue}
                            required
                          />
                        </div>
                      </div>
                      {results.employeeId === undefined ? null : (
                        <div className="col-12 col-sm-3 col-md-2 col-lg-2 col-xl-2">
                          <div className="form-group">
                            <div className="d-flex justify-content-start">
                              <div className="navi-icon cursor-pointer">
                                <Link to={`/userProfile/${results.employeeId}`} className="navi-icon-label">
                                  <span className="svg-icon svg-icon-2x svg-icon-primary">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      width="20px"
                                      height="20px"
                                      viewBox="0 0 24 24"
                                      version="1.1"
                                    >
                                      <g
                                        stroke="none"
                                        strokeWidth="1"
                                        fill="none"
                                        fillRule="evenodd"
                                      >
                                        <polygon points="0 0 24 0 24 24 0 24" />
                                        <path
                                          d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                                          fill="#000000"
                                          fillRule="nonzero"
                                          opacity="0.3"
                                        />
                                        <path
                                          d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                                          fill="#000000"
                                          fillRule="nonzero"
                                        />
                                      </g>
                                    </svg>
                                  </span>
                                </Link>
                              </div>
                              <span className="d-flex align-items-center text-secondary font-size-md">
                                {renderStars(
                                  results.maxTrainingLevelRankAchieved,
                                )}
                                <span className="text-muted text-nowrap">
                                  {renderStars(
                                    4 - results.maxTrainingLevelRankAchieved,
                                  )}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                      {errorMessage && (
                        <div className="col-12">
                          <Alert
                            message={errorMessage}
                            alertClass="light-danger"
                            onClose={() => setErrorMessage(undefined)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {errorMessage === '' && (
                <>
                  {results.employeeId === undefined
                    ? (
                      <div className="no-user-data-section">
                        <div className="row justify-content-center">
                          <div className="col-11 col-sm-11 col-md-11">
                            <div className="no-user-msg">
                              <img src={noData} className="img-fluid d-block m-auto" width="150" alt="No Data" />
                              <p className="text-center mt-10 font-size-lg font-weight-normal text-muted">
                                <span>{ inputValue }</span>
                                {' '}
                                is not a manager.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                    : (
                      <div className="card-header align-items-center">
                        <div className="card-title align-items-center justify-content-between w-100">
                          <h3 className="font-size-lg col p-0">Team Training </h3>
                          <div className="d-flex xs-flx-col">
                            {results.supervisorId !== results.employeeId && (
                              <div
                                className="d-flex justify-content-end align-items-center text-nowrap text-primary font-size-md up-level-section"
                                onClick={() => changeSupervisor(results.supervisorId)}
                              >
                                <label className="m-0">
                                  {results.supervisorName}
                                </label>
                                <FontAwesomeIcon icon={faLevelUpAlt} />
                              </div>
                            )}
                            <CsvDownloader
                              className="ml-3"
                              filename="EducationTrainingData"
                              extension=".csv"
                              datas={CSVData}
                            >
                              <button type="button" className="btn btn-sm btn-primary">
                                <span className="svg-icon svg-light">
                                  <FontAwesomeIcon icon={faDownload} />
                                </span>
                                {' '}
                                Download CSV
                              </button>
                            </CsvDownloader>
                          </div>
                        </div>
                      </div>
                    )}

                  {results && results.reports && results.reports.length > 0 && (
                    <EducationResults
                      reports={results.reports}
                      isContentEditable={isContentEditable}
                      changeSupervisor={changeSupervisor}
                      reloadResults={executeSearch}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  );
};

Education.propTypes = {
  user: PropTypes.shape({
    employeeId: PropTypes.number,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    isAdmin: PropTypes.bool,
  }).isRequired,
};

export default Education;
