import React from 'react';
import PropTypes from 'prop-types';
import BigSearch from '../Common/BigSearch';

const NetworkSearch = (props) => {
  const {
    selectedValue, search, environments, types,
  } = props;

  const searchTypes = [{
    key: 'Name',
    display: 'Name',
    placeholder: 'The virtual network name or default identifier',
  }, {
    key: 'Owner',
    display: 'Contact / Owner',
    placeholder: 'The name of the account contact / owner',
  }, {
    key: 'Mine',
    display: 'Mine',
    placeholder: 'The virtual network name or default identifier - only mine',
    alwaysSearch: true,
  }, {
    key: 'CidrBlock',
    display: 'CIDR Block',
    placeholder: 'The IP V4 CIDR Block of the virtual network',
  }, {
    key: 'Region',
    display: 'Region',
    placeholder: 'Part or all of the region in which the virtual network resides',
  }];

  const filters = [
    {
      key: 'environment',
      url: '/api/lookup/environments',
      label: 'Environments',
    }, {
      key: 'type',
      url: '/api/lookup/vpcDefaults',
      label: 'Types',
    },
  ];

  const findIndex = (value) => searchTypes.findIndex((p) => p.key === (value || 'Name')) || 0;

  const executeSearch = (e) => {
    const { executeSearch } = props;
    const { bigSearch } = e;
    const { search, selectedValue, selectedFilters } = bigSearch;
    const { key } = selectedValue;
    const { type, environment } = selectedFilters;
    executeSearch(key, search, type || [], environment || []);
  };

  const clearSearch = (e) => {
    const { clearSearch } = props;
    const { bigSearch } = e;
    const { selectedFilters } = bigSearch;
    const { types, environment } = selectedFilters;
    clearSearch(types || [], environment || []);
  };

  const typeIndex = filters.findIndex((p) => p.key === 'type');
  const environmentIndex = filters.findIndex((p) => p.key === 'environment');

  if (typeIndex >= 0 && types !== undefined && String(types) !== '') {
    filters[typeIndex].initialSelection = types.split(',');
  }

  if (
    environmentIndex >= 0
    && environments !== undefined
    && String(environments) !== ''
  ) {
    filters[environmentIndex].initialSelection = environments.split(',');
  }

  return (
    <BigSearch
      srLabelTitle="Virtual Network Search"
      selectedIndex={findIndex(selectedValue)}
      search={search}
      executeSearch={executeSearch}
      filters={filters}
      clearSearch={clearSearch}
      searchTypes={searchTypes}
    />
  );
};

NetworkSearch.propTypes = {
  selectedValue: PropTypes.string,
  search: PropTypes.string,
  types: PropTypes.string,
  environments: PropTypes.string,
  executeSearch: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
};

export default NetworkSearch;
