import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { getUnavailableServices } from '../../../store/actions/vendorAction';

// Contexts
import { Context } from '../../../Context/VendorContext';

const SearchFilter = () => {
  // Get filter data from Vendor Services Context
  const [serviceFilter, setServiceFilter] = useContext(Context);
  const {
    regionServices,
    regions,
    services,
    selectedRegion,
    selectedService,
    filteredRegions,
    filteredServices,
  } = serviceFilter;

  const dispatch = useDispatch();
  const vendorInfo = useSelector((state) => state.vendorInfo);
  const {
    unavailableServices,
    unavailableServicesLoading,
  } = vendorInfo;

  function unavailableServicePopulateData() {
    const currentUnavailableServices = [];
    for (let i = 0; i < unavailableServices[0].current?.length; i++) {
      currentUnavailableServices.push({
        region: unavailableServices[0].current[i].regionname,
        regionCode: unavailableServices[0].current[i].regioncode,
        serviceName: unavailableServices[0].current[i].servicename,
        serviceCode: unavailableServices[0].current[i].servicecode,
        date: unavailableServices[0].current[i].date,
        summary: unavailableServices[0].current[i].summary,
        description: unavailableServices[0].current[i].description,
      });
    }

    const unavailableServiceByRegion = currentUnavailableServices.filter((i) => i.regionCode === selectedRegion.value);

    const currentUnavailableServicesByRegion = [];
    if (selectedRegion !== null) {
      for (let i = 0; i < unavailableServiceByRegion.length; i++) {
        currentUnavailableServicesByRegion.push({
          value: unavailableServiceByRegion[i].serviceCode,
          label: unavailableServiceByRegion[i].serviceName,
        });
      }
    }
    return currentUnavailableServicesByRegion;
  }
  // when we select Region this function will call to filter available and unavailable services.
  function filteredServicesFunc() {
    const availableServices = [];
    const unavailableServices = [];
    if (selectedRegion !== '') {
      for (let j = 0; j < services.length; j++) {
        if (filteredServices.filter((i) => i.serviceName === services[j].value).length > 0) {
          availableServices.push({ value: services[j].value, label: services[j].value, url: services[j].url });
        } else {
          unavailableServices.push({ value: services[j].value, label: services[j].value, url: services[j].url });
        }
      }
      setServiceFilter({
        ...serviceFilter,
        availableList: availableServices,
        unavailableList: unavailableServices,
        tempUnavailableList: unavailableServicePopulateData(),
        loadedResult: true,
      });
    }
  }
  // when we select Service this function will call to filter available and unavailable regions.
  function filteredRegionsFunc() {
    const filteredRegionList = [];
    const availableRegions = [];
    const unavailableRegions = [];

    if (selectedService !== '') {
      for (let i = 0; i < regions.length; i++) {
        filteredRegionList.push({ region: regions[i].regionName, value: regions[i].regionCode });
      }
      for (let j = 0; j < regions.length; j++) {
        if (filteredRegions.filter((i) => i.regionCode === regions[j].value).length > 0) {
          availableRegions.push({ value: regions[j].value, label: regions[j].label });
        } else {
          unavailableRegions.push({ value: regions[j].value, label: regions[j].label });
        }
      }
      setServiceFilter({
        ...serviceFilter,
        availableList: availableRegions,
        unavailableList: unavailableRegions,
      });
    }
  }
  function handleRegionChange(event) {
    if (event !== null) {
      setServiceFilter({
        ...serviceFilter,
        filteredServices: regionServices.filter((i) => i.regionCode === event.value),
        selectedRegion: event,
        selectedService: '',
        displayMode: 'region',
        loadedResult: false,
      });
    } else {
      setServiceFilter({
        ...serviceFilter,
        selectedRegion: '',
        displayMode: 'all',
      });
    }
  }
  function handleServiceChange(event) {
    if (event !== null) {
      setServiceFilter({
        ...serviceFilter,
        filteredRegions: regionServices.filter((i) => i.serviceName === event.value),
        selectedService: event,
        selectedRegion: '',
        displayMode: 'service',
        loadedResult: true,
      });
    } else {
      setServiceFilter({
        ...serviceFilter,
        selectedService: '',
        displayMode: 'all',
      });
    }
  }
  useEffect(() => {
    if (selectedService !== '') {
      filteredRegionsFunc();
    } else if (selectedRegion !== '') {
      const apiUrl = '/api/Vendor/GetUnavailableServices';
      dispatch(getUnavailableServices(apiUrl));
    }
  }, [selectedService, selectedRegion]);
  useEffect(() => {
    if (selectedRegion !== null && selectedRegion !== '') {
      filteredServicesFunc();
    }
  }, [unavailableServicesLoading, unavailableServices]);
  return (
    <div className="row justify-content-center justify-content-end-xs">
      <div className="col-lg-4 col-md-5 col-sm-6 col-12">
        <div className="form-group">
          <Select
            value={selectedRegion}
            options={regions}
            onChange={(item) => handleRegionChange(item)}
            isClearable="true"
            className=""
            placeholder="Select Region"
          />
        </div>
      </div>
      <div className="col-g-4 col-md-5 col-sm-6 col-12">
        <div className="form-group">
          <Select
            value={selectedService}
            options={services}
            onChange={(item) => handleServiceChange(item)}
            isClearable="true"
            className=""
            placeholder="Select Service"
          />
        </div>
      </div>
    </div>
  );
};

export default SearchFilter;
