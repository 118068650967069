import React from 'react';
import { Link } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

const AccessByRole = (props) => {
  const { isOpen } = props;

  const renderStarts = (number) => {
    const lis = [];
    for (let i = 0; i < number; i++) {
      lis.push(<FontAwesomeIcon key={i} icon={faStar} />);
    }
    return lis;
  };

  function handleClick(value) {
    props.onClick(value);
  }

  function mapAccountList(key, mapper) {
    const list = props.accountWithRole;
    const ret = {};
    list.forEach((i) => {
      const index = i[key];
      ret[index] = mapper(i, ret[index]);
    });
    return ret;
  }

  function mapRole() {
    const key = 'roleCode';
    const data = mapAccountList(key, (i, current) => {
      if (!current) {
        current = {
          users: [],
          code: i[key],
          name: i.roleName || i[key],
          minimumTrainingLevel: i.roleMinimumTrainingLevel,
          minimumTrainingLevelRank: i.roleMinimumTrainingLevelRank,
          maxTrainingLevelAchieved: i.awsMaxTrainingLevelAchieved,
          maxTrainingLevelRankAchieved: i.awsMaxTrainingLevelRankAchieved,
        };
      }
      current.users.push({
        first: i.firstName,
        last: i.lastName,
        employeeId: i.employeeId,
        maxTrainingLevelAchieved: i.awsMaxTrainingLevelAchieved,
        maxTrainingLevelRankAchieved: i.awsMaxTrainingLevelRankAchieved,
      });
      return current;
    });
    return data;
  }

  function byRoleListTable() {
    const byRole = mapRole();
    if (Object.keys(byRole).length > 0) {
      return Object.keys(byRole).map((roleCode) => {
        const role = byRole[roleCode];
        const { users } = role;
        return (
          <div className="accounts-role" key={role.code}>
            <div className="accounts-role-header">
              <div className="d-flex align-items-center justify-content-between">
                <div className="m-0 font-size-md font-weight-500 text-nowrap">
                  {role.name}
                </div>
                <div className="accounts-role-rank">
                  <span className="d-flex align-items-center text-user-profile text-primary-hover font-size-lg font-weight-500 mr-2 text-nowrap">
                    {role.minimumTrainingLevel}
                    <span className="d-flex align-items-center text-secondary font-size-sm ml-2">
                      {renderStarts(role.minimumTrainingLevelRank)}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="accounts-role-body">
              {users.map((item) => {
                // Training level color code
                const achieved = item.maxTrainingLevelRankAchieved
                  >= role.minimumTrainingLevelRank;
                const color = achieved ? (item.maxTrainingLevelRankAchieved === 0 ? 'unassigned' : 'success') : 'danger';
                const achievedColor = achieved ? 'warning' : 'white';
                return (
                  <div key={item.employeeId}>
                    <Link
                      to={`/userProfile/${item.employeeId}`}
                      className="d-flex align-items-center mb-3 justify-content-between card-hoverable p-2"
                    >
                      {/* <!--begin::Text--> */}
                      <div className="d-flex justify-content-start align-items-center font-weight-500">
                        <div className="d-flex flex-column font-weight-500">
                          <div className="text-dark text-hover-primary text-primary mb-1 font-size-lg">
                            {item.first}
                            {' '}
                            {item.last}
                          </div>
                        </div>
                      </div>

                      <div
                        className={`badge badge-${color} accounts-role-rank`}
                      >
                        <span
                          className={`star-${achievedColor} d-flex align-items-center text-secondary font-size-sm`}
                        >
                          {renderStarts(item.maxTrainingLevelRankAchieved)}
                          {' '}
                          <span className="text-white-50">
                            {renderStarts(
                              role.minimumTrainingLevelRank
                                - item.maxTrainingLevelRankAchieved,
                            )}
                          </span>
                        </span>
                      </div>
                      {/* <!--end::Text--> */}
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        );
      });
    }
    return '';
  }

  return (
    <div className={`card border-0 ${isOpen ? 'card-active' : ''}`}>
      <div className="card-header card-header2" id="headingOne">
        <h5 className="mb-0">
          <button
            type="button"
            className={`btn btn-link ${isOpen ? '' : 'collapsed'}`}
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="false"
            aria-controls="collapseOne"
            onClick={() => handleClick('byRole')}
          >
            By Role
          </button>
        </h5>
      </div>
      <div
        id="collapseOne"
        className={`collapse ${isOpen ? 'show' : ''}`}
        aria-labelledby="headingOne"
        data-parent="#accordion"
      >
        <div className="card-body px-0">
          <div className="role-section-tab">{byRoleListTable()}</div>
        </div>
      </div>
    </div>
  );
};
export default AccessByRole;
