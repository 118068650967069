import React from 'react';
import { Link } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import noData from '../../Images/no-data.png';
import './circular-progress-bar.css';

const renderStarts = (number) => {
  const lis = [];
  for (let i = 0; i < number; i++) {
    lis.push(<FontAwesomeIcon key={i} icon={faStar} />);
  }
  return lis;
};
const TrainingIndividualWidget = ({ user, awsTrainingInfo, azureTrainingInfo }) => (
  <div className="col-lg-6 gutter-b">
    <div className="card card-custom gutter-b h-100">
      <div className="card-header align-items-center border-0 mt-4">
        <div className="card-title align-items-start flex-column">
          <h3 className="card-label">Training Details</h3>
          <span className="text-muted mt-2 font-weight-500 font-size-sm">{`${user.firstName}'s Education Progress`}</span>
        </div>
      </div>
      {
        awsTrainingInfo != null
          || azureTrainingInfo != null

          ? (
            <div className="card-body">
              <div className="table-responsive">
                <div className="col">
                  <div className="training-progress">
                    <div id="clock" className="progress-clock m-auto" style={{ width: '200px', height: '200px' }}>
                      <button data-unit="aws" data-group="aws" type="button" className="btn-aws">
                        AWS
                        {' '}
                        <span className="badge badge-pill badge-dark">
                          {awsTrainingInfo.progress !== undefined ? parseInt(awsTrainingInfo.progress.replace('%', ''), 10) : 0}
                          %
                        </span>
                      </button>
                      <button data-unit="azure" data-group="azure" type="button" className="btn-azure">
                        Azure
                        {' '}
                        <span className="badge badge-pill badge-dark">
                          {azureTrainingInfo.progress !== undefined ? parseInt(azureTrainingInfo.progress.replace('%', ''), 10) : 0}
                          %
                        </span>
                      </button>
                      <svg className="progress-clock__rings" width="256" height="256" viewBox="0 0 256 256">
                        <defs>
                          <linearGradient id="pc-yellow" x1="1" y1="0.5" x2="0" y2="0.5">
                            <stop offset="0%" stopColor="hsl(43,90%,55%)" />
                            <stop offset="100%" stopColor="hsl(23,90%,55%)" />
                          </linearGradient>
                          <linearGradient id="pc-blue" x1="1" y1="0.5" x2="0" y2="0.5">
                            <stop offset="0%" stopColor="hsl(223,90%,55%)" />
                            <stop offset="100%" stopColor="hsl(203,90%,55%)" />
                          </linearGradient>
                        </defs>

                        <g data-units="aws">
                          <circle className="progress-clock__ring" cx="128" cy="128" r="90" fill="none" opacity="0.1" stroke="url(#pc-yellow)" strokeWidth="12" />
                          {awsTrainingInfo != null && (awsTrainingInfo?.progress !== undefined && awsTrainingInfo?.progress !== '') ? (
                            <circle
                              className="progress-clock__ring-fill"
                              data-ring="aws"
                              cx="128"
                              cy="128"
                              r="90"
                              fill="none"
                              stroke="url(#pc-yellow)"
                              strokeWidth="12"
                              strokeDasharray="565.5 565.5"
                              strokeDashoffset={565.5 * (1 - (parseInt(awsTrainingInfo?.progress.replace('%', ''), 10) / 100))}
                              strokeLinecap="round"
                              transform="rotate(-90,128,128)"
                            />
                          ) : <></>}
                        </g>
                        <g data-units="azure">
                          <circle className="progress-clock__ring" cx="128" cy="128" r="106" fill="none" opacity="0.1" stroke="url(#pc-blue)" strokeWidth="12" />
                          {azureTrainingInfo != null && (azureTrainingInfo?.progress !== undefined && azureTrainingInfo?.progress !== '') ? (
                            <circle
                              className="progress-clock__ring-fill"
                              data-ring="azure"
                              cx="128"
                              cy="128"
                              r="106"
                              fill="none"
                              stroke="url(#pc-blue)"
                              strokeWidth="12"
                              strokeDasharray="666 666"
                              strokeDashoffset={666 * (1 - (parseInt(azureTrainingInfo?.progress.replace('%', ''), 10) / 100))}
                              strokeLinecap="round"
                              transform="rotate(-90,128,128)"
                            />
                          ) : <></>}
                        </g>
                      </svg>
                    </div>
                  </div>

                  <div>
                    <table className="table my-3">
                      <tbody>
                        <tr>

                          <th style={{ backgroundColor: '#88888833' }} />
                          <th style={{ backgroundColor: '#ff990033' }}>AWS</th>
                          <th style={{ backgroundColor: '#0080ff33' }}>Azure</th>
                        </tr>
                        <tr>
                          <th style={{ backgroundColor: '#88888822' }}>License Goal</th>
                          <td style={{ backgroundColor: '#ff990022' }}>
                            <div className="d-flex flex-column">
                              <div
                                className="d-flex justify-content-between align-items-center mb-1"
                              >
                                <span
                                  className="text-dark text-hover-primary font-size-lg font-weight-500"
                                >
                                  {awsTrainingInfo?.description === undefined || '' ? 'No Progress' : awsTrainingInfo.description}

                                </span>
                              </div>
                              <span
                                className="d-flex align-items-center text-secondary font-size-xs"
                              >
                                {awsTrainingInfo?.description === undefined || '' ? '' : renderStarts(awsTrainingInfo.rank)}
                              </span>
                            </div>
                          </td>
                          <td style={{ backgroundColor: '#0080ff22' }}>
                            <div className="d-flex flex-column">
                              <div
                                className="d-flex justify-content-between align-items-center mb-1"
                              >
                                <span
                                  className="text-dark text-hover-primary font-size-lg font-weight-500"
                                >
                                  {azureTrainingInfo?.description === undefined || '' ? 'No Progress' : azureTrainingInfo.description}
                                </span>
                              </div>
                              <span
                                className="d-flex align-items-center text-secondary font-size-xs"
                              >
                                {azureTrainingInfo?.description === undefined || '' ? '' : renderStarts(azureTrainingInfo.rank)}
                              </span>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th style={{ backgroundColor: '#88888822' }}>Last Login</th>
                          <td style={{ backgroundColor: '#ff990022' }}>{(awsTrainingInfo?.lastLogin === '' || awsTrainingInfo?.lastLogin === undefined) ? '-' : awsTrainingInfo.lastLogin}</td>
                          <td style={{ backgroundColor: '#0080ff22' }}>
                            <div className="text-dark">N/A</div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <Link
                    to={`/userProfile/${user.employeeId}`}
                    className="btn btn-primary btn-block mt-3"
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          )
          : (
            <div className="card-body card-empty">
              <img src={noData} className="img-fluid d-block m-auto w-50" alt="" />
              <p className="text-center mt-10 font-size-lg font-weight-normal text-muted">You don&apos;t have any training details to show.</p>
            </div>
          )
      }
    </div>
  </div>
);

export default TrainingIndividualWidget;
