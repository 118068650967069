import React from 'react';

const StockWidgetInfo = () => (
  <div className="row">
    <div className="col-12">
      <h4 className="custom-tooltip-text">
        Top Cloud Provider stocks widgets
      </h4>
    </div>
  </div>
);

export default StockWidgetInfo;
