import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { useMediaQuery } from 'react-responsive';
import ApiRadioButtonList from '../Common/ApiRadioButtonList';
import ApiTypeAhead from '../Common/ApiTypeAhead';
import Input from '../Common/Input';
import Loader from '../Loader';
import Alert from '../Common/Alert';

const EditSubscription = (props) => {
  const defaultRequest = () => ({
    azureSubName: '',
    ownerId: '',
    ownerName: '',
    teamEmail: '',
    iTag: '',
    tenantId: '',
    tenant: '',
    productCodes: [],
    remedyGroup: '',
    businessJustification: '',
    managementGroup: '',
  });

  const { label, title } = props;
  const infoBlockRef = useRef(null);

  const [loaded, setLoaded] = useState(false);
  const [validateLoader, setValidateLoader] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageClass, setMessageClass] = useState('info');
  const [message, setMessage] = useState('');
  const [validation, setValidation] = useState({});
  const [request, setRequest] = useState(defaultRequest());
  const [validAzureSubName, setValidAzureSubName] = useState('');
  const [validITag, setValidITag] = useState(false);
  const [productCodes, setProductCodes] = useState({ multiValue: [], filterOptions: [] });
  const [showNamingInfo, setShowNamingInfo] = useState(false);

  const [origSubName, setOrigSubName] = useState('');
  const [oldProductCodes, setOldProductCodes] = useState([]);

  const prefixes = ['cogs', 'rand'];
  const suffixes = ['prod', 'dev', 'corp'];

  const [styleTheme, setStyleTheme] = useState({ display: 'none' });
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  function getProducts() {
    axios
      .get(
        '/api/lookup/products',
      )
      .then((response) => {
        const result = response.data;
        const productData = [];
        Object.keys(result).forEach((key) => {
          productData.push({ value: key, label: result[key] });
        });
        setProductCodes({
          ...productCodes,
          filterOptions: productData,
        });
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          setMessage(message.err);
        }
      });
  }
  useEffect(() => {
    getProducts();
    setLoaded(true);
    setRequest(defaultRequest());
  }, []);

  const validateSubNameFormatNew = (newSubName) => {
    // some subscription names contain brackets in their name. Exclude the brackets value
    const actualSubName = newSubName.includes('(') ? newSubName.split('(')[0].trim() : newSubName.trim();

    // take first split containing rand / cogs
    const firstParam = actualSubName.split('-')[0];
    const seconsParam = actualSubName.split('-')[1];
    const lastParam = actualSubName.split('-')[actualSubName.split('-').length - 1];

    if (lastParam.toLowerCase() !== request.tenant.toLowerCase()) {
      return 'Start subscription name with "cogs-sub" for prod or "rand-sub" for other tenant, end subscription name with tenant names like -prod, -corp or -dev ';
    }

    if (prefixes.indexOf(firstParam.toLowerCase()) < 0 && seconsParam === 'sub') {
      return 'Start subscription name with "cogs-sub" for prod or "rand-sub" for other tenant.';
    }

    // take last split containing tenant name
    if (suffixes.indexOf(lastParam.toLowerCase()) < 0) {
      return 'End subscription name with tenant names like -prod, -corp or -dev';
    }
    // check if first param is cogs should be prod
    if (firstParam.toLowerCase() === 'cogs' && lastParam.toLowerCase() !== 'prod') {
      return 'As subscription name starts with "cogs-" it should end with "-prod".';
    } // check if first param is rand tenant should not be prod
    if (firstParam.toLowerCase() === 'rand' && lastParam.toLowerCase() === 'prod') {
      return 'As subscription name starts with "rand-" it should end with {-tenant} other than -prod.';
    }

    // check if after removing pref and suf the name should be in {product}-{customer} format
    const remainingName = actualSubName.replace(`${firstParam}-`, '').replace('sub-', '').replace(`-${lastParam}`, '');
    if (remainingName.split('-').length < 2) {
      return 'Follow {rand/cogs}-{product}-{customer}-{tenant} naming format.';
    }

    // check if the subscription name has whitespaces
    if (/\s/g.test(remainingName)) {
      return 'Please remove whitespaces in the subscription name.';
    }

    return '';
  };

  const reload = () => {
    const { subscriptionId, subscriptionName } = props;
    if (subscriptionId && subscriptionId !== '00000000-0000-0000-0000-000000000000') {
      axios
        .get(`/api/azureSubscription/SearchSubs/${subscriptionId}`)
        .then((response) => {
          setRequest(response.data);
          const pCodes = response.data?.productCodes?.map((item) => ({ value: item.split('-')[0], label: item }));
          setOldProductCodes(pCodes);
          setOrigSubName(response.data?.azureSubName);
          setLoaded(true);
        })
        .catch((err) => {
          setMessage(err);
          setMessageClass('light-danger');
          setLoaded(true);
          setRequest(defaultRequest());
        });
    } else {
      setLoaded(true);
      // if subscriptionRequestId is null send only subscriptionName and Id.
      const some = defaultRequest();
      setRequest({
        ...some,
        azureSubName: subscriptionName,
      });
    }
  };

  useEffect(() => {
    if (oldProductCodes?.length > 0) {
      const codes = oldProductCodes.map((item) => ({ value: item.value, label: item.label }));
      setProductCodes({ ...productCodes, multiValue: codes });
      setRequest({
        ...request,
        productCodes: request?.productCodes.map((item) => item.split('-')[0]),
      });
    }
  }, [oldProductCodes]);

  useEffect(() => {
    reload();
  }, [props.subscriptionId]);

  const validEmail = (value) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);

  const validateAzureSubName = (name, value) => {
    setValidateLoader(true);
    const existMessage = 'This azure subscription name already exists';
    axios
      .get(`/api/AzureSubscription/ValidateAzurSubName/${value}`)
      .then((response) => {
        setValidation({
          ...validation,
          [name]: value ? (response.data ? '' : existMessage) : 'Required *',
        });
        setValidateLoader(false);
        setValidAzureSubName(response.data ? '' : existMessage);
      })
      .catch((err) => {
        setMessage(err.message);
        setMessageClass('light-danger');
        setLoaded(true);
        setRequest(defaultRequest());
        setValidateLoader(false);
      });
  };

  const validateITag = (name, value) => {
    setValidateLoader(true);
    axios
      .get(`/api/accountRequest/ValidateITag/${value}`)
      .then((response) => {
        setValidation({
          ...validation,
          [name]: value ? (response.data ? '' : 'This iTag does not exist') : 'Required *',
        });
        setValidITag(!response.data);
        setValidateLoader(false);
      })
      .catch((err) => {
        setMessage(err.message);
        setMessageClass('light-danger');
        setLoaded(true);
        setRequest({ ...defaultRequest, iTag: value });
        setValidateLoader(false);
        setValidation({
          ...validation,
          [name]: '',
        });
      });
  };
  const handleProductChange = (item) => {
    setProductCodes({ ...productCodes, multiValue: item });
    setRequest({
      ...request,
      productCodes: item?.map((key) => key.value),
    });
    setValidation({
      ...validation,
      multiValue: item === null ? 'Required *' : '',
    });
  };

  const handleChange = (e) => {
    const { target } = e;
    const {
      name, type, value, checked,
    } = target;

    if (name === 'azureSubName') {
      // show naming convention tooltip only if subscription name is changed from original value
      if (value !== origSubName) {
        setShowNamingInfo(true);
      } else {
        setShowNamingInfo(false);
      }
    }

    setRequest((ps) => {
      if (type === 'OptionsRadioButtonList' && value && value.length) {
        return {
          ...ps,
          [name]: value,
        };
      }
      return {
        ...ps,
        [name]: type === 'checkbox' ? checked : value,
      };
    });
    setValidation((v) => {
      if (type === 'OptionsRadioButtonList' && value && value.length) {
        const validSubName = validateSubNameFormatNew(request.azureSubName);
        if (validSubName === '') {
          setShowNamingInfo(true);
          return {
            ...v,
            [name]: 'Tenant should be selected based on subscription naming convention. If you wish to change tenant please change the subscription name accordingly.',
          };
        }
        setShowNamingInfo(false);
        return {
          ...v,
          [name]: '',
        };
      }
      return {
        ...v,
      };
    });
  };

  // Close info modal when clicked outside it
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        infoBlockRef.current
        && !infoBlockRef.current.contains(event.target)
      ) {
        setStyleTheme({ display: 'none' });
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [infoBlockRef]);

  // On click info icon
  const handleOnInfoClick = () => {
    // Display information as block only when on mobile view
    if (isMobile) {
      setStyleTheme({ display: 'block' });
    }
  };

  const subscriptionNamingToolTip = (
    <span
      className="svg-icon svg-light ml-2 custom-tooltip-text"
      style={{ display: 'inline' }}
      onClick={handleOnInfoClick}
    >
      <svg stroke="currentColor" className="showModal" id="showModal" fill="#3699ff" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 108 0a8 8 0 000 16z" clipRule="evenodd" />
        <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
        <circle cx="8" cy="4.5" r="1" />
      </svg>
      <div className="custom-tooltip-info showModalSection" id="showModalSection" style={isMobile ? styleTheme : {}}>
        <div ref={infoBlockRef} className="info-section">
          <div className="row">
            <div className="col-12">
              <h5 className="custom-tooltip-text custom-tooltip-font">
                As you have decided to change subscription name, please follow the below cloud usage rules:
                <div>
                  <strong>
                    &#123;rand/cogs&#125;-sub-&#123;product&#125;-&#123;customer&#125;-&#123;tenant&#125;
                  </strong>
                </div>
                <ul className="pl-5">
                  <li>
                    <strong>
                      &#123;rand/cogs&#125;:&nbsp;
                    </strong>
                    cogs if subscription used for prod tenant. rand if otherwise.
                  </li>
                  <li>
                    <strong>
                      &#123;sub&#125;:&nbsp;
                    </strong>
                    sub
                  </li>
                  <li>
                    <strong>
                      &#123;product&#125;:&nbsp;
                    </strong>
                    product name
                  </li>
                  <li>
                    <strong>
                      &#123;customer&#125;:&nbsp;
                    </strong>
                    customer this subscription is intended to
                  </li>
                  <li>
                    <strong>
                      &#123;tenant&#125;:&nbsp;
                    </strong>
                    tenant this subscription is associated with i.e. prod, corp, dev
                  </li>
                </ul>
              </h5>
            </div>
            <div className="col-12">
              <h5 className="custom-tooltip-text custom-tooltip-font">
                <strong>Note: </strong>
                Once the subscription name is entered as per the correct format, the tenant will be selected for you as per subscription name entered.
              </h5>
            </div>
          </div>
        </div>
      </div>
    </span>
  );

  const handleValidation = (e) => {
    const { target } = e;
    const {
      name, required, value, type,
    } = target;

    if (required === true) {
      if (name === 'azureSubName' && value !== '') {
        if (value !== origSubName) {
          const validateMsg = validateSubNameFormatNew(value);
          // once subscription name is in proper format select env programatically
          if (validateMsg === '') {
            validateAzureSubName(name, value);
          } else {
            setValidation({
              ...validation,
              [name]: validateMsg,
              tenantId: validateMsg === '' ? '' : 'Follow subscription name rules to select proper tenant',
            });
          }
        } else {
          const validAccName = validateSubNameFormatNew(value);
          if (validAccName === '') {
            setShowNamingInfo(true);
            setValidation({
              ...validation,
              [name]: '',
            });
          } else {
            setValidation({
              ...validation,
              azureSubName: '',
              tenantId: '',
            });
          }
        }
      } else if (name === 'iTag' && value !== '') {
        validateITag(name, value);
      } else {
        setValidation({
          ...validation,
          [name]: value ? '' : 'Required *',
        });
      }
    }
    if (type === 'email' && value) {
      setValidation({
        ...validation,
        [name]: validEmail(value) ? '' : 'Invalid email address',
      });
    }
    if (name === 'businessJustification') {
      setValidation({
        ...validation,
        [name]: value ? '' : 'Required *',
      });
    }
  };

  const handleSubmit = () => {
    const { saveUrl, saveVerb } = props;
    const submitValidation = {
      teamEmail: request.teamEmail
        ? validEmail(request.teamEmail)
          ? ''
          : 'Invalid email address'
        : "Please provide your team's email.",
      iTag: request.iTag
        ? ''
        : 'Please provide the iTag.',
      azureSubName: request.azureSubName && (validation.azureSubName === '' || validation.azureSubName === undefined)
        ? validAzureSubName
        : 'Please provide the azure subscription name.',
      remedyGroup: request.remedyGroup
        ? ''
        : 'Please provide a remedy group',
      businessJustification: request.businessJustification
        ? ''
        : 'Please provide business justification',
      multiValue: request.productCodes && request.productCodes.length
        ? ''
        : 'Please provide a product code.',
      tenant:
        request.tenant && request.tenant.length
          ? ''
          : 'Please choose tenant.',
      ownerId:
        request.ownerId && request.ownerName
          ? ''
          : 'Please provide the subscription owner.',
    };
    const showMsg = false;
    const msg = 'Submitting form...';
    const msgClass = 'info';
    let validErrorExist = false;

    Object.keys(submitValidation).forEach((o) => {
      if (submitValidation[o]) {
        validErrorExist = true;
      }
    });

    const method = saveVerb || 'post';
    setValidation(submitValidation);
    setLoaded(validErrorExist);
    setShowMessage(showMsg);
    setMessage(msg);
    setMessageClass(msgClass);
    if (!validErrorExist) {
      const formatRequest = {
        ...request,
        azureSubName: request.azureSubName.trim(),
      };
      axios({
        method,
        url: `${saveUrl}`,
        data: JSON.stringify(formatRequest),
        headers: { 'Content-Type': 'application/json' },
      })
        .then(() => {
          const successMsg = (
            <div>
              You have successfully updated an azure subscription request.
              <br />
            </div>
          );
          setShowMessage(true);
          setMessage(successMsg);
          setMessageClass('success');
          setRequest(defaultRequest());
          setLoaded(true);
        })
        .catch((err) => {
          if (err.name !== 'AbortError') {
            setMessage(err.message);
            setShowMessage(true);
            setMessageClass('light-danger');
            setRequest(defaultRequest());
            setLoaded(true);
          }
        });
    }
  };
  return (
    <>
      <div
        className="modal fade reqNewAccount show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        style={{ display: 'block' }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header px-4">
              <h4 className="modal-title" id="exampleModalLabel">
                {title}
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => props.onCancel(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {loaded ? (
                <form className="form" noValidate>
                  <div className="row">
                    {showMessage ? (
                      <div className="col-12">
                        <Alert
                          message={message}
                          alertClass={messageClass}
                          onClose={() => setShowMessage(false)}
                        />
                      </div>
                    ) : ''}
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        {
                          request.tenantId || showMessage
                            ? (
                              <ApiRadioButtonList
                                name="tenant"
                                title="Tenant(s)"
                                requiredSign="*"
                                selectedItems={request.tenantId}
                                url="/api/lookup/tenants"
                                // onChange={handleChange}
                                required
                                errorText={validation.tenant}
                                disabled
                              />
                            ) : 'Loading...'
                        }

                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <Input
                          maxLength={255}
                          name="azureSubName"
                          value={request.azureSubName}
                          placeholder={request.tenant === '' ? 'Please Select Tenant First.' : '{rand/cogs}-sub-{product}-{customer}-{tenant}'}
                          title="Desired Azure Subscription Name"
                          onChange={handleChange}
                          disableAutoComplete
                          required
                          onBlur={handleValidation}
                          errorText={validation.azureSubName}
                          tooltip={showNamingInfo ? subscriptionNamingToolTip : ''}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        {
                          origSubName || showMessage
                            ? (
                              <ApiTypeAhead
                                name="ownerId"
                                defaultId={request.ownerId}
                                displayKey="ownerName"
                                query={request.ownerName}
                                title="Azure Subscription Owner"
                                placeholder="Start typing to search"
                                url="/api/lookup/people"
                                onChange={handleChange}
                                required
                                errorText={validation.ownerId}
                                onBlur={handleValidation}
                              />
                            ) : 'Loading...'
                        }

                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <Input
                          maxLength={255}
                          name="managementGroup"
                          value={request.managementGroup}
                          placeholder="Management Group"
                          title="Management Group"
                          onChange={handleChange}
                          errorText={validation.managementGroup}
                          onBlur={handleValidation}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <Input
                          maxLength={255}
                          type="email"
                          name="teamEmail"
                          value={request.teamEmail}
                          placeholder="i.e. list-my-team@csgi.com"
                          title="Team Email (Distribution List)"
                          requiredSign=" *"
                          onChange={handleChange}
                          required
                          errorText={validation.teamEmail}
                          onBlur={handleValidation}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <Input
                          maxLength={10}
                          type="text"
                          name="iTag"
                          value={request.iTag}
                          prepend={<span className="input-group-text">i-</span>}
                          placeholder="i.e. 12345"
                          title="iTag"
                          requiredSign="*"
                          onChange={handleChange}
                          required
                          errorText={validation.iTag}
                          onBlur={handleValidation}
                          warning={validITag}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <label>
                        Product Code
                        {' '}
                        <label className="required-sign">*</label>
                      </label>
                      <div className="form-group">
                        <Select
                          id="productCodes"
                          title={`Product Codes ${<label className="required-sign">*</label>}`}
                          name="productCodes"
                          value={productCodes.multiValue}
                          options={productCodes.filterOptions}
                          onChange={handleProductChange}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Select Product Codes"
                          isMulti
                          required
                        />
                        {
                          validation.multiValue === '' ? '' : <div className="danger-font">{validation.multiValue}</div>
                        }
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <Input
                          maxLength={255}
                          name="remedyGroup"
                          value={request.remedyGroup}
                          placeholder="i.e. WFX or ACPx"
                          title="Remedy Group"
                          requiredSign="*"
                          onChange={handleChange}
                          required
                          errorText={validation.remedyGroup}
                          onBlur={handleValidation}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12">
                      <div className="form-group">
                        <Input
                          maxLength={255}
                          name="businessJustification"
                          value={request.businessJustification}
                          placeholder="Provide Business Justification"
                          title="Business Justification"
                          requiredSign="*"
                          onChange={handleChange}
                          required
                          errorText={validation.businessJustification}
                          onBlur={handleValidation}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              ) : (
                <Loader />
              )}
            </div>
            <div className="modal-footer px-4">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={handleSubmit}
              >
                {label}
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => props.onCancel(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        {validateLoader ? <Loader /> : ''}
      </div>
    </>
  );
};

EditSubscription.propTypes = {
  saveUrl: PropTypes.string.isRequired,
  user: PropTypes.shape({
    employeeId: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  }),
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default EditSubscription;
