import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../../Common/Alert';
import { getAccountCompute } from '../../../store/actions/accountAction';
import Loader from '../../Loader';
import {
  ERROR_ACC_COMPUTE_STRG,
  GET_ACC_COMPUTE_STRG,
} from '../../../store/types';

const ComputeStorage = (props) => {
  const { accountId, onClick, isOpen } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [showErrMsg, setShowErrMsg] = useState(false);

  const dispatch = useDispatch();
  const accountData = useSelector((state) => state.accountProfile);
  const { compStorageLoading, errCompStorage, compStorage } = accountData;

  useEffect(() => {
    dispatch(
      getAccountCompute(
        `/api/account/${accountId}/S3Storage`,
        GET_ACC_COMPUTE_STRG,
        ERROR_ACC_COMPUTE_STRG,
      ),
    );
  }, [dispatch]);

  useEffect(() => {
    if (errCompStorage !== '') {
      setErrorMessage(errCompStorage);
      setShowErrMsg(true);
    }
  }, [errCompStorage]);

  /* eslint-disable react/no-array-index-key */
  const resourceMarkup = (list) => list.map((rs, index) => (
    <div className="div-row" key={rs.accountNumber + index}>
      <div className="div-column">{rs.bucketName}</div>
      <div className="div-column">{rs.createdDate}</div>
      <div className="div-column">{rs.region}</div>
      <div className="div-column">{rs.bucketPolicy.toString()}</div>
      <div className="div-column">{rs.encryption.toString()}</div>
      <div className="div-column">{rs.publicAccess.toString()}</div>
      <div className="div-column">{rs.versioning}</div>
    </div>
  )/* eslint-enable */);

  return (
    <div className={`card border-0 ${isOpen ? 'card-active' : ''}`}>
      <div className="card-header card-header2" id="headingOne">
        <h5 className="mb-0">
          <button
            type="button"
            className={`btn btn-link ${isOpen ? '' : 'collapsed'}`}
            data-toggle="collapse"
            data-target="#collapseSix"
            aria-expanded="false"
            aria-controls="collapseSix"
            onClick={() => onClick('storage')}
          >
            Storage
          </button>
        </h5>
      </div>
      <div
        id="collapseSix"
        className={`collapse ${isOpen ? 'show' : ''}`}
        aria-labelledby="headingOne"
        data-parent="#accordion"
      >
        <div className="card-body px-0">
          {compStorageLoading ? (
            <Loader />
          ) : showErrMsg ? (
            <div className="col-12">
              <Alert
                message={errorMessage}
                alertClass="light-danger"
                onClose={() => {
                  setShowErrMsg(false);
                  onClick('instances');
                }}
              />
            </div>
          ) : (
            !errorMessage && (
              <>
                {compStorage.length === 0 ? (
                  <div className="col-12">
                    <Alert
                      message="No Storage compute found."
                      alertClass="info"
                    />
                  </div>
                ) : (
                  <div className="custom-table">
                    <div className="respnsive-custom-table">
                      <div className="div-table" width="100%">
                        <div className="div-thead">
                          <div className="div-row">
                            <div className="div-column-th">Name</div>
                            <div className="div-column-th">CreationTime</div>
                            <div className="div-column-th">Region</div>
                            <div className="div-column-th">Policy</div>
                            <div className="div-column-th">EncryptionRule</div>
                            <div className="div-column-th">PublicAccess</div>
                            <div className="div-column-th">Versioning</div>
                          </div>
                        </div>
                        <div className="div-tbody">
                          {resourceMarkup(compStorage)}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
};
export default ComputeStorage;
