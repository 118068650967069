import React from 'react';
import { Link } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { faAws } from '@fortawesome/free-brands-svg-icons';
import azurelogo from '../../Images/azure.png';

const renderStars = (number) => {
  const lis = [];
  for (let i = 0; i < number; i++) {
    lis.push(<FontAwesomeIcon key={i} icon={faStar} />);
  }
  return lis;
};

const TrainingTable = ({ trainingData }) => (
  <Link
    to={`/userProfile/${trainingData.employeeId}`}
  >
    <div className="card training-card bg-light">
      <div className="card-body p-3">
        <div className="d-flex justify-content-between">
          <div className="manager-label">
            <div className="navi-icon">
              <span className="svg-icon svg-icon-xl svg-icon-primary">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="24px"
                  height="24px"
                  viewBox="0 0 24 24"
                  version="1.1"
                >
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24" />
                    <path
                      d="M12,11 C9.790861,11 8,9.209139 8,7 C8,4.790861 9.790861,3 12,3 C14.209139,3 16,4.790861 16,7 C16,9.209139 14.209139,11 12,11 Z"
                      fill="#000000"
                      fillRule="nonzero"
                      opacity="0.3"
                    />
                    <path
                      d="M3.00065168,20.1992055 C3.38825852,15.4265159 7.26191235,13 11.9833413,13 C16.7712164,13 20.7048837,15.2931929 20.9979143,20.2 C21.0095879,20.3954741 20.9979143,21 20.2466999,21 C16.541124,21 11.0347247,21 3.72750223,21 C3.47671215,21 2.97953825,20.45918 3.00065168,20.1992055 Z"
                      fill="#000000"
                      fillRule="nonzero"
                    />
                  </g>
                </svg>
              </span>
            </div>
            <div className="text-nowrap">
              {trainingData.firstName}
              {trainingData.lastName}
            </div>
          </div>
        </div>
        <table className="table table-sm">
          <tr>
            <th width="35%" style={{ backgroundColor: '#88888833' }} />
            <th width="27.5%" className="text-center" style={{ backgroundColor: '#ff990033' }}>
              <FontAwesomeIcon icon={faAws} style={{ fontSize: '1.5rem' }} />
            </th>
            <th width="27.5%" className="text-center" style={{ backgroundColor: '#0080ff33' }}>
              <img src={azurelogo} style={{ height: '24px' }} alt="Azure" />
              Azure
            </th>
          </tr>
          <tr>
            <th style={{ backgroundColor: '#88888822' }}>Current Training Progress</th>
            <td className="text-center" style={{ backgroundColor: '#ff990022' }}>
              <div className="aws-current-level mx-auto">
                <div className="training-progress">
                  <div id="clock" className="progress-clock m-auto" style={{ width: '50px', height: '50px' }}>
                    <button data-unit="dataUnit" data-group="aws" type="button" className="btn-aws">
                      {trainingData.awsCurrentTrainingLevelPercent}
                      %
                    </button>
                    <svg className="progress-clock__rings" width="256" height="256" viewBox="0 0 256 256">
                      <defs>
                        <linearGradient id="pc-yellow" x1="1" y1="0.5" x2="0" y2="0.5">
                          <stop offset="0%" stopColor="hsl(43,90%,55%)" />
                          <stop offset="100%" stopColor="hsl(23,90%,55%)" />
                        </linearGradient>
                      </defs>
                      <g data-units="aws">
                        <circle className="progress-clock__ring" cx="128" cy="128" r="90" fill="none" opacity="0.1" stroke="url(#pc-yellow)" strokeWidth="20" />
                        <circle
                          className="progress-clock__ring-fill"
                          data-ring="aws"
                          cx="128"
                          cy="128"
                          r="90"
                          fill="none"
                          stroke="url(#pc-yellow)"
                          strokeWidth="20"
                          strokeDasharray="565.5 565.5"
                          strokeDashoffset={565.5 * (1 - (parseInt((trainingData.awsCurrentTrainingLevelPercent), 10) / 100))}
                          strokeLinecap="round"
                          transform="rotate(-90,128,128)"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-center text-center">
                  <span className="text-secondary font-weight-500">
                    {trainingData.awsCurrentTrainingLevel}
                  </span>
                </div>
              </div>
            </td>
            <td className="text-center" style={{ backgroundColor: '#d5f0ff' }}>
              <div className="azure-current-level mx-auto">
                <div className="training-progress">
                  <div id="clock" className="progress-clock m-auto" style={{ width: '50px', height: '50px' }}>
                    <button data-unit="dataUnit" data-group="azure" type="button" className="btn-azure">
                      {trainingData.azCurrentTrainingLevelPercent}
                      %
                    </button>
                    <svg className="progress-clock__rings" width="256" height="256" viewBox="0 0 256 256">
                      <defs>
                        <linearGradient id="pc-blue" x1="1" y1="0.5" x2="0" y2="0.5">
                          <stop offset="0%" stopColor="hsl(223,90%,55%)" />
                          <stop offset="100%" stopColor="hsl(203,90%,55%)" />
                        </linearGradient>
                      </defs>
                      <g data-units="azure">
                        <circle className="progress-clock__ring" cx="128" cy="128" r="90" fill="none" opacity="0.1" stroke="url(#pc-blue)" strokeWidth="20" />
                        <circle
                          className="progress-clock__ring-fill"
                          data-ring="azure"
                          cx="128"
                          cy="128"
                          r="90"
                          fill="none"
                          stroke="url(#pc-blue)"
                          strokeWidth="20"
                          strokeDasharray="565.5 565.5"
                          strokeDashoffset={565.5 * (1 - (parseInt((trainingData.azCurrentTrainingLevelPercent), 10) / 100))}
                          strokeLinecap="round"
                          transform="rotate(-90,128,128)"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
                <div className="d-flex flex-column align-items-center text-center">
                  <span className="text-primary font-weight-500">
                    {trainingData.azCurrentTrainingLevel}
                  </span>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <th style={{ backgroundColor: '#88888822' }}>Highest Training Achieved</th>
            <td className="text-center" style={{ backgroundColor: '#ff990022' }}>
              <div className="d-flex flex-column align-items-center text-center">
                <span className="text-secondary font-weight-500">
                  {trainingData.awsMaxTrainingLevelAchieved}
                </span>
                <span className="text-secondary">
                  {renderStars(trainingData.awsMaxTrainingLevelRankAchieved)}
                </span>
              </div>
            </td>
            <td className="text-center" style={{ backgroundColor: '#d5f0ff' }}>
              <div className="d-flex flex-column align-items-center text-center">
                <span className="text-primary font-weight-500 text-wrap">
                  {trainingData.azMaxTrainingLevelAchieved}
                </span>
                <span className="text-primary">
                  {renderStars(trainingData.azMaxTrainingLevelRankAchieved)}
                </span>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>

  </Link>
);

export default TrainingTable;
