import React from 'react';
import noData from '../../../Images/no-data.png';

const List = (props) => {
  const {
    title, className, data, countLabel, notFoundMessage,
  } = props;
  return (
    <>
      <div className={`col-lg-4 ${className} mb-10`}>
        <h6 className="text-count h6">
          <span>{countLabel}</span>
        </h6>
        <div className="service-sec">
          <div className="service-head">
            {title}
          </div>
          {data && data.length > 0
            ? (
              <div className="service-body">
                <div className="service-list service-list-hover-scroll-overlay-y">
                  {data.map((item) => (
                    <div className="service-list-link" key={item.value}>
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {item.label}
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            )
            : (
              <div className="service-body d-flex align-items-center justify-content-center">
                <div className="service-list">
                  <div className="no-data-sec mt-5">
                    <img src={noData} className="img-fluid d-block m-auto w-50" alt="No Data" />
                    <p className="text-muted text-center mt-5">
                      {notFoundMessage}
                    </p>
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default List;
