import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from 'react-responsive';
import Select from 'react-select';
import ApiCheckboxList from '../../Common/ApiCheckboxList';
import ApiTypeAhead from '../../Common/ApiTypeAhead';
import Input from '../../Common/Input';
import RadioButtons from '../../Common/RadioButtons';
import Loader from '../../Loader';
import Alert from '../../Common/Alert';
import ApiRadioButtonList from '../../Common/ApiRadioButtonList';

// this component for Edit Existing Account from Admin or Owner in Account Search Result
const EditAccountRequest = (props) => {
  const defaultRequest = () => {
    const { user } = props;
    const { firstName, lastName, employeeId } = user || {};

    return {
      teamEmail: '',
      iTag: '',
      accountName: '',
      productCodes: [],
      environment: [],
      region: [],
      directConnectNeeded: null,
      remedyGroup: '',
      accountNumber: null,
      ownerId: user ? employeeId : '',
      ownerName: user ? `${firstName} ${lastName}` : '',
      agreed: false,
      managerId: '',
      csgServiceOwnerId: '',
      executiveLevelId: '',
      vpId: '',
      managerName: '',
      csgServiceOwnerName: '',
      executiveLevelName: '',
      vpName: '',
    };
  };

  const { label, title } = props;
  const [origAcctName, setOrigAcctName] = useState('');
  const [origEnv, setOrigEnv] = useState([]);
  const [oldProductCodes, setOldProductCodes] = useState([]);
  const [origAccNum, setOrigAccNum] = useState(null);
  const [showNamingInfo, setShowNamingInfo] = useState(false);
  const [styleTheme, setStyleTheme] = useState({ display: 'none' });
  const infoBlockRef = useRef(null);
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [loaded, setLoaded] = useState(false);
  const [validateLoader, setValidateLoader] = useState(false);
  const [id, setId] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [messageClass, setMessageClass] = useState('info');
  const [message, setMessage] = useState('');
  const [validation, setValidation] = useState({});
  const [request, setRequest] = useState(defaultRequest());
  const [validAccounName, setValidAccounName] = useState('');
  const [validITag, setValidITag] = useState(false);
  const prefixes = ['cogs', 'rand'];
  const suffixes = ['prod', 'cte', 'qa', 'dev', 'test', 'corp'];
  const [productCodes, setProductCodes] = useState({ multiValue: [], filterOptions: [] });

  // Close info modal when clicked outside it
  useEffect(() => {
    axios
      .get(
        '/api/lookup/products',
      )
      .then((response) => {
        const result = response.data;
        const productData = [];
        Object.keys(result).forEach((key) => {
          productData.push({ value: key, label: result[key] });
        });
        setProductCodes({
          ...productCodes,
          filterOptions: productData,
        });
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          console.error(err);
        }
      });
    function handleClickOutside(event) {
      if (
        infoBlockRef.current
        && !infoBlockRef.current.contains(event.target)
      ) {
        setStyleTheme({ display: 'none' });
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [infoBlockRef]);

  // On click info icon
  const handleOnInfoClick = () => {
    // Display information as block only when on mobile view
    if (isMobile) {
      setStyleTheme({ display: 'block' });
    }
  };

  const reload = () => {
    const { id, accName, accNum } = props;
    if (id && id !== '00000000-0000-0000-0000-000000000000') {
      axios
        .get(`/api/accountRequest/${id}`)
        .then((response) => {
          setRequest(response.data);
          const pCodes = response.data?.productCodes?.map((item) => ({ value: item.split('-')[0], label: item }));
          setOldProductCodes(pCodes);
          setOrigAcctName(response.data?.accountName);
          setOrigEnv(response.data?.environment);
          setOrigAccNum(response.data?.accountNumber);
          setId(id);
          setLoaded(true);
        })
        .catch((err) => {
          setMessage(err);
          setMessageClass('light-danger');
          setLoaded(true);
          setId(null);
          setRequest(defaultRequest());
        });
    } else {
      setId(null);
      setLoaded(true);
      // setRequest(defaultRequest());
      // if accountRequestId is null send only accountName and Number.
      const some = defaultRequest();
      setRequest({
        ...some,
        accountName: accName,
        accountNumber: accNum,
      });
    }
  };

  useEffect(() => {
    if (oldProductCodes?.length > 0) {
      const codes = oldProductCodes.map((item) => ({ value: item.value, label: item.label }));
      setProductCodes({ ...productCodes, multiValue: codes });
      setRequest({
        ...request,
        productCodes: request?.productCodes.map((item) => item.split('-')[0]),
      });
    }
  }, [oldProductCodes]);

  useEffect(() => {
    reload();
  }, []);

  /* eslint-disable react/destructuring-assignment */
  useEffect(() => {
    reload();
  }, [props.id]);
  /* eslint-enable */
  const validEmail = (value) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value);

  const validAccount = (value) => !value || value.length === 12;

  const validateITag = (name, value) => {
    setValidateLoader(true);
    axios
      .get(`/api/accountRequest/ValidateITag/${value}`)
      .then((response) => {
        setValidation({
          ...validation,
          [name]: value ? (response.data ? '' : 'This iTag does not exist') : 'Required *',
        });
        setValidITag(!response.data);
        setValidateLoader(false);
      })
      .catch((err) => {
        setMessage(err.message);
        setMessageClass('light-danger');
        setLoaded(true);
        setId(null);
        setRequest({ ...defaultRequest, iTag: value });
        setValidateLoader(false);
        setValidation({
          ...validation,
          [name]: '',
        });
      });
  };

  const validateAccNameFormatNew = (newAcctName) => {
    // some account names contain brackets in their name. Exclude the brackets value
    const actualAccntName = newAcctName.includes('(') ? newAcctName.split('(')[0].trim() : newAcctName.trim();

    // take first split containing rand / cogs
    const firstParam = actualAccntName.split('-')[0];
    if (prefixes.indexOf(firstParam.toLowerCase()) < 0) {
      return 'Start account name with "cogs-" for prod or "rand-" for other environments.';
    }

    // take last split containing env name
    const lastParam = actualAccntName.split('-')[actualAccntName.split('-').length - 1];
    if (suffixes.indexOf(lastParam.toLowerCase()) < 0) {
      return 'End account name with env names like -prod, -cte, -qa, -test, -dev or -corp';
    }
    // check if first param is cogs env should be prod
    if (firstParam.toLowerCase() === 'cogs' && lastParam.toLowerCase() !== 'prod') {
      return 'As account name starts with "cogs-" it should end with "-prod".';
    } // check if first param is rand env should not be prod
    if (firstParam.toLowerCase() === 'rand' && lastParam.toLowerCase() === 'prod') {
      return 'As account name starts with "rand-" it should end with {-env} other than -prod.';
    }

    // check if after removing pref and suf the name should be in {product}-{customer} format
    const remainingName = actualAccntName.replace(`${firstParam}-`, '').replace(`-${lastParam}`, '');
    if (remainingName.split('-').length < 2) {
      return 'Follow {rand/cogs}-{product}-{customer}-{env} naming format.';
    }

    // check if the account name has whitespaces
    if (/\s/g.test(remainingName)) {
      return 'Please remove whitespaces in the account name.';
    }

    return '';
  };

  const selectEnvOnAccountName = (newAcctName) => {
    // some account names contain brackets in their name. Exclude the brackets value
    const actualAccntName = newAcctName.includes('(') ? newAcctName.split('(')[0].trim() : newAcctName.trim();
    // take last split containing env name
    const lastParam = actualAccntName.split('-')[actualAccntName.split('-').length - 1];
    switch (lastParam.toLowerCase()) {
      case 'prod':
        return 1;
      case 'cte':
        return 2;
      case 'dev':
      case 'qa':
      case 'test':
        return 3;
      case 'corp':
        return 4;
      default: return 0;
    }
  };
  // product
  const handleProductChange = (item) => {
    setProductCodes({ ...productCodes, multiValue: item });
    setRequest({
      ...request,
      productCodes: item?.map((key) => key.value),
    });
    setValidation({
      ...validation,
      multiValue: item === null ? 'Required *' : '',
    });
  };
  const handleChange = (e) => {
    const { target } = e;
    const {
      name, type, value, checked,
    } = target;
    if (name === 'accountName') {
      // show naming convention tooltip only if account name is changed from original value
      if (value !== origAcctName) {
        setShowNamingInfo(true);
      } else {
        setShowNamingInfo(false);
      }
    }

    setRequest((ps) => {
      if ((type === 'checkbox' || type === 'radio') && checked === true) {
        return {
          ...ps,
          [name]: type === 'checkbox' ? checked : value,
        };
      }
      if ((type === 'OptionsCheckBoxList' || type === 'OptionsRadioButtonList') && value && value.length) {
        return {
          ...ps,
          [name]: value,
        };
      }
      return {
        ...ps,
        [name]: type === 'checkbox' ? checked : value,
      };
    });
    setValidation((v) => {
      if ((type === 'checkbox' || type === 'radio') && checked === true) {
        return {
          ...v,
          [name]: '',
        };
      }
      if (type === 'OptionsCheckBoxList' && value && value.length) {
        return {
          ...v,
          [name]: '',
        };
      }
      if (type === 'OptionsRadioButtonList' && value && value.length) {
        const validAccName = validateAccNameFormatNew(request.accountName);
        if (validAccName === '' && value !== selectEnvOnAccountName(request.accountName).toString()) {
          setShowNamingInfo(true);
          return {
            ...v,
            [name]: 'Environment should be selected based on account naming convention. If you wish to change environment please change the account name accordingly.',
          };
        }
        setShowNamingInfo(false);
        return {
          ...v,
          [name]: '',
        };
      }
      return {
        ...v,
      };
    });
  };

  const validateAccountName = (name, value) => {
    setValidateLoader(true);
    const existMessage = 'This account name is already existing.';
    axios
      .get(`/api/accountRequest/ValidateAccountName/${value}`)
      .then((response) => {
        setValidation({
          ...validation,
          environment: '',
          [name]: value ? (response.data ? '' : existMessage) : 'Required *',
        });
        setValidateLoader(false);
        setValidAccounName(response.data ? '' : existMessage);
      })
      .catch((err) => {
        setMessage(err.message);
        setMessageClass('light-danger');
        setLoaded(true);
        setRequest(defaultRequest());
        setValidateLoader(false);
      });
  };

  const handleValidation = (e) => {
    const { target } = e;
    const {
      name, required, value, type,
    } = target;
    if (required === true) {
      if (name === 'accountName' && value !== '') {
        // do the validation only if user changes the account name from the orginal account name value
        if (value !== origAcctName) {
          const validateMsg = validateAccNameFormatNew(value);
          // once account name is in proper format select env programatically
          if (validateMsg === '') {
            const correctEnv = selectEnvOnAccountName(value);
            setRequest({
              ...request,
              environment: [correctEnv],
            });
            // check if account name is already exisitng
            validateAccountName(name, value);
          } else {
            setValidation({
              ...validation,
              [name]: validateMsg,
              environment: validateMsg === '' ? '' : 'Follow account name rules to select proper environment',
            });
          }
          setValidAccounName(validateMsg);
        } else {
          // check if account name is in correct format env should be correctly selected
          const validAccName = validateAccNameFormatNew(value);
          if (validAccName === '' && name === 'environment' && value !== selectEnvOnAccountName(value).toString()) {
            setShowNamingInfo(true);
            setValidation({
              ...validation,
              environment: 'Environment should be selected based on account naming convention. If you wish to change environment please change the account name accordingly.',
            });
          } else {
            setValidation({
              ...validation,
              accountName: '',
              environment: '',
            });
            setValidAccounName('');
          }
        }
      } else if (name === 'iTag' && value !== '') {
        validateITag(name, value);
      } else {
        setValidation({
          ...validation,
          [name]: value ? '' : 'Required *',
        });
      }
    }
    if (name === 'accountNumber') {
      setValidation({
        ...validation,
        [name]: validAccount(value)
          ? ''
          : 'Please provide a valid 12 digit account number.',
      });
    }

    if (type === 'email' && value) {
      setValidation({
        ...validation,
        [name]: validEmail(value) ? '' : 'Invalid email address',
      });
    }
  };

  const handleSubmit = () => {
    const { saveUrl, saveVerb } = props;
    const validation = {
      teamEmail: request.teamEmail
        ? validEmail(request.teamEmail)
          ? ''
          : 'Invalid email address'
        : "Please provide your team's email.",
      iTag: request.iTag ? '' : 'Please provide the iTag.',
      accountName: request.accountName
        ? validAccounName
        : 'Please provide the desired account name.',
      accountNumber: validAccount(request.accountNumber)
        ? ''
        : 'Please provide 12 digit account number.',
      remedyGroup: '',
      multiValue: request.productCodes && request.productCodes.length ? '' : 'Please provide a product code.',
      environment:
        request.environment && request.environment.length
          ? ''
          : 'Please choose environment.',
      region:
        request.region && request.region.length
          ? ''
          : 'Please provide at least one region.',
      ownerId:
        request.ownerId && request.ownerName
          ? ''
          : 'Please provide the account owner.',
      directConnectNeeded:
        String(request.directConnectNeeded) === 'false'
          || String(request.directConnectNeeded) === 'true'
          ? ''
          : 'You must answer the question about Direct Connect',
      agreed: '',
    };
    const showMsg = false;
    const msg = 'Submitting form...';
    const msgClass = 'info';
    let validErrorExist = false;

    Object.keys(validation).forEach((o) => {
      if (validation[o]) {
        validErrorExist = true;
      }
    });

    const method = saveVerb || 'post';
    setValidation(validation);
    setLoaded(validErrorExist);
    setShowMessage(showMsg);
    setMessage(msg);
    setMessageClass(msgClass);

    if (!validErrorExist) {
      const formatRequest = {
        ...request,
        // to avoid spaces before and after account name
        accountName: request.accountName.trim(),
      };
      // we are converting environment into array, server side have array type and using many places
      formatRequest.environment = Array.isArray(formatRequest.environment) ? formatRequest.environment : [formatRequest.environment];
      axios({
        method,
        url: `${saveUrl}`,
        data: JSON.stringify(formatRequest),
        headers: { 'Content-Type': 'application/json' },
      })
        .then((response) => {
          if (id) {
            const successMsg = (
              <div>
                You have successfully created an account request.
                <br />
                {response.data.key && (
                <>
                  <a
                    href={`https://jira.csgicorp.com/browse/${response.data.key}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    &nbsp;
                    {response.data.key}
                    &nbsp;
                  </a>
                  is created for you to track your request.
                </>
                )}
              </div>
            );
            setShowMessage(true);
            setMessage(successMsg);
            setMessageClass('success');
            setRequest(defaultRequest());
            setLoaded(true);
            props.doSearch(true);
            props.onCancel(false);
          } else {
            props.doSearch(true);
            reload();
            props.onCancel(false);
          }
        })
        .catch((err) => {
          if (err.name !== 'AbortError') {
            console.error(err);
            setShowMessage(true);
            setMessageClass('light-danger');
            setRequest(defaultRequest());
            setLoaded(true);
          }
        });
    }
  };

  const accountNamingToolTip = (
    <span
      className="svg-icon svg-light ml-2 custom-tooltip-text"
      style={{ display: 'inline' }}
      onClick={handleOnInfoClick}
    >
      <svg stroke="currentColor" className="showModal" id="showModal" fill="#3699ff" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" d="M8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 108 0a8 8 0 000 16z" clipRule="evenodd" />
        <path d="M8.93 6.588l-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588z" />
        <circle cx="8" cy="4.5" r="1" />
      </svg>
      <div className="custom-tooltip-info showModalSection" id="showModalSection" style={isMobile ? styleTheme : {}}>
        <div ref={infoBlockRef} className="info-section">
          <div className="row">
            <div className="col-12">
              <h5 className="custom-tooltip-text custom-tooltip-font">
                As you have decided to change account name, please follow the below cloud usage rules:
                <div>
                  <strong>
                    &#123;rand / cogs&#125; - &#123;product&#125; - &#123;customer&#125; - &#123;env&#125;
                  </strong>
                </div>
                <ul className="pl-5">
                  <li>
                    <strong>
                      &#123;rand / cogs&#125;:&nbsp;
                    </strong>
                    cogs if account used for production environment. rand if otherwise.
                  </li>
                  <li>
                    <strong>
                      &#123;product&#125;:&nbsp;
                    </strong>
                    product name
                  </li>
                  <li>
                    <strong>
                      &#123;customer&#125;:&nbsp;
                    </strong>
                    customer this account is intended to
                  </li>
                  <li>
                    <strong>
                      &#123;env&#125;:&nbsp;
                    </strong>
                    environment this account is associated with i.e. prod, cte, dev, qa, test, corp
                  </li>
                </ul>
              </h5>
            </div>
            <div className="col-12">
              <h5 className="custom-tooltip-text custom-tooltip-font">
                <strong>Note: </strong>
                Once the account name is entered as per the correct format, the environment will be selected for you as per account name entered.
              </h5>
            </div>
          </div>
        </div>
      </div>
    </span>
  );

  return (
    <>
      <div
        className="modal fade reqNewAccount show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        style={{ display: 'block' }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header px-4">
              <h4 className="modal-title" id="exampleModalLabel">
                {title}
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => props.onCancel(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {loaded ? (
                <form className="form" noValidate>
                  <div className="row">
                    {showMessage ? (
                      <div className="col-12">
                        <Alert
                          message={message}
                          alertClass={messageClass}
                          onClose={() => setShowMessage(false)}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <Input
                          maxLength={255}
                          name="accountName"
                          value={request.accountName}
                          placeholder={request.environment !== '3' && request.environment !== '' ? '{product}-{customer}' : '{product}-{customer}-{environment}'}
                          title="Desired Account Name"
                          onChange={handleChange}
                          disableAutoComplete
                          required
                          onBlur={handleValidation}
                          errorText={validation.accountName}
                          tooltip={showNamingInfo ? accountNamingToolTip : ''}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <Input
                          maxLength={12}
                          name="accountNumber"
                          value={request.accountNumber}
                          placeholder="Enter 12 digit account number"
                          title="Account Number"
                          onChange={handleChange}
                          errorText={validation.accountNumber}
                          onBlur={handleValidation}
                          disabled={origAccNum?.length >= 12}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        { origEnv?.length > 1
                          ? (
                            <ApiCheckboxList
                              name="environment"
                              title="Environment(s)"
                              selectedItems={request.environment}
                              url="/api/lookup/environments"
                              onChange={handleChange}
                              required
                              errorText={validation.environment}
                              disabled={request.accountName !== origAcctName}
                            />
                          )
                          : (
                            <ApiRadioButtonList
                              name="environment"
                              title="Environment(s)"
                              selectedItems={request.environment}
                              url="/api/lookup/environments"
                              onChange={handleChange}
                              required
                              errorText={validation.environment}
                              disabled={request.accountName !== origAcctName}
                            />
                          )}
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <ApiCheckboxList
                          name="region"
                          title="Region(s)"
                          url="/api/lookup/regions"
                          selectedItems={request.region}
                          onChange={handleChange}
                          required
                          errorText={validation.region}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <ApiTypeAhead
                          name="ownerId"
                          defaultId={request.ownerId}
                          displayKey="ownerName"
                          query={request.ownerName}
                          title="Account Contact/Owner"
                          placeholder="Start typing to search"
                          url="/api/lookup/people"
                          onChange={handleChange}
                          required
                          errorText={validation.ownerId}
                          onBlur={handleValidation}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <Input
                          maxLength={255}
                          type="email"
                          name="teamEmail"
                          value={request.teamEmail}
                          placeholder="i.e. list-my-team@csgi.com"
                          title="Team Email"
                          onChange={handleChange}
                          required
                          errorText={validation.teamEmail}
                          onBlur={handleValidation}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <Input
                          maxLength={10}
                          type="text"
                          name="iTag"
                          value={request.iTag}
                          prepend={<span className="input-group-text">i-</span>}
                          placeholder="i.e. 12345"
                          title="iTag"
                          onChange={handleChange}
                          required
                          errorText={validation.iTag}
                          onBlur={handleValidation}
                          warning={validITag}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <label>
                        Product Code
                      </label>
                      <div className="form-group">
                        <Select
                          id="productCodes"
                          title="Product Codes"
                          name="productCodes"
                          value={productCodes.multiValue}
                          options={productCodes.filterOptions}
                          onChange={handleProductChange}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          placeholder="Select Product Codes"
                          isMulti
                        />
                        {
                          validation.multiValue === '' ? '' : <div className="danger-font">{validation.multiValue}</div>
                        }
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <Input
                          maxLength={255}
                          name="remedyGroup"
                          value={request.remedyGroup}
                          placeholder="i.e. WFX or ACPx"
                          title="Remedy Group"
                          onChange={handleChange}
                          errorText={validation.remedyGroup}
                          onBlur={handleValidation}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <ApiTypeAhead
                          name="managerId"
                          defaultId={request.managerId}
                          displayKey="managerName"
                          query={request.managerName?.trim()}
                          title="Account Manager/Director"
                          placeholder="Start typing to search"
                          url="/api/lookup/people"
                          onChange={handleChange}
                          required
                          errorText={validation.managerId}
                          onBlur={handleValidation}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <ApiTypeAhead
                          name="csgServiceOwnerId"
                          defaultId={request.csgServiceOwnerId}
                          displayKey="csgServiceOwnerName"
                          query={request.csgServiceOwnerName?.trim()}
                          title="CSG Service Owner"
                          placeholder="Start typing to search"
                          url="/api/lookup/people"
                          onChange={handleChange}
                          required
                          errorText={validation.csgServiceOwnerId}
                          onBlur={handleValidation}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <ApiTypeAhead
                          name="executiveLevelId"
                          defaultId={request.executiveLevelId}
                          displayKey="executiveLevelName"
                          query={request.executiveLevelName?.trim()}
                          title="Account Executive Level"
                          placeholder="Start typing to search"
                          url="/api/lookup/people"
                          onChange={handleChange}
                          required
                          errorText={validation.executiveLevelId}
                          onBlur={handleValidation}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <ApiTypeAhead
                          name="vpId"
                          defaultId={request.vpId}
                          displayKey="vpName"
                          query={request.vpName?.trim()}
                          title="Account VP/EVP"
                          placeholder="Start typing to search"
                          url="/api/lookup/people"
                          onChange={handleChange}
                          required
                          errorText={validation.vpId}
                          onBlur={handleValidation}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12">
                      <div className="form-group">
                        <RadioButtons
                          name="directConnectNeeded"
                          value={request.directConnectNeeded}
                          inline
                          options={{ true: 'Yes', false: 'No' }}
                          onChange={handleChange}
                          required
                          title="Needs direct connect to on-prem data center?"
                          errorText={validation.directConnectNeeded}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              ) : (
                <Loader />
              )}
            </div>
            <div className="modal-footer px-4">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={handleSubmit}
              >
                {label}
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => props.onCancel(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
        {validateLoader ? <Loader /> : ''}
      </div>
    </>
  );
};

EditAccountRequest.propTypes = {
  saveUrl: PropTypes.string.isRequired,
  saveVerb: PropTypes.oneOf(['POST', 'PUT']),
  label: PropTypes.string.isRequired,
  /* eslint-disable react/forbid-prop-types */
  id: PropTypes.any,
  user: PropTypes.shape({
    employeeId: PropTypes.number.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    isAdmin: PropTypes.bool.isRequired,
  }),
};

export default EditAccountRequest;
