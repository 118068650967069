import React from 'react';
import Chart from 'react-apexcharts';

const BarChart = (props) => {
  const date = new Date(props.data[0].Date).toLocaleDateString();
  const amzn = props.data[1].AMZN;
  const googl = props.data[2].GOOGL;
  const msft = props.data[3].MSFT;
  const options = {
    chart: {
      id: 'basic-bar',
      width: '100%',
    },
    xaxis: {
      categories: ['Microsoft', 'Google', 'Amazon'],
    },
    dataLabels: {
      formatter(val) {
        return `$${val}`;
      },
    },
    yaxis: {
      labels: {
        formatter(value) {
          if (value > 0) {
            value = `$${value}`;
          }
          return value;
        },
      },
    },
    tooltip: {
      y: {
        formatter(val) {
          return `$${val}`;
        },
      },
    },
    responsive: [
      {
        breakpoint: 767,
        options: {
          title: {
            style: {
              width: '500px',
              fontSize: '0px',
            },
          },
          dataLabels: {
            enabled: false,
          },
        },
      },
    ],
    theme: {
      mode: 'light',
      palette: 'palette1',
      monochrome: {
        enabled: false,
        color: '#255aee',
        shadeTo: 'light',
        shadeIntensity: 0.65,
      },
    },
  };
  const series = [
    {
      name: date,
      data: [msft, googl, amzn],
    },
  ];

  return <Chart options={options} series={series} type="bar" height="300" />;
};
export default BarChart;
