import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import axios from 'axios';
import Input from '../Common/Input';
import ApiTypeAhead from '../Common/ApiTypeAhead';
import MultiApiTypeAhead from '../Common/MultiApiTypeAhead';
import Loader from '../Loader';
import Alert from '../Common/Alert';

const SubscriptionAccessRequest = (props) => {
  const { label, title, user } = props;
  const { firstName, lastName, employeeId } = user || {};
  const today = new Date();
  const defaultRequest = () => ({
    subscriptionId: '',
    subscriptionName: '',
    subscriptionOwner: '',
    subscriptionOwnerName: '',
    requestedBy: user ? employeeId : '',
    requestedByName: user ? `${firstName} ${lastName}` : '',
    createdDate: new Date(),
    requestCompletionDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 3),
    classOfService: 'standard',
    expediteJustification: '',
    comments: '',
    contributors: [],
    readers: [],
  });

  const [createdDate, setCreatedDate] = useState(new Date());
  const [request, setRequest] = useState(defaultRequest());
  const [validation, setValidation] = useState({});
  const [loader, setLoader] = useState(false);
  const [showOwner, setShowOwner] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageClass, setMessageClass] = useState('info');
  const [message, setMessage] = useState('');
  const [contributorRole, setContributorRole] = useState([]);
  const [readerRole, setReaderRole] = useState([]);

  function bindSubscriptionDetails(name, value) {
    setShowOwner(false);
    setRequest({
      ...request,
      subscriptionId: '',
      subscriptionOwner: '',
      subscriptionOwnerName: '',
    });
    axios
      .get(`/api/azureSubscription/SearchSubs/${value}`)
      .then((response) => {
        if (response.data === '') {
          setValidation({
            ...validation,
            [name]: 'invalid subscription Id',
          });
        }
        setRequest({
          ...request,
          subscriptionId: response.data.id,
          subscriptionName: response.data.azureSubName,
          subscriptionOwner: response.data.ownerId,
          subscriptionOwnerName: response.data.ownerId !== 0 ? response.data.ownerName : '',
        });
      })
      .catch((err) => {
        console.log(err);
        setValidation({
          ...validation,
          [name]: 'invalid subscription Id',
        });
      });
  }

  useEffect(() => {
    axios
      .get(
        '/api/lookup/RoleTraining/contributor',
      )
      .then((response) => {
        const result = response.data;
        setContributorRole(result);
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          setMessage(message.err);
        }
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        '/api/lookup/RoleTraining/reader',
      )
      .then((response) => {
        const result = response.data;
        setReaderRole(result);
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          setMessage(message.err);
        }
      });
  }, [contributorRole]);

  useEffect(() => {
    if (request.subscriptionOwnerName !== ('') || request.subscriptionOwner === 0) {
      setShowOwner(true);
    }
  }, [request.subscriptionOwner]);

  const setRolesValidation = (value, role) => {
    if (role === 'contributor') {
      if (contributorRole.filter((e) => e === value).length === 0) { return (false); }
      return (true);
    }
    if (readerRole.filter((e) => e === value).length === 0) {
      return (false);
    }
    return (true);
  };

  const handleChange = (e) => {
    const { target } = e;
    const {
      name, value, training,
    } = target;
    if (name === 'subscriptionId') {
      bindSubscriptionDetails(name, value);
    }
    setRequest((ps) => {
      if (name === 'contributorId') {
        const contributors = [...request.contributors];
        if (contributors.filter((e) => e.id === value).length === 0) {
          contributors.push({ id: value, training });
          return { ...ps, contributors };
        }
      }

      if (name === 'readerId') {
        const readers = [...request.readers];
        if (readers.filter((e) => e.id === value).length === 0) {
          readers.push({ id: value, training });
          return { ...ps, readers };
        }
      }
      return {
        ...ps,
        [name]: value,
      };
    });

    if (name === 'classOfService') {
      const today = new Date();
      if (value === 'expedited') {
        setRequest({
          ...request,
          classOfService: value,
          requestCompletionDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1),
        });
      } else if (value === 'standard') {
        setRequest({
          ...request,
          classOfService: value,
          requestCompletionDate: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 3),
        });
      } else {
        setRequest({
          ...request,
          classOfService: value,
          requestCompletionDate: today,
        });
      }
      setValidation({
        ...validation,
        classOfService: value ? '' : 'Required *',
      });
    }
    if (name === 'contributorId' || name === 'readerId') {
      setValidation({
        ...validation,
        contributorOrReader: value ? '' : 'Required *',
      });
    }
  };

  const handleValidation = (e) => {
    const { target } = e;
    const {
      name, required, value,
    } = target;

    if (required === true) {
      if (name !== 'expediteJustification') {
        setValidation({
          ...validation,
          [name]: value ? '' : 'Required *',
        });
      }
    }
    if (name === 'contributorId' || name === 'readerId') {
      setValidation({
        ...validation,
        contributorOrReader: value ? '' : 'Required *',
      });
    }
  };

  const handleSubmit = () => {
    const validation = {
      subscriptionId: request.subscriptionId
        ? ''
        : 'Please provide Azure Subscription Id.',
      subscriptionName: request.subscriptionName
        ? ''
        : 'Please provide Azure Subscription Name.',
      requestedBy: request.requestedBy
        ? ''
        : 'Please provide the requested by.',
      subscriptionOwner: request.subscriptionOwner
        ? ''
        : 'Please provide Subscription Owner',
      contributorOrReader: request.contributors.length > 0 || request.readers.length > 0
        ? ''
        : 'Please provide Contributor Or Reader or Both',
      requestCompletionDate: request.requestCompletionDate
        ? ''
        : 'Please provide the requested completion date.',
      classOfService: request.classOfService
        ? ''
        : 'Please provide Class Of Service.',
    };

    setValidation(validation);
    let validErrorExist = false;

    Object.keys(validation).forEach((o) => {
      if (validation[o]) {
        validErrorExist = true;
      }
    });

    const method = 'post';
    setValidation(validation);
    setLoader(!validErrorExist);
    if (!validErrorExist) {
      const formatRequest = {
        ...request,
        createdDate: request.createdDate.toLocaleDateString(),
        requestCompletionDate: request.requestCompletionDate.toLocaleDateString(),
        ContributorRoleAssignment: request.contributors,
        ReaderRoleAssignment: request.readers,
      };
      axios({
        method,
        url: '/api/AzureSubscription/SaveSubscriptionAccessRequest',
        data: JSON.stringify(formatRequest),
        headers: { 'Content-Type': 'application/json' },
      })
        .then((response) => {
          if (response.data === 'success') {
            setShowMessage(true);
            setMessage('You have successfully raised request for subscription access. You will be notified once SR will generate.');
            setMessageClass('success');
            setRequest(defaultRequest());
          }
          if (response.data === 'failure') {
            setShowMessage(true);
            setMessage('Something went wrong!');
            setMessageClass('light-danger');
            setRequest(defaultRequest());
          }
        })
        .catch((err) => {
          if (err.name !== 'AbortError') {
            console.error(err);
            setShowMessage(true);
            setMessage('Something went wrong!');
            setMessageClass('light-danger');
            setRequest(defaultRequest());
          }
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  return (
    <>
      <div
        className="modal fade show"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
        style={{ display: 'block' }}
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header px-4">
              <h4 className="modal-title" id="exampleModalLabel">
                {title}
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => props.onCancel(false)}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {loader ? <Loader />
                : (
                  <form className="form" noValidate>
                    <div className="row">
                      {showMessage ? (
                        <div className="col-12">
                          <Alert
                            message={message}
                            alertClass={messageClass}
                            onClose={() => setShowMessage(false)}
                          />
                        </div>
                      ) : ''}
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="d-block">
                            Created Date
                            {' '}
                            <span className="text-danger">*</span>
                          </label>
                          <DatePicker
                            name="createdDate"
                            className="form-control"
                            placeholderText="Created Date"
                            onChange={(date) => { setCreatedDate(date); setRequest({ ...request, createdDate: date }); }}
                            selected={createdDate}
                            minDate={new Date()}
                            maxDate={new Date()}
                            required
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <ApiTypeAhead
                            name="requestedBy"
                            defaultId="requestedBy"
                            displayKey="requestedByName"
                            query={request.requestedByName}
                            title="Request By"
                            requiredSign="*"
                            placeholder="Requested By"
                            url="/api/lookup/people"
                            onChange={handleChange}
                            required
                            errorText={validation.requestedBy}
                            onBlur={handleValidation}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="d-block">
                            Request Completion Date
                            {' '}
                            <span className="text-danger">*</span>
                          </label>
                          <DatePicker
                            name="requestCompletionDate"
                            className="form-control"
                            placeholderText="Request Completion Date"
                            onChange={(date) => {
                              setRequest({
                                ...request,
                                requestCompletionDate: date,
                              });
                              setValidation({
                                ...validation,
                                requestCompletionDate: date === null ? 'Required *' : '',
                              });
                            }}
                            selected={request.requestCompletionDate}
                            required
                          />
                          <span className="danger-font font-size-sm">{validation.requestCompletionDate}</span>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label className="d-block">
                            Class of Service
                            {' '}
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            name="classOfService"
                            id={request.classOfService}
                            value={request.classOfService}
                            className="form-control"
                            placeholder="Class Of Service"
                            onChange={handleChange}
                            onBlur={handleValidation}
                          >
                            <option value="">Select Class of Service</option>
                            <option value="expedited">Expedited</option>
                            <option value="standard" defaultValue>Standard</option>
                          </select>
                          <span className="danger-font font-size-sm">{validation.classOfService}</span>
                        </div>
                      </div>
                      {request.classOfService === 'expedited' && (
                        <div className="col-sm-12">
                          <div className="form-group">
                            <Input
                              name="expediteJustification"
                              placeholder="Expedite Justification"
                              title="Expedite Justification"
                              requiredSign=" *"
                              onChange={handleChange}
                              value={request.expediteJustification}
                              required
                              errorText={validation.expediteJustification}
                              onBlur={handleValidation}
                            />
                          </div>
                        </div>
                      )}
                      <div className="col-sm-6">
                        <div className="form-group">
                          <Input
                            name="subscriptionId"
                            placeholder="Azure Subscription Id"
                            title="Azure Subscription Id"
                            requiredSign=" *"
                            onChange={handleChange}
                            required
                            value={request.subscriptionId}
                            errorText={validation.subscriptionId}
                            onBlur={handleValidation}
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <Input
                            maxLength={255}
                            name="subscriptionName"
                            placeholder="Azure Subscription Name"
                            title="Azure Subscription Name"
                            requiredSign=" *"
                            onChange={handleChange}
                            required
                            value={request.subscriptionName}
                            errorText={validation.subscriptionName}
                            onBlur={handleValidation}
                          />
                        </div>
                      </div>
                      {showOwner
                        && (
                          <div className="col-sm-6">
                            <div className="form-group">
                              <ApiTypeAhead
                                name="subscriptionOwner"
                                defaultId={request.subscriptionOwner}
                                displayKey="subscriptionOwnerName"
                                query={request.subscriptionOwnerName}
                                title="Azure Subscription owner"
                                value={request.subscriptionOwner}
                                placeholder="Azure Subscription Owner"
                                required
                                requiredSign="*"
                                url="/api/lookup/people"
                                onChange={handleChange}
                                errorText={validation.subscriptionOwner}
                                onBlur={handleValidation}
                              />
                            </div>
                          </div>
                        )}
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <MultiApiTypeAhead
                            name="contributorId"
                            defaultId="contributorName"
                            displayKey="contributorName"
                            query={request.contributors}
                            title="Contributor"
                            placeholder="Contributor"
                            url="/api/lookup/PeopleTraining"
                            onChange={handleChange}
                            errorText={validation.contributorOrReader}
                            validation={setRolesValidation}
                            onBlur={handleValidation}
                            roles="contributor"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <MultiApiTypeAhead
                            name="readerId"
                            defaultId="readerName"
                            displayKey="readerName"
                            query={request.readers.name}
                            title="Reader"
                            placeholder="Reader"
                            url="/api/lookup/PeopleTraining"
                            onChange={handleChange}
                            errorText={validation.contributorOrReader}
                            onBlur={handleValidation}
                            validation={setRolesValidation}
                            roles="reader"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <Input
                            maxLength={255}
                            name="comments"
                            placeholder="Additional Comments"
                            title="Additional Comments"
                            onChange={handleChange}
                            onBlur={handleValidation}
                            value={request.comments}
                            errorText={validation.comments}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                )}
            </div>
            <div className="modal-footer px-4">
              <button
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={handleSubmit}
              >
                {label}
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => props.onCancel(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionAccessRequest;
