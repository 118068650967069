import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPython, faNodeJs } from '@fortawesome/free-brands-svg-icons';
import Alert from '../../Common/Alert';
import { getNetworkCompute } from '../../../store/actions/virtualNetworkAction';
import {
  GET_VN_COMPUTE_SRVR,
  ERROR_VN_COMPUTE_SRVR,
} from '../../../store/types';
import Loader from '../../Loader';

const NetworkComputeServerless = (props) => {
  const { vnId, onClick, isOpen } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [showErrMsg, setShowErrMsg] = useState(false);

  const dispatch = useDispatch();
  const vnData = useSelector((state) => state.vnProfile);
  const { compServerlessLoading, errCompServerless, compServerless } = vnData;

  useEffect(() => {
    dispatch(
      getNetworkCompute(
        `/api/virtualnetwork/${vnId}/serverlesscompute`,
        GET_VN_COMPUTE_SRVR,
        ERROR_VN_COMPUTE_SRVR,
      ),
    );
  }, [dispatch]);

  useEffect(() => {
    if (errCompServerless !== '') {
      setErrorMessage(errCompServerless);
      setShowErrMsg(true);
    }
  }, [errCompServerless, isOpen]);

  const resourceMarkup = (list) => list.map((rs) => (
    <div className="div-row" key={rs.id}>
      <div className="div-column">{rs.name}</div>
      <div className="div-column">
        {rs.description ? rs.description : '-'}
      </div>
      <div className="div-column">{rs.handler}</div>
      <div className="div-column">
        {rs.runtime.startsWith('python') && (
          <FontAwesomeIcon icon={faPython} />
        )}
        {rs.runtime.startsWith('nodejs') && (
          <FontAwesomeIcon icon={faNodeJs} />
        )}
        {rs.runtime}
      </div>
    </div>
  ));

  return (
    <div className={`card border-0 ${isOpen ? 'card-active' : ''}`}>
      <div className="card-header card-header2" id="headingOne">
        <h5 className="mb-0">
          <button
            type="button"
            className={`btn btn-link ${isOpen ? '' : 'collapsed'}`}
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="false"
            aria-controls="collapseOne"
            onClick={() => onClick('serverless')}
          >
            Serverless Compute
          </button>
        </h5>
      </div>
      <div
        id="collapseOne"
        className={`collapse ${isOpen ? 'show' : ''}`}
        aria-labelledby="headingOne"
        data-parent="#accordion"
      >
        <div className="card-body px-0">
          {compServerlessLoading ? (
            <Loader />
          ) : showErrMsg ? (
            <div className="col-12">
              <Alert
                message={errorMessage}
                alertClass="light-danger"
                onClose={() => {
                  setShowErrMsg(false);
                  onClick('serverless');
                }}
              />
            </div>
          ) : (
            !errorMessage && (
              <>
                {compServerless.length === 0 ? (
                  <div className="col-12">
                    <Alert
                      message="No serverless compute found."
                      alertClass="info"
                    />
                  </div>
                ) : (
                  <div className="custom-table">
                    <div className="respnsive-custom-table">
                      <div className="div-table" width="100%">
                        <div className="div-thead">
                          <div className="div-row">
                            <div className="div-column-th">Name</div>
                            <div className="div-column-th">Description</div>
                            <div className="div-column-th">Handler</div>
                            <div className="div-column-th">Runtime</div>
                          </div>
                        </div>
                        <div className="div-tbody">
                          {resourceMarkup(compServerless)}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
};
export default NetworkComputeServerless;
