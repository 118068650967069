import React, { useEffect, useContext, useState } from 'react';
import axios from 'axios';
import { Context as MenuContext } from '../Context/MenuContext';
import Tab from '../Components/Common/Tab';
import Loader from '../Components/Loader';
import SubscriptionReqList from '../Components/AzureSubscription/SubscriptionReqList';

const SubscriptionRequests = () => {
  const [tab, setTab] = useState('newRequests');
  const [subList, setSubList] = useState([]);
  const [loader, setLoader] = useState(false);
  const tabList = [
    { value: 'newRequests', label: 'New Requests' },
    { value: 'approvedRequests', label: 'Approved Requests' },
    { value: 'rejectedRequests', label: 'Rejected Requests' },
  ];
  const [, setSelectedMenu, , setSelectedSubMenu] = useContext(MenuContext);
  const apiCall = () => {
    setLoader(true);
    setSelectedMenu('Azure');
    setSelectedSubMenu('subscriptionRequests');
    axios
      .get(
        '/api/azureSubscription/SearchAzureRequestsByApproverId',
      )
      .then((response) => {
        const result = response.data;
        setSubList(result);
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          console.log(err);
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };
  useEffect(() => {
    apiCall();
  }, []);
  function formatSubscriptionData(type) {
    const filterData = subList?.filter((i) => i.approvalStatus === type);
    if (filterData?.length > 0) {
      const formattedData = filterData
        .map((d) => ({
          Subscription: d.azureSubName,
          Owner: d.ownerName,
          requestor: d.requestorName,
          ITag: d.iTag,
          Id: d.id,
          Team_Email: d.teamEmail,
          bussinessJustification: d.businessJustification,
          createdOn: d.createdOn,
          environment: d.environment,
          isActive: d.isActive,
          tenantId: d.tenantId,
          tenant: d.tenant,
          managementGroup: d.managementGroup,
        }));
      return formattedData;
    }
    return [];
  }
  function requestTabs(tabName) {
    switch (tabName) {
      case 'newRequests':
        return (
          loader ? <Loader />
            : <SubscriptionReqList list={formatSubscriptionData('PENDING')} reload={apiCall} type="new" />
        );
      case 'approvedRequests':
        return (
          <SubscriptionReqList list={formatSubscriptionData('APPROVED')} reload={apiCall} type="approved" />
        );
      case 'rejectedRequests':
        return (
          <SubscriptionReqList list={formatSubscriptionData('DENIED')} reload={apiCall} type="rejected" />
        );
      default:
        return null;
    }
  }

  return (
    <>
      <div className="card card-custom mb-4">
        <div className="card-header align-items-center">
          <div className="card-title align-items-start flex-column">
            <h3 className="card-label">Azure Subscription Requests</h3>
          </div>
        </div>
        <div className="card-body p-0">
          <div className="horizontal-tab">
            <Tab
              onClick={(e) => setTab(e)}
              tabList={tabList}
              selected="newRequests"
            />
            <div className="tab-content">{requestTabs(tab)}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubscriptionRequests;
