import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from './Select';

const ApiDropdown = (props) => {
  const {
    name,
    title,
    value,
    placeholder,
    onChange,
    tabIndex,
    required,
    errorText,
    onBlur,
    hideLabel,
    url,
    disabled,
  } = props;
  const [options, setOptions] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  function serviceCall() {
    axios
      .get(url)
      .then((response) => {
        setOptions(response.data);
        setLoaded(true);
      })
      .catch((e) => {
        if (e.name !== 'AbortError') {
          console.error(e);
          setErrorMessage(e);
        }
        setLoaded(false);
      });
  }

  useEffect(() => {
    serviceCall();
  }, []);

  // Call when url props are changed.
  useEffect(() => {
    serviceCall();
  }, [url]);

  return (
    <div>
      {loaded === true ? (
        <Select
          name={name}
          title={title}
          value={value}
          placeholder={placeholder}
          options={options}
          onChange={onChange}
          tabIndex={tabIndex}
          hideLabel={hideLabel}
          required={required}
          disabled={disabled}
          errorText={errorMessage || errorText}
          onBlur={onBlur}
        />
      ) : (
        <div>Loading Data...</div>
      )}
    </div>
  );
};
export default ApiDropdown;
