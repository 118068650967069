import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import AccountList from '../Account/AccountList';

const AccessByRole = (props) => {
  const {
    isOpen, accountWithRole, subscriptionWithRole, onClick, env,
  } = props;
  const renderStarts = (number) => {
    const lis = [];
    for (let i = 0; i < number; i++) {
      lis.push(<FontAwesomeIcon key={i} icon={faStar} />);
    }
    return lis;
  };

  function handleClick(value) {
    onClick(value);
  }

  function mapSubscriptionList(key, mapper) {
    const list = subscriptionWithRole;
    const ret = {};
    list.forEach((i) => {
      const index = i[key];
      ret[index] = mapper(i, ret[index]);
    });
    return ret;
  }

  function mapAccountList(key, mapper) {
    const list = accountWithRole;
    const ret = {};
    list.forEach((i) => {
      const index = i[key];
      ret[index] = mapper(i, ret[index]);
    });
    return ret;
  }

  function mapRole() {
    const key = 'roleCode';
    const awsData = mapAccountList(key, (i, current) => {
      if (!current) {
        current = {
          accounts: [],
          code: i[key],
          name: i.roleName || i[key],
          minimumTrainingLevel: i.roleMinimumTrainingLevel,
          minimumTrainingLevelRank: i.roleMinimumTrainingLevelRank,
          maxTrainingLevelAchieved: i.maxTrainingLevelAchieved,
          maxTrainingLevelRankAchieved: i.maxTrainingLevelRankAchieved,
        };
      }
      current.accounts.push({
        name: i.accountName,
        accountNumber: i.accountNumber,
      });
      return current;
    });
    const azureData = (mapSubscriptionList(key, (i, current) => {
      if (!current) {
        current = {
          subscription: [],
          code: i[key],
          name: i.roleName || i[key],
          minimumTrainingLevel: i.roleMinimumTrainingLevel,
          minimumTrainingLevelRank: i.roleMinimumTrainingLevelRank,
          maxTrainingLevelAchieved: i.maxTrainingLevelAchieved,
          maxTrainingLevelRankAchieved: i.maxTrainingLevelRankAchieved,
        };
      }
      current.subscription.push({
        subscriptionName: i.subscriptionName,
        subscriptionId: i.subscriptionId,
      });
      return current;
    }));
    return env === 'aws' ? awsData : azureData;
  }

  function byRoleListTable() {
    const byRole = mapRole();
    if (Object.keys(byRole).length > 0) {
      return Object.keys(byRole).map((roleCode) => {
        const role = byRole[roleCode];
        const { accounts } = role;
        const { subscription } = role;
        const achieved = role.maxTrainingLevelRankAchieved >= role.minimumTrainingLevelRank;
        const color = achieved ? 'success' : 'danger';
        const achievedColor = achieved ? 'warning' : 'white';

        return (
          <div className="accounts-role" key={role.code}>
            <div className="accounts-role-header">
              <div className="d-flex align-items-center justify-content-between">
                <div className="m-0 font-size-md font-weight-500 text-nowrap">
                  {role.name}
                  {' '}
                </div>
                <div className={`badge badge-${color} accounts-role-rank`}>
                  <span className="d-flex align-items-center font-size-lg font-weight-500 mr-2 text-nowrap text-white">
                    {role.minimumTrainingLevel}
                    <span
                      className={`star-${achievedColor} d-flex align-items-center text-secondary font-size-sm ml-2`}
                    >
                      {renderStarts(role.minimumTrainingLevelRank)}
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div className="accounts-role-body">
              {env === 'aws'
                ? (accounts.map((item) => (
                  <div key={item.accountNumber}>
                    <AccountList
                      acctName={item.name}
                      acctNum={item.accountNumber}
                      link={`/accounts/${item.accountNumber}`}
                    />
                  </div>
                )))
                : (subscription.map((item) => (
                  <div key={item.subscriptionId}>
                    <AccountList
                      acctName={item.subscriptionName}
                      acctNum={item.subscriptionId}
                      link={`/subscriptions/${item.subscriptionId}`}
                    />
                  </div>
                )))}
            </div>
          </div>
        );
      });
    }
    return '';
  }

  return (
    <div className={`card border-0 ${isOpen ? 'card-active' : ''}`}>
      <div className="card-header card-header2" id="headingOne">
        <h5 className="mb-0">
          <button
            type="button"
            className={`btn btn-link ${isOpen ? '' : 'collapsed'}`}
            data-toggle="collapse"
            data-target="#collapseOne"
            aria-expanded="false"
            aria-controls="collapseOne"
            onClick={() => handleClick('byRole')}
          >
            By Role
          </button>
        </h5>
      </div>
      <div
        id="collapseOne"
        className={`collapse ${isOpen ? 'show' : ''}`}
        aria-labelledby="headingOne"
        data-parent="#accordion"
      >
        <div className="card-body px-0">
          <div className="role-section-tab">{byRoleListTable()}</div>
        </div>
      </div>
    </div>
  );
};

AccessByRole.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  accountWithRole: PropTypes.instanceOf(Array).isRequired,
  subscriptionWithRole: PropTypes.instanceOf(Array).isRequired,
  onClick: PropTypes.func.isRequired,
};

export default AccessByRole;
