import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Loader from '../Components/Loader';
import Header from '../Components/SRCustomerDashboard/Header';
import Tab from '../Components/Common/Tab';
import Alert from '../Components/Common/Alert';
import { Context as MnContext } from '../Context/MenuContext';
import JiraSRDetails from '../Components/SRCustomerDashboard/Display';

const SRCustomerDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [jiraSRList, setJiraSRList] = useState([]);
  const [requestType, setRequestType] = useState('accountAccess');
  const [showError, setShowError] = useState(false);

  const tabList = [
    { value: 'accountAccess', label: 'Account Access Request' },
    { value: 'accountCreation', label: 'Account Creation Request' },
  ];
  // Get selectedMenu data from Menu Context
  const [, setSelectedMenu] = useContext(MnContext);

  function fetchJiraSR() {
    setLoading(true);
    axios
      .get(`/api/JiraSRDashboard/GetCustomerJiraSR?requestType=${requestType}`)
      .then((response) => {
        setJiraSRList(response.data);
        setLoading(false);
      })
      .catch((e) => {
        if (e.name !== 'AbortError') {
          setError(e.message);
          setShowError(true);
        }
        setLoading(false);
      });
  }

  useEffect(() => {
    setSelectedMenu('srdashboard');
    fetchJiraSR();
  }, []);

  useEffect(() => {
    fetchJiraSR();
  }, [requestType]);

  return (
    <>
      <div className="row gutter-b">
        <div className="col-12">
          <div className="card card-custom gutter-b">
            <Header />
            <div className="card-body p-0">
              <div className="row">
                <div className="col-12">
                  <div className="horizontal-tab">
                    <Tab
                      onClick={(e) => {
                        setRequestType(e);
                      }}
                      tabList={tabList}
                      selected="accountAccess"
                    />
                    {loading ? (
                      <Loader />
                    ) : (
                      <div className="tab-content">
                        {showError ? (
                          <Alert
                            message={error}
                            alertClass="light-danger"
                            onClose={() => setShowError(false)}
                          />
                        ) : (
                          !error && (
                            <JiraSRDetails jiraSRList={jiraSRList} requestType={requestType} />
                          )
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SRCustomerDashboard;
