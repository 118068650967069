import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';

const CloudabilityData = (props) => {
  const {
    accountId, data, onClick, isOpen,
  } = props;
  const [tableData, setTableData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const columns = ['Name', 'Family', 'Last Month', 'MTD', 'Estimated'];

  const onChangeRowsPerPage = (rowsPerPagee) => {
    setRowsPerPage(rowsPerPagee);
  };

  const options = {
    filter: true,
    filterType: 'dropdown',
    rowsPerPage,
    rowsPerPageOptions: [5, 10],
    onChangeRowsPerPage,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    downloadOptions: { filename: `${accountId}CostData.csv` },
    responsive: 'standard',
  };

  const formatCost = (val) => `$${(Math.round(val * 100) / 100).toLocaleString()}`;

  useEffect(() => {
    if (data) {
      const formattedData = data
        .sort((a, b) => (a.serviceName > b.serviceName ? 1 : 0))
        .map((d) => ({
          serviceName: d.serviceName,
          usageFamily: d.usageFamily,
          previousMonthSpend: formatCost(d.previousMonthSpend),
          mtdSpend: formatCost(d.mtdSpend),
          estimatedSpend: formatCost(d.estimatedSpend),
        }));
      setTableData(formattedData.map(Object.values));
    }
  }, [data]);

  return (
    <>
      <div className={`card border-0 ${isOpen ? 'card-active' : ''}`}>
        <div className="card-header card-header2" id="headingOne">
          <h5 className="mb-0">
            <button
              type="button"
              className={`btn btn-link ${isOpen ? '' : 'collapsed'}`}
              data-toggle="collapse"
              data-target="#collapseFour"
              aria-expanded="false"
              aria-controls="collapseFour"
              onClick={() => onClick('data')}
            >
              Cloudability cost data
            </button>
          </h5>
        </div>
        <div
          id="collapseFour"
          className={`collapse ${isOpen ? 'show' : ''}`}
          aria-labelledby="headingOne"
          data-parent="#accordion"
        >
          <div className="card-body px-0 cost-alingment-table">
            <MUIDataTable
              data={tableData}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CloudabilityData;
