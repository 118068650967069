import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Alert from '../../Common/Alert';
import AccessByRole from './AccessByRole';
import Loader from '../../Loader';

const SubscriptionUserTab = (props) => {
  const [loader, setLoader] = useState(true);
  const [activeByRole, setActiveByRole] = useState(true);
  const [activeByUsers, setActiveByUsers] = useState(false);
  const [accountWithRole, setAccountWithRole] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrMsg, setShowErrMsg] = useState(false);

  const getApiCall = () => {
    setLoader(true);
    axios.get(`/api/azureSubscription/${props.subscriptionId}/Users`)
      .then((response) => {
        setAccountWithRole(response.data);
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          console.log(err.message);
          setErrorMessage(err.message);
          setShowErrMsg(true);
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getApiCall();
  }, []);

  function collapseSection(button) {
    switch (button) {
      case 'byRole':
        setActiveByRole(!activeByRole);
        break;
      case 'byUsers':
        setActiveByUsers(!activeByUsers);
        setActiveByRole(false);
        break;
      default:
        return '';
    }
    return '';
  }

  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <>
          {showErrMsg ? (
            <div className="col-12">
              <Alert
                message={errorMessage}
                alertClass="light-danger"
                onClose={() => setShowErrMsg(false)}
              />
            </div>
          ) : (
            !errorMessage && (
              <>
                {accountWithRole.length === 0 ? (
                  <div className="col-12">
                    <Alert
                      message="Account contains no users info"
                      alertClass="info"
                    />
                  </div>
                ) : (
                  <div className="tab-pane active" id="messages-v">
                    <div className="accordion" id="accordion">
                      <AccessByRole
                        isOpen={activeByRole}
                        accountWithRole={accountWithRole}
                        onClick={(e) => collapseSection(e)}
                      />
                    </div>
                  </div>
                )}
              </>
            )
          )}
        </>
      )}
    </div>
  );
};

export default SubscriptionUserTab;
