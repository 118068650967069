import axios from 'axios';
import {
  GET_POST_EVENTS_DATA,
  ERROR_POST_EVENTS_DATA,
  GET_STOCK_WIDGETS_DATA,
  ERROR_STOCK_WIDGETS_DATA,
  GET_HEALTH_EVENTS_DATA,
  ERROR_HEALTH_EVENTS_DATA,
  GET_REGION_SERVICES_DATA,
  ERROR_REGION_SERVICES_DATA,
  GET_UNAVAILABLE_SERVICES_DATA,
  ERROR_UNAVAILABLE_SERVICES_DATA,
} from '../types';

export const getPostEventSummries = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_POST_EVENTS_DATA,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_POST_EVENTS_DATA,
      payload: error,
    });
  }
};
export const getStockWidgets = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_STOCK_WIDGETS_DATA,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_STOCK_WIDGETS_DATA,
      payload: error,
    });
  }
};
export const getHealthEvents = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_HEALTH_EVENTS_DATA,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_HEALTH_EVENTS_DATA,
      payload: error,
    });
  }
};
export const getRegionService = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_REGION_SERVICES_DATA,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_REGION_SERVICES_DATA,
      payload: error,
    });
  }
};
export const getUnavailableServices = (apiUrl) => async (dispatch) => {
  try {
    const res = await axios.get(apiUrl);
    dispatch({
      type: GET_UNAVAILABLE_SERVICES_DATA,
      payload: res.data,
    });
  } catch (error) {
    dispatch({
      type: ERROR_UNAVAILABLE_SERVICES_DATA,
      payload: error,
    });
  }
};
