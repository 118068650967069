import React, { useState, useEffect } from 'react';
import './NetworkWidget.css';
import { Link } from '@reach/router';
import axios from 'axios';
import NetworkTable from './NetworkTable';
import Alert from '../Common/Alert';
import noData from '../../Images/no-data.png';

const NetworkCard = ({ user }) => {
  const [errorMessageNetwork, setErrorMessageNetwork] = useState('');
  const [showNetworkErr, setShowNwError] = useState(false);
  const [networkDataList, setNetworkDataList] = useState({});
  const userFullName = user && `${user.firstName} ${user.lastName}`;

  function getNetworkDataList() {
    axios
      .get(`/api/virtualnetwork/search?t=Owner&q=${userFullName}`)
      .then((response) => {
        // Get Network List that user owns
        if (response.data.items.length > 0) {
          setNetworkDataList(response.data.items);
        }
      })
      .catch((e) => {
        if (e.name !== 'AbortError') {
          setErrorMessageNetwork(`Network Widget: ${e.message}`);
          setShowNwError(true);
        }
      });
  }

  useEffect(() => {
    getNetworkDataList();
  }, []);
  return (
    <>
      {showNetworkErr ? (
        <div className="col-12 gutter-b">
          <Alert
            message={errorMessageNetwork}
            alertClass="light-danger"
            onClose={() => setShowNwError(false)}
          />
        </div>
      ) : (
        !errorMessageNetwork && (
          <div className="col-lg-12">
            <div className="card card-custom gutter-b h-100">
              <div className="card-header align-items-center border-0 mt-4">
                <div className="card-title align-items-start flex-column">
                  <h3 className="card-label">Networks</h3>
                  {networkDataList.length > 0 && (
                    <span className="text-muted mt-2 font-weight-500 font-size-sm">
                      The networks you own (
                      {networkDataList.length}
                      ). The networks you own (
                      {networkDataList.length}
                      ). To view more
                      <Link to={`/virtualnetworks?t=Owner&q=${userFullName}`}>
                        {' '}
                        click here.
                      </Link>
                    </span>
                  )}
                </div>
              </div>
              {networkDataList.length > 0 ? (
                <NetworkTable networkDataList={networkDataList} />
              ) : (
                <div className="card-body card-empty">
                  <img
                    src={noData}
                    className="img-fluid d-block m-auto w-50-"
                    width="200"
                    alt=""
                  />
                  <p className="text-center mt-10 font-size-lg font-weight-normal text-muted">
                    You don&apos;t have any AWS Health Event List available
                  </p>
                </div>
              )}
            </div>
          </div>
        )
      )}
    </>
  );
};

export default NetworkCard;
