import React, { useState } from 'react';

const initialState = {
  regionServices: [],
  regions: [],
  services: [],
  filteredRegions: [],
  filteredServices: [],
  selectedRegion: '',
  selectedService: '',
  displayMode: 'all',
  availableList: [],
  unavailableList: [],
  tempUnavailableList: [],
  loadedResult: false,
};

export const Context = React.createContext();

export const VendorServiceContext = ({ children }) => {
  const [serviceFilter, setServiceFilter] = useState(initialState);
  return (
    <Context.Provider value={[serviceFilter, setServiceFilter]}>
      {children}
    </Context.Provider>
  );
};
