import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import Alert from '../Components/Common/Alert';
import Loader from '../Components/Loader';
import Tab from '../Components/Common/Tab';
import SubscriptionInfo from '../Components/AzureSubscription/InfoTab';
import { Context as MenuContext } from '../Context/MenuContext';
import SubscriptionUserTab from '../Components/AzureSubscription/UserTab';
import SubscriptionComplianceTab from '../Components/AzureSubscription/ComplianceTab';

const SubscriptionProfile = (props) => {
  const [tab, setTab] = useState('subscriptionInfo');
  const tabList = [
    { value: 'subscriptionInfo', label: 'Subscription Info' },
    { value: 'users', label: 'User Info' },
    { value: 'compliance', label: 'Compliance' },
  ];
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Get selectedMenu data from Menu Context
  const [, setSelectedMenu] = useContext(MenuContext);

  // Change menu css with peope page is loaded.
  useEffect(() => {
    setSelectedMenu('subscriptions');
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `/api/azuresubscription/SearchSubs/${props.subscriptionId}`,
      )
      .then((response) => {
        setSubscriptionData(response.data);
        setLoading(false);
      })
      .catch((err) => {
        if (err.name !== 'AbortError') {
          setErrorMessage(err.message);
          setLoading(false);
        }
      });
  }, []);

  function subscriptionDetailTab(tabName) {
    switch (tabName) {
      case 'subscriptionInfo':
        return <SubscriptionInfo data={subscriptionData} />;
      case 'users':
        return <SubscriptionUserTab subscriptionId={props.subscriptionId} />;
      case 'compliance':
        return <SubscriptionComplianceTab id={props.subscriptionId} />;
      default:
        return '';
    }
  }
  return (
    <div className="row gutter-b">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="col-12">
            <div className="card card-custom gutter-b">
              <div className="card-header align-items-center">
                <div className="card-title align-items-start flex-column">
                  <h3 className="card-label">
                    {Object.keys(subscriptionData).length > 0
                      ? subscriptionData.azureSubName
                      : 'Subscription Specific Page'}
                  </h3>
                </div>
              </div>
              {errorMessage ? (
                <div className="card-body">
                  <Alert
                    message={errorMessage}
                    alertClass="light-danger"
                    onClose={(val) => setErrorMessage(val)}
                  />
                </div>
              ) : (
                <>

                  <div className="card-body p-0">
                    <div className="row">
                      <div className="col-12">
                        <div className="horizontal-tab">
                          <Tab
                            onClick={(e) => setTab(e)}
                            tabList={tabList}
                            selected="subscriptionInfo"
                          />
                          <div className="tab-content">
                            {subscriptionDetailTab(tab)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SubscriptionProfile;
