import {
  GET_USER_PROFILE,
  ERROR_USER_PROFILE,
  GET_USER_TRAINING,
  ERROR_USER_TRAINING,
  GET_ACCOUNT_WITH_ROLE,
  ERROR_ACCOUNT_WITH_ROLE,
  SAVE_USER_PAGE_VISIT_HISTORY,
  ERROR_USER_PAGE_VISIT_HISTORY,
} from '../types';

const initialState = {
  error: '',
  errTraining: '',
  userDetails: {},
  userTraining: [],
  accountWithRole: [],
  loading: true,
  userDetailsLoading: true,
  userTrainingLoading: true,
  accountWithRoleLoading: true,
  saveUserVisitHistory: '',
};

/* eslint-disable func-names */
export default function (state = initialState, action) {
  /* eslint-enable */
  switch (action.type) {
    case GET_USER_PROFILE:
      return {
        ...state,
        userDetails: action.payload,
        userDetailsLoading: false,
      };
    case ERROR_USER_PROFILE:
      return {
        loading: false,
        error: action.payload.message,
      };
    case GET_USER_TRAINING:
      return {
        ...state,
        userTraining: action.payload,
        userTrainingLoading: false,
      };
    case ERROR_USER_TRAINING:
      return {
        ...state,
        errTraining: action.payload.message,
        userTrainingLoading: false,
      };
    case GET_ACCOUNT_WITH_ROLE:
      return {
        ...state,
        accountWithRole: action.payload,
        accountWithRoleLoading: false,
      };
    case ERROR_ACCOUNT_WITH_ROLE:
      return {
        ...state,
        error: action.payload.message,
        accountWithRoleLoading: false,
      };
    case SAVE_USER_PAGE_VISIT_HISTORY:
      return {
        ...state,
        saveUserVisitHistory: action.payload,
      };
    case ERROR_USER_PAGE_VISIT_HISTORY:
      return {
        ...state,
        error: action.payload.message,
      };
    default:
      return state;
  }
}
