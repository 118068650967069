import {
  UPDATE_USER_ANNOUNCEMENT_MAAPING,
  ERROR_UPDATE_USER_ANNOUNCEMENT_MAAPING,
} from '../types';

const initialState = {
  updateUserAnnouncement: '',
  updateUserAnnouncementLoading: true,
  errorUpdateUserAnnouncement: '',
};

/* eslint-disable func-names */
export default function (state = initialState, action) {
  /* eslint-enable */
  switch (action.type) {
    case UPDATE_USER_ANNOUNCEMENT_MAAPING:
      return {
        ...state,
        updateUserAnnouncement: action.payload,
        updateUserAnnouncementLoading: false,
      };
    case ERROR_UPDATE_USER_ANNOUNCEMENT_MAAPING:
      return {
        ...state,
        updateUserAnnouncementLoading: false,
        errorUpdateUserAnnouncement: action.payload.message,
      };
    default: return state;
  }
}
