import React from 'react';

const HealthEventModalPopUp = (props) => {
  const { healthEventData, onClose } = props;
  return (
    <div
      className="modal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      style={{ display: 'block' }}
    >
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          <div className="modal-header px-4">
            <h4 className="modal-title" id="exampleModalLabel">
              Health Event Details
            </h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => onClose(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div className="modal-body">
            <p>
              <b>Event Code:</b>
              {' '}
              {healthEventData.eventTypeCode}
            </p>
            <p>
              <b>Region: </b>
              {healthEventData.region}
            </p>
            <p>
              <b>Start Time: </b>
              {healthEventData.startTime}
            </p>
            <p>
              <b>End Time: </b>
              <span className={`${healthEventData.endTime === undefined ? 'ml-2' : ''}`}>
                {' '}
                {healthEventData.endTime === undefined ? '-' : healthEventData.endTime}
              </span>
            </p>
            <p>{healthEventData.eventDescription}</p>
          </div>
          <div className="modal-footer px-4">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => onClose(false)}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HealthEventModalPopUp;
