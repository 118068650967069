import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import HeaderWithInfo from '../Components/Common/HeaderWithInfo';
import Summaries from '../Components/Vendor/PostEventSummaries/Summaries';
import { Context } from '../Context/MenuContext';
import { Context as PageContext } from '../Context/PageContext';
import { saveUserPageVisitHistory } from '../store/actions/peopleAction';

const PostEventSummaries = (props) => {
  // Get selectedMenu data from Menu Context
  const [, setSelectedMenu, , setSelectedSubMenu] = useContext(Context);
  const [pageContextState, setPageContextState] = useContext(PageContext);
  const { postEventSummariesPage } = pageContextState;
  const dispatch = useDispatch();
  const { user } = props;
  // Change menu css with peope page is loaded.
  useEffect(() => {
    setSelectedMenu('vendor');
    setSelectedSubMenu('postEventSummaries');
  }, []);

  // Get user login details
  useEffect(() => {
    if (postEventSummariesPage === '') {
      setPageContextState({
        ...pageContextState,
        postEventSummariesPage: 'postEventSummaries',
      });
      const apiUrl = `/api/Auth/SaveUserPageVisit/${user.employeeId}/postEventSummaries`;
      dispatch(saveUserPageVisitHistory(apiUrl));
    }
  }, [postEventSummariesPage]);

  return (
    <div className="row mb-2">
      <div className="col-12">
        <div className="card card-custom mb-4">
          <div className="card-header align-items-center">
            <div className="card-title">
              <HeaderWithInfo headerName="AWS Post-Event Summaries" />
            </div>
          </div>
          <div className="card-body">
            <Summaries />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostEventSummaries;
