import React, { useState } from 'react';
import { Link } from '@reach/router';
import { FaEdit, FaChevronDown } from 'react-icons/fa';

const AccountResult = ({
  account, onScroll, user, beginEdit,
}) => {
  const [active, setActive] = useState(null);

  // for score badge color
  const getColor = (percent) => {
    if (percent < 77) {
      return 'danger';
    }
    if (percent < 85) {
      return 'warning';
    }
    if (percent < 93) {
      return 'info';
    }
    return 'success';
  };

  return (
    <>
      {account.length > 0 && (
        <div className="card card-custom mb-4">
          <div className="card-body">
            <div className="row">
              <div className="col-md-12 col-12">
                <div className="FilteredResult" onScroll={onScroll}>
                  {account.length > 0
                    && account.map((data) => (
                      <Link
                        key={data.number}
                        to={data.number}
                        onMouseOver={() => setActive(data)}
                        onMouseLeave={() => setActive(null)}
                        className="FilteredResultList position-relative border mb-3 "
                      >
                        <div className="row">
                          <div className="col-md-4 col-sm-4 col-8">
                            <div className="d-flex flex-column align-items-start">
                              <span className="text-dark text-hover-primary font-size-lg font-weight-500">
                                <span className="text-primary mr-1 font-size-lg">
                                  {data.name}
                                  <br />
                                  <span
                                    className="text-dark"
                                    title={
                                      data.suspended === true ? 'suspended' : ''
                                    }
                                    style={{
                                      textDecoration:
                                        data.suspended === true
                                          ? 'line-through'
                                          : 'none',
                                    }}
                                  >
                                    {data.number}
                                  </span>
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="col-md-5 col-sm-5 col-8 ml-auto">
                            <div className="account-cost-section">
                              <span
                                className="text-dark text-hover-primary font-size-lg font-weight-500"
                                title="Account Contact / Owner"
                              >
                                <span>{data.owner}</span>
                              </span>
                              <div
                                className="font-weight-normal text-right"
                                title="Executive / Director over account"
                              >
                                <span className="text-inverse">
                                  {data.director}
                                  -
                                  {data.directorTitle}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-1 col-sm-1 col-8">
                            <div className="account-cost-section align-items-center">
                              <div
                                className="d-flex justify-content-between align-items-center"
                                title="Last Month's Cost"
                              >
                                <span className="text-success font-weight-500 text-hover-primary">
                                  {data.cost}
                                </span>
                              </div>
                              <span
                                className={`badge badge-pill badge-${getColor(
                                  data.cloudUsageFrameworkScore.score,
                                )} font-size-sm`}
                                title="Cloud Usage Framework Compliance"
                              >
                                {data.cloudUsageFrameworkScore.score}
                                %
                              </span>
                            </div>
                          </div>
                          {user.employeeId === data.ownerId
                            ? (
                              <div className="col-md-1 col-sm-1 col-8 text-center">
                                <button
                                  type="button"
                                  className="btn btn-link text-edit"
                                  title="Edit"
                                  onClick={(e) => beginEdit(e, data.accountRequestId, data.number, data.name)}
                                >
                                  <FaEdit />
                                </button>
                              </div>
                            ) : ''}
                          <div className="col-md-1 col-sm-1 col-8  d-flex align-items-center justify-content-center">
                            <FaChevronDown />
                          </div>
                        </div>
                        <div id="collapseOne" className={`collapse mt-3 pt-3 border-top ${active === data ? 'show' : ''}`} aria-labelledby="headingOne" data-parent="#accordion">
                          <div to={data.number} className="row">
                            <div className="col-md-4">
                              <strong>Product</strong>
                              {' '}
                              -
                              {' '}
                              {data.productCode}
                            </div>
                            <div className="col-md-4">
                              <strong>Subfamily</strong>
                              {' '}
                              -
                              {data.subfamily}
                            </div>
                            <div className="col-md-4">
                              <strong>Family</strong>
                              {' '}
                              -
                              {' '}
                              {data.family}
                            </div>

                          </div>
                        </div>
                      </Link>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AccountResult;
